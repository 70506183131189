@value colorGrey100, colorDark100 from '~Styles/vars/colors.css';

.root {
  display: inline-flex;
  box-sizing: border-box;
  position: relative;
  align-items: center;
  justify-content: center;

  .activeIcon {
    display: flex;

    &.customListWhiteFilled {
      overflow: hidden;

      img {
        height: calc(100% + 4px);
        width: calc(100% + 4px);
        top: -2px;
        left: -2px;
      }
    }
  }

  .turnOffIcon {
    display: flex;

    &.customListGray {
      color: colorGrey100;
    }

    &.customListDark {
      color: colorDark100;
    }
  }
}
