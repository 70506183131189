@value (
  colorWetAsphalt,
  colorBase,
  colorGrayDog,
  colorWhite,
  colorRed,
  colorGreen,
  colorYellow,
  colorGrey50,
  colorOlive,
  colorOrange,
  colorGrey50,
  colorDark100,
  colorGrey50,
  colorGrey100
) from '~Styles/vars/colors.css';

.color-black {
  svg path {
    fill: colorWetAsphalt;
  }
}

.color-base {
  svg path {
    fill: colorBase;
  }
}

.color-white {
  svg path {
    fill: colorWhite;
  }
}

.color-grayDog {
  svg path {
    fill: colorGrayDog !important;
  }
}

.color-alto {
  svg path {
    fill: colorGrey50;
  }
}

.color-red {
  svg path {
    fill: colorRed !important;
  }
}

.color-green {
  svg path {
    fill: colorGreen;
  }
}

.color-yellow {
  svg path {
    fill: colorYellow;
  }
}

.color-orange {
  svg path {
    fill: colorOrange;
  }
}

.color-olive {
  svg path {
    fill: colorOlive;
  }
}

.color-dark100 {
  svg path {
    fill: colorDark100;
  }
}

.color-grey50 {
  svg path {
    fill: colorGrey50;
  }
}

.color-grey100 {
  svg path {
    fill: colorGrey100;
  }
}

.withBg {
  padding: 10px;
  background-color: colorGrey50;
  border-radius: 8px;
}

.root {
  height: 24px;

  svg path {
    transition: fill 0.1s ease-in;
  }
}

.size-md {
  height: 40px;

  svg {
    size: 40px;
  }
}

.size-sm {
  height: 16px;

  svg {
    size: 16px;
  }
}

.hasHover {
  svg path {
    fill: colorWetAsphalt;
  }
}