@value colorGrey100 from '~Styles/vars/colors.css';

.root {
  display: inline-grid;
  grid-template-columns: 24px max-content;
  grid-gap: 16px;
  align-items: center;

  .text {

    .subtitle {
      font-size: 12px;
      line-height: 16px;
      color: colorGrey100;
    }

  }
}
