@value colorConcrete from '~Styles/vars/colors.css';

.root {
  display: flex;
  flex-wrap: wrap;
  font-size: 0;
  position: relative;
}

.root::before {
  content: "";
  display: block;
  padding-top: 100%;
}

.loading {
  width: 100%;
  height: 0;
  padding-bottom: 100%;
  background-color: colorConcrete;
}

.root {
  display: block;
}

.medias {
  width: 100%;
  height: 0;
  padding-bottom: 100%;
  border-radius: 4px 4px 0 0;
  overflow: hidden;
  font-size: 0;
}

.slider {
  .media {
    width: 340px;
  }
}

.media {
  width: 33.33%;
  position: relative;
  background-color: colorConcrete;
  display: inline-block;
  background-size: cover;
  background-position: center;
}


.imageStyle {
  height: 0;
  padding-bottom: 100%;
}

.youtubeMedia {
  width: 100%;
  position: relative;
  background-color: colorConcrete;
  display: inline-block;
  background-size: cover;
  background-position: center;
}

.media::before {
  content: "";
  display: block;
}

.stub {
  size: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  background: linear-gradient(360deg, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0) 50%);
}
