@value (colorDark100, colorGrey50) from '~Styles/vars/colors.css';

@keyframes open {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.shrinkTitle {
  grid-template-columns: 0.5fr 0fr 1fr !important;
}

.root {
  display: grid;
  grid-template-columns: 1fr;

  details {
    margin: 0;

    &:not(:last-child):not(.withoutSeperaton)::after {
      display: block;
      content: '';
      height: 0;
      width: 100%;
      border-bottom: 1px solid colorGrey50;
    }

    summary {
      padding: 16px 0;
      cursor: pointer;
      &::marker {
        display: none;
        content: '';
      }
    }

    .title {
      display: grid;
      grid-template-columns: 1fr 36px;
      align-items: center;
      color: colorDark100;
      font-size: 20px;
      line-height: 24px;
      font-weight: 500;

      &.withRightArrow {
        grid-template-columns: 1fr 36px;
      }

      &.withLeftArrow {
        grid-template-columns: 36px 1fr;
      }
    }

    .icon {
      height: 36px;
      width: 36px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 36px;
      transition: transform var(--time) var(--ease);
      transform: rotateX(0deg);
    }

    &[open] .icon {
      transform: rotateX(-180deg);
    }

    .content {
      position: relative;
      color: colorDark100;
      font-size: 17px;
      line-height: 24px;
      font-weight: 400;
      margin-bottom: 16px;
      opacity: 0;
      left: 0;
    }

    &[open] .content {
      animation: open 0.5s ease-out both;
    }

    &[open] .icon {
      transform: rotateX(-180deg);
    }
  }
}
