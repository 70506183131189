.item {
  > img {
    size: 100%;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    pointer-events: none;
  }
}

.itemImage {
  display: block;
  size: 100%;
  background-size: cover;
  background-position: center;
}

.fullScreen {
  cursor: pointer;
}

.isStories {
  display: flex;
  justify-content: center;
  background-color: #000000;

  > img {
    opacity: 1;
    size: auto 100%;
    position: relative;
  }
}

.playWrap {
  size: 28px;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 50%;
  @mixin center;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -14px;
  margin-top: -14px;
}
