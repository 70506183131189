@keyframes loading {
  to {
    transform: translateX(100%);
  }
}

.root {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 16px;
}

.stub {
  size: 100% 80px;
  border-radius: 16px;
  @mixin skeletonLoading;
}
