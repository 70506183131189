@value colorPink60 from '~Styles/vars/colors.css';

.drawerContainer {
  display: flex;
  height: 100%;
  width: 100%;
  flex-direction: column;
  justify-content: space-between;
}

.tooltip {
  max-width: 304px;
  top: -8px !important;
  right: 0 !important;
  left: auto !important;
  z-index: 100;
  display: flex;
  flex-direction: column;
  gap: 8px;
}
