@value newDesktopMinWidth, mobileMaxInnerWidth from '~Styles/vars/query.css';

.root {
  display: block;
}

.container {
  @mixin newInnerContainer;
}

.title {
  padding-top: 16px;
  padding-bottom: 16px;
}

.serachParams {
  margin-top: 20px;
}

.preloader {
  margin: 40px auto;
}
