.root {
  position: relative;
  top: 100%;
  right: unset;

  .dropdownGroup {
    padding: 8px;
    top: auto;
  }

  .alterButton {
    box-sizing: border-box;
    padding: 12px 16px;
    width: 100%;

    :not(:first-child) {
      margin-left: 8px;
    }
  }

  .fullWidth {
    box-sizing: border-box;
    width: 100%;
  }

  .justifyCenter {
    justify-content: center;
  }

  .marginTop8 {
    margin-top: 8px;
  }
}
