@value colorConcrete, colorWetAsphalt from '~Styles/vars/colors.css';

.root {
  @mixin vertical-center;
  padding: 8px;
  height: 32px;
  border-radius: 4px;
  box-sizing: border-box;
  background: colorConcrete;
}

.icon {
  margin-right: 8px;
}

.delete {
  display: flex;
  margin-left: 8px;
}

.copyable {
  cursor: pointer;
}