@value desktopMinWidth, newDesktopMinWidth from '~Styles/vars/query.css';

.root {
  flex: 1;
  display: block;
  min-width: desktopMinWidth;

  @media (max-width: newDesktopMinWidth) {
    padding-left: 64px;
  }
}
