@value (colorDark100, colorSky100) from '~Styles/vars/colors.css';

.header {
  padding: 8px 16px;

  .title {
    color: colorDark100;
  }

  .subtitle {
    color: colorDark100;

    .subtitleLink {
      color: colorSky100;
      font-weight: 500;
    }
  }
}
