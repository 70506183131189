.list {
  left: -4px;
  width: calc(100% + 4px);
  max-height: 283px;
  overflow: hidden;

  .listContainer {
    overflow: auto;
  }
}
.clearBtn {
  display: flex;
  align-items: center;
  justify-content: center;
}
