@value colorWhite, colorGrey100 from '~Styles/vars/colors.css';

.root {
  flex: 1;
}

.stub {
  size: 100%;
  padding-top: 12px;
  border-radius: 8px;
  box-sizing: border-box;
  background: colorWhite;
  color: colorGrey100;
  @mixin center;
  text-align: center;
  flex-direction: column;
  gap: 8px;
}
