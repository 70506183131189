@value colorGrey100 from '~Styles/vars/colors.css';

.creatorInfo {
  display: flex;
  gap: 16px;
  width: 100%;
}

.countries {
  color: colorGrey100;
}

.details {
  display: flex;
  flex-direction: row;
  gap: 16px;
  width: 212px;
  overflow: hidden;
}

.creatorName {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.badges {
  display: flex;
  gap: 4px;
}

.badgeIcon {
  size: 16px;
}

.badge {
  padding: 0;
}

.registartionMark {
  margin-right: 4px;
}

.avatarWrap {
  position: relative;
  max-height: 48px;
}

.platform {
  size: 24px;
  background: rgba(256, 256, 256, 0.85);
  position: absolute;
  border-radius: 50%;
  bottom: -4px;
  right: -4px;
  @mixin center;
}

.username {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  &.shipment {
    max-width: 128px;
  }
}

.blockedUsername {
  color: colorGrey100;
}

.creatorData {
  display: grid;
  grid-template-columns: 2fr 2fr 1fr 1fr 1fr;
  grid-column-gap: 8px;
  width: 100%;
  margin-right: 8px;
}

.categories {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 8px;
}

.followers,
.er,
.views,
.profile {
  display: flex;
  align-items: center;
}

.profile {
  flex-direction: column;
}

.profileInfo {
  display: flex;
  flex-direction: row;
  align-items: center;
}