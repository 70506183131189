@value colorWhite from '~Styles/vars/colors.css';

.transactionTypeSelectionWrapper {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 4px;
}

.valueText {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
}

.selectField {
  width: 150px;

  & > div > div > div:first-child {
    flex-wrap: nowrap;
  }
}

.dateInput {
  border-radius: 4px !important;
}

.datePicker {
  z-index: unset;
}

.select {
  width: 150px;
  background: colorWhite;
}
