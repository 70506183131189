@value colorWhite from '~Styles/vars/colors.css';

.title {
  margin-bottom: 20px;
  text-transform: uppercase;
  font-weight: 500 !important;
}

.buttonContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
}

.attachmentsContainer {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  gap: 4px;
  margin-bottom: 20px;
}
.media {
  width: 100%;
  height: 64px;
  overflow: hidden;
  border-radius: 8px;
  position: relative;
}

.drawer {
  background-color: white;
}
.drawerContainer {
  display: flex;
  height: 100%;
  width: 516px;
  flex-direction: column;
  justify-content: space-between;

  .description {
    margin: 16px 0;
  }

  .feedbackInput {
    height: 180px !important;
  }

  button {
    width: 100%;
  }
}
