.root {
  display: flex;
}

.upload {
  display: flex;
  position: relative;
  align-items: flex-start;
}

.title {
  margin-left: 9px;
}

.input {
  opacity: 0;
  size: 100%;
  position: absolute;
}

.uploader {
  size: 100%;
}