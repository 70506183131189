.root {
  margin-bottom: 32px;
}

.param {
  margin-top: 8px;
}

.linkList {
  margin-top: -20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}