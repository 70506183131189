@value colorGrey50 from '~Styles/vars/colors.css';

.root {
  display: flex;
}

.statItem {
  &:not(:last-child) {
    margin-right: 20px;
  }
}

.label {
  color: colorGrey50;
}
