@value colorGrey100, colorPink100, colorSand40, colorWhite from '~Styles/vars/colors.css';

.root {
  flex: 1;

  .container {
    display: flex;
    flex-direction: column;
  }
}

.emptyList {
  flex: 1;
  @mixin center;
  padding: 16px;
  border-radius: 16px;
  background: colorSand40;
  margin-top: 16px;
}

.link {
  color: colorPink100;
}

.emptyDescr {
  margin-top: 107px;
  padding: 8px;
  @mixin center;
  flex-direction: column;
}

.ads {
  color: colorGrey100;
  margin-bottom: 8px;
}

.drawer {
  background-color: colorWhite;
}
