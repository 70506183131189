@value colorWhite, colorGrey50 from '~Styles/vars/colors.css';

.root {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 60px;
  margin-right: 60px;
  padding: 20px 0;
  box-sizing: border-box;
  position: relative;

  &::before {
    content: '';
    size: 100% 1px;
    background: colorGrey50;
    display: block;
    position: absolute;
    z-index: -1;
  }
}

.date {
  padding: 4px 12px;
  background: colorWhite;
}
