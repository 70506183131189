@value colorGrey50 from '~Styles/vars/colors.css';

.customListSelectionContainer {
  position: relative;
}

.customListDropdownGroup {
  right: unset !important;
  top: -12px !important;
  left: calc(100% + 112px) !important;
  width: 300px;
}

.menuClassName {
  overflow: visible;
}

.menuItemIconContainer {
  display: flex;
  justify-content: space-between;
  min-width: 227px;
}

.blocklistTooltip {
  max-width: 340px;
}

.dropdown {
  padding: 0;
}

.dropdownGroup {
  width: 233px;

  &.withReimbursement {
    width: 273px;
  }

  &.withUresponseReport {
    width: 289px;
  }
}

.isNewView {
  .dropdownGroup {
    top: auto;
    bottom: calc(100% + 8px);
  }
}

.btn {
  width: 100%;
  box-sizing: border-box;
  justify-content: flex-start;
}

.separator {
  width: 100%;
  padding: 16px;
  box-sizing: border-box;
  &::after {
    content: '';
    display: block;
    size: 100% 1px;
    background: colorGrey50;
  }
}
