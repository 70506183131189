.drawer {
  background-color: white;
  padding-top: 8px;

  .textContainer {
    gap: 16px;
  }

  .contentRoot {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr max-content;
    height: 100%;
    margin: 0 -16px;
    grid-gap: 8px;
  }

  .buttonWrapper {
    padding: 0 16px;

    .submitButton {
      box-sizing: border-box;
      width: 100%;
    }
  }
}
