@value colorGrey50, colorGrey100, colorDark100, colorPink40, colorPink100 from '~Styles/vars/colors.css';

.drawerContainer {
  .drawerBackdrop {
    z-index: 1100;
  }

  .drawerWrapper {
    z-index: 1101;
    background-color: white;

    .root {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .formContainer {
        display: flex;
        flex-direction: column;
        flex: 1;
        .title {
          margin-bottom: 24px;
        }

        .descriptionText {
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 8px;
          margin-bottom: 16px;
        }

        .typeformContainer {
          flex: 1;
          margin: 16px 0 48px;

          .typeformWidget {
            height: 100%;
          }
        }
      }


      .bottomContainer {
        width: 100%;
        display: flex;
        flex-direction: row;
        gap: 8px;
      }
      
    }
  }
}