@value headerHeight, subheaderHeight from '~Styles/vars/size.css';

:root {
  --detailsSize: 320px;
}

.root {
  width: 100%;
  position: relative;
  overflow: auto;
  display: flex;
}

.searchSection {
  flex-grow: 1;
  height: calc(100vh - headerHeight);
  display: inline-block;
  vertical-align: top;
  overflow: auto;
}

.mediaplanSection {
  width: var(--detailsSize);
  height: calc(100vh - headerHeight);
  flex: none;
  overflow: auto;
}
