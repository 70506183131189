.titleWrap {
  display: flex;
}

.content {
  margin-top: 20px;
}

.priceWrap {
  display: flex;
  align-items: center;
  margin: 20px 0;
}

.prevPrice {
  margin-left: 4px;
  text-decoration: line-through;
}

.priceTitle {
  margin-right: 8px;
}

.disclaimer {
  margin-top: 12px;
}

.hireAndChangePrice {
  margin-top: 20px;
}