@value desktopMinWidth from '~Styles/vars/query.css';
@value (colorDark100, colorGrey100, colorSand40, colorPink60, colorGrey10) from '~Styles/vars/colors.css';
@value (borderRadius20) from '~Styles/vars/query.css';

div.root {
  background-color: colorGrey10;
  position: relative;
}

.tooltip {
  font-size: 13px;
  line-height: 17px;
  font-weight: 400;

  a {
    color: colorPink60;
    text-decoration: underline;
  }
}

.planFeatureTextValue {
  font-weight: 500;
  color: inherit;
  font-size: inherit;
  display: flex;
  align-items: center;
}

.inner {
  height: auto;
  width: desktopMinWidth;
  margin: 0 auto;
  padding: 40px 0;
  box-sizing: border-box;
  position: relative;
  z-index: 1;
}

.header {
  display: grid;
  grid-gap: 40px;
  margin-bottom: 32px;

  .titleWrapper {
    text-align: center;
    .title {
      color: colorDark100;
    }

    .subtitle {
      color: colorGrey100;
    }
  }

  .updatePlansNotifications {
    background-color: colorSand40;
    border-radius: borderRadius20;
    padding: 16px 20px;
    font-size: 15px;
    line-height: 24px;
    width: 827px;
    box-sizing: border-box;
    margin: 0 auto;
  }
}

.content {
  display: flex;
  align-items: baseline;
}

.priceEl {
  display: flex;
  align-items: baseline;
}

.drawer {
  @mixin drawer;
}

.drawerContainer {
  @mixin drawerContainer;
  justify-content: flex-start;
}
