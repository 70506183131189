@value colorGrey100, colorGrey100, colorSand40, colorPurple100 from '~Styles/vars/colors.css';

.root {
  padding: 12px 20px;
  display: flex;
  align-items: center;

  &:not(.isActive) {
    cursor: pointer;
  }
}

.avatar {
  margin-right: 12px;
}

.campaignName {
  color: colorGrey100;
}

.row {
  display: flex;
  gap: 4px;
  align-items: center;
}

.details {
  flex: 1;
  overflow: hidden;
}

.messageWrap {
  align-items: flex-end;
}

.messageData {
  flex: 1;
  overflow: hidden;
  user-select: none;
}

.message {
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.isActive {
  background: colorSand40;
  cursor: default;
}

.date {
  color: colorGrey100;
}

.unread {
  color: colorGrey100;
}

.read {
  color: colorPurple100;
}
