.root {
  display: block;
  padding: 20px;
}

.header {
  display: flex;
}

.title {
  flex-grow: 1;
  margin-bottom: 20px;
}

.icon {
  cursor: pointer;
}

.approve {
  display: flex;
  flex-wrap: nowrap;
  padding-top: 24px;
  gap: 16px;
}

.textarea {
  margin-top: 16px;
}