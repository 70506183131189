@value (colorPink40, colorSun40, colorPurple40, colorSky40) from '~Styles/vars/colors.css';
@value (headerHeight) from '~Styles/vars/size.css';

.header {
  .wrap {
    transition: .5s background-color;
    border-bottom-color: transparent;

    &.themePink {
      background-color: colorPink40;
    }

    &.themeSun {
      background-color: colorSun40;
    }

    &.themePurple {
      background-color: colorPurple40;
    }

    &.themeSky {
      background-color: colorSky40;
    }
  }
}

.content {
  display: grid;
  grid-template-rows: 1fr;
  width: 100%;
  z-index: 2;
  min-height: calc(100vh - headerHeight);
  box-sizing: border-box;
  padding: 8px 16px;
  position: relative;
  transition: .5s background-color;
  overflow-x: hidden;

  &.withProgress {
    grid-template-rows: 56px 1fr;
  }

  &.themePink {
    background-color: colorPink40;
  }

  &.themeSun {
    background-color: colorSun40;
  }

  &.themePurple {
    background-color: colorPurple40;
  }

  &.themeSky {
    background-color: colorSky40;
  }

  .steps {
    width: 100%;
    max-width: 500px;
    box-sizing: border-box;
    margin: 0 auto;
  }
}

.backgroundImage {
  background: url("../../../../files/images/general/sign-up-bg.png") right 0 no-repeat;
  background-size: contain;
  width: 100vw;
  height: calc(100vh - headerHeight);
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 1;

  &.themePink {
    background-color: colorPink40;
  }

  &.themeSun {
    background-color: colorSun40;
  }

  &.themePurple {
    background-color: colorPurple40;
  }

  &.themeSky {
    background-color: colorSky40;
  }
}
