@value colorWhite, colorPink100 from '~Styles/vars/colors.css';

.root {
  position: relative;
}

.withBadge {
  .ava {
    position: relative;
    top: 4px;
    left: 4px;
  }
}

.border {
  top: 0;
  left: 0;
  position: absolute;
  border-radius: 50%;

  &::after {
    content: '';
    top: 2px;
    left: 2px;
    border-radius: 50%;
    background: colorWhite;
    size: calc(100% - 8px);
    padding: 2px;
    display: block;
    position: absolute;
  }
}

.topRated {
  .border {
    background: linear-gradient(135deg, #4236dd 0%, #e26dff 100%);
  }
}

.highPotential {
  .border {
    background: linear-gradient(135deg, #ff3fb2 0%, #ffa077 100%);
  }
}

.rightIcon {
  position: absolute;
  bottom: 0;
  right: 0;
}

.leftIcon {
  position: absolute;
  bottom: 2px;
  left: -2px;

  svg {
    stroke: colorWhite;
    stroke-width: 2px;
    width: 20px;
    height: 20px;
  }
}

.favorite {
  position: absolute;
  bottom: -4px;
  right: -4px;
  size: 24px;
  background: rgba(256, 256, 256, 0.85);
  border-radius: 50%;
  @mixin center;
  color: colorPink100;

  img {
    size: 16px;
  }
}
