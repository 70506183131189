@value colorGrey50, colorGrey100 from '~Styles/vars/colors.css';

.requestContainerOld {
  padding: 20px;
  background-color: white;
  border-top: 1px solid colorGrey50;
}

.requestContainer {
  background-color: white;
  border-top: 1px solid colorGrey50;
}

.buttonContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  margin-top: 16px;
}

.descr {
  color: colorGrey100;
}

.controls {
  margin-bottom: 8px;
  padding-left: 0;
}

.details {
  padding-left: 0;
  padding-right: 0;
}
