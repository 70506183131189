@value colorGrey10, colorGrey100 from '~Styles/vars/colors.css';

.root {
  padding: 4px;
}
.list {
  display: flex;
  flex-direction: column;
  gap: 8px;
  max-height: 350px;
  overflow: auto;
}

.listItem {
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  border-radius: 8px;
  padding-right: 16px;
}

.filterContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  padding: 0 16px;
}
.additionalGroupTitle {
  color: colorGrey100;
  padding: 8px 16px;
}