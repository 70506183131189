@value colorGrey100 from '~Styles/vars/colors.css';

.root {
  margin-top: 40px;
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
}

.emptyList {
  margin-top: 18px;
  color: colorGrey100;
}
