@value colorGrey50 from '~Styles/vars/colors.css';

.root {
  display: block;
}

.list {
  left: 0;
  width: 100%;
  overflow: auto;
}

.checkbox {
  size: 24px;
  padding: 4px;
  @mixin center;
  position: relative;

  &::before {
    content: '';
    display: block;
    size: 16px;
    border-radius: 4px;
    border: 2px solid #323232;
    box-sizing: border-box;
  }

  &::after {
    content: '';
    display: block;
    position: absolute;
    size: 10.6px;
    top: 10.6px;
    left: 10.6px;
    border-radius: 4px;
    transition: background 0.1s ease-in;
  }
}

.isActive {
  .checkbox {
    &::after {
      background: #323232;
    }
  }
}

.clearBtn {
  display: flex;
  align-items: center;
  justify-content: center;
}

.divider {
  margin: 16px;
  height: 1px;
  background-color: colorGrey50;
}

