@value colorConcrete, colorWhite, colorWetAsphalt from '~Styles/vars/colors.css';

.root {
  size: 100% 56px;
  box-sizing: border-box;
  border-top: 1px solid colorConcrete;
  border-bottom: 1px solid colorConcrete;
  background-color: colorWhite;
}

.list {
  display: flex;
  align-items: center;
  padding: 0 20px;
  height: 100%;
}

.item {
  flex-shrink: 0;
  position: relative;

  &:not(:last-child) {
    margin-right: 20px;
  }
}

.tooltip {
  display: inline;
}

.title {
  height: 100%;
  user-select: none;
  position: relative;
  cursor: pointer;
  @mixin vertical-center;
  display: inline;
}
