@value colorGrey100, colorWhite,colorPink60, colorDark from '~Styles/vars/colors.css';

.root {
  width: 288px;
  background: colorDark;
  padding: 12px 16px;
  box-sizing: border-box;
  position: fixed;
  border-radius: 16px;
  z-index: 10;
}

.title {
  color: colorWhite;
  margin-bottom: 8px;
}

.closeButton {
  color: colorGrey100;
  position: absolute;
  top: 12px;
  right: 16px;
  size: 24px;
  color: colorWhite;
  @mixin center;
}

.closeButtonIcon {
  width: 20px;
  height: 20px;
}

.descr {
  margin-bottom: 16px;
  color: colorWhite;
}

.btn {
  justify-content: center;
  width: 100%;
  box-sizing: border-box;
}

.image {
  margin-bottom: 8px;
}
