@value colorGrey50 from '~Styles/vars/colors.css';

.root {
  display: block;
}

.dropdown {
  height: 72px;
  max-height: 72px;
  border-radius: 16px;
  border-color: colorGrey50;
  margin-bottom: 16px;
  display: flex;

  .dropdownGroup {
    width: 100%;
    max-height: 300px;
    overflow: auto;
  }
}

.edit {
  padding: 0 12px;
}

.dropdownItem {
  padding-left: 12px;
  padding-right: 12px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  gap: 8px;
}

.item {
  display: flex;
  align-items: center;
  gap: 8px;
  width: 100%;

  & > * {
    flex-shrink: 0;
  }
}

.brandName {
  flex: 1;
  justify-content: flex-start;
}

.addNewBtn {
  justify-content: center;
}

.addPauseText {
  color: colorGrey50;
  width: 100%;
  white-space: normal;
  padding: 16px 0;
  text-align: center;
}
