@value colorDark100 from '~Styles/vars/colors.css';

.root {
  display: flex;
  justify-content: flex-end;
  gap: 8px;

  .active {
    border-color: colorDark100;
  }
}
