@value colorConcrete, colorWhite from '~Styles/vars/colors.css';

.root {
  width: 400px;
  height: 100%;
  padding: 8px;
  box-sizing: border-box;
  background-color: colorConcrete;
}

.warning {
  margin-bottom: 12px;
}

.dateItem {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;

  &:not(:last-child) {
    margin-right: 16px;
  }
}

.section {
  width: 100%;
  background-color: colorWhite;
  border-radius: 4px;
  margin-bottom: 12px;
  padding: 20px;
  box-sizing: border-box;

  &:nth-last-of-type {
    margin-bottom: 0;
  }
}

.deadline {
  width: 100%;
  background-color: colorWhite;
  padding: 12px 16px;
  box-sizing: border-box;
  border-bottom: 1px solid colorConcrete;
  display: flex;
}

:global .isMobile {
  :local .stepper {
    display: none;
  }

  :local .root {
    padding: 0;
    height: unset;
  }
}
