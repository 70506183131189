@value colorConcrete from '~Styles/vars/colors.css';

.root {
  display: block;
}

.rate {
  display: flex;
  position: absolute;
  top: 20px;
  right: 20px;
}

.scores {
  display: flex;
  justify-content: space-between;
}

.scoreIcon {
  size: 60px;
  margin-bottom: 8px;

  svg {
    size: 60px;
  }
}

.newDeal {
  margin-top: 12px;
}
