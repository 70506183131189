.root {
  //grid-template-rows: 1fr max-content !important;

  .textPresetRoot {
    align-items: flex-start;
  }

  .titleTextPresetContainer {
    gap: 16px;
  }

  .buttonGroup {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }

}
