.root {
  display: flex;
  justify-content: space-between;
  padding: 8px 0;
}

.dropdowns {
  display: flex;
  gap: 8px;
}

.dropdown {
  width: 280px;
}

.sortBy {
  left: 0;
}

.sortBy,
.activeState {
  width: 367px;
}

.search {
  width: 280px;
}
