@value colorError100 from '~Styles/vars/colors.css';

.root {
  display: block;
}

.section {
  padding: 20px 0 !important;
}

.descr {
  margin: 20px 0;
  padding: 0 40px;
}

.addBtn {
  height: 92px;
}

.title {
  padding: 0 40px;
}

.error {
  color: colorError100;
  padding: 0 40px 20px;
}

.noPostingWarning {
  margin-bottom: 20px;
}
