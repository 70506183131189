@value colorWhite, colorSun80, colorApproval100, colorPink100, colorApproval100, colorGrey100 from '~Styles/vars/colors.css';

.root {
  padding-top: 8px;
  padding-bottom: 8px;
  gap: 10px;
}

.content {
  border-radius: 16px;
  background: colorWhite;
  padding: 8px 16px 8px 0;
  display: flex;
  align-items: center;
}

.title {
  padding-left: 16px;
  display: flex;
  align-items: center;
  gap: 16px;
  padding: 8px 16px;
}

.name {
  width: 112px;
  @mixin cuttedText;
}

.label {
  padding: 8px;
  flex-shrink: 0;
  position: relative;
}

.columnLabel {
  color: colorGrey100;
}

.username {
  width: 134px;
  cursor: pointer;
  @mixin cuttedText;

  &:hover {
    color: colorPink100 !important;
  }
}

.pageName {
  width: 144px;
  @mixin cuttedText;

  &:hover {
    color: colorPink100 !important;
  }
}

.dates {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.drawer {
  background-color: colorWhite;
}

.closeIcon {
  color: colorSun80;
}

.closeCheck {
  color: colorApproval100;
}

.state {
  display: flex;
  align-items: center;
  gap: 4px;
  cursor: default;
}

.checkCircleIcon {
  height: 14px;
  width: 14px;

  .green {
    color: colorApproval100;
  }
}

.disconnectedLabel {
  color: colorSun80 !important;
}

.guideTooltip {
  position: absolute;
  top: 60px;
  z-index: 1;
  width: 265px;
  box-sizing: border-box;
  min-width: unset !important;
}

.moreInfoWrap {
  position: relative;

  .guideTooltip {
    left: -28px;
  }
}
