.root {
  margin-top: 20px;
}

.project {
  margin-bottom: 12px;
}

.bar {
  padding-left: 20px;
  margin-bottom: 12px;
}

.header {
  margin-top: 40px;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  z-index: 11;
  position: relative;
}

.preloader {
  margin: 20px auto;
}