.root {
  display: block;
}

.container {
  display: flex;
  justify-content: space-between;
}

.menu {
  width: 250px;
  padding: 40px 0 0 50px;
  box-sizing: border-box;
}

.content {
  width: 620px;
}

.sidebar {
  padding: 16px 16px 0 0;
  box-sizing: border-box;
}

.loader {
  margin: 40px auto;
}

.errorManager {
  padding: 0 40px;
}
