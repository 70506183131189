@value colorWhite, colorConcrete from '~Styles/vars/colors.css';

.root {
  top: 0;
  size: 100%;
  position: absolute;
  background: rgba(219, 219, 219, 0.5);
  @mixin center;
  z-index: 1;
}

.media {
  border-bottom: 1px solid colorConcrete;
}

.blank {
  background: colorWhite;
  width: 540px;
  border-radius: 4px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
}

.content {
  text-align: center;
  padding: 60px 80px;
}

.descr {
  margin-top: 20px;
  margin-bottom: 40px;
}

.buttons {
  display: flex;
  justify-content: center;
}

.button {
  flex-grow: 1;

  &:not(:last-child) {
    margin-right: 20px;
  }
}
