@value colorWhite from '~Styles/vars/colors.css';

.root {
  margin-bottom: 24px;
  position: relative;
}

.groups {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.group {
  display: flex;
  gap: 8px;
  margin-bottom: 16px;

  & > * {
    flex: 1;
  }
}

.date {
  width: 284px;
}

.clearParams {
  top: -40px;
  right: 0;
  position: absolute;
}

.selectCheckbox {
  background: colorWhite;
}
