.drawer {
  @mixin drawer;
}

.drawerContainer {
  @mixin drawerContainer;
}

.title {
  margin-bottom: 16px;
}

.descr {
  margin-bottom: 40px;
}

.content {
  flex: 1;
}

.buttons {
  display: flex;
  gap: 8px;

  & > * {
    flex: 1;
  }
}
