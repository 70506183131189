@value colorGrey10, colorSuccessful100 from '~Styles/vars/colors.css';

.root {
  padding: 16px;
  border-radius: 16px;
  background: colorGrey10;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  gap: 16px;
}

.icon {
  color: colorSuccessful100;
}

.spacer {
  flex: 1;
}
