@value colorGrey10 from '~Styles/vars/colors.css';

.root {
  display: flex;
  align-items: center;
  padding: 16px;
  border-radius: 16px;
  gap: 16px;
  background: colorGrey10;
  position: relative;
}

.avaWrap {
  position: relative;

  .platform {
    bottom: -4px;
    right: -4px;
  }
}

.content {
  flex: 1;
  overflow: auto;
}

.badges {
  display: flex;
  gap: 4px;
}

.badgeIcon {
  width: 16px;
  height: 16px;
}

.badge {
  padding: 0;
}

.usernameWrap {
  display: flex;
  gap: 4px;
  align-items: center;
}

.stats {
  display: flex;
  gap: 8px;
  margin-top: 8px;
}

.details {
  flex: 1;
}

.invited {
  padding: 12px 16px;
}

.link {
  size: 381px 100%;
  position: absolute;
  border-radius: 16px 0 0 16px;
  top: 0;
  left: 0;
}

.spinner {
  margin-right: 16px;
}
