@value colorWhite, colorConcrete from '~Styles/vars/colors.css';

.root {
  width: 100%;
  position: absolute;
  z-index: 101;
  padding: 8px 0;
  border-radius: 4px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  background: colorWhite;
  max-height: 170px;
  overflow: auto;
}

.allCampaigns {
  padding: 18px 12px;
  border-bottom: 1px solid colorConcrete;
  margin-bottom: 8px;
}