@value colorGrey100 from '~Styles/vars/colors.css';

.root {
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: auto;
  background-color: white;
  border-radius: 8px;
}

.header {
  flex: none;
  display: flex;
  margin-bottom: 32px;
  flex-direction: row;
  align-items: center;
}

.summary {
  width: 100px;
  flex: none;
  margin-right: 20px;
}

.searchParams {
  flex: auto;
}

.list {
  flex: auto;
}

.trial {
  width: 100%;
  padding: 47px 0 40px;
  text-align: center;
  box-sizing: border-box;
}

.descr {
  margin-top: 15px;
  margin-bottom: 26px;
}

.preloader {
  display: flex;
  flex-wrap: wrap;
  margin: -10px;
}

.showMore {
  margin-top: 20px;
  margin-bottom: 28px;
}

.listHeader {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  padding: 16px 8px;
  background-color: white;
  border-radius: 8px;
  box-sizing: border-box;
  gap: 8px;
  margin-bottom: 8px;

  .listHeaderTextWrapper {
    width: 100%;
    display: grid;
    grid-template-columns: 3fr 1fr 1fr 1fr 1fr 1fr;
    grid-column-gap: 8px;
    text-align: left;
    align-items: center;
    margin-right: 8px;
  }

  .listHeaderText {
    color: colorGrey100;
    cursor: default;
    &.profile {
      text-align: center;
    }
  }
}

.loadingContainer {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}