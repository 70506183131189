@value colorGrey100 from '~Styles/vars/colors.css';

.root {
  display: flex;
  align-items: flex-start;

  .backButton {
    cursor: pointer;
    margin-top: 12px;
  }

  .subtitle {
    color: colorGrey100;
  }
}
