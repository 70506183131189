@value colorGrey50, colorGrey100 from '~Styles/vars/colors.css';

.root {
  position: relative;
  height: 18px;
  width: 18px;
  padding: 8px;

  &.noPadding {
    padding: 0;
  }

  .input {
    display: block;
    opacity: 0;
    cursor: pointer;
    box-sizing: border-box;
    border: 0;
    height: 18px;
    width: 18px;
    padding: 0;
    margin: 0;
    position: absolute;
    left: 8px;
    top: 8px;

    &.unsetLeftTop {
      left: unset;
      top: unset;
    }
  }

  .markerWrapper {
    background-color: white;
    box-sizing: border-box;
    pointer-events: none;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid colorGrey50;
    border-radius: 2px;
    height: 100%;
    width: 100%;

    .marker {
      opacity: 0;
      background-color: #323232;
      border-radius: 2px;
      height: 10px;
      width: 10px;

      &.indeterminate {
        height: 2px;
      }
    }
  }

  .input:hover + .markerWrapper,
  .input:focus + .markerWrapper {
    border-color: colorGrey100;
  }

  .input:checked {
    & + .markerWrapper {
      border-color: #323232;
    }

    & + .markerWrapper .marker {
      opacity: 1;
    }
  }

  .input:disabled {
    cursor: default;

    & + .markerWrapper {
      border-color: colorGrey50;
    }

    &:checked {
      & + .markerWrapper .marker {
        background-color: colorGrey50;
      }
    }
  }

}
