@value colorGrey10,colorGrey50,colorGrey100, colorPink100,colorDark100, colorError100 from '~Styles/vars/colors.css';

.root {
  display: flex;
  flex-direction: column;
  gap: 8px;
  position: relative;
  cursor: pointer;
  border-radius: 16px;
  padding: 16px;
  border: 1px solid colorGrey50;

  &.active {
    border-color: colorDark100;
  }

  .titleContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: nowrap;
    justify-content: space-between;
  }

  .title {
    display: flex;
    flex-direction: row;
    gap: 16px;
    align-items: center;
    overflow: hidden;

    .titleText {
      width: 100%;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }

  .label {
    height: 24px;
    width: 24px;

  }

  .deletePlaceholder {
    height: 48px;
    width: 54px;
  }

  .mailInfo {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    padding-left: 16px;
  }
}

.delayInput {
  width: 90px;
  min-width: 90px;
  padding: 0px 8px;
  margin-right: 4px;
  max-height: 34px;
}

.delayValueList {
  background-color: white;
  width: 64px;
  max-height: 200px;
  overflow: auto;
}

.timeInput {
  width: 90px;
  min-width: 90px;
  padding: 0px 8px;
  margin-right: 4px;
}

.timeValueList {
  background-color: white;
  width: 90px;
  max-height: 200px;
  overflow: auto;
}

.timeValueDescription {
  color: colorGrey100;
  margin-top: 4px;
  margin-bottom: 16px;
}

.addButton {
  color: colorGrey100;
  justify-content: flex-start;
  padding: 8px 0;
}

.mailOptions {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 1;
}
.mailOptionValue {
  color: colorPink100;
  margin-left: 4px;
}