.root {
  background-color: white;
  display: flex;
  flex-direction: column;

  .content {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 16px;
    flex: 1;
  }

  .buttonContainer {
    width: 100%;

    .mainBtn {
      width: 100%;
    }
  }
}
