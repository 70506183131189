@value colorWhite, colorGrey100 from '~Styles/vars/colors.css';

.root {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 383px;
}

.content {
  background: colorWhite;
  border-radius: 16px;
  padding: 16px;
}

.balanceContent {
  composes: content;
  flex: 1;
  display: flex;
  flex-direction: column;
}

.balanceValue {
  flex: 1;
}

.title {
  margin-bottom: 16px;
}

.subtitle {
  color: colorGrey100;
}

.topup {
  width: 100%;
  box-sizing: border-box;
  justify-content: center;
}

.transactionsContent {
  composes: content;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.transactions {
  padding: 8px 0;
}
