.drawer {
  @mixin drawer;
}

.drawerContainer {
  @mixin drawerContainer;
  justify-content: flex-start;
}

.content {
  flex: 1;
}

.title {
  margin-bottom: 16px;
}
