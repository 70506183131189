@value colorWhite from '~Styles/vars/colors.css';

@keyframes loading {
  to {
    transform: translateX(100%);
  }
}

.root {
  display: block;
}

.item {
  display: flex;
  align-items: center;
  gap: 16px;
  height: 68px;
  background: colorWhite;

  &:not(:last-child) {
    border-bottom: 1px solid #e7e5e4;
  }
}

.name,
.ava,
.status,
.brand {
  @mixin skeletonLoading;
}

.ava {
  size: 40px;
  border-radius: 50%;
}

.name {
  height: 14px;
  width: 128px;
  margin-bottom: 9px;
}

.brand {
  height: 8px;
  width: 80px;
}

.names {
  width: 332px;
}

.applications,
.messages,
.completed,
.creatives,
.spent {
  width: 167px;
  height: 10px;
  @mixin skeletonLoading;
}

.status {
  height: 10px;
  width: 130px;
}

.withBars {
  padding: 8px 16px;
  box-sizing: border-box;
  background: colorWhite;
  border-radius: 16px;
  border-bottom: 1px solid #e7e5e4;
}

.createdDateWrap {
  width: 533px;
}

.createdDate {
  width: 150px;
  height: 10px;
  @mixin skeletonLoading;
}
