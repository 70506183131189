.uploadBtn {
  margin-top: 40px;
  position: relative;
  display: inline-block;
}

.uploadInput {
  position: absolute;
  size: 100%;
  top: 0;
  left: 0;
}

.table {
  td {
    padding: 12px;
    border: 1px solid black;
  }
}

.example {
  margin: 40px 0;
}

.reasons {
  margin: 40px 0 20px;
}

.list {
  li {
    margin: 10px 0;
  }
}