@value colorGrey50 from '~Styles/vars/colors.css';

.root {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  .userContent {
    margin-bottom: 8px;

    .userInfoWrap {
      padding-bottom: 4px;
      border-bottom: 1px solid colorGrey50;

      .userInfo {
        padding: 12px 20px;
        display: flex;
        align-items: center;

        .names {
          margin-left: 12px;
        }
      }
    }
  }
}
