.root {
  display: flex;
  flex-direction: column;

  form {
    flex: 1;
    display: flex;
    flex-direction: column;
  }
}
