@value colorGrey10 from '~Styles/vars/colors.css';

.root {
  width: 100%;
  min-width: 180px;
  display: block;
  max-height: 220px;
  overflow: auto;
}

.radioItem {
  display: flex;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  padding: 12px;
  cursor: pointer;
  transition: background 0.1s ease-in;

  &:hover {
    background: colorGrey10;
  }
}

.icon {
  margin-right: 12px;
}

.inGroup {
  .radioItem {
    padding: 12px 0;

    &:hover {
      background: none;
    }
  }
}
