.root {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  width: 100%;
  padding: 8px;
  box-sizing: border-box;
}

.textContainer {
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-bottom: 4px;
  max-width: 200px;
}
