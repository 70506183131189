.root {
  .title {
    margin-right: 19px;
    font-weight: 500;
  }
}

.content {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.contentData {
  display: flex;
  gap: 4px;
}
