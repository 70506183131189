@value colorGrey10, colorGrey50, colorWhite from '~Styles/vars/colors.css';

.drawer {
  @mixin drawer;
}

.drawerContainer {
  @mixin drawerContainer;
  justify-content: flex-start;
}

.title {
  margin-bottom: 16px;
}

.descr {
  margin-bottom: 32px;
}

.itemTitle {
  text-align: center;
  margin: 8px auto;
}

.example {
  width: 282px;
  box-sizing: border-box;
  padding: 24px 16px 16px;
  background: colorGrey10;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin: 24px auto;
}

.examplePrice {
  width: 110px;
  height: 48px;
  padding: 12px 16px;
  border: 1px solid colorGrey50;
  border-radius: 8px;
  background: colorWhite;
  box-sizing: border-box;
}

.examplePrices {
  display: flex;
  align-items: center;
  gap: 8px;
}

.tableTitle {
  display: flex;
  gap: 8px;
  margin-top: 16px;
  margin-bottom: 8px;
}

.table {
  border-radius: 16px;
  border: 1px solid colorGrey50;
  background: colorGrey10;
  width: 100%;
  box-sizing: border-box;
  margin-bottom: 16px;
}

.tr {
  display: flex;

  &:not(:last-child) {
    border-bottom: 1px solid colorGrey50;
  }

  & > div {
    flex: 1;
    text-align: center;
    padding-top: 8px;
    padding-bottom: 8px;

    &:nth-child(2n) {
      border-left: 1px solid colorGrey50;
    }
  }
}

.content {
  margin-bottom: 32px;
}

.desrValues {
  display: inline;
}
