@value mobileMax from '~Styles/vars/query.css';
@value colorYellow, colorConcrete from '~Styles/vars/colors.css';

.title {
  margin-bottom: 40px;
  word-break: break-word;
}

.description {
  max-width: 100%;
  word-break: break-word;
}

.item {
  margin-bottom: 20px;
}

.infoTitle {
  margin-bottom: 4px;
}

.infoContent {
  margin-left: 12px;
}

.infoContainer {
  display: grid;
  grid-template-columns: auto auto;
  grid-column-gap: 24px;
}

.infoItem {
  display: flex;
  align-items: flex-start;
}

.cover {
  margin-bottom: 40px;
  border-radius: 20px;
}

.rectangeCover {
  width: 100%;
  height: 300px;
  background-size: cover;
  background-position: center;
}

.squareCover {
  width: 380px;
  height: 380px;
}

.compactCover {
  height: 120px;

  &.squareCover {
    width: 120px;
  }
}

.section {
  margin: 40px auto 0;
  padding-bottom: 40px;
}

.section:not(:last-child) {
  border-bottom: 1px solid colorConcrete;
}

.content {
  width: 720px;
  margin: 40px auto 0;
}

.icon {
  margin-right: 12px;
}

.websiteBtn {
  margin-top: 40px;
}

.sectionTitle {
  margin-bottom: 12px;
}

.brandHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  composes: sectionTitle;
}

.readMore {
  margin-left: 4px;
}

.title {
  margin-bottom: 20px;
}

.productItem {
  width: 165px;
  display: inline-block;
  margin-bottom: 20px;
  margin-right: 20px;

  &:nth-child(4n) {
    margin-right: 0;
  }
}

div.productImage {
  size: 165px;
}

.productTitle {
  margin-top: 8px;
  width: 165px;
  overflow: hidden;
}

@media (max-width: mobileMax) {
  .barter {
    padding: 32px 16px;
  }
}

:global .isMobile {
  :local .cover {
    width: 100% !important;
    height: 40vw !important;
  }
}

:global .isMobile,
.mobileView {
  :local .cover {
    margin: 0 0 12px;
    width: 100%;
    height: unset;

    &.rectangeCover {
      height: 147px;
    }
  }

  :local .barter {
    padding: 32px 16px;
  }

  :local .content {
    width: 100%;
    padding: 0 16px;
    box-sizing: border-box;
  }

  :local .infoContainer {
    grid-template-columns: auto;
  }

  :local .infoDescr {
    flex: 1;
    margin-right: 8px;
  }

  :local .websiteBtn {
    margin-top: 32px;
  }

  :local .productItem {
    width: calc(50% - 16px);
    display: inline-block;
    margin-bottom: 16px;
    margin-right: 16px;
    position: relative;

    &:nth-child(even) {
      margin-right: 0;
    }

    .imageWrap {
      position: relative;
    }

    div.productImage {
      size: 100%;
      position: absolute;
      top: 0;
      left: 0;
    }

    .stub {
      width: 100%;
      height: 0;
      padding-bottom: 100%;
    }

    .productTitle {
      margin-top: 8px;
      width: calc(50% - 16px);
      overflow: hidden;
    }
  }
}
