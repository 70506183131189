@value colorYellow, colorRed, colorWetAsphalt from '~Styles/vars/colors.css';

.root {
  width: 100%;
  padding: 20px;
  border-radius: 4px;
  border: 1px solid colorYellow;
  background-color: rgba(252, 189, 66, 0.1);
  display: flex;
  box-sizing: border-box;
}

.icon {
  flex-shrink: 0;
  margin-right: 12px;

  :global .path {
    fill: colorYellow;
  }
}

.content {
  svg {
    size: 16px;
    vertical-align: middle;

    path {
      fill: colorWetAsphalt;
    }
  }
}


/* COLORS */

.color-red {
  border-color: colorRed;
  background-color: rgba(245, 71, 71, 0.1);

  .icon {
    :global .path {
      fill: colorRed;
    }
  }
}