@value colorWhite, colorGrey50 from '~Styles/vars/colors.css';

.root {
  size: 225px;
  border-radius: 4px;
  box-sizing: border-box;
  overflow: hidden;
  position: relative;
  @mixin center;
  border: 1px solid colorGrey50;
}

.loading {
  @mixin center;
  border-radius: 4px;
  background: rgba(0, 0, 0, 0.5);
}

.preview {
  size: 100%;
  background-size: cover;
  background-position: center;
  cursor: pointer;
}

.video {
  size: 48px;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 50%;
  @mixin center;
  position: absolute;
  pointer-events: none;
}

.deleteIcon {
  size: 24px;
  background-color: rgba(0, 0, 0, 0.5);
  @mixin center;
  cursor: pointer;
  opacity: 0;
  transition: opacity 0.1s ease-in;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
  border-radius: 4px;

  path {
    fill: colorWhite;
  }
}

.root.canDelete {
  &:hover {
    .deleteIcon {
      opacity: 1;
    }
  }
}

.isOther {
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.document {
  position: absolute;
  max-width: 80%;
}

.documentIcon {
  size: 48px;
  margin: 0 auto 12px;

  svg {
    size: 48px;
  }
}