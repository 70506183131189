@value colorConcrete from '~Styles/vars/colors.css';

.root {
  display: block;
}

.projectsWrap {
  margin-top: 12px;
  display: inline-flex;
  align-items: center;
  padding: 6px 12px;
  border-radius: 8px;
  background: colorConcrete;
}

.creatorsWrap {
  display: flex;
  margin-right: 8px;
}

.creatorAva {
  border: 2px solid colorConcrete;
  border-radius: 8px;
}

.creatorAvaWrap {
  &:not(:first-child) {
    margin-left: -10px;
  }
}

.projectsCount {
  display: flex;
}

.addCreators {
  display: block;
}