@value colorGrey10, colorGrey100, colorWhite from '~Styles/vars/colors.css';

.title {
  margin-bottom: 16px;
}

.item {
  border-radius: 16px;
  background: colorGrey10;
  padding: 16px;
  cursor: pointer;
}

.items {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.subtitle {
  color: colorGrey100;
}

.itemTitle {
  display: flex;
  align-items: center;
  gap: 4px;
}

.tag {
  color: colorWhite !important;
}
