@value colorGrey10 from '~Styles/vars/colors.css';
.select {
  width: 170px;
}

.root {
  display: flex;
  gap: 8px;
  padding-top: 4px;
  padding-bottom: 4px;
  width: 100%;
  justify-content: space-between;
}

.previewRoot {
  display: flex;
  padding: 8px 12px;
  background-color: colorGrey10;
  gap: 8px;
  border-radius: 8px;
}

.previewContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-bottom: 32px;
}

.avatarContainer {
  display: flex;
  flex-direction: row-reverse;
  gap: 8px;
}

.avatar {
  width: 24px;
  height: 24px;
  border-radius: 8px;
  border: 2px solid colorGrey10;
  margin-left: -16px;
  position: relative;
  &:first-child {
    margin-left: 0px;
    z-index: 2;
  }
  &:nth-child(2) {
    z-index: 1;
  }
}

.previewDelete {
  cursor: pointer;
}

