.root {
  position: fixed;
  bottom: 100px;
  left: 25px;
  background-color: #5239c4;
  height: 60px;
  width: 60px;
  border-radius: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
  cursor: pointer;
  box-shadow: 0 2px 8px 0px rgba(0, 0, 0, 0.15), 0 4px 6px 0 rgba(82, 57, 196, 0.2)
}

.counter {
  position: absolute;
  right: 2px;
  top: 2px;
  background: red;
  color: white;
  height: 22px;
  width: 22px;
  border-radius: 11px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.widgetContainer {
  position: absolute;
  bottom: 64px;
  left: 0;
  background-color: #5239c4;
  padding: 2px;
  border-radius: 8px;
  box-shadow: '0 4px 8px rgba(0, 0, 0, 0.1)';
  z-index: 3;
  width: 360px;
  min-height: 200px;
  cursor: default;
}

.widget {
  flex: 1;
  background: white;
  border-radius: 8px;
  padding: 16px 12px;
  display: flex;
  gap: 16px;
  flex-direction: column;
  align-items: center;
}

.codeImg {
  height: 120px;
  width: 120px;
}

.closeButton {
  text-decoration: underline;
}

.backImg {
  height: 190px;
  object-fit: contain;
}