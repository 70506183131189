@value colorGrey50, colorGrey100, colorError100 from '~Styles/vars/colors.css';

.drawerContainer {
  display: flex;
  height: 100%;
  width: 516px;
  flex-direction: column;
  justify-content: space-between;

  .drawerClosable {
    display: flex;
    flex-grow: 1;
  }

  .drawerHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;
  }

  .drawerMenu {
    display: flex;
    flex-direction: column;
    gap: 16px;
    .menuItem {
      background-color: white;
      min-height: 56px;
      border-radius: 16px;

      .title {
        font-size: 15px;
        font-weight: 400;
      }
      .subtitle {
        font-size: 12px;
        font-weight: 400;
        color: colorGrey100;
      }

      &.pointer {
        cursor: pointer;
      }
    }
  }
}

.disabled {
  color: colorGrey50 !important;
  cursor: default !important;

  .subtitle {
    color: colorGrey50 !important;
  }
}

.menuItemWrap {
  display: flex;
  gap: 16px;
}

.buttonsRowContainer {
    display: flex;
    gap: 16px;
    & > div {
      width: 250px;
      box-sizing: border-box;
    }
}


