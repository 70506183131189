.info {
  margin-bottom: 100px;
  padding-left: 8px;
  padding-right: 8px;
  position: relative;

  .infoBtn {
    size: 48px;
    padding: 12px;
    box-sizing: border-box;
  }
}

.tooltip {
  position: absolute;
  z-index: 54;
  min-width: unset !important;

  &.withCloseButton {
    padding-right: 48px;
  }
}

.guideTooltip {
  position: absolute;
  top: -50%;
  left: 67px;
  z-index: 1;
  width: 278px;
  box-sizing: border-box;
  min-width: unset !important;
}

.text {
  padding-right: 8px;
}

.close {
  top: 15px;
  right: 12px;
  color: colorWhite;
  position: absolute;
}

.contentWrapper {
  display: grid;
  grid-gap: 8px;
}
