@value colorWhite, colorGrey100 from '~Styles/vars/colors.css';

.root {
  background: colorWhite;
  border-radius: 16px;
  padding: 16px;
  width: 383px;
  min-height: 352px;
  display: flex;
  flex-direction: column;

  &:nth-child(2) {
    width: 350px;
  }
}

.title {
  margin-bottom: 16px;
}

.content {
  flex: 1;
}

.increaseBtn {
  width: 100%;
  box-sizing: border-box;
  justify-content: flex-start;
}
