@value mobileMaxInnerWidth, newDesktopMinWidth from '~Styles/vars/query.css';
@value colorPurple100, colorGrey10 from '~Styles/vars/colors.css';

.root {
  background: colorGrey10;
}

.container {
  @mixin newInnerContainer;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 16px;
}

.title {
  margin-bottom: 24px;
}

.preloaderContainer {
  width: 100%;
}
.preloader {
  margin: 40px auto;
}

.content {
  width: 100%;
}

.params {
  display: flex;
  flex-direction: column;
  min-width: 367px;

  .searchInput {
    margin-bottom: 16px;
  }
}

:global .isMobile {
  :local .content {
    width: 100%;
    margin: 0 auto;
    max-width: mobileMaxInnerWidth;
    padding-left: 16px;
    padding-right: 16px;
    box-sizing: border-box;

    .serachInput {
      display: none;
    }
  }

  :local .title {
    padding-left: 16px;
    padding-right: 16px;
    margin: 12px 0;
  }
}

.drawer {
  background-color: white;
  z-index: 1200;
}
.drawerContainer {
  display: flex;
  height: 100%;
  width: 516px;
  flex-direction: column;
  justify-content: space-between;

  .drawerTitleText {
    margin-bottom: 16px;
  }

  a[href^='mailto:'] {
    color: colorPurple100;
  }

  button {
    width: 100%;
  }
}

.preloaderContainer {
  display: flex;
  justify-content: center;
}
