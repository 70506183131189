@value colorGrey100, colorGrey10, colorGrey50, colorWhite, colorPink100 from '~Styles/vars/colors.css';

.root {
  padding: 16px;
  border-radius: 16px;
  display: flex;
  align-items: center;
  gap: 16px;
  background: colorWhite;
  border: 1px solid colorWhite;

  &:not(.inDropdown) {
    &:hover {
      border-color: colorGrey50;
    }
  }

  &:hover {
    .readMark {
      display: none;
    }

    .readBtn {
      display: block;
    }
  }
}

.link {
  width: 100%;
}

.notViewed {
  &.inDropdown {
    border-radius: 0;
  }
  background: rgba(217, 221, 255, 0.5);
}

.readBtn {
  cursor: pointer;
  display: none;
}

.iconWrap {
  size: 40px;
  @mixin center;
  background: colorGrey10;
  border-radius: 16px;
}

.date {
  color: colorGrey100;
}

.details {
  flex: 1;
}

.icon {
  size: 40px;
}

.readMark {
  size: 8px;
  border-radius: 50%;
  background: colorPink100;
  cursor: pointer;
}

.inDropdown {
  width: 100%;
  padding: 0 16px;
  box-sizing: border-box;

  .readMark {
    order: -1;
  }

  .readBtn {
    order: -1;
  }
}
