@value colorGrey100 from '~Styles/vars/colors.css';

.drawer {
  @mixin drawer;
}

.drawerContainer {
  @mixin drawerContainer;
}

.content {
  flex: 1;
  overflow: auto;
}

.descr {
  margin: 16px 0;
  color: colorGrey100;
}

.buttons {
  display: flex;
  gap: 8px;
  padding-top: 8px;
  padding-bottom: 8px;
  margin-bottom: 24px;
}

.button {
  flex: 1;
  display: flex;
}

.results {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.marketplaceLink {
  display: flex;
}

.closeTooltip {
  cursor: pointer;
  position: absolute;
  top: 12px;
  right: 16px;
}

.tooltipContainer {
  margin-left: -14px;
}

.tooltip {
  padding-right: 40px;
}
