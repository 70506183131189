@value colorGrey100 from '~Styles/vars/colors.css';

.root {
  flex: 1;
  min-width: 0;

  .container {
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 100%;
  }
}

.emptyList {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: colorGrey100;
}
