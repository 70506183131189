@value colorGrey50 from '~Styles/vars/colors.css';

.disabled {
  color: colorGrey50 !important;
  cursor: default !important;

  .subtitle {
    color: colorGrey50 !important;
  }
}

.title {
  font-size: 15px;
  font-weight: 400;
}

.icons {
  display: flex;
  align-items: center;
}
