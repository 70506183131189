@value colorWhite, colorConcrete from '~Styles/vars/colors.css';

.root {
  padding: 10px 12px;
  @mixin vertical-center;
  background: colorWhite;
  transition: background 0.1s ease-in;
  cursor: pointer;

  &:hover {
    background: colorConcrete;
  }
}

div.text {
  white-space: nowrap;
  flex-grow: 1;

  &.hasSecondaryText {
    flex-grow: initial;
  }
}

.checkbox {
  margin-right: 8px;
  flex-shrink: 0;
}

.icon {
  margin-right: 12px;
}

.activeIcon {
  size: 24px;
  flex-shrink: 0;
}

.active {
  .activeIcon {
    background-image: url('~files/images/icons/checkNew.svg');
  }
}

.inGroup {
  padding: 12px 0;

  &:hover {
    background: none;
  }
}

.ava {
  margin-right: 12px;
}