@value colorGrey100, colorGrey50 from '~Styles/vars/colors.css';

.root {
  line-height: 17px;
  height: 100%;
  display: flex;
  align-items: center;
}

.featureTooltipClassName {
  max-width: 200px;
}

.featureTooltipChildrenWrapClassName {
  position: relative;
  bottom: -2px;
}

.infoIcon {
  margin: 0;
  text-align: center;

  &.color-grey100 {
    color: colorGrey100;
  }

  &.color-grey50 {
    color: colorGrey50;
  }
}
