@value colorWhite, colorConcrete from '~Styles/vars/colors.css';

.root {
  width: 100%;
  display: flex;
  align-items: center;
  padding: 14px 20px 13px;
  border-top: 1px solid colorConcrete;
  border-bottom: 1px solid colorConcrete;
  background: colorWhite;
  margin-top: -1px;
  position: relative;
  box-sizing: border-box;

  &:hover {
    z-index: 100;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    border-color: colorWhite;
  }
}

.container {
  display: flex;
  align-items: center;
}

.link {
  width: 100%;
}

.item {
  flex-shrink: 0;
}

.item:not(:last-child) {
  margin-right: 20px;
}

.background-red {
  background: #feeded;

  &:hover {
    border-color: #feeded;
  }
}
