@value colorWhite from '~Styles/vars/colors.css';

.content {
  background: colorWhite;
  width: 471px;
  border-radius: 22px;
  padding: 20px;
  box-sizing: border-box;
}

.drawer {
  @mixin center;
}

.title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}

.cropperWrap {
  size: 431px 296px;
  background-image: url('~files/images/general/stub.png');
  background-repeat: repeat;
  position: relative;
  @mixin center;
}

.changeFile {
  position: relative;
}

.uploader {
  position: absolute;
  top: 0;
  left: 0;
  size: 100%;
}

img.image {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.closeWrap {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 12px;
}

.buttons {
  margin-top: 12px;
  display: flex;
  justify-content: space-between;
}

:global(.ReactCrop__child-wrapper) {
  max-height: 296px !important;
}

:global(.ReactCrop__image) {
  max-height: 296px !important;
}
