@value (
  colorGrass60,
  colorGrass100,
  colorError50,
  colorError100
) from '~Styles/vars/colors.css';

.drawer {
  @mixin drawer;
}

.drawerContainer {
  @mixin drawerContainer;
}

.drawerContent {
  display: flex;
  height: 100%;
  overflow: auto;
  flex-direction: column;
  justify-content: space-between;
}

.text {
  margin-top: 16px;
  height: 400px;
  min-height: 400px;
}

.buttons {
  display: flex;
  gap: 8px;
}

.check {
  color:colorGrass100;
  font-size: 80px !important;
  margin: 0 auto;
  display: block;
}

.checkContainer {
  width: 180px;
  height: 180px;
  border-radius: 50%;
  background-color: colorGrass60;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto 0;
}

.close {
  color:colorError100;
  font-size: 80px !important;
  margin: 0 auto;
  display: block;
}

.closeContainer {
  width: 180px;
  height: 180px;
  border-radius: 50%;
  background-color: colorError50;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto 0;
}
