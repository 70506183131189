@value colorGrey50, colorConcrete from '~Styles/vars/colors.css';

.root {
  padding: 20px 20px 0;
  display: flex;
  flex-wrap: wrap;
}

.file {
  display: flex;
  align-items: center;
}

.text {
  margin-right: 5px;
}

.fileName {
  white-space: nowrap;
}

.preview {
  @mixin center;
  height: 64px;
  border-radius: 4px;
  border: 1px solid colorGrey50;
  box-sizing: border-box;
  margin-bottom: 20px;
  margin-right: 8px;
  overflow: hidden;
  position: relative;

  &:hover {
    .delete {
      opacity: 1;
    }
  }
}

.media {
  width: 62px;
  height: 64px;
  background-size: cover;
  background-position: center;
}

.video {
  @mixin center;

  &::after {
    content: '';
    size: 24px;
    background-color: rgba(0, 0, 0, 0.5);
    background-image: url('~files/images/general/play_white.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: 12px;
    border-radius: 50%;
  }
}

.doc {
  height: 64px;
  max-width: 208px;
  display: flex;
  padding: 20px;
  box-sizing: border-box;
}

.icon {
  margin-right: 12px;
  flex-shrink: 0;
}

.delete {
  position: absolute;
  border-radius: 4px;
  top: 0;
  right: 0;
  cursor: pointer;
  opacity: 0;
  transition: opacity 0.1s ease-in;
}

.pendingPreview {
  size: 64px;
  border-radius: 4px;
  border: 1px solid colorGrey50;
  background: colorConcrete;
  margin-right: 8px;
  margin-bottom: 20px;
  box-sizing: border-box;
  @mixin center;
}
