@value (
  colorGrey50,
  colorGrey100,
  colorSky100,
  colorPink100,
  colorGrass100,
  colorPurple100,
  colorWhite,
  colorSand60,
  colorSand40,
  colorDark100,
  colorSuccess100
) from '~Styles/vars/colors.css';

.video {
  padding: 16px;
  border-radius: 16px;
  border: 1px solid colorGrey50;
  flex: 1;
  max-width: 284px;
  box-sizing: border-box;
  &:hover {
    border: 1px solid colorDark100;
  }
}

.videoIcon {
  cursor: pointer;
  position: absolute;
}

.videoDescr {
  color: colorGrey100;
}

.file {
  width: 100%;
  height: 139px;
  background-color: colorSand40;
  background-size: cover;
  border-radius: 16px;
  margin-top: 24px;
  @mixin center;
  cursor: pointer;
}

.cta {
  margin-top: 32px;
  margin-bottom: 8px;
}

.sky {
  .icon {
    color: colorSky100;
  }
}

.pink {
  .icon {
    color: colorPink100;
  }
}

.purple {
  .icon {
    color: colorPurple100;
  }
}

.successfully {
  .icon {
    color: colorSuccess100;
  }
}

.purple {
  .icon {
    color: colorPurple100;
  }
}

.poster {
  width: 247px;
  border-radius: 16px;
}
