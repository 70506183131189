.root {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 32px;
  margin-bottom: 16px;
}
.leftContainer {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.leftComponent
.rightComponent {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
}