@value briefWidth from '~Styles/vars/size.css';

.root {
  padding-bottom: 64px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 8px;

  .actionsContainer {
    position: fixed;
    bottom: 0;
    width: 100%;
    max-width: briefWidth;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px 0px;

    .actionsContainerButton {
      margin: 0px 8px;
    }
  }
}
.mobileDrawer {
  padding: 0;
}

:global .isMobile {
  :local .root {
    margin-top: 0px;
  }
}
