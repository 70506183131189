@value colorGrey10, colorGrey50, colorDark100 from '~Styles/vars/colors.css';

.drawer {
  background-color: white;
  padding: 0;

  .drawerTitleText {
    margin-bottom: 16px;
  }

  .bulletedList {
    margin-top: 16px;
    margin-bottom: 24px;
  }

  .drawerText {
    margin-bottom: 16px;
  }

  .drawerBullet {
    display: flex;
    margin-bottom: 16px;
  }

  .drawerBulletText {
    margin-left: 8px;
  }

  .buttonContainer {
    display: flex;
    flex-direction: column;
  }

  .button {
    background-color: colorGrey10;
    border-color: transparent;
    margin-bottom: 16px;
    padding: 36px 16px;
    border-radius: 16px;
    color: colorDark100;
    font-weight: 400;

    &:hover {
      &:not(:disabled) {
        border-color: colorGrey50;
      }
    }

    & span {
      margin-right: auto !important;
    }
  }

  .container {
    height: 100%;
    display: flex;
    flex-direction: column;
    padding-bottom: 16px;
    box-sizing: border-box;
  }

  .content {
    flex: 1;
    padding: 16px;
    box-sizing: border-box;
  }
}
