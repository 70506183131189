@value colorGrayDog, colorWetAsphalt, colorGrey50 from '~Styles/vars/colors.css';

:root {
  --time: 0.1s;
  --ease: ease-in;
}

.root {
  display: flex;
  width: 108px;
}

.input {
  border-radius: 4px;
  outline: none;
  font-size: 14px;
  line-height: 16px;
  box-sizing: border-box;
  border: 1px solid colorGrey50;
  color: colorWetAsphalt;
  padding: 10px;
  width: 100%;

  transition: background var(--time) var(--ease),
  border var(--time) var(--ease),
  color var(--time) var(--ease);

  &:hover,
  &:focus {
    border-color: colorGrayDog;
  }

  &::placeholder {
    color: colorGrayDog;
  }
}
