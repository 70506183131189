@value colorGrey10, colorConcrete from '~Styles/vars/colors.css';

:root {
  --sumHeight: 120px;
}

.root {
  right: 0;
  display: flex;
  flex-direction: column;
  background-color: colorGrey10;
  border-left: 1px solid colorConcrete;
  box-sizing: border-box;
}

.header {
  flex: none;
}

.mediaplanItems {
  flex: auto;
  overflow: auto;
}

.footer {
  flex: none;
  height: var(--sumHeight);
}

.search {
  height: 100%;
  display: inline-block;
  vertical-align: top;
  overflow: visible;
}