@value colorDark100, colorGrey50, colorGrey100 from '~Styles/vars/colors.css';

.box {
  width: 16px;
  height: 16px;
  min-width: 16px;
  min-height: 16px;
  margin: 4px;
  border: 2px solid colorGrey50;
  border-radius: 4px;
  box-sizing: border-box;
  position: relative;

  &:not(.checked):not(.disabled):hover {
    border-color: colorGrey100;
  }

  &.alignedRight {
    margin: 0 4px 0 auto;
  }
  .checkboxCheckedItem {
    width: 10px;
    height: 10px;
    background-color: colorDark100;
    border-radius: 2px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &.disabled {
    border-color: colorGrey50;
  }

  &.indeterminate {
    border-color: colorDark100;
  }
}

.clickable {
  cursor: pointer;
}

.checked {
  border: 2px solid colorDark100;
}

.line {
  size: 10px 2px;
  border-radius: 4px;
  background: colorDark100;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
