.root {
  display: flex;
  flex-direction: column;
}

.list {
  overflow: auto;
  flex: auto;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  padding-bottom: 30px;
}

.item {
  flex: none;
}

.emptyList {
  margin-top: 30px;
  text-align: center;
  padding: * 20px;
}

.preloader {
  size: 30px;
}
