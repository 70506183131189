@value (colorSky40, colorBase) from '~Styles/vars/colors.css';

.root {
  position: relative;
  background-color: colorSky40;
  border-radius: 16px;
  padding: 16px 178px 16px 16px;
  box-sizing: border-box;
  margin: 48px 0 32px 0;

  &:after {
    content: "";
    background: url('~files/images/brief/product_seeding_details.png') 0 0 no-repeat;
    background-size: contain;
    width: 146px;
    height: 183px;
    position: absolute;
    bottom: -18px;
    right: 16px;
  }

  .link {
    color: colorBase;
    font-weight: 500;
  }
}
