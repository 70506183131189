@value mobileMaxInnerWidth, newDesktopMinWidth from '~Styles/vars/query.css';
@value colorGrey10, colorSand40, colorGrey100, colorWhite, colorGrey10 from '~Styles/vars/colors.css';

.root {
  background: colorGrey10;

  .title {
    padding-top: 16px;
    padding-bottom: 8px;
  }

  .content {
    padding-top: 16px;
  }

  .container {
    @mixin newInnerContainer;
    margin-bottom: 32px;

    .chartsContainer {
      display: grid;
      grid-gap: 16px;
      grid-template-columns: repeat(2, 1fr);

      > * {
        height: 100%;
      }
    }
  }

  .spinner {
    position: relative;
    top: calc(50% - 20px);
    left: calc(50% - 20px);
  }
}

.warning {
  padding: 16px 20px;
  background-color: colorSand40;
  border-radius: 20px;
  display: inline-block;
  margin-bottom: 16px;
}

.titleStub {
  padding-top: 16px;
}

.descr {
  color: colorGrey100;
}

.newCampaign {
  padding: 16px 0;
}

.filters {
  position: relative;
}

.filtersStub {
  position: absolute;
  size: 100%;
  top: 0;
  left: 0;
}

.chartStub {
  background-color: colorWhite;
  padding: 16px;
  border-radius: 16px;
}

.chartsStub {
  padding: 16px;
}

.values {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 32px;
  row-gap: 16px;

  & > div {
    flex: 1;
    color: colorGrey100;
    padding: 16px 8px;

    &::before {
      content: '';
      display: block;
      size: 50% 8px;
      max-width: 50px;
      margin: 4px 0;
      box-sizing: border-box;
      border-radius: 16px;
      background: colorGrey10;
    }
  }
}

.chartTitleStub {
  margin-bottom: 0;
}
