@value colorGrey50,colorDark100, colorWhite from '~Styles/vars/colors.css';

.button {
  border-radius: 8px;
  &.active {
    border: 1px solid colorDark100 !important;
  }
}

.root {
  width: 268px;
  box-sizing: border-box;
  padding: 8px;
  border: 1px solid colorGrey50;
  border-radius: 8px;
  background-color: colorWhite;
  z-index: 200;
  position: absolute;
  right: 0;
  top: calc(100% + 8px);
  cursor: default;
  .option {
    display: flex;
    padding: 12px 16px;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    cursor: pointer;
  }
  .label {
    box-sizing: border-box;
    padding: 8px 16px;
    width: 100%;
  }
  .rowContainer {
    padding: 16px;
    box-sizing: border-box;
    width: 100%;
    .row {
      background-color: colorGrey50;
      height: 1px;
      width: 100%;
    }
  }
  .buttonContainer {
    width: 100%;
    box-sizing: border-box;
    padding: 8px 16px;
    .button {
      width: 100%;
      display: flex;
      padding: 12px 0;
      justify-content: center;
      box-sizing: border-box;
    }
  }
}
