@value colorError50 from '~Styles/vars/colors.css';

.drawer {
  background-color: white;
  padding-top: 8px;

  .titleTextPreset {
    margin-bottom: 16px;
  }

  .contentRoot {
    display: flex;
    flex-direction: column;
    height: calc(100vh - 64px);
    margin: 0 -16px;
    gap: 8px;
    justify-content: space-between;
  }

  .subtitleText {
    margin-left: 16px;
  }

  .trashIcon {
    width: 180px;
    height: 180px;
    border-radius: 90px;
    margin: 0 auto;
    background-color: colorError50;
    background-image: url('../../../../../files/images/icons/trash-delele.svg');
    background-position: center;
    background-repeat: no-repeat;
  }

  .submitButtonWrapper {
    box-sizing: border-box;
    position: relative;
    width: 100%;
    margin-top: 32px;

    .submitButton {
      box-sizing: border-box;
      width: 100%;
    }
  }
}
