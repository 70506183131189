.uploader {
  position: absolute;
  top: 0;
  left: 0;
  size: 100%;
}
.uploaderWrapper {
  position: relative;
  display: inline-flex;
  width: 100%;
}