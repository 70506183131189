@value colorWhite from '~Styles/vars/colors.css';

.root {
  width: 100%;
  background-color: colorWhite;
  border-radius: 4px;
  margin-bottom: 12px;
  padding: 20px;
  box-sizing: border-box;
  position: relative;

  &:nth-last-of-type {
    margin-bottom: 0;
  }
}

.header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}