.tag {
  position: absolute;
  top: 4px;
  right: 4px;
  z-index: 1;
}

.itemContainer {
  img, video {
    position: absolute;
    object-fit: cover;
    object-position: center;
    z-index: 0;
    height: 100%;
    width: 100%;
  }
}