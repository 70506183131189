.referralRateInput {
  input {
    border-radius: 4px !important;
    &:focus {
      border: 1px solid #4e42e4;
      box-shadow: 0 0 0 1px #4e42e4;
    }
  }
}

.warning {
  margin-top: -24px;
}
