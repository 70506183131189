@value colorWhite, colorGrey100 from '~Styles/vars/colors.css';

.list {
  display: flex;
  flex-direction: column;
  margin: 2px 0 0; 
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  overflow: hidden;
}

.drawer {
  background-color: colorWhite;
}

.emptyWrap {
  size: 100% 300px;
  @mixin center;
  flex-direction: column;
  color: colorGrey100;
  text-align: center;
  gap: 8px;
}

.listHeader {
  display: grid;
  grid-template-columns: 212px 176px 90px 90px 90px 264px 182px;
  grid-column-gap: 8px;
  width: 100%;
  padding: 16px;
  background-color: white;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  box-sizing: border-box;
  margin-top: 32px;

  .listHeaderText {
    color: colorGrey100;
    cursor: default;
    &.profile {
      text-align: center;
    }
  }
}