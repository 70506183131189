.root {
  position: relative;
}

.anchor {
  cursor: pointer;
}

.menu {
  z-index: 2;
  position: absolute;
  display: none;
}

.open {
  display: block;
}

.menuItem {
  display: block;
}

.position-topLeft {
  .menu {
    bottom: 100%;
    margin-bottom: 8px;
  }
}

.position-topRight {
  .menu {
    bottom: 100%;
    right: 0;
    margin-bottom: 8px;
  }
}

.position-bottomLeft {
  .menu {
    margin-top: 8px;
  }
}

.position-bottomRight {
  .menu {
    margin-top: 8px;
    right: 0;
  }
}

.position-rightCenter {
  .menu {
    margin-top: -50%;
    left: 100%;
  }
}