@value colorWhite, colorGrey50 from '~Styles/vars/colors.css';

.root {
  width: 100%;
  background-color: colorWhite;
  border-radius: 4px;
  margin-bottom: 12px;
  box-sizing: border-box;
}

.item {
  padding: 20px;
  display: flex;
  justify-content: space-between;

  .textContainer {
    max-width: calc(100% - 90px);
  }

  &:not(:last-child) {
    border-bottom: 1px solid colorGrey50;
  }
}
