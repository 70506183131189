@value newDesktopMinWidth, mobileMaxInnerWidth from '~Styles/vars/query.css';

.root {
  @mixin newInnerContainer;
}

.title {
  padding-top: 16px;
  padding-bottom: 16px;
}

.spinner {
  margin: 40px auto;
}

.filters {
  margin-bottom: 32px;
}

.textInput {
  padding: 20px;
  min-width: 240px;
}
