@value colorWhite, colorConcrete from '~Styles/vars/colors.css';

.root {
  width: 100%;
  background-color: colorWhite;
  transition: background 0.1s ease-in;
  text-align: left;

  &:not(.isDisabled):hover {
    cursor: pointer;
    background-color: colorConcrete;

    &.hasIcon.hasActionElement {
      .icon {
        display: none;
      }
    }

    &.hasActionElement {
      .icon {
        display: none;
      }

      .actionElement {
        display: block;
      }
    }

  }
}

.hasActionElement {
  .actionElement {
    display: none;
  }
}

.root.isDisabled {
  cursor: default;
  background-color: colorConcrete;
}

.content {
  @mixin vertical-center;
  padding: 12px 20px;
}

.subElement {
  opacity: 1;
}

.child {
  flex-grow: 1;
  overflow: hidden;
}