.root {
  width: 477px;
}

.title {
  margin-bottom: 16px;
  padding-top: 8px;
}

.input {
  height: 72px;
  box-sizing: border-box;
  margin-bottom: 16px;
}
