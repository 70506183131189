@value colorConcrete, colorPurple100 from '~Styles/vars/colors.css';

.root {
  width: 100%;
  height: 64px;
  box-sizing: border-box;
  padding: 12px 20px;
  display: flex;
  align-items: center;
  transition: background 0.1s ease-in;
  position: relative;
}

.root:hover {
  background: colorConcrete;

  .actions {
    display: flex;
  }

  .price {
    display: none;
  }
}

.ava {
  margin-right: 12px;
}

.info {
  flex-grow: 1;
  overflow: hidden;
}

.username {
  display: block;
}

.usernameLink {
  overflow: hidden;
}

.details {
  flex-shrink: 0;
}

.price {
  text-align: right;
}

.control {
  margin-left: 12px;
}

.actions {
  display: none;
}

.archiveBtn {
  margin-left: 8px;
}

.blockedName {
  position: relative;
}

.blured {
  padding-left: 3px;
  filter: blur(4px);
}

.lockIcon {
  top: 4px;
  position: absolute;
  color: colorPurple100;
  cursor: pointer;
  z-index: 1;
}
