@value colorWhite, colorGrey100 from '~Styles/vars/colors.css';

.list {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin: 16px 0;
  max-height: calc(100vh - 344px);
  overflow-y: scroll;
}

.drawer {
  background-color: colorWhite;
}

.emptyWrap {
  size: 100% 300px;
  @mixin center;
  flex-direction: column;
  color: colorGrey100;
  text-align: center;
  gap: 8px;
}

.elementSkeleton {
  size: 100% 140px !important;
}
