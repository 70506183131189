.root {
  display: block;
}

div.item {
  display: inline-flex;
  vertical-align: top;
  size: 180px;

  &:not(:nth-of-type(3n)) {
    margin-right: 20px;
    margin-bottom: 20px;
  }
}

.uploadFile {
  size: 180px;
  position: relative;
  vertical-align: top;
  display: inline-flex;
  margin-right: 20px;
}

.stub {
  size: 180px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin-right: 20px;
  background-color: rgba(0, 0, 0, 0.8);
  border-radius: 4px;
}

:global .isMobile {
  :local .root {
    div.item {
      size: 288px;
      margin-bottom: 20px;

      &:not(:nth-of-type(3n)) {
        margin-right: 0;
        margin-bottom: 20px;
      }
    }

    .content {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .uploadFile {
      size: 288px;
      margin-right: 0;
      margin-bottom: 20px;
    }

    .stub {
      size: 288px;
    }
  }
}