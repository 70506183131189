@value colorGrey100, colorGrey10, colorWhite from '~Styles/vars/colors.css';

.root {
  padding: 16px;
  background: colorWhite;
  display: flex;
  justify-content: space-between;
  border-bottom: 2px solid colorGrey10;
  align-items: center;
}

.creatorInfo {
  display: flex;
  gap: 16px;
}

.badges {
  display: flex;
  gap: 4px;
}

.badgeIcon {
  size: 16px;
}

.badge {
  padding: 0;
}

.controls {
  display: flex;
  gap: 8px;
}

.countries {
  color: colorGrey100;
}

.details {
  display: flex;
  align-items: center;
  gap: 4px;
}

.tooltip {
  z-index: 2;
}
