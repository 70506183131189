/* main colors */

@value colorWhite: #ffffff;
@value colorWetAsphalt: #404040;

@value colorLightOcean: rgba(217, 221, 255, 0.5);
@value colorLightBeige: rgba(237, 225, 214, 0.5);

@value colorDark: #333333;
@value colorDark100: #333333;
@value colorDark40: rgba(0, 0, 0, 0.4);
@value colorDark70: rgba(0, 0, 0, 0.7);

@value darkBase: #3F35B6;
@value colorBase: #4E42E4;
@value colorDarkBase: #3F35B6;
@value lightBase: rgba(78, 66, 228, 0.1);

/* blue */

@value kashmirBlue: #475993;
@value creamCan: #1D63CB;
@value colorBlue: #42B9FC;
@value colorCreamCan: #1D63CB;
@value colorTurquoise: #11BBEE;

/* gray */

@value colorConcrete: #F2F2F2;
@value colorGrayDog: #AFAFAF;
@value colorGrey10: #F9F9F9;
@value colorGrey20: rgba(255, 255, 255, 0.2);
@value colorGrey50: #C2C2C2;
@value colorGrey80: rgba(255, 255, 255, 0.8);
@value colorGrey100: #888888;

/* green */

@value colorGreen: #1FAC4F;
@value colorOlive: #8CB448;
@value colorApproval100: #62DF5D;
@value colorGrass100: #62DF5D;
@value colorGrass80: #70EF9A;
@value colorGrass60: #A6F3BD;
@value colorGrass40: #D4FBE0;

/* red */

@value colorRed: #F54747;

/* yellow */

@value colorOrange: #F88444;
@value colorYellow: #FCBD42;

/* pink */

@value colorPink100: #F740C4;
@value colorPink80: #FEAAFE;
@value colorPink60: #FFC5FF;
@value colorPink40: #FFE2FF;
@value colorNewPink: #CC2E9F;

/* ocean */
@value colorOcean10: #D9DDFF;
@value colorOcean40: #CDDBE2;
@value colorOcean80: #5F91A5;
@value colorOcean100: #134863;

/* sand */
@value colorSand40: #F3F0ED;
@value colorSand60: #EDE1D6;
@value colorSand80: #E2D2C3;
@value colorSand100: #B9A080;

/* purple */
@value colorPurple60: #B9BAFF;
@value colorPurple40: #D9DDFF;
@value colorPurple100: #5962FF;
@value colorPurple80: #9297FF;

/* Brown */
@value colorBrown100: #C7894F;
@value colorBrown60: #F1D1B6;
@value colorBrown40: #F9E9DC;

/* Sun */
@value colorSun100: #FF5900;
@value colorSun60: #FFBF97;
@value colorSun40: #FFDFCD;

/* Error */
@value colorError100: #FF0000;
@value colorError50: #FFEFEE;

/* Success */
@value colorSuccess100: #189912;

/* sky */
@value sky100: #01CAE5;
@value colorSky100: #01CAE5;
@value colorSky60: #B4FBFF;
@value colorSky40: #D8FCFF;

/* sun */
@value sun60: #FFBF97;
@value colorSun60: #FFBF97;
@value colorSun80: #FF9C5E;

@value colorSuccessful100:#189912;
