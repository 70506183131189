@value colorGrey100 from '~Styles/vars/colors.css';

.root {
  margin-top: 48px;
  margin-bottom: 64px;
}

.details {
  display: flex;
  gap: 4px;
  margin-top: 8px;
}

.badge {
  padding: 0;
}

.creator {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 16px;
}

.deals {
  color: colorGrey100;
}

.descr {
  text-align: center;
}

.tags {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 12px;
  margin: 14px auto 0;
  width: 460px;

  .tag {
    font-size: 15px;
    line-height: 24px;
    padding: 6px 8px;
  }
}
