@value (  colorGrey10, colorWhite ) from '~Styles/vars/colors.css';

.wrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
  background: colorWhite;
  min-height: 100vh;
  margin: 0;
  padding: 0;
}

.drawerContainer {
  z-index: 9999;
}

.header {
  width: 100%;
  height: 64px;
  border-bottom: 1px solid colorGrey10;
}

.logo {
  width: 103px;
  margin-left: 50px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.drawerBackdrop {
  background: colorWhite;
  opacity: 1 !important;
}

.imageWrapper {
  width: 100%;
  max-width: 716px;
  margin-top: 60px;
  margin-bottom: 60px;

  img {
    width: 100%;
  }
}

.textWrapper {
  max-width: 720px;
  text-align: center;
}

.title {
  overflow: hidden;
  margin-top: 20px;
}

.footer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 0px;
  margin-bottom: 20px;
}

.footerBtn {
  max-width: 720px;
}

:global .isMobile {
  :local .logo,
  :local .header {
    display: none;
    height: 0;
  }
  :local .wrapper {
    padding: 16px;
  }
  :local .imageWrapper {
    margin-top: 30px;
    margin-bottom: 50px;
  }
  :local .footer {
    margin-bottom: 100px;
  }
}
