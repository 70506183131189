@value colorGrey100,colorError100,colorSun100, colorWhite,colorSuccessful100 from '~Styles/vars/colors.css';

.root {
  background: colorWhite;
  border-radius: 16px;
  display: flex;
  justify-content: space-between;
  min-height: 104px;
  padding: 16px 16px 16px 0;
  align-items: flex-start;
}

.creator {
  grid-column: 2;
  max-width: 168px;
}

.content {
  display: grid;
  grid-template-columns: 56px 168px 138px 220px 150px 1fr 88px;
  justify-content: space-between;
  justify-items: center;
  width: 100%;
  align-items: flex-start;
}

.count {
  color: colorGrey100;
  width: 140px;
  box-sizing: border-box;
  padding: 8px 16px;
}

.controls {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  gap: 8px;
  grid-column: 7;
}

.email {
  grid-column: 4;
  justify-self: flex-start;
  padding-left: 34px;
  max-width: 200px;
  word-break: break-word;
}

.phone {
  grid-column: 5;
}

.address {
  grid-column: 6;
}

.orderStatus {
  grid-column: 3;
}

.checkboxContainer {
  grid-column: 1;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  height: 104px;
  width: 100%;
  &.pointer {
    cursor: pointer;
  }
}
.error {
  color: colorError100 !important;
}

.notCreated {
  color: colorSun100 !important;
}

.created {
  color: colorSuccessful100 !important;
}

.default {
  color: colorGrey100 ;
}
