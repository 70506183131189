@value colorConcrete from '~Styles/vars/colors.css';

.root {
  display: flex;
  justify-content: space-between;
}

.button {
  &:not(:first-child) {
    margin-left: 20px;
  }
}

.link {
  display: block;
}

.buttons {
  display: flex;
}

.download {
  padding: 8px 0;
  border-top: 1px solid colorConcrete;
}

.saveCampaign {
  flex: 1;
}