.drawer {
  @mixin drawer;
}

.drawerContainer {
  @mixin drawerContainer;
}

.title {
  margin-bottom: 16px;
}

.content {
  flex: 1;
}

.input {
  min-height: 263px;
}
