@value colorWhite, colorGrayDog from '~Styles/vars/colors.css';

.root {
  display: none;
  text-align: center;
  size: 60px 100%;
  padding: 20px 18px;
  box-sizing: border-box;
  position: fixed;
  left: 0;
  color: colorGrayDog;
  background: colorWhite;
  transition: background 0.1s ease-in;

  &:hover {
    background: rgba(74, 74, 74, 0.1);

    .icon,
    .text {
      opacity: 1;
    }
  }
}

.text,
.icon {
  transition: opacity 0.1s ease-in;
}

.icon {
  transform: rotate(90deg);
}

.show {
  display: block;
}
