@value colorGrey10 from '~Styles/vars/colors.css';

.header {
  border-bottom: 1px solid colorGrey10;
}

.preloaderContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
}