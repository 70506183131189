@value (colorWhite, colorGrey10, colorGrey50) from '~Styles/vars/colors.css';
@value desktopMinWidth, mobileMaxInnerWidth from '~Styles/vars/query.css';
@value headerHeight from '~Styles/vars/size.css';

.root {
  width: 100%;
  min-height: headerHeight;

  &.hidden {
    display: none;
  }

  &.contractor {
    min-height: 132px;
  }
}

.wrap {
  background: colorWhite;
  top: 0;
  position: fixed;
  z-index: 55;
  border-bottom: 1px solid colorGrey50;
  min-height: headerHeight;
  width: 100%;
}

.content {
  size: 100% headerHeight;
  display: flex;
  margin: 0 auto;
  align-items: center;
  box-sizing: border-box;
  justify-content: space-between;
  padding: 0 25px;

  &.withDesktopMinWidth {
    max-width: calc(desktopMinWidth + 50px);
  }
}

.contractorHeaderContent {
  display: flex;
  margin: 0 auto;
  align-items: center;
  flex-direction: column;
  box-sizing: border-box;
  justify-content: space-between;
  min-height: 134px;
}

.fmpBadge {
  margin-top: 6px;
  margin-left: 20px;
}

.logo {
  size: 101px 33px;
}

.menuWrap,
.userWrap {
  display: flex;
  align-items: center;
}

.userWrap {
  display: flex;
  height: 100%;
}

.item {
  margin-right: 20px;
}

.login {
  margin-right: 8px;
}

.admin {
  top: 0;
  left: 50%;
  position: absolute;
  margin-left: -32px;
}

:global .isMobile {
  :local .root {
    min-width: auto;

    .content {
      width: 100%;
      max-width: mobileMaxInnerWidth;
      padding: 0 16px;
    }
    &.contractor {
      min-height: 64px;
    }
  }
}
