@value colorWhite, colorGrey100 from '~Styles/vars/colors.css';

.root {
  background: colorWhite;
  border-radius: 16px;
  padding: 16px;
  width: 383px;
  display: flex;
  flex-direction: column;
}

.item {
  margin-bottom: 16px;
}

.title {
  margin-bottom: 16px;
}

.subtitle {
  color: colorGrey100;
}

.freeDescr {
  padding: 8px 0;
}

.changePlanLink {
  width: 100%;
  .changePlan {
    width: 100%;
    box-sizing: border-box;
    align-items: center;
    justify-content: center;
  }
}


.content {
  flex: 1;
}

.pauseButtonContainer {
  flex: 1;
}

.buttonContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
}