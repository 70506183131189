@value colorError100, colorError50, colorGrey50 from '~Styles/vars/colors.css';

.drawer {
  @mixin drawer;
}

.drawerContainer {
  @mixin drawerContainer;
  justify-content: flex-start;
}

.content {
  flex: 1;
}

.title {
  margin-bottom: 16px;
}

.dropdownGroup {
  width: 100%;
  height: 200px;
  overflow: hidden;
  overflow-y: scroll;
}

.dropdown {
  border: 1px solid colorGrey50;
  border-radius: 16px;
  padding: 24px 16px;
  max-height: initial;
  margin-bottom: 24px;

  &.error {
    background-color: colorError50;
    border-color: colorError100;
  }
}

.dateRangeInput {
  width: 100%;
  height: 74px;
  margin-bottom: 24px;
}

.amount {
  min-height: 74px;
  margin-bottom: 24px;
}

.comment {
  min-height: 148px;
  margin-bottom: 24px;
}
