@value colorConcrete, colorWetAsphalt from '~Styles/vars/colors.css';

.root {
  padding: 20px;
  border-radius: 8px;
  background: colorConcrete;
  display: flex;
  margin-bottom: 12px;
  min-height: 48px;
}

.creatorsWrap {
  display: flex;
  margin-right: 12px;
}

.creatorsLoading {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.creatorAva {
  border: 2px solid colorConcrete;
}

.creatorAvaWrap {
  &:not(:first-child) {
    margin-left: -12px;
  }
}

.countWrap {
  display: flex;
}

.totalCount {
  &::after {
    content: '';
    margin: 0 8px;
    display: inline-block;
    size: 4px;
    border-radius: 50%;
    background: colorWetAsphalt;
    vertical-align: middle;
  }
}