.content {
  margin-top: 20px;
}

.disclaimer {
  margin-top: 12px;
}

.btn {
  margin-bottom: 20px;
}