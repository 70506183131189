@value colorDark100, colorGrey50, colorGrey100, colorWhite, colorError100, colorError50 from '~Styles/vars/colors.css';

.root {
  position: relative;
  width: 100%;
  box-sizing: border-box;

  &.withValue {
    textarea {
      padding-right: 42px;
    }
    &.withLabel {
      textarea {
        padding-top: 32px;
      }
    }
  }

  &.forceLabelShow {
    textarea {
      padding-top: 32px;
    }
  }

  &.error {
    textarea,
    .label {
      background-color: colorError50;
      border-color: colorError100 !important;
    }
  }

  .label {
    position: absolute;
    top: 5px;
    left: 16px;
    z-index: 2;
    font-size: 12px;
    color: colorGrey100;
    background-color: colorWhite;
    margin: 4px 0 0;
    padding-top: 4px;
    width: calc(100% - 52px);
  }

  textarea {
    height: 100%;
    max-height: 264px;
    width: 100%;
    background-color: colorWhite;
    padding: 24px 16px;
    border: 1px solid transparent;
    border-radius: 16px;
    box-sizing: border-box;
    z-index: 1;
    font-size: 14px;
    outline: none;
    resize: none;

    &::-webkit-resizer {
      display: none;
    }

    &.bordered {
      border: 1px solid colorGrey50;
      &.withValue {
        border-color: colorDark100;
      }
    }

    &:hover {
      border: 1px solid colorGrey100;
    }

    &:focus {
      border: 1px solid colorDark100;
    }
  }

  .clearButton {
    position: absolute;
    z-index: 2;
    right: 16px;
    top: 50%;
    transform: translateY(-50%);
  }

  .lengthCounter {
    margin-top: 8px;
    color: colorGrey100;
    font-size: 12px;
  }
}
