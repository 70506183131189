:global {
  html body {
    min-width: auto;
  }
}

.root {
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;

  .errorText {
    padding: 0 32px;
  }

  .strongText {
    font-weight: 600;
  }
}
