@value colorWhite from '~Styles/vars/colors.css';

.root {
  position: absolute;
  width: 100%;
  z-index: 101;
  padding: 8px 0;
  border-radius: 4px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  background: colorWhite;
  max-height: 170px;
  overflow: auto;
}