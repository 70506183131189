.preloader {
  margin: 20px auto;
}

.startLoadButton {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 16px;
}
