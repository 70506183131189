@value colorGrey100, colorPink60 from '~Styles/vars/colors.css';

.drawer {
  background-color: white;
}

.root {
  .progressTitle {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    box-sizing: border-box;
    color: colorGrey100;
    margin-bottom: 16px;
  }
}

.controls {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.row {
  display: flex;
  gap: 8px;
  margin-bottom: 24px;
}

.search {
  flex: 1;
}

.actionBtn {
  justify-content: center;
}

.addBrandButton {
  padding: 11px 32px;
}

.resumePlanTooltipLink {
  color: colorPink60;
  margin-top: 8px;
}
