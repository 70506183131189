.root {
  display: block;
  padding: 20px;
}

.header {
  display: flex;
  margin-bottom: 20px;
}

.title {
  flex-grow: 1;
  text-transform: uppercase;
}

.deadlineText {
  margin-bottom: 16px;
}