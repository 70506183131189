.root {
  width: 46px;
}
.group {
  width: 240px;
  max-height: 240px;
  overflow: auto;

}
.item {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-right: 16px;
}