@value colorPurple80, colorGrey50, colorGrey20, colorGrey100, colorDark100, colorGrey10, colorWhite, colorSuccessful100, colorRed from '~Styles/vars/colors.css';

.root {
  position: relative;
  padding: 12px 16px;
  border-radius: 8px;
  border: 1px solid transparent;
  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 48px;
  &:hover {
    background-color: colorGrey10;
    border: 1px solid colorGrey100;
  }
  &.dirty:hover {
    border: 1px solid colorGrey100;
  }
  &.bordered {
    border-color: colorGrey50;
  }
  &.error {
    border-color: colorRed;
  }
}

.open {
  background-color: colorGrey10;
  border: 1px solid colorGrey100;
}

.container {
  position: absolute;
  top: calc(100% + 8px);
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  padding: 8px;
  border-radius: 8px;
  border: 1px solid colorGrey50;
  z-index: 50;
  max-height: 385px;
  overflow-y: auto;
  min-width: 260px;
  width: 100%;
  cursor: unset;
  background-color: colorWhite;
}

.checkboxItem {
  padding: 16px 12px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 8px;
  gap: 8px;
  &.withIcon {
    justify-content: flex-start;
  }
  &:hover {
    background-color: colorGrey10;
    cursor: pointer;
  }
}

.checkboxItemContent {
  display: flex;
  gap: 8px;
  align-items: center;
  flex: 1;
}

.dirty {
  border: 1px solid colorDark100;
  background-color: colorWhite;
}

.disabled {
  cursor: default;
  background: colorGrey50;

  &:hover {
    background: colorGrey50;
  }
}

.titleWrap {
  flex: 1;
}

.box {
  width: 16px;
  height: 16px;
  min-width: 16px;
  min-height: 16px;
  margin: 4px;
  border: 2px solid colorGrey100;
  border-radius: 4px;
  box-sizing: border-box;
  position: relative;
  &.alignedRight {
    margin: 0 4px 0 auto;
  }
  .checkboxCheckedItem {
    width: 10px;
    height: 10px;
    background-color: colorDark100;
    border-radius: 2px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.checked {
  border: 2px solid colorDark100;
}

.disable {
  background: colorGrey10;
  border-radius: 0;
  cursor: default;
}

.searchInput {
  border-radius: 8px !important;
  margin-bottom: 16px;
  flex-shrink: 0;
}

.label {
  padding: 8px 12px;
  cursor: unset;
  &.centered {
    text-align: center;
  }
}

.placeholder {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.accentSelect {
  border: 1px solid colorSuccessful100 !important;
}

.clearBtnWrap {
  padding-top: 8px;
  padding-left: 12px;
  padding-right: 12px;
  width: 100%;
  box-sizing: border-box;
}

.clearBtn {
  @mixin center;
  width: 100%;
  box-sizing: border-box;
}

.content {
  overflow: auto;
  flex: 1;
}

.subtitle {
  color: colorGrey100;
}

.title,
.subtitle {
  display: inline;
}

.bottomLeft {
  .container {
    left: 0;
  }
}

.bottomRight {
  .container {
    right: 0;
  }
}
