.root {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  width: 100%;

  .value {
    display: flex;
    align-items: center;
    flex: 1;
    width: 100%;
  }
}
