@value colorBase, colorWhite, colorGrey50, colorError100 from '~Styles/vars/colors.css';

.caption {
  margin-bottom: 8px;
}

.tabs > * {
  flex: 1;
}

.customAmount {
  margin-top: 20px;

  input[type='number']::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }

  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
  }
}

.minimumAmount {
  margin-top: 8px;
  color: colorGrey50;
}

.input {
  height: 72px;
  box-sizing: border-box;
}

.error {
  color: colorError100;
}
