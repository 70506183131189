.input {
  width: 400px;
}

.form {
  margin-top: 40px;
  display: flex;
}

.btn {
  margin-left: 20px;
}

.result {
  margin-top: 12px;
}