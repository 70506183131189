@value colorWhite, colorPink60, colorDark100 from '~Styles/vars/colors.css';

.root {
  position: fixed;
  left: 100px;
  bottom: 12px;
  z-index: 1000;
  gap: 16px;
  display: flex;
  flex-direction: column;
}

.title {
  color: colorWhite;
}

.link {
  color: colorPink60;
  cursor: pointer;
}

.notification {
  padding: 12px 16px;
  background: colorDark100;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  gap: 8px;
}
