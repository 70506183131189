@value colorWhite, colorGrey100, colorGrey10 from '~Styles/vars/colors.css';
@value desktopMinWidth from '~Styles/vars/query.css';

.container {
  background: colorWhite;
  border-radius: 16px;
  width: 100%;
}

.header {
  padding: 0 16px;
  width: 100%;
  box-sizing: border-box;
  display: flex;
}

.dateItem {
  width: 120px;
  min-width: 120px;
}

.typeItem,
.brandItem,
.campaignType,
.amountItem {
  width: 257px;
  min-width: 257px;
  box-sizing: border-box;
}

.item {
  padding: 16px;
  border-bottom: 1px solid colorGrey10;
}

.headerItem {
  color: colorGrey100;
}

.emptyList {
  margin-top: 140px;
  color: colorGrey100;
  gap: 8px;
  text-align: center;
}

.walletIcon {
  margin-bottom: 8px;
  display: inline-block;
}

.clearBtn {
  margin: 16px auto 0;
}
