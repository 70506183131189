@value colorGrey100, colorGrey50, colorConcrete from '~Styles/vars/colors.css';

.root {
  display: flex;
  gap: 4px;
  margin-bottom: 16px;
}

.dates {
  display: flex;
  gap: 4px;
  align-items: center;
  .date {
    width: 154px;
    box-sizing: border-box;
    border-color: transparent;
  }
}

.separator {
  color: colorGrey100;
}

.selector {
  flex: 1;
  min-width: 155px;
  max-width: 260px;
  opacity: 1;
}

.selectorLabel {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  color: colorGrey100;
}

.dropdownList {
  max-height: 50vh;
  overflow-y: auto;
}

.clearBtn {
  width: 100%;
  padding: 12px 20px 20px;
  border-bottom: 1px solid colorConcrete;
}

.group {
  width: 100% !important;
}

.selector.dirty {
  border: 1px solid colorGrey50;

  .selectorLabel {
    color: unset;
  }
}
