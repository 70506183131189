@value colorWhite, colorBase, colorDarkBase, colorGrey50, colorWetAsphalt, colorGrayDog, colorRed, colorGreen from '~Styles/vars/colors.css';

:root {
  --time: 0.1s;
  --ease: ease-in;
}

/* GENERAL */

.root {
  outline: none;
  border: none;
  @mixin vertical-center;
  padding: 0;
  background: none;
  border-radius: 4px;
  transition: color var(--time) var(--ease), border var(--time) var(--ease);

  &:not(.disabled) {
    cursor: pointer;
  }
}

.root > * {
  flex-shrink: 0;

  &:not(:last-child):not(:only-child) {
    margin-right: 12px;
  }
}

.text {
  font-family: 'Aeroport';
  font-weight: 500;
}

/* ________ */

/* MODIFICATIONS */

.overflow-true {
  .text {
    flex: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.flex-column.root {
  flex-direction: column;

  & > * {
    &:not(:last-child):not(:only-child) {
      margin-right: 0;
      margin-bottom: 4px;
    }
  }

}

/* SIZES */

.size-sm {
  .text {
    font-size: 12px;
    line-height: 16px;
  }
}

.size-md {
  .text {
    font-size: 14px;
    line-height: 24px;
  }
}

/* WEIGHT */

.weight-400 {
  .text {
    font-family: 'Aeroport';
  }
}

/* DISPLAY */

.display-inline {
  display: inline;
}

/* MAIN COLORS */

.mainColor-red {
  color: colorRed;
}

.mainColor-green {
  color: colorGreen;
}

.mainColor-black {
  color: colorWetAsphalt;
}

.mainColor-grayDog {
  color: colorGrayDog;
}

.mainColor-white {
  color: colorWhite;
}

/* COLORS */

.color-primary {
  color: colorBase;
  border-color: colorBase;

  :global .path {
    fill: colorBase;
  }

  &:not(.disabled) {
    &.loading,
    &:hover {
      color: colorDarkBase;
      border-color: colorDarkBase;

      :global .path {
        fill: colorDarkBase;
      }
    }
  }

  &.disabled {
    color: colorGrey50;
    border-color: colorGrey50;

    :global .path {
      fill: colorGrey50;
    }
  }
}

.color-secondary {
  color: colorWetAsphalt;
  border-color: colorWetAsphalt;

  :global .path {
    fill: colorWetAsphalt;
  }

  &:not(.disabled) {
    &.loading,
    &:hover {
      color: colorBase;
      border-color: colorBase;

      :global .path {
        fill: colorBase;
      }
    }
  }

  &.disabled {
    color: colorGrey50;
    border-color: colorGrey50;

    :global .path {
      fill: colorGrey50;
    }
  }
}

.color-normal:not(.theme-dark) {
  color: colorGrayDog;
  border-color: colorGrayDog;

  :global .path {
    fill: colorGrayDog;
  }

  &:not(.disabled) {
    &.loading,
    &:hover {
      color: colorWetAsphalt;
      border-color: colorWetAsphalt;

      :global .path {
        fill: colorWetAsphalt;
      }
    }
  }

  &.disabled {
    color: colorGrey50;
    border-color: colorGrey50;

    :global .path {
      fill: colorGrey50;
    }
  }
}

.theme-dark.color-normal {
  color: colorWhite;
  border-color: colorWhite;
  opacity: 0.5;

  &:not(.disabled) {
    &.loading,
    &:hover {
      opacity: 1;
    }
  }

  :global .path {
    fill: colorWhite;
  }
}