.root {
  flex: 1;
  background: #ffffff;
  border-radius: 8px;
  width: 268px;
  padding: 8px 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
