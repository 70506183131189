@value colorDark100, colorGrey100 from '~Styles/vars/colors.css';

.root {
  .title {
    color: colorDark100;
  }
  .descr {
    color: colorGrey100;
    margin-bottom: 8px;
  }
}
