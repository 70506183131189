.root {
  display: flex;
  align-items: center;
  padding-top: 8px;
  padding-bottom: 16px;
  gap: 8px;
}

.control {
  width: 100%;
  justify-content: flex-start;
  box-sizing: border-box;
}
