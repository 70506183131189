@value colorGrey10, colorConcrete from '~Styles/vars/colors.css';
@value headerHeight from '~Styles/vars/size.css';


.root {
  display: flex;
  height: calc(100vh - headerHeight);
  overflow: hidden;
}

.medias {
  flex-grow: 1;
  background-color: colorGrey10;
  border-left: 1px solid colorConcrete;
  height: 100%;
  overflow: auto;
}

.stats {
  width: 700px;
  height: 100%;
  overflow: auto;
  flex-shrink: 0;
}

.content {
  display: flex;
  overflow: hidden;
  position: relative;
  min-height: calc(100vh - headerHeight);
}