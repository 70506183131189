@value colorGrey10, colorSand40, colorDark100 from '~Styles/vars/colors.css';

.mobileContainer {
  height: 100%;
  border-radius: 24px;
  background-color: white;
  padding: 16px;

  .headerBanner {
    background-image: url('../../../files/images/modals/app.png');
    background-size: 220px;
    background-repeat: no-repeat;
    background-color: colorSand40;
    border-radius: 8px;
    background-position: center bottom;
    width: 100%;
    height: 200px;
  }

  .mobileContent {
    margin-top: 16px;
    display: flex;
    flex-direction: column;
    .mobileDescription {
      margin: 8px 0px;
    }
  }

  .bulletList {
    margin-top: 20px;
    margin-bottom: 24px;
  }

  .bulletItem {
    display: flex;
    align-items: center;
  }

  .bulletIcon {
    width: 6px;
    height: 6px;
    border-radius: 6px;
    background-color: colorDark100;
    margin-right: 8px;
    margin-top: 2px;
  }

  .qrBlock {
    margin-top: 16px;
    background-color: colorGrey10;
    border-radius: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 32px;
    padding: 16px;
    .qrCodeDescription {
    }
  }

  .mobileBtn {
    display: none;
    margin-top: 8px;
  }
}

:global .isMobile {
  :local .qrBlock {
    display: none;
  }
  :local .mobileBtn {
    display: block;
  }
}
