.root {
  display: block;
}

.content {
  display: flex;
  flex-wrap: wrap;
}

.row {
  width: 100%;
  display: flex;
}

.hashtag {
  flex-grow: 1;
}