@value colorGrey50 from '~Styles/vars/colors.css';

.root {
  display: inline-block;
  padding: 20px;
  border-radius: 4px;
  border: 1px solid colorGrey50;
}

.list {
  margin-top: 20px;
}

.value {
  width: 36px;
  text-align: right;
  margin-right: 8px;
  flex-shrink: 0;
}

.item {
  display: flex;
}

.chart {
  size: 100px;
  margin: 0 20px 20px;
}

.icon {
  size: 8px;
  border-radius: 50%;
  margin: 8px;
}

.name {
  flex-grow: 1;
  display: flex;
}