.drawer {
  background-color: white;
  padding-top: 8px;

  .titleTextPreset {
    margin-bottom: 8px;
  }

  .contentRoot {
    display: flex;
    flex-direction: column;
    height: calc(100vh - 64px);
    margin: 0 -16px;
    gap: 8px;
    justify-content: space-between;
  }

  .buttonsGroup {
    display: grid;
    grid-template-columns: max-content 1fr;
    grid-template-rows: 1fr;
    width: 100%;
    box-sizing: border-box;
  }

  .submitButtonWrapper {
    box-sizing: border-box;
    position: relative;

    .submitButton {
      box-sizing: border-box;
      width: 100%;
    }

    .clicker {
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      width: 100%;
      opacity: 0;
      cursor: pointer;
    }
  }

  .input {
    height: 72px;
  }

  .textarea {
    min-height: 300px;
  }

  .tooltip {
    z-index: 1;
  }
}
