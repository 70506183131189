@value colorError100, colorWhite, colorError50 from '~Styles/vars/colors.css';

.root {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.resultContainer {
  flex: 1;
  align-items: center;
  justify-content: center;
  display: flex;
}

.title {
  margin-bottom: 16px;
}

.errorIcon {
  height: 180px;
  width: 180px;
  border-radius: 90px;
  background-color: colorError50;
  position: relative;
  @mixin center;

  &::before,
  &::after {
    content: '';
    width: 4px;
    height: 100px;
    background: colorError100;
    display: flex;
    border-radius: 3px;
  }

  &::before {
    transform: rotate(45deg);
  }

  &::after {
    transform: rotate(-45deg);
  }
}
