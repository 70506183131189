
@value colorGrey100 from '~Styles/vars/colors.css';

.root {
  background-color: white;
  display: grid;
  grid-template-columns: 240px 120px 350px 160px 90px 72px;
  grid-column-gap: 24px;
  padding: 16px;
  min-height: 80px;
  box-sizing: border-box;
  align-items: center;
  cursor: pointer;

  .textPreset {
    display: grid;
    grid-template-columns: repeat(2, max-content);
    grid-gap: 8px;
  }

  .creatorsCountText {
    color: colorGrey100;
    text-align: left;
  }

}
