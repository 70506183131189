@value headerHeight, subheaderHeight from '~Styles/vars/size.css';

.root {
  display: block;
}

.bar {
  z-index: 2;
  position: sticky;
  top: headerHeight;
}

:global .subheader {
  :local .bar {
    top: calc(headerHeight + subheaderHeight);
  }
}