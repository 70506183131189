.root {
  margin-top: 32px;
}

.btn {
  margin-bottom: 20px;
}

.disclaimer {
  margin-top: 12px;
}

.controls {
  display: flex;
  gap: 12px;
}

.control {
  flex: 1;
}

.noMarginBottom {
  margin-bottom: 0;
}
