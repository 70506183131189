@value colorGrey10, colorDark100, colorError100, colorSuccess100 from '~Styles/vars/colors.css';

.drawerContainer {
  .drawerBackdrop {
    z-index: 1100;
  }

  .drawerWrapper {
    z-index: 1101;
    background-color: white;

    .root {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .formContainer {
        display: flex;
        flex-direction: column;
        flex: 1;
        .title {
          margin-bottom: 24px;
        }

        .offerContainer {
          display: flex;
          flex-direction: row;
          align-items: center;
          height: 256px;
          border-radius: 8px;
          background-color: colorGrey10;
          overflow: hidden;

          .offerText {
            flex: 1;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding: 16px;

            .fullPriceText {
              color: colorError100;
              text-decoration: line-through;
              font-size: 24px;
              line-height: 32px;
              font-weight: 400;
            }
            .offerPriceText {
              color: colorDark100;
              font-size: 40px;
              line-height: 44px;
              margin-bottom: 8px;
            }
            .inlineText {
              display: inline-block;
              .offerInfoText {
                color: colorDark100;
                font-weight: 400;
                display: inline;
              }
              .offerSaveText {
                display: inline;
                color: colorSuccess100;
                font-weight: 500;
              }
            }
          }

          .offerImage {
            width: 211px;
            height: 100%;
            background-image: url('../../../../../../../files/images/plans/offer.png');
            background-size: cover;
          }
        }
      }


      .bottomContainer {
        width: 100%;
        display: flex;
        flex-direction: column;

        .loadingContainer {
          display: flex;
          align-items: center;
          justify-content: center;
          min-height: 138px;
        }

        .buttonContainer {
          width: 100%;
          display: flex;
          flex-direction: row;
          text-align: center;
          gap: 16px;
          margin-top: 16px;
          .buttonText {
            width: 100%;
            text-align: center;
          }
        }
      }
      .cancelBtn {
        width: 100%;
        padding: 16px 0 8px;
      }
    }
  }
}