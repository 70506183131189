@value colorWhite from '~Styles/vars/colors.css';

.drawer {
  @mixin center;
}

.content {
  background: colorWhite;
  width: 471px;
  border-radius: 22px;
  padding: 20px;
  box-sizing: border-box;
  text-align: center;

  .title {
    font-weight: 700;
    margin-bottom: 8px;
  }
}

.image {
  margin: 0 auto 24px;
}

.closeWrap {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 12px;
}

.buttons {
  margin-top: 27px;
  display: flex;
  justify-content: space-between;
}
