.uploadBtn {
  margin-top: 40px;
  position: relative;
  display: inline-block;
}

.uploadInput {
  position: absolute;
  size: 100%;
  top: 0;
  left: 0;
}

.result {
  margin-top: 24px;

  &.beer {
    color: white;
  }
}

details {
  margin-top: 20px;

  summary {
    font-weight: bold;
    cursor: pointer;
  }

  ol {
    list-style-type: lower-alpha;
    margin-left: 20px;

    li {
      margin: 8px;
    }
  }

  ul {
    list-style-type: circle;

    li {
      margin-left: 20px;
    }
  }
}
