@value desktopMinWidth from '~Styles/vars/query.css';
@value headerHeight from '~Styles/vars/size.css';
@value colorGrey10 from '~Styles/vars/colors.css';

.root {
  display: block;
  width: 100%;
  padding-top: 16px;
  box-sizing: border-box;
  background: colorGrey10;
}

.content {
  @mixin newInnerContainer;
  height: calc(100vh - headerHeight);
  overflow: auto;
}

.title {
  margin-bottom: 16px;
}

.creatives {
  margin: 0 auto;
}

.container {
  width: desktopMinWidth;
  margin: 0 auto;
}
