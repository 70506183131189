.root {
  display: flex;
  flex-wrap: wrap;
  margin: -10px;
}

.item {
  flex: none;
  margin: 10px;
  width: calc(33.3% - 20px);

  .customListDropdownGroup {
    right: unset;
    left: -4px;
  }

  @media (max-width: 1650px) {
    &:nth-child(3n) {
      .customListDropdownGroup {
        left: unset;
        right: -4px;
      }
    }
  }

  @media (min-width: 1650px) {
    width: calc(25% - 20px);
    &:nth-child(4n) {
      .customListDropdownGroup {
        left: unset;
        right: -4px;
      }
    }
  }
}

.preloader {
  width: 100%;
  flex: none;
  display: flex;
  justify-content: center;
}

.noResult {
  margin-left: 10px;
}
