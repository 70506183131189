@value colorGrey100 from '~Styles/vars/colors.css';

.root {
  margin-bottom: 40px;
}

.title {
  display: flex;
}

.optional {
  color: colorGrey100;
}

.descr {
  color: colorGrey100;
  margin-bottom: 24px;
}

.add {
  width: 270px;
  margin-top: 16px;
  justify-content: center;
}

.questions {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.addQuestion {
  margin-top: 24px;
}

.controls {
  display: flex;
}
