@value colorGrey50, colorWhite from '~Styles/vars/colors.css';

.inputWrap {
  position: relative;
}

.inputIcon {
  left: 8px;
  top: 12px;
  position: absolute;
  z-index: 10;
}

.input {
  padding-left: 48px !important;
  width: 242px !important;
  border-radius: 8px !important;

  input {
    padding-top: 23px !important;
    padding-bottom: 23px !important;
    border: 1px solid colorGrey50 !important;
  }
}

.selector {
  padding: 0;
}

.group {
  width: 100%;
}
