@value (colorPink100, colorSun100, colorPurple100, colorSky100, colorPink40, colorSun40, colorPurple40, colorSky40) from '~Styles/vars/colors.css';
@value (headerHeight) from '~Styles/vars/size.css';

.root {
  width: 100%;
  box-sizing: border-box;
  margin: 0 auto;
  position: relative;

  .fixedInner {
    width: 100%;
    position: fixed;
    height: 56px;
    left: 0;
    top: headerHeight;
    z-index: 1;
    transition: .5s background-color;
    background-color: colorSky40;

    &.themePink {
      background-color: colorPink40;
    }

    &.themeSun {
      background-color: colorSun40;
    }

    &.themePurple {
      background-color: colorPurple40;
    }

    &.themeSky {
      background-color: colorSky40;
    }

    .content {
      display: grid;
      grid-template-columns: 1fr max-content;
      align-items: center;
      grid-gap: 12px;
      width: 100%;
      max-width: 500px;
      box-sizing: border-box;
      padding: 16px;
      position: fixed;
      left: 50%;
      transform: translateX(-50%);
      z-index: 1;
    }
  }

  .progressBar {
    background-color: white;

    .progressBarWrap {
      transition: .5s background-color, .5s width;

      &.themePink {
        background-color: colorPink100;
      }

      &.themeSun {
        background-color: colorSun100;
      }

      &.themePurple {
        background-color: colorPurple100;
      }

      &.themeSky {
        background-color: colorSky100;
      }
    }
  }
}
