@value colorDark100, colorGrey50, colorGrey100, colorWhite, colorError100, colorError50 from '~Styles/vars/colors.css';

.root {
  position: relative;
  height: 48px;
  width: 100%;
  box-sizing: border-box;

  &.fluid {
    min-width: 100%;
  }

  &.squeezed {
    width: 48px;
  }

  &.withValue {
    &.withLabel {
      input {
        padding-right: 42px;
        padding-top: 32px;
      }
    }
  }

  &.withLeftIcon {
    padding-left: 24px;
    &:not(.withValue) {
      .icon {
        position: absolute;
        z-index: 2;
        left: 14px;
        top: calc(50% + 1px);
        transform: translateY(-50%);
        width: 24px;
      }
    }
    &.withValue {
      padding-left: 16px;
    }
  }

  &.error {
    input {
      background-color: colorError50;
      border-color: colorError100 !important;
    }
  }

  .label {
    color: colorGrey100;
    position: absolute;
    top: 4px;
    left: 16px;
    z-index: 2;
    font-size: 12px;
  }

  input {
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: colorWhite;
    padding: 24px 16px;
    border: 1px solid transparent;
    border-radius: 8px;
    box-sizing: border-box;
    z-index: 1;
    font-size: 14px;
    outline: none;

    &.bordered {
      padding-top: 23px;
      padding-bottom: 23px;
      border: 1px solid colorGrey50;
    }

    &.active {
      border: 1px solid colorDark100;
    }

    &:hover:not(.disabled) {
      border: 1px solid colorGrey100;
    }

    &:hover {
      &.borderless {
        border: 1px solid transparent;
      }
    }

    &:focus:not(.disabled) {
      border: 1px solid colorGrey100;
    }

    &:focus {
      &.borderless {
        border: 1px solid transparent;
      }
    }

    &.disabled {
      background: transparent;
    }
  }

  .icon {
    position: absolute;
    z-index: 2;
    right: 16px;
    top: calc(50% + 2px);
    transform: translateY(-50%);

    .resetIcon {
      color: colorGrey100;
    }
  }

  .symbol {
    position: absolute;
    z-index: 2;
    right: 16px;
    top: 50%;
    transform: translateY(-50%);
    color: colorGrey100;
  }

  .lengthCounter {
    position: relative;
    margin-top: 8px;
    color: colorGrey100;
    font-size: 12px;
    padding-left: 16px;
  }
}

.rightIcon,
.leftIcon {
  color: colorGrey100;
}
