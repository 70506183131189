@value colorSun100, colorGrey10, colorPink60, colorApproval100, sky100, colorRed from '~Styles/vars/colors.css';
@value borderRadius4 from '~Styles/vars/query.css';

.header {
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;

  &.nomargin {
    margin-bottom: 0;
  }

  .title {
    text-transform: uppercase;
  }

  .bcaPermissionState {
    background-color: colorGrey10;
    color: colorSun100;
    padding: 4px 8px;
    border-radius: borderRadius4;

    &.sky {
      color: sky100;
    }

    &.green {
      color: colorApproval100;
    }
  }
}

.bcaErrorText {
  color: colorRed;
  margin: -10px 0 20px 0;
  &.revokeError {
    margin: 20px 0;
  }
}

.item:not(:last-child) {
  margin-bottom: 20px;
}

.header {
  display: flex;
}

.status {
  margin-left: 12px;
}

.activeDescr {
  margin-top: -8px;
  margin-bottom: 20px;
}

.newDealDescr {
  margin-bottom: 20px;
}

.connect {
  color: colorPink60;
}

.bcaButton {
  margin-top: 16px;
}