@value colorGrey10, colorGrey50, colorGrey100 from '~Styles/vars/colors.css';

.root {
  position: relative;
  height: 100%;

  .spinner {
    position: absolute;
    top: calc(50% - 20px);
    left: calc(50% - 20px);
  }

  .spinnerContainer {
    width: 100%;
    height: 40px;
    position: relative;
    overflow-y: hidden;
  }

  .noResultsMessage {
    color: colorGrey100;
    position: absolute;
    top: 50%;
    left: calc(50% - 90px);
    width: 180px;
    text-align: center;
  }

  .searchInput {
    background-color: colorGrey10;
  }

  .tooltip {
    width: 184px;
    text-align: left;
    z-index: 10;
  }

  .tooltipContainer {
    text-align: center;
  }

  .searchWrapper {
    position: fixed;
    padding: 8px 16px;
    box-sizing: border-box;
    width: 100%;
    z-index: 1;

    .guideTooltip {
      position: absolute;
      left: -335px;
      top: 3px;
      width: 251px;
      min-width: unset;
    }

    .searchInput {
      padding-left: 48px;
      padding-right: 16px;
      border-radius: 8px;
      z-index: 1;

      &::placeholder {
        color: colorGrey100;
      }
    }

    .searchIcon {
      position: absolute;
      left: 32px;
      top: calc(50% - 12px);
      z-index: 2;
      pointer-events: none;
    }

    &:after {
      background-color: white;
      content: '';
      position: absolute;
      left: 16px;
      top: 0;
      width: calc(100% - 32px);
      height: 64px;
    }
  }

  ul.productList {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(auto-fit, minmax(0, max-content));

    &.withSearch {
      & > li:first-child {
        margin-top: 64px;
      }
    }

    & > li {
      height: auto;
      padding: 8px 16px;
    }

    .productCard {
      background-color: colorGrey10;
      border-radius: 16px;
      padding: 8px 0;

      .productCardButton {
        display: grid;
        grid-template-rows: 1fr;
        grid-template-columns: 44px 1fr max-content max-content;
        grid-gap: 16px;
        padding: 8px 16px;
        align-items: center;
        width: 100%;
        box-sizing: border-box;
        height: 60px;
        overflow: hidden;

        &.threeColumns {
          grid-template-columns: 44px 1fr max-content;
        }

        &.cursorDefault {
          cursor: default;
        }

        .productImg {
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: white;
          height: 44px;
          width: 44px;

          img {
            display: block;
            max-width: 100%;
            max-height: 100%;
          }

          .noImageIcon {
            color: colorGrey50;
            display: block;
          }

          img + .noImageIcon {
            display: none;
          }
        }

        .productTitle {
          overflow: hidden;
          max-height: 100%;
        }

        .arrowIcon {
          &.hidden {
            visibility: hidden;
          }
        }

        .productVariantsSelectionCount {
          &.hidden {
            visibility: hidden;
          }
        }
      }

      ul.variantsList {
        & > li {
          box-sizing: border-box;
          padding: 12px 20px;
          display: grid;
          grid-template-columns: 40px 45% 1fr 1fr;
          grid-template-rows: max-content;
          align-items: center;
          column-gap: 16px;
          width: 100%;

          .disabled {
            opacity: 0.2;
          }
        }

        .variantTitle {
          overflow: hidden;
          max-height: 100%;
        }

        .variantAvailability {
          color: colorGrey100;
        }

        .variantPrice {
          color: colorGrey100;
          text-align: right;
        }

        .checkboxWrapper {
          text-align: center;

          .checkbox {
            display: inline-block;
          }
        }
      }
    }
  }
}
