@value colorDark100 from '~Styles/vars/colors.css';
@value borderRadius12 from '~Styles/vars/query.css';

.root {
  background-color: colorDark100;
  border-radius: borderRadius12;
  padding: 12px 16px;
  color: white;
  position: relative;

  &.size-m {
    min-width: 340px;
  }

  &.size-s {
    max-width: 220px;
  }

  &::after {
    content: '';
    display: block;
    position: absolute;
    width: 18px;
    height: 12px;
    overflow: hidden;
    background: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iOCIgdmlld0JveD0iMCAwIDIwIDgiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0wIDBMMjAgMy40OTY5MmUtMDZMMTMuNTM1NSA2LjUyMjQxQzExLjU4MjkgOC40OTI1MyA4LjQxNzA5IDguNDkyNTMgNi40NjQ0NyA2LjUyMjRMMCAwWiIgZmlsbD0iIzMzMzMzMyIvPgo8L3N2Zz4K')
      center -2px no-repeat;
  }

  &.hiddenArrow::after {
    display: none;
  }

  &.arrow-position-left-top {
    &::after {
      top: -12px;
      transform: rotate(180deg);
    }
    &.size-m::after {
      left: 16px;
    }
    &.size-s::after {
      left: 16px;
    }
  }

  &.arrow-position-center-top {
    &::after {
      top: -12px;
      left: calc(50% - 9px);
      transform: rotate(180deg);
    }
  }

  &.arrow-position-right-top {
    &::after {
      top: -12px;
      right: 16px;
      transform: rotate(180deg);
    }
    &.size-m::after {
      right: 16px;
    }
    &.size-s::after {
      right: 16px;
    }
  }

  &.arrow-position-left-bottom {
    &::after {
      top: 100%;
    }
    &.size-m::after {
      left: 16px;
    }
    &.size-s::after {
      left: 16px;
    }
  }

  &.arrow-position-center-bottom {
    &::after {
      top: 100%;
      left: calc(50% - 9px);
    }
  }

  &.arrow-position-right-bottom {
    &::after {
      top: 100%;
      right: 50px;
    }
    &.size-m::after {
      right: 16px;
    }
    &.size-s::after {
      right: 16px;
    }
  }

  &.arrow-position-top-left {
    &::after {
      left: -14px;
      transform: rotate(90deg);
    }
    &.size-m::after {
      top: 25%;
    }
    &.size-s::after {
      top: 25%;
    }
  }

  &.arrow-position-center-left {
    &::after {
      left: -14px;
      transform: rotate(90deg);
    }
    &.size-m::after {
      top: calc(50% - 6px);
    }
    &.size-s::after {
      top: calc(50% - 6px);
    }
  }

  &.arrow-position-center-right {
    &::after {
      right: -15px;
      top: 50%;
      margin-top: -4px;
      transform: rotate(-90deg);
    }
    &.size-m::after {
      top: calc(50% - 6px);
    }
    &.size-s::after {
      top: calc(50% - 6px);
    }
  }

  &.arrow-position-bottom-left {
    &::after {
      left: -14px;
      transform: rotate(90deg);
    }
    &.size-m::after {
      bottom: 25%;
    }
    &.size-s::after {
      bottom: 25%;
    }
  }
}
