@value colorGrey10, colorGrey100, colorPink100, colorPurple100 from '~Styles/vars/colors.css';

.root {
  padding: 16px;
  width: 100%;
  box-sizing: border-box;
  background: colorGrey10;
  border-radius: 16px;
  z-index: 1;
  margin-top: 20px;
}

.unlockInfo {
  color: colorPink100;
}

.details {
  display: flex;
  gap: 12px;
  color: colorGrey100;
}

.connectButton {
  color: colorPurple100 !important;
}

.info {
  white-space: normal;
}
