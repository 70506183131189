.root {
  .headerControls {
    display: flex;
    gap: 8px;
    justify-content: space-between;

    .startHeaderControls {
      justify-self: flex-start;
    }

    .endHeaderControls {
      display: flex;
      gap: 8px;
    }
  }

  .newListButton {
    box-sizing: border-box;
    min-width: 177px;
    justify-content: center;
  }
}