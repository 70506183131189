@value colorWhite, colorGrey50, colorGrey100, colorPink60 from '~Styles/vars/colors.css';
@value headerHeight, subheaderHeight from '~Styles/vars/size.css';

:root {
  --height: 55px;
  --info-width: 400px;
}

.newView {
  --info-width: 510px;
}

.root {
  background: colorWhite;
  position: relative;

  &.newView {
    flex: 1;
    border-color: colorGrey50;

    .content {
      height: calc(100vh - 120px);
    }

    .projectActions {
      min-height: 107px;
    }
  }

  &:not(.newView) {
    flex: 1;

    .content {
      height: calc(100vh - 64px);
    }
  }

  &.contractorView {
    .content {
      height: calc(100vh - 134px);
    }

    .projectActions {
      min-height: unset;
    }
  }
}

.content {
  display: flex;
}

.project {
  width: 400px;
  min-height: 100%;
  position: relative;
}

.projectWrap {
  width: 400px;
  flex-shrink: 0;
  height: 100%;
  overflow: auto;
}

.overflowVisible {
  overflow: visible;
}

.messages {
  width: calc((100% - 400px - 400px) / 2);
  height: 100%;
}

.dialog {
  flex-grow: 1;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.projectEventsWrap {
  flex: 1;
  overflow: auto;
}

.projectEvents {
  padding-left: 20px;
  padding-right: 20px;
  box-sizing: border-box;
}

.onboardingTooltip {
  top: 8px !important;
  max-width: 304px;
}

.projectActions {
  flex-shrink: 0;
  position: relative;
  background-color: white;
}

.campany {
  height: var(--height);
  display: flex;
}

.info {
  padding-left: 18px;
  box-sizing: border-box;
  border-bottom: 1px solid #e3e3e3;
}

.preloader {
  width: 100%;
  position: absolute;
}

.isMobile {
  width: 100%;

  .messages {
    width: 100%;
    flex-grow: 1;
    overflow: hidden;
  }

  .content {
    flex-direction: column;
  }

  .dialog {
    overflow-y: auto;
  }
}

.emptyContent {
  @mixin center;
  flex: 1;
  color: colorGrey100;
  text-align: center;
}

.emptyContentTitle {
  margin-top: 8px;
}

.back {
  position: absolute;
  top: -43px;
  left: -150px;
  z-index: 55;
}

.stub {
  height: calc(100vh - 110px);
  box-sizing: border-box;
  padding-left: 12px;
  display: flex;
  justify-content: space-between;
  gap: 12px;
}

.stubItem {
  height: calc(100vh - 64px);
  background: colorWhite;
  flex: 1;
}

.projectInfoStub {
  width: 376px;
  height: 100%;
  border-radius: 8px;
  background: colorWhite;
  margin-top: 12px;
}

.stubLoader {
  margin: 40px auto;
}
