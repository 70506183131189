.root {
  display: flex;
  flex-direction: column;
  padding: 0 24px;
  box-sizing: border-box;
}

.header {
  flex: none;
}

.up {
  cursor: pointer;
  position: fixed;
}
