@value colorGrey100 from '~Styles/vars/colors.css';

.clearBtn {
  margin: 16px auto 0;
}

.emptyList {
  margin-top: 140px;
  color: colorGrey100;
  gap: 8px;
  text-align: center;
}
