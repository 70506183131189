@value colorError100, colorWhite from '~Styles/vars/colors.css';

.drawer {
  background-color: colorWhite;
}

.drawerContainer {
  display: flex;
  height: 100%;
  width: 516px;
  flex-direction: column;
  justify-content: center;

  .drawerTitleText {
    margin-bottom: 16px;
  }
}

.title {
  margin-bottom: 20px;
}

.descr {
  margin-bottom: 40px;
}

.field {
  margin-bottom: 40px;
}

.topupDescr {
  margin-top: 20px;
}

.error {
  margin-bottom: 20px;
  color: colorError100;
}

.btn {
  width: 100%;
}

.content {
  flex: 1;
}

.input {
  height: 72px;
}

.needTopup {
  margin-top: 20px;
  color: colorError100;
}
