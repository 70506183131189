@value colorGrey100 from '~Styles/vars/colors.css';

.root {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr auto;
  height: calc(100vh - 64px);

  .title {
    margin-bottom: 20px;
  }

  .avatar {
    margin-right: 16px;
  }

  .creator {
    display: flex;
    align-items: center;
    margin: 20px 0;
  }

  .contentTitle {
    font-size: 12px;
    line-height: 12px;
    color: colorGrey100;
    margin: 20px 0;
  }

  .btn {
    width: 100%;
  }

  .informationListContent {
    .listTitle {
      font-size: 12px;
      line-height: 12px;
      color: colorGrey100;
    }
  }
}
