@value colorWetAsphalt from '~Styles/vars/colors.css';

.root {
  position: absolute;
  top: 0;
  left: 0;
  size: 100%;

  &:hover {
    .link,
    .hideBtn,
    .controls {
      opacity: 1;
    }

    .back {
      opacity: 0.5;
    }

    .actions {
      opacity: 1;
    }
  }
}

.active {
  .back {
    opacity: 0.5;
  }
}


.hover {
  .link,
  .controls {
    opacity: 1;
  }
}

.root {
  .controls,
  .hideBtn,
  .link,
  .actions {
    opacity: 0;
    transition: opacity 0.1s ease-in;
  }
}

.back {
  top: 0;
  size: 100%;
  position: absolute;
  background: colorWetAsphalt;
  opacity: 0;
  transition: opacity 0.1s ease-in;
  border-radius: 4px 4px 0 0;
}

.controls {
  top: 0;
  left: 0;
  size: 100%;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px 4px 0 0;
}

.actions {
  position: absolute;
  top: 20px;
  right: 20px;
  display: grid;
  grid-template-columns: repeat(2, max-content);
  align-items: center;
  grid-gap: 16px;
  opacity: 0;
  z-index: 1;

  .favorites, .add, .statusIcon {
    cursor: pointer;
  }

  .favoritesIcon {
    color: white;
  }
}

.addedToMediaplan {
  &.controls {
    opacity: 1;
  }
}

.link {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
}

.hideBtn {
  top: 20px;
  left: 20px;
  position: absolute;
  z-index: 1;
}

.onboarded {
  pointer-events: none;
}
