.drawer {
  @mixin drawer;
}

.drawerContainer {
  @mixin drawerContainer;
}

.descr {
  margin: 16px 0;
}

.content {
  flex: 1;
}
