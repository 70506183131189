@value colorWhite, colorGrey100 from '~Styles/vars/colors.css';

.drawer {
  background-color: colorWhite;
}

.descr {
  color: colorGrey100;
  margin-bottom: 16px;
}
