@value colorGrey100, colorGrey50, colorSun100, colorGrass100, colorSky100, colorPurple100, colorPink100, colorError100, colorWhite from '~Styles/vars/colors.css';

.stage {
  display: flex;
  align-items: center;
  color: colorGrey100;

  &::before {
    content: '';
    size: 6px;
    border-radius: 50%;
    margin-left: 5px;
    margin-right: 9px;
  }
}

.unsent {
  color: colorGrey50;
  &::before {
    border: 1px solid colorGrey50;
  }
}

.unread,
.read {
  &::before {
    background: colorGrey100;
  }
}

.accepted {
  &::before {
    background: colorSun100;
  }
}

.launched {
  &::before {
    background: colorGrass100;
  }
}

.content_submitted {
  &::before {
    background: colorSky100;
  }
}

.publishing_started {
  &::before {
    background: colorPurple100;
  }
}

.completed {
  &::before {
    background: colorPink100;
  }
}

.rejected {
  &::before {
    background: colorError100;
  }
}
