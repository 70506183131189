.root {
  display: flex;
  flex-direction: column;
  flex: 2;

  .inputWrap {
    width: 100%;
  }

  .input {
    border-radius: 8px;
  }
}

.title {
  margin-bottom: 8px;
}
