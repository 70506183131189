.drawer {
  background-color: white;
  z-index: 1200;
}

.content {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr auto;
  height: calc(100vh - 64px);
}

.title {
  font-size: 24px;
  font-weight: 400;
  line-height: 32px;
  margin-bottom: 16px;
}

.howItWorksTitle {
  font-weight: 500;
  font-size: 17px;
  line-height: 24px;
}

.description {
  margin: 16px 0;
}

.btn {
  justify-content: center;
}
