@value headerHeight, subheaderHeight from '~Styles/vars/size.css';
@value colorGrey50, colorPink60, colorPurple60, colorOcean80 from '~Styles/vars/colors.css';

@keyframes linear_loader {
  0% {
    right: 100%;
    width: 10%;
  }

  30% {
    right: 0%;
    width: 40%;
  }

  50% {
    right: 0%;
    width: 0%;
  }

  80% {
    right: 0%;
    width: 0%;
  }

  100% {
    right: 0%;
    width: 0%;
  }
}

.root {
  top: headerHeight;
  size: 100%;
  display: none;
  position: fixed;
  z-index: 999999;
}

.show {
  display: flex;
}

.top {
  top: 0 !important;
}

:global .subheader {
  :local .root {
    top: calc(headerHeight + 40px);
  }
}

.barContainer {
  position: relative;
  height: 5px;
  width: 100%;
  background-color: colorGrey50;
}

.bar {
  content: '';
  display: inline;
  position: absolute;
  height: 100%;
  width: 0;
  right: 0;
}

.bar:nth-child(1) {
  background-color: colorPink60;
  animation: linear_loader 3s linear 1s infinite;
}

.bar:nth-child(2) {
  background-color: colorPurple60;
  animation: linear_loader 3s linear 2s infinite;
}

.bar:nth-child(3) {
  background-color: colorOcean80;
  animation: linear_loader 3s linear 3s infinite;
}
