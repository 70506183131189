@value colorGrey50 from '~Styles/vars/colors.css';

.root {
  position: relative;
}

.panel {
  cursor: pointer;
  @mixin vertical-center;
  min-width: 200px;
  max-width: 280px;
  height: 40px;
}

.menu {
  min-width: 100%;
}