@value colorGrey10 from '~Styles/vars/colors.css';
@value desktopMinWidth from '~Styles/vars/query.css';

.root {
  background: colorGrey10;
}

.content {
  width: desktopMinWidth;
  padding: 0 16px;
  margin: 0 auto;
}

.tabs {
  padding: 8px 0;
  margin-bottom: 20px;
}

.back {
  padding-top: 20px;
  padding-bottom: 20px;
}
