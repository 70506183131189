@value colorGrey100 from '~Styles/vars/colors.css';

.root {
  display: block;
}

.checkboxWrapper {
  display: flex;
}

.icon {
  margin-right: 12px;
}

.label {
  font-size: 12px;
  line-height: 16px;
  color: colorGrey100;
  margin-left: 37px;
  margin-top: 2px;
}
