.root {
  position: fixed;
  width: 100vw;
  left: 0;
  top: 0;
  z-index: 55;
}

.status {
  display: flex;
  align-items: center;
}

.statusTitle {
  margin-left: 12px;
}

.controls {
  display: flex;
  gap: 20px;
}

.disabled {
  pointer-events: none;
  opacity: 0.6;
}

.drawer {
  @mixin drawer;
}

.drawerContainer {
  @mixin drawerContainer;
  justify-content: flex-start;
}

.drawerContent {
  flex: 1;
  @mixin center;
}

.drawerControls {
  display: flex;
  gap: 8px;
}
