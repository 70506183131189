@value colorGrass40, colorApproval100 from '~Styles/vars/colors.css';

.root {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.title {
  margin-bottom: 16px;
}

.resultContainer {
  flex: 1;
  align-items: center;
  justify-content: center;
  display: flex;
}

.greenIcon {
  height: 180px;
  width: 180px;
  border-radius: 90px;
  background-color: colorGrass40;
  position: relative;

  &:before {
    content: '';
    display: block;
    height: 80px;
    width: 40px;
    border-bottom: 3px solid colorApproval100;
    border-right: 3px solid colorApproval100;
    position: absolute;
    top: calc(50% - 10px);
    left: 50%;
    transform: translate3d(-50%, -50%, 0) rotate(45deg);
  }
}
