@value colorSun40 from '~Styles/vars/colors.css';
@value briefWidth from '~Styles/vars/size.css';

.root {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  max-width: briefWidth;
  background-color: colorSun40;
  padding: 16px;
  border-radius: 16px;
  box-sizing: border-box;
  margin: 0 auto;

  .warnIcon {
    margin-right: 16px;
  }
}

:global .isMobile {
  :local .root {
    border-radius: 0px;
  }
}
