.drawer {
  @mixin drawer;
}

.drawerContainer {
  @mixin drawerContainer;
  justify-content: flex-start;

  .input {
    flex: 1;
    height: 74px;
  }
}

.title {
  margin-bottom: 16px;
}

.link {
  width: 100%;
}

.seatsDesr {
  margin-top: 24px;
  text-align: center;
}

.linkWrap {
  width: 100%;
  display: block;
}

.emailWrap {
  display: flex;
  gap: 8px;
  margin-top: 16px;
  margin-bottom: 32px;
}
