@value colorWhite from '~Styles/vars/colors.css';

.root {
  position: relative;

  &:hover {
    .tooltip {
      &.left {
        display: block;
      }

      &:not(.left) {
        display: flex;
      }
    }
  }
}

.tooltip {
  display: none;
  z-index: 11;
  position: absolute;
  padding: 20px;
  min-width: 320px;
  justify-content: center;
  border-radius: 8px;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.05), 0 0 20px rgba(0, 0, 0, 0.1);
  background: colorWhite;

  &.place-top {
    top: unset;
    bottom: calc(100% + 14px);

    &::before {
      top: unset;
      bottom: -14px;
    }

    &::after {
      top: unset;
      bottom: -14px;
    }
  }

  &.place-bottom {
    bottom: unset;
    top: calc(100% + 14px);

    &::before {
      bottom: unset;
      top: -14px;
    }

    &::after {
      bottom: unset;
      top: -14px;
      transform: rotate(180deg);
    }
  }

  &:not(.left) {
    left: 50%;
    transform: translate3d(-50%, 0, 0);
  }

  &::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 14px;
  }

  &::after {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 14px 14px 0;
    border-color: #ffffff transparent transparent;
  }
}

.textWrap {
  display: flex;
  justify-content: space-between;

  &:not(:last-child) {
    margin-bottom: 12px;
  }
}

.textItem {
  margin-right: 12px;
}