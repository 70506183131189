@value colorError100, colorWhite from '~Styles/vars/colors.css';

.title {
  margin-bottom: 16px;
}

.content {
  flex: 1;
  .item {
    display: flex;
    justify-content: space-between;

    &:not(:first-child) {
      margin-top: 12px;
    }

    .option {
      text-align: left;
    }
  }

  .paymentForm {
    margin-top: 30px;
  }
}

.error {
  margin-top: 30px;
  margin-bottom: 20px;
  text-align: center;
  color: colorError100;
}

.buttons {
  display: flex;
  margin-top: 40px;
}

.confirmBtn {
  flex: 1;
}

.backButton {
  size: 60px;
  background: colorWhite;
  position: fixed;
  left: -76px;
  top: 0;
  border-radius: 16px;
  @mixin center;
  cursor: pointer;
  z-index: 1;
}
