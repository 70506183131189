@value colorWhite, colorConcrete from '~Styles/vars/colors.css';
@value desktopMinWidth from '~Styles/vars/query.css';

.totalsWrap {
  position: fixed;
  bottom: 0;
  left: 0;
  background: colorWhite;
  width: 100%;
  box-sizing: border-box;
}

.totals {
  width: desktopMinWidth;
  box-sizing: border-box;
  margin: 0 auto;
  padding: 20px;
  display: flex;
  border-top: 1px solid colorConcrete;
}

.totalItem {
  display: flex;

  &:not(:last-child) {
    margin-right: 20px;
  }
}

.list {
  margin-bottom: 76px;
}

.amount {
  display: flex;
  align-items: center;
}

.w9Icon {
  margin-left: 4px;
}

.campaignLink {
  width: 100%;
  overflow: hidden;

  button {
    text-align: left;
    overflow: hidden;
    width: 100%;
  }

  span {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}