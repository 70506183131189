.drawer {
  background-color: white;
  padding-top: 8px;

  .contentRoot {
    display: flex;
    flex-direction: column;
    height: calc(100vh - 64px);
    margin: 0 -16px;
    gap: 8px;
    justify-content: space-between;
    padding: 16px;
    box-sizing: border-box;
  }

  .headerContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 16px;
    gap: 16px;
  }
  .tabsContainer {
    width: 100%;
    margin-bottom: 16px;
  }

  .tabs {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    gap: 8px;
  }

  .filtersContainer {
    width: 100%;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 20px;
  }

  .filterItem {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    width: 100%;
  }

  .filterItemText {
    min-width: 45%;
  }

  .valueContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 4px;
    width: calc(55% - 8px);
  }
}
