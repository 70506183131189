.root {
  display: block;
}

.button {
  flex-grow: 1;
  flex-shrink: 0;

  &:not(:last-child) {
    margin-right: 12px;
  }
}

.actions {
  width: 100%;
  display: flex;
}

.iconWrap {
  padding: 8px;
  border-radius: 4px;
  margin-right: 12px;
}

.processing {
  background: rgba(252, 189, 66, 0.1);
}

.canceled {
  background: rgba(175, 175, 175, 0.1);
}

.failed {
  background: rgba(245, 71, 71, 0.1);
}

.confirmed {
  background: rgba(31, 172, 79, 0.1);
}

.statusWrap {
  display: flex;
  align-items: center;
}

.fullButton {
  width: 100%;
}