.topup {
  width: 100%;
  height: calc(100vh - 64px);
  position: absolute;
  background: white;
  bottom: 0;
  z-index: 9999;
}

.backButton {
  size: 60px;
  background: white;
  position: fixed;
  left: -76px;
  top: 0;
  border-radius: 16px;
  @mixin center;
  cursor: pointer;
  z-index: 1;
}
