@value colorGrey50, colorWhite from '~Styles/vars/colors.css';

.progressiveInput {
  background-color: white;
  border-radius: 8px;
}

.stub {
  height: 48px;
  position: relative;
  padding: 12px 16px;
  border-radius: 8px;
  border: 1px solid transparent;
  background: colorWhite;
  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}
