@value colorDark, colorPink100 from '~Styles/vars/colors.css';

@define-mixin line {
  height: 2px;
  width: 8px;
  background-color: colorDark;
  border-radius: 1px;
}

.root {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  user-select: none;

  .valueText {
    min-width: 16px;
    text-align: center;

    &.infinityValue {
      position: relative;
      &::after {
        content: '∞';
        font-size: 24px;
        position: absolute;
        top: calc(50% - 2px);
        transform: translate3d(-50%, -50%, 0);
        left: 50%;
        color: colorPink100;
      }
      
    }
  }

  .button {
    height: 20px;
    width: 20px;
    border-radius: 12px;
    box-sizing: border-box;
    border: 2px colorDark solid;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    &.disabled {
      opacity: 0.2;
      cursor: not-allowed;
    }

    .minus {
      @mixin line;
    }

    .plus {
      @mixin line;

      &::after {
        content: '';
        display: block;
        @mixin line;
        transform: rotate(90deg);
      }
    }
  }
}