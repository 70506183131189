@value colorPurple100, colorGrey100 from '~Styles/vars/colors.css';

.root {
  display: flex;
  align-items: center;
  gap: 16px;
}

.avaWrap {
  position: relative;
}

.platform {
  position: absolute;
  border-radius: 50%;
  size: 24px;
  bottom: -4px;
  right: -4px;
  background: rgba(255, 255, 255, 0.85);
  @mixin center;
}

.username {
  color: colorPurple100;
}

.countries {
  color: colorGrey100;
}
