@value colorGrey100 from '~Styles/vars/colors.css';

.root {
  position: relative;

  .count {
    color: colorGrey100;
  }

  .list {
    margin-top: 16px;
  }

  .headerControls {
    display: flex;
    gap: 8px;
    justify-content: space-between;

    .startHeaderControls {
      justify-self: flex-start;
    }

    .endHeaderControls {
      display: flex;
      gap: 8px;
    }
  }

}
