@value colorWhite, colorGrayDog, colorWetAsphalt, colorGrey50, colorBase, colorConcrete, colorPink100 from '~Styles/vars/colors.css';

.root {
  display: block;
  position: relative;
}

button.button {
  font-family: 'Aeroport';
  border-radius: 0;
  min-width: unset !important;
  padding-left: 2px !important;
  padding-right: 2px !important;
}

.root:first-child .button {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.root:last-child .button {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.root:not(:first-child) .button {
  margin-left: -1px;
}

.open .button,
.button:hover {
  z-index: 1;
}

.dirty .button {
  font-family: 'Aeroport';
  font-weight: 500;
  background-color: rgba(78, 66, 228, 0.1);
  color: colorBase;
  border: 1px solid colorBase;
  z-index: 2;

  &:hover {
    z-index: 2;

    &::before {
      opacity: 0;
    }
  }
}

.content {
  display: none;
  position: absolute;
  z-index: 3;
  margin-top: 8px;
  padding: 8px 0;
  border-radius: 4px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  background: colorWhite;
  min-width: 110px;
  align-items: flex-end;
}

.open .content {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
}

.wrapContent {
  padding: 20px;
}

.top {
  .content {
    bottom: 100%;
    margin: 0 0 8px;
  }
}

/* POSITION */

.position-left {
  .content {
    right: 0;
  }
}

.newDesign {
  button.button {
    font-family: 'Aeroport';
    font-weight: 500;
    height: auto;
    width: 129px;
    padding: 20px;
    border: 1px solid colorGrey50;
    border-top-left-radius: 8px !important;
    border-bottom-left-radius: 8px !important;
    border-top-right-radius: 8px !important;
    border-bottom-right-radius: 8px !important;

    span {
      width: 100%;
      text-align: center;
    }
  }

  &.dirty .button {
    border: 1px solid colorBase;
    box-shadow: 0 0 0 1px colorBase;
  }

  &.open .button {
    border: 1px solid colorBase;
    box-shadow: 0 0 0 1px colorBase;
    background-color: colorConcrete;
  }

  .content {
    left: 50%;
    transform: translate3d(-50%, 0, 0);
  }
}

.accentDot {
  width: 16px;
  height: 16px;
  position: absolute;
  top: -8px;
  right: -8px;
  z-index: 2;
  border-radius: 50%;
  background-color: colorPink100;
}
