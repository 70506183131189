.root {
  margin-top: 16px;
}

.title {
  margin-bottom: 16px;
}

.tabs {
  & > * {
    flex: 1;
  }
}

.row {
  margin-bottom: 32px;
}

.videos {
  display: flex;
  gap: 16px;
}
