.root {
  padding: 40px 0;
  margin-top: -400px;

  &::before {
    content: '';
    width: 10px;
    height: 400px;
    display: block;
    top: 0;
  }
}

.content {
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.section {
  flex-wrap: wrap;
  display: flex;
}

.item {
  width: 180px;
  margin-bottom: 20px;

  &:not(:nth-child(3n)) {
    margin-right: 40px;
  }
}

.bar {
  width: 290px;
  margin-bottom: 20px;

  &:not(:nth-child(2n)) {
    margin-right: 40px;
  }
}
