@value colorGrey100, colorGrey10, colorWhite from '~Styles/vars/colors.css';
@value newDesktopMinWidth from '~Styles/vars/query.css';

.root {
  display: block;
  width: 100%;
  padding-top: 24px;
  box-sizing: border-box;
  background: colorGrey10;
}

.content {
  @mixin newInnerContainer;
}

.header {
  margin: 8px 0 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.members {
  border-radius: 16px;
  background: colorWhite;
  padding: 16px 0;
}

.member {
  padding: 12px 0 12px 32px;
  display: flex;
  background: colorWhite;
  size: 100% 68px;
  border-bottom: 1px solid colorGrey10;
  box-sizing: border-box;
}

.avatar {
  margin-right: 8px;
}

.names {
  width: 205px;
}

.email {
  color: colorGrey100;
}

.fieldName {
  color: colorGrey100;
}

.fields {
  padding-left: 32px;
  padding-bottom: 16px;
  border-bottom: 1px solid colorGrey10;
}

.tag {
  margin-left: 8px;
}
