@value headerHeight, subheaderHeight from '~Styles/vars/size.css';
@value colorGrey10 from '~Styles/vars/colors.css';

:root {
  --detailsSize: 320px;
}

.root {
  width: 100%;
  position: relative;
  overflow: auto;
  display: flex;
  min-height: calc(100vh - headerHeight);
  align-items: center;
  justify-content: center;
  background-color: colorGrey10;
}

.searchSection {
  flex-grow: 1;
  height: calc(100vh - headerHeight);
  display: inline-block;
  vertical-align: top;
  overflow: auto;
}

.mediaplanSection {
  width: var(--detailsSize);
  height: calc(100vh - headerHeight);
  flex: none;
  overflow: auto;
}
