@value colorWetAsphalt, colorBlue, colorRed, colorError100, colorSuccess100 from '~Styles/vars/colors.css';
@value desktopMinWidth, mobileMin from '~Styles/vars/query.css';

html,
body {
  height: 100%;
}

body {
  width: 100%;
  min-width: desktopMinWidth;
  color: colorWetAsphalt;
  font-family: 'Aeroport';
  font-weight: 200;
  -webkit-font-smoothing: antialiased;
  -webkit-backface-visibility: hidden;
}

a {
  color: inherit;
  display: inline-block;
  text-decoration: none;
}

b {
  font-weight: bold;
}

input:-webkit-autofill,
textarea:-webkit-autofill,
select:-webkit-autofill {
  background: none !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}

input,
textarea {
  outline: none;
  border: none;
  font-family: 'Aeroport';
  -webkit-appearance: none;
}

input[type='file'] {
  cursor: pointer;
}

div[role='menu'],
div[role='presentation'] {
  width: auto !important;
  min-width: 198px;
}

img {
  font-size: 0;
}

video {
  outline: none;
}

:global .alarm {
  color: colorRed;
}

:global .enter {
  opacity: 0.01;
}

:global .enterActive {
  opacity: 1;
  transition: opacity 500ms ease-in;
}

:global .leave {
  opacity: 1;
}

:global .leaveActive {
  opacity: 0.01;
  transition: opacity 300ms ease-in;
}

:global .link {
  color: colorBlue;
}

:global .isMobile {
  min-width: 100%;

  #content {
    min-width: 100%;
  }

  #formWrapper {
    width: 100%;
    padding-left: 10px;
    padding-right: 10px;
    box-sizing: border-box;
  }
}

:global .modalIsOpened {
  overflow: hidden;
}

:global .hidden {
  overflow: hidden;
}

:global .path {
  transition: fill 0.1s ease-in, stroke 0.1s ease-in;
}

:global .rightAnswer {
  size: 8px;
  display: inline-block;
  background: colorSuccess100;
  margin-right: 8px;
  border-radius: 50%;
}

:global .falseAnswer {
  size: 8px;
  display: inline-block;
  background: colorError100;
  margin-right: 8px;
  border-radius: 50%;
}

:global .react-datepicker-wrapper {
  width: 100%;
}

:global .react-datepicker__input-container {
  vertical-align: bottom;
}

img[data-test='powered-by-image'] {
  opacity: 0;
}

details > summary {
  list-style: none;
}

details > summary::marker,
details > summary::-webkit-details-marker {
  display: none;
}

::-webkit-file-upload-button {
  cursor: pointer;
}
