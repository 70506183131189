@value colorError100, colorWhite, colorGrey100 from '~Styles/vars/colors.css';
.drawer {
  background-color: colorWhite;
  padding-top: 8px;

  .titleTextPreset {
    margin-bottom: 16px;
  }

  .contentRoot {
    display: flex;
    flex-direction: column;
    height: calc(100vh - 64px);
    margin: 0 -16px;
    gap: 8px;
    justify-content: space-between;
  }

  .submitButtonWrapper {
    box-sizing: border-box;
    position: relative;
    width: 100%;
    margin-top: 32px;
    display: flex;

    .submitButton {
      box-sizing: border-box;
      width: 100%;
    }
  }

  .input {
    height: 72px;
  }

  .textarea {
    min-height: 300px;
  }
  .requirements {
    padding: 0 16px;
  }
  .errorTextPreset {
    color: colorError100;
    padding: 0 16px;
  }
  .example {
    border-radius: 16px;
    border: 1px solid colorGrey100;
    margin-top: 8px;
    object-fit: contain;
  }
}
