.play {
  size: 40px;
  background-color: rgba(0, 0, 0, 0.5);
  background-image: url('~files/images/general/play_white.svg');
  background-repeat: no-repeat;
  background-position: calc(50% + 1px) center;
  background-size: 18px;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
  z-index: 1;
}