@value ( colorGrey10, colorError100, colorGrey100 ) from '~Styles/vars/colors.css';

.root {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: colorGrey10;
}

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 16px;
  background-color: white;
  border-radius: 16px;
  padding: 40px 40px 24px;
}

.form {
  width: 400px;
  div {
    box-shadow: none;
  }
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 16px;

  &.withoutContent {
    margin-top: 0px;
  }

  .submitButton {
    margin-top: 16px;
    margin-bottom: 16px;
  }

  .input {
    width: calc(100% - 16px);
    margin-left: 8px;
  }

  .textByCenter {
    flex: 1;
    text-align: center;

    &.subtitle {
      color: colorGrey100;
    }
  }

  .additionalButtonContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
  }
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
}

.logo {
  display: flex;
  align-items: center;
}

.title {
  margin-bottom: 12px;
}

.creatorSignup {
  display: flex;
  margin-bottom: 40px;
}

.creatorSignupTitle {
  margin-right: 8px;
}

.errorText {
  color: colorError100;
  margin-left: 16px;
}

:global .isMobile {
  :local .root {
    background-color: white;

    :local .container {
      padding: 40px 0px;

      :local .formContainer {
        width: calc(100% - 32px);

        :local .form {
          width: 100%;
        }
      }
    }
  }
}

.privacy {
  margin: 0 auto;
}
