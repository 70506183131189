.root {
  display: block;
}

.details {
  display: flex;
}

.icon {
  margin-right: 4px;
}

.detail {
  @mixin vertical-center;
}

.detail:not(:last-child) {
  margin-right: 12px;
}

.stats {
  display: flex;
}

.statItem {
  flex: 1;
  text-align: center;
}