@value colorGrey100, colorSky100, colorPink100 from '~Styles/vars/colors.css';

.root {
  margin: 16px 0;

  .title {
    margin-bottom: 8px;
  }

  .list {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 8px;

    .infoIcon {
      &.default {
        color: colorGrey100;
      }
      &.sky {
        color: colorSky100;
      }
      &.pink {
        color: colorPink100;
      }
    }

    .item {
      display: grid;
      grid-template-columns: 24px 1fr;
      align-items: center;
      grid-gap: 8px;
    }

    .tooltipInfo {
      display: inline-flex;
      margin-left: 4px;
      position: relative;
      bottom: -2px;
      z-index: 2;
    }
  }

}
