@value colorGrey50, colorDark100, colorPink40, colorPink60 from '~Styles/vars/colors.css';

.root {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  width: 40px;
  height: 40px;
  border-radius: 100%;
  z-index: 1;
}

[class*='react-datepicker__day--selected'] > .root,
[class*='react-datepicker__day--in-range'] > .root,
[class*='react-datepicker__day--in-range'] {
  background-color: colorPink60 !important;
}

[class*='react-datepicker__day--range-start'] {
  background-color: transparent !important;
  position: relative;

  &::after {
    content: '';
    display: block;
    width: 50%;
    height: 100%;
    background-color: colorPink60;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 0;
  }
}

[class*='react-datepicker__day--range-end'] {
  background-color: transparent !important;
  position: relative;

  &::before {
    content: '';
    display: block;
    width: 50%;
    height: 100%;
    background-color: colorPink60;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
  }
}

[class*='react-datepicker__day--range-start'][class*='react-datepicker__day--range-end'] {
  &::before,
  &::after {
    background-color: transparent !important;
  }
}

[class*='react-datepicker__day--in-selecting-range'],
[class*='react-datepicker__day--in-selecting-range'] > .root {
  background-color: colorPink40 !important;
}

[class*='react-datepicker__day--selecting-range-start'] {
  background-color: transparent !important;
  position: relative;

  &::after {
    content: '';
    display: block;
    width: 50%;
    height: 100%;
    background-color: colorPink40;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 0;
  }
}

[class*='react-datepicker__day--selecting-range-end'] {
  background-color: transparent !important;
  position: relative;

  &::before {
    content: '';
    display: block;
    width: 50%;
    height: 100%;
    background-color: colorPink40;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
  }
}

[class*='react-datepicker__day--selecting-range-start'][class*='react-datepicker__day--selecting-range-end'] {
  &::before,
  &::after {
    background-color: transparent !important;
  }
}

[class*='react-datepicker__day'] > .root {
  &:hover {
    background-color: colorPink40;
  }
}

[class*='react-datepicker__day--selected'] > .root {
  background-color: colorPink60 !important;
}

[class*='react-datepicker__day--today'] > .root {
  &::after {
    content: '';
    width: 4px;
    height: 4px;
    position: absolute;
    bottom: 5px;
    left: 50%;
    margin-left: -2px;
    background-color: colorDark100;
    border-radius: 100%;
  }
}
