@value colorWhite, colorGrey100 from '~Styles/vars/colors.css';

.root {
  flex: 1;
  overflow: auto;
  background: colorWhite;
  border-radius: 8px;
  width: 268px;
  padding: 8px 0;

  .loader {
    padding: 16px;
    width: 268px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.emptyTitle {
  color: colorGrey100;
  text-align: center;
  size: 268px 100%;
  @mixin center;
}
