@value colorGrey100, colorPurple100 from '~Styles/vars/colors.css';

.root {
  position: relative;

  .counters {
    display: flex;
    color: colorGrey100;
    margin-bottom: 8px;
  }
}

.count:not(:last-child)::after {
  content: ' / ';
}

.drawer {
  background-color: white;
}

.drawerContainer {
  display: flex;
  height: 100%;
  width: 516px;
  flex-direction: column;
  justify-content: space-between;

  .drawerTitleText {
    margin-bottom: 16px;
  }

  a[href^='mailto:'] {
    color: colorPurple100;
  }

  button {
    width: 100%;
  }
}

.actionBtn {
  position: absolute;
  right: 0;
  top: -32px;
}
