@value colorWhite from '~Styles/vars/colors.css';

.root {
  display: flex;
  align-items: center;
  gap: 12px;
}

.details {
  flex: 1;
  overflow: hidden;
}

.campaignInfo {
  display: flex;
  flex-wrap: wrap;
  align-items: baseline;
}

.organization {
  display: flex;
  align-items: center;
  margin-top: 4px;
}

.organizationBefore {
  display: flex;
  align-items: center;

  &::before {
    content: '•';
    padding: 0 8px;
  }
}

.inlineTextBtn {
  display: inline-block;
}

.platform {
  size: 16px;
  background: colorWhite;
  border-radius: 50%;
  @mixin center;
  position: absolute;
  bottom: 0;
  right: 0;
}

.platformIcon {
  height: 10px;
  margin-top: -6px;

  svg {
    size: 10px;
  }
}

.openBtn {
  size: 60px 32px;
  min-width: 60px !important;
}
