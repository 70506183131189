@value colorWhite, colorDark100, colorGrey50, colorGrey10 from '~Styles/vars/colors.css';

.root {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: colorWhite;
  font-family: 'Aeroport';
  margin-bottom: 4px;
}

.monthYearContainer {
  display: flex;
}

.dropdown {
  font-size: 15px;
  line-height: 24px;
  color: colorDark100;
  font-weight: 400;
  transition: 0.3s background;

  &:hover {
    background: colorGrey10;
  }
}

.dropdownGroup {
  width: 220px;
  left: -1px;
  height: 322px;
  overflow: hidden;
  overflow-y: scroll;
}

.reversedDropdownGroup {
  display: flex;
  flex-direction: column-reverse;
}

.changeMonthBtn {
  padding: 12px 16px;
}

.changeMonthContainer {
  display: flex;
}
