@value colorPurple100, colorGrey100, colorGrey10 from '~Styles/vars/colors.css';

.subtitleContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
}

.root {
  background-color: white;
  padding: 8px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 100%;
  box-sizing: border-box;
  margin-bottom: 16px;
  border-bottom: 2px solid colorGrey10;
}

.title {
  width: 371px;
  align-items: start;
  padding-left: 16px;
  box-sizing: border-box;
}

.details {
  display: flex;
  gap: 10px;
  align-items: center;
  flex: 1;
}

.dataContainer {
  padding: 8px 0;
  display: flex;
  width: 100%;
}

.downloadContainer {
  display: flex;
  gap: 4px;
  align-items: center;
}

.purple {
  color: colorPurple100;
}

.grey {
  color: colorGrey100;
}

.names {
  width: 310px;
  align-items: flex-start;
}

.controls {
  flex: 1;
}
