@value colorWhite from '~Styles/vars/colors.css';

.root {
  background-color: colorWhite;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1), 0 0 2px rgba(0, 0, 0, 0.05);
  padding: 8px *;
  min-width: 180px;
  max-height: 292px;
  overflow: auto;
}

.root.autoHeight {
  max-height: 80vh;
}
