@value colorGrey10 from '~Styles/vars/colors.css';
.attachTitle {
  margin: 4px 16px;
}

.dropdownValue {
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: calc(100% - 32px);
  overflow: hidden;
}

.dropdownPlatform {
  width: calc(100% - 32px);
  background: colorGrey10;
  margin: 0px 16px 32px;
}


.dropdownMenu {
  width: 100%;
  right: 0;
  max-height: 276px;
  overflow: auto;
}