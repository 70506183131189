@value colorWhite from '~Styles/vars/colors.css';

.content {
  top: -214px;
  left: -93px;
  position: absolute;
  width: 385px;
  height: 827px;
  overflow: auto;
  transform: scale(0.46);
  background: colorWhite;
  border-radius: 40px;
}

.phone {
  position: relative;
}
