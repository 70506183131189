@value colorWhite, colorWetAsphalt, colorGrey50, colorBase, colorError100 from '~Styles/vars/colors.css';

.root {
  border-radius: 16px;
  position: relative;
  display: flex;
  align-items: center;
}

.actionAble {
  border-radius: 8px 8px 0 0;
}

.header {
  top: 20px;
  right: 20px;
  position: absolute;
}

.content {
  position: relative;
  font-size: 0;
}

.readMark {
  margin-left: 4px;
  @mixin center;
}

.dateWrap {
  padding: 4px 8px;
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  right: 12px;
  bottom: 12px;
  @mixin center;

  svg {
    size: 16px;

    path {
      fill: colorWhite;
    }
  }
}

.date:not(:only-child) {
  margin-right: 4px;
}

.video {
  .container {
    border-radius: 16px;
    padding: 8px 12px;
  }

  .dateWrap {
    right: 0;
    bottom: 0;
    background: none;
    position: relative;
    justify-content: flex-end;
    color: colorWetAsphalt;
    padding: 8px 0 4px;
  }
}

.container {
  width: 100%;
}

.image {
  font-size: 0;
  border-radius: 16px;
  background-size: cover;
  background-position: center;

  &.isProcessing {
    opacity: 0.5;

    &::after {
      content: '';
      size: 100%;
      display: block;
      background-color: rgba(0, 0, 0, 0.5);
      border-radius: 16px;
    }
  }
}

.imageSm {
  size: 85px;
}

.imageMd {
  size: 150px;
}

.preloader {
  size: 24px;
  position: absolute;
}

.imageWrap {
  position: relative;
  display: flex;
  @mixin center;
  cursor: pointer;
}

.duration {
  background: rgba(0, 0, 0, 0.4);
  border-radius: 40px;
  padding: 4px 8px 4px 4px;
  display: flex;
  align-items: center;
  color: white;
  position: absolute;
  bottom: 8px;
  left: 8px;
  gap: 2px;
}

.fileWrap {
  border-radius: 16px;
  border: 1px solid colorGrey50;
  @mixin center;
  flex-direction: column;
  gap: 8px;
  box-sizing: border-box;
}

.fileIcon {
  color: colorBase;
}
