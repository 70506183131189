@value colorGrey10, colorGrey50, colorGrey100, colorDark100, colorGrass40, colorApproval100 from '~Styles/vars/colors.css';

.drawerContainer {
  .drawerBackdrop {
    z-index: 1100;
  }

  .drawerWrapper {
    z-index: 1101;
    background-color: white;

    .root {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .formContainer {
        display: flex;
        flex-direction: column;
        flex: 1;
        .title {
          margin-bottom: 24px;
        }

        .descriptionText {
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 8px;
          margin-bottom: 16px;
        }

        .selectorLabel {
          width: 178px;
          background-color: colorGrey10;
          margin-bottom: 16px;
          margin-top: 16px;

          .group {
            width: 178px;
          }
        }

        .greenContainer {
          flex: 1;
          align-items: center;
          justify-content: center;
          display: flex;

          .greenIcon {
            height: 180px;
            width: 180px;
            border-radius: 90px;
            background-color: colorGrass40;
            position: relative;

            &:before {
              content: '';
              display: block;
              height: 80px;
              width: 40px;
              border-bottom: 3px solid colorApproval100;
              border-right: 3px solid colorApproval100;
              position: absolute;
              top: calc(50% - 10px);
              left: 50%;
              transform: translate3d(-50%, -50%, 0) rotate(45deg);
            }
          }
        }
      }


      .bottomContainer {
        width: 100%;
        display: flex;
        flex-direction: column;

        .buttonContainer {
          width: 100%;
          display: flex;
          flex-direction: row;
          text-align: center;
          gap: 16px;
          margin-top: 16px;
          .buttonText {
            width: 100%;
            text-align: center;
          }
        }
      }
      
    }
  }
}