@value headerHeight from '~Styles/vars/size.css';
@value desktopMinWidth from '~Styles/vars/query.css';
@value colorWhite, colorConcrete from '~Styles/vars/colors.css';

.root {
  width: 100%;
  display: block;
  margin: 0 auto;
  box-sizing: border-box;
  padding-top: headerHeight;
}

.container {
  width: desktopMinWidth;
  margin: 0 auto;
}

.title {
  margin-top: 40px;
  margin-bottom: 40px;
}

.list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.list::after {
  content: '';
  width: 380px;
}

.addProfile {
  size: 380px 88px;
  background: colorWhite;
  border: 1px dashed colorConcrete;
  margin-bottom: 20px;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.plus {
  margin-right: 4px;
}

.spinner {
  margin: 40px auto;
}


:global .isMobile {
  :local .root {
    .container {
      width: 100%;
      margin-bottom: 40px;
    }

    .title {
      padding: 0 16px;
      margin-top: 32px;
      margin-bottom: 32px;
    }

    .addProfile {
      size: 100% 80px;
      border-style: solid;
      margin-bottom: 0;
    }

    .list {
      display: block;
    }

    .list::after {
      content: unset;
    }
  }
}