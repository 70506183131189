@value desktopMinWidth from '~Styles/vars/query.css';

.root {
  min-height: 100%;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
}

.info {
  @mixin innerContainer;
  margin: 24px auto 12px;
}

.tabs {
  @mixin innerContainer;
}

.viewToggle {
  display: flex;
  align-items: center;
  flex-shrink: 0;
}

.toggle {
  margin-left: 12px;
}

.tabsWrap {
  @mixin innerContainer;
  display: flex;
  justify-content: space-between;
  margin-bottom: 4px;
}

.tabsElements {
  display: flex;
  align-items: center;
}
