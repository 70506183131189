@value colorGrey100 from '~Styles/vars/colors.css';

.root {
  &:not(:last-child) {
    margin-bottom: 32px;
  }
}

.title {
  user-select: none;
}

.descr {
  display: inline-block;
  margin-bottom: 8px;
}

.titleWrap {
  display: flex;
}

.optional {
  margin-left: 4px;
}

.content {
  &:not(.active) {
    display: none;
  }
}

.contentContainerWithRight {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
