@value colorWhite, colorGrey10 from '~Styles/vars/colors.css';

.root {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: colorWhite;
}

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 16px;
  background-color: white;
  border-radius: 16px;
  max-width: 720px;
}

.header {
  width: 100%;
  height: 64px;
  border-bottom: 1px solid colorGrey10;
}

.imageWrapper {
  width: 100%;
  height: 100px;
  background-color: #f2f0ed;
  display: flex;
  justify-content: center;
  padding-top: 5px;
}

.logo {
  width: 103px;
  margin-left: 50px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.title {
  margin-bottom: 32px;
  margin-top: 32px;
  text-align: center;
}

.form {
  max-width: 484px;
  div {
    box-shadow: none;
  }
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-top: 16px;

  .submitButton {
    margin-top: 16px;
    margin-bottom: 16px;
  }

  .cities {
    margin-bottom: 0;
  }

  .input:disabled {
    background-color: colorGrey10;
  }
}

.errorText {
  margin-top: 6px;
}

.tosAgreement {
  margin-top: 60px;
  text-align: center;
}

.inlineLink {
  display: inline;
}

:global .isMobile {
  :local .logo {
    display: none;
  }
  :local .container {
    padding: 16px;
  }
  :local .title {
    text-align: left;
    margin-top: 8px;
    margin-bottom: 8px;
  }
  :local .tosAgreement {
    margin-top: 30px;
  }
}
