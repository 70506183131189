@value colorGrey10 from '~Styles/vars/colors.css';

.searchForm {
  margin-bottom: 20px;
}

.contentCreators {
  margin-bottom: 32px;
}

.loadingContainer {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  background-color: colorGrey10;
}

.barItemRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
}