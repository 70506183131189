@value colorGrey50 from '~Styles/vars/colors.css';

.root {
  display: inline-flex;
  padding: 20px;
  border-radius: 4px;
  border: 1px solid colorGrey50;
}

.item {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  min-width: 59px;
}

.title {
  margin-top: 8px;
}

.bar {
  width: 12px;
  border-radius: 99px;
  margin: 0 auto;
}

.barWrap {
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
}

.bars {
  display: flex;
  justify-content: space-around;
}
