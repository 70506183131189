@value colorWhite, colorDark100 from '~Styles/vars/colors.css';

.tooltip {
  size: 314px auto;
  padding: 20px;
  background-color: colorWhite !important;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  border-radius: 2px;
  pointer-events: auto !important;
  opacity: 1 !important;
  cursor: default;
  box-sizing: border-box;

  &:hover {
    visibility: visible !important;
    opacity: 1 !important;
  }
}

.tooltip-content {
  height: 100%;
  box-sizing: border-box;
}

.color-black {
  background-color: colorDark100 !important;
}