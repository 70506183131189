@value colorBase, colorGrey50 from '~Styles/vars/colors.css';

.preloader {
  margin: 0 auto;
}

.item {
  display: flex;

  &:not(:last-child) {
    margin-bottom: 20px;
  }
}

.item.isActive {
  .count {
    background: colorBase;
  }

  .countWrap {
    &::after {
      content: '';
      top: 44px;
      left: 11px;
      width: 2px;
      height: calc(100% - 44px);
      display: block;
      position: absolute;
      background: colorBase;
    }
  }
}

.count {
  size: 24px;
  border-radius: 50%;
  @mixin center;
}

.countWrap {
  margin-right: 12px;
  position: relative;
}

.isDisabled {
  .count {
    background: colorGrey50;
  }
}

.content {
  flex-grow: 1;
}

.experimentButtonsGroup {
  .textButton {
    padding: 8px 16px;

    .text {
      color: colorBase;
      font-size: 16px;
      line-height: 24px;
      font-weight: 500;
    }
  }
}

.reportBtn {
  box-sizing: border-box;
  width: 100%;
  justify-content: center;
}
