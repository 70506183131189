@value colorGrey50 from '~Styles/vars/colors.css';

.root {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: calc(100% + 8px);
  right: -1px;
  width: 380px;
  background-color: white;
  z-index: 54;
  border: 1px solid colorGrey50;
  border-radius: 8px;
  padding: 8px 4px;
  box-sizing: border-box;

  &.right {
    right: -1px;
  }

  &.left {
    left: -1px;
  }
}
