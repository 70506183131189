@value colorConcrete, colorBase from '~Styles/vars/colors.css';

.root {
  overflow: hidden;
}

.item {
  display: flex;
  align-items: center;
}

.name {
  flex-grow: 1;
  margin-right: 20px;
}

.count {
  flex-shrink: 0;
  text-align: right;
  margin-right: 8px;
}

.bar {
  size: 91px 8px;
  flex-shrink: 0;
  border-radius: 7px;
  position: relative;
  background: colorConcrete;
}

.progress {
  top: 0;
  left: 0;
  height: 8px;
  border-radius: 7px;
  position: absolute;
  background: colorBase;
}