@value colorWhite from '~Styles/vars/colors.css';

.root {
  size: 100% 72px;
  display: flex;
  align-items: center;
  background: colorWhite;
  padding: 16px;
  box-sizing: border-box;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.05), 0 1px 4px rgba(0, 0, 0, 0.1);
}

.names {
  margin-left: 12px;
  flex-grow: 1;
  overflow: hidden;
}

.username,
.fullName {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.back {
  margin-right: 16px;
}

.price {
  flex-shrink: 0;
  margin-left: 10px;
  border: 1px solid;
  padding: 2px 8px;
  border-radius: 4px;
}