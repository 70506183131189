.priceWrap {
  display: flex;
  align-items: center;
  margin: 20px 0;
}

.priceTitle {
  margin-right: 8px;
}

.psEnable {
  width: 100%;
  height: 40px;
  @mixin center;
  background-color: rgba(78, 66, 228, 0.1);
  border-radius: 4px;
  padding: 8px 0;
  box-sizing: border-box;
  margin-top: 20px;
  margin-bottom: 12px;
}
