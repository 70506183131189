@value colorDark40, colorDark100 from '~Styles/vars/colors.css';

.emptyList {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;

  .emptyTitle {
    margin-bottom: 8px;
  }
}

.root {
  flex: 1;
  overflow-y: auto;
  margin: 8px 0;

  .listContainer {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 8px;
  }

  .mediaWrapper {
    height: 115px;
    width: 115px;
    border-radius: 16px;
    overflow: hidden;
    position: relative;
    border: 1px solid transparent;

    &.active {
      border: 1px solid colorDark100;

      .checkboxWrapper {
        opacity: 1;
        background-color: colorDark100;

        i {
          color: white;
        }
      }
    }

    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
      position: absolute;
      z-index: 0;
    }

    .checkboxWrapper {
      position: absolute;
      top: 8px;
      right: 8px;
      height: 24px;
      width: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      background-color: white;
      opacity: 0.8;
      cursor: pointer;
    }

    .durationContainer {
      position: absolute;
      bottom: 8px;
      left: 8px;
      border-radius: 12px;
      background-color: colorDark40;
      padding: 4px;
      display: flex;
      flex-direction: row;
      align-items: center;
      backdrop-filter: blur(10px);

      .playIcon {
        color: white;
        margin-right: 2px;
      }
    }
  }
}
