@value colorGrayDog, colorRed from '~Styles/vars/colors.css';

.preview {
  display: flex;
  justify-content: space-between;

  .notValid {
    border: 1px solid colorRed;
  }
}

.previewTitle {
  margin-bottom: 4px;
}

.previewDetails {
  display: flex;
  align-items: center;
}

.previewContent {
  flex-grow: 1;
}

.previewControls {
  display: flex;
  gap: 20px;
  align-items: center;
  flex-shrink: 0;
}

.imagePreview {
  size: 100%;
  background-position: center;
  background-size: cover;
}

.subtitleWrap {
  display: flex;
  flex-wrap: nowrap;
  overflow: hidden;
}

.subtitleItem {
  display: flex;
  align-items: center;

  &:not(:last-child) {
    &::after {
      content: '';
      size: 4px;
      display: block;
      background: colorGrayDog;
      border-radius: 50%;
      margin: 0 6px;
    }
  }
}

.references {
  display: flex;
  gap: 4px;

  &:not(:empty) {
    margin-right: 12px;
  }
}

.referencesPreview {
  size: 24px;
  overflow: hidden;
  @mixin center;
  border-radius: 4px;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
}