@value colorGrey10 from '~Styles/vars/colors.css';

.archive {
  width: 100%;
  padding: 16px 64px;
  box-sizing: border-box;
  margin-bottom: 20px;
}

.newPrice {
  width: 100%;
  box-sizing: border-box;
  padding: 32px 20px;
  background-color: colorGrey10;
  text-align: center;
  position: relative;
}

.icon {
  top: -20px;
  margin-left: -20px;
  size: 40px;
  position: absolute;
}

.buttons {
  display: flex;
  justify-content: center;
  margin: 20px auto 0;
}

.button {
  min-width: 115px;
}

.button:not(:last-child) {
  margin-right: 20px;
}