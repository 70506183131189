@value colorGrey100 from '~Styles/vars/colors.css';

.valueText {
  width: max-content;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  min-width: 28px;

  .selectorCounter {
    color: colorGrey100;
  }
}
