@value colorConcrete, colorBlue, colorGrey50 from '~Styles/vars/colors.css';

.root {
  overflow: hidden;
  border-radius: 50%;
  background-color: colorConcrete;
  flex-shrink: 0;
  font-size: 0;
  position: relative;
  @mixin center;
}

.image {
  width: 100%;
}

.defaultColor-blue {
  background-color: colorBlue;
}

.defaultColor-alto {
  background-color: colorGrey50;
}

.icon {
  max-width: 75%;

  svg {
    width: 100%;
  }
}

.stub {
  size: 100%;
}
