@value (colorGrey50, colorGrey100, colorError100) from '~Styles/vars/colors.css';

.root {
  position: relative;
}

.content {
  position: relative;

  .twoColRow {
    display: grid;
    grid-template-columns: repeat(2, 1fr);

    .inputPreset {
      &:nth-child(2n + 1) {
        padding-right: 8px;
      }
      &:nth-child(2n) {
        padding-left: 8px;
      }
    }
  }

  .formInput {
    height: 72px;
  }

  .errorMsg {
    color: colorError100;
    margin-top: 8px;
  }

  .dropdown {
    border-radius: 16px;
    min-height: 72px;
    box-sizing: border-box;
  }

  .dropdownValue {
    display: flex;
    align-items: center;
    color: colorGrey100;
    height: 48px;

    &.selected {
      color: black;
    }
  }

  .dropdownGroup {
    width: 100%;
    left: 0;
    right: 0;
  }
}
