@value headerHeight from '~Styles/vars/size.css';

.root {
  width: 268px;
  padding: 12px 12px 0;
  height: calc(100vh - headerHeight - 24px);
  display: flex;
  flex-direction: column;

  .filters {
    margin-bottom: 12px;
  }
}
