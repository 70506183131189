@value colorGrayDog, colorWetAsphalt from '~Styles/vars/colors.css';

.root {
  display: inline-block;
  cursor: pointer;

  path {
    fill: colorGrayDog;
  }

  &:hover {
    path {
      fill: colorWetAsphalt;
    }
  }
}