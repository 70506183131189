@value colorConcrete from '~Styles/vars/colors.css';
@value mobileMin, mobileMax from '~Styles/vars/query.css';

.root {
  size: 100% 100vh;
  @mixin center;
  background: colorConcrete;
  position: relative;
  text-align: center;

  .errorText {
    font-size: 500px;
    line-height: 100%;
  }

  .title {
    font-size: 80px;
    line-height: 88px;
    margin-bottom: 40px;
  }

  .descr {
    font-size: 20px;
    line-height: 32px;
  }
}

.details {
  padding: 58px 0;
  position: absolute;
}

@media (max-width: mobileMax) {
  .errorText {
    font-size: 170px;
  }

  .title {
    font-size: 32px;
    line-height: 40px;
    margin-bottom: 10px;
  }

  .descr {
    padding: 0 30px;
    line-height: 28px;
  }
}
