@value colorGrey50 from '~Styles/vars/colors.css';

@keyframes backgroundAnimate {
  0% {
    background-color: none;
  }

  25% {
    background-color: rgba(252, 189, 66, 0.2);
  }

  75% {
    background-color: none;
  }
}

.root {
  width: 100%;
  border-radius: 8px;
  margin: 32px 0;
}

.container {
  display: flex;
  border-radius: 8px 8px 0 0;
  border: 1px solid colorGrey50;
  border-bottom: unset;
  align-items: flex-start;
}

.content {
  padding: 32px;
}

.showSparkAdsAwareness {
  animation: backgroundAnimate 5s linear;
  transition: background 0.1s ease-in;
}

.img {
  height: 232px;
}

.readMore {
  padding-left: 4px;
}
