@value colorBrown40, colorSky100, colorSky40, colorPink100, colorSuccess100, colorGrey10,colorPurple100, colorGrey100 from '~Styles/vars/colors.css';

.root {
  display: block;
}

.title {
  margin-bottom: 24px;
}

.drawer {
  @mixin drawer;
}

.drawerContainer {
  @mixin drawerContainer;
  justify-content: flex-start;
}

.content {
  margin-bottom: 80px;
}

.benefit1 {
  border-radius: 16px;
  background: colorBrown40;
  padding: 24px 16px;

  .benefitTitle {
    margin-bottom: 8px;
  }

  .benefitDescr {
    margin-bottom: 20px;
  }
}

.images {
  display: flex;
  gap: 60px;
  justify-content: center;
}

.imageTitle {
  margin-top: 8px;
  margin-bottom: 8px;
}

.spacer {
  size: 100% 32px;
}

.benefit2 {
  .benefitTitle {
    margin-bottom: 24px;
    text-align: center;
  }
}

.adsBenefit {
  display: flex;
  gap: 16px;
  align-items: center;
  border-radius: 16px;
  background: colorGrey10;
  padding: 16px;
  box-sizing: border-box;
  margin-bottom: 16px;
}

.blue {
  color: colorSky100;
}

.pink {
  color: colorPink100;
}

.green {
  color: colorSuccess100;
}

.purple {
  color: colorPurple100;
}

.grey {
  color: colorGrey100;
}

.benefit3 {
  padding: 24px 16px 24px 26px;
  border-radius: 16px;
  display: flex;
  background: colorSky40;
  display: flex;
  gap: 28px;
  align-items: center;
  justify-content: center;

  .benefitDescr {
    margin-top: 8px;
    margin-bottom: 16px;
  }

  .link {
    display: flex;
    gap: 3px;
  }
}

.benefit4 {
  display: flex;
  padding-top: 24px;
  padding-left: 16px;
  padding-bottom: 24px;
  align-items: center;
  justify-content: space-between;

  .benefitTitle {
    margin-bottom: 8px;
  }
}

.exampleItem {
  margin-bottom: 10px;
}

.benefit5 {
  display: flex;
  align-items: center;
  margin-top: 16px;
  gap: 24px;
}

.benefi6 {
  border-radius: 16px;
  background: colorBrown40;
  width: 100%;
  padding: 56px 0 72px 36px;
  position: relative;
  margin-top: 64px;
  box-sizing: border-box;

  .benefitTitle {
    margin-bottom: 8px;
  }

  .link {
    text-decoration: underline;
  }

  .image {
    right: 14px;
    bottom: 16px;
    position: absolute;
  }
}

.buttons {
  display: flex;
  padding: 8px 0;
  gap: 8px;
  position: absolute;
  bottom: 16px;
  width: 516px;
}
