@value colorDark100 from '~Styles/vars/colors.css';

.root {
  &:not(:last-child) {
    margin-bottom: 32px;
  }
}

.descr {
  margin-bottom: 8px;
}

.titleWrap {
  display: flex;
}

.optional {
  margin-left: 4px;
}

.range {
  align-items: flex-start;
  display: flex;
  gap: 8px;
}

.field {
  flex: 1;
}

.seperator {
  margin: 8px 0;
}

.dirty {
  border: 1px solid colorDark100 !important;
}

.shrink {
  padding-left: 0 !important;
  padding-right: 0 !important;
  text-align: center;
}
