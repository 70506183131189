.root {
  position: relative;
}

.title {
  padding-top: 16px;
}

.content {
  display: flex;
}

.aside {
  flex: 1;
  padding-right: 16px;
  position: relative;
}

.preloader {
  margin: 0 auto;
  padding: 40px 0;
}

.guideTooltip {
  min-width: 100% !important;
  box-sizing: border-box;
  margin-top: 16px;
}
