@value colorConcrete, colorGrey50, colorGrey100 from '~Styles/vars/colors.css';

.root {
  display: flex;
  margin-bottom: 10px;

  &:not(.isOwner) {
    .content {
      border: 1px solid colorGrey50;
    }
  }
}

.container {
  order: 2;
  border-radius: 16px;
  max-width: calc(100% - 54px);
}

.content {
  padding: 8px 12px;
  box-sizing: border-box;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.ava {
  order: 1;
  margin-left: 0;
  margin-right: 12px;
}

.details {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.readMark {
  margin-left: 4px;
  @mixin center;
}

.message {
  white-space: pre-wrap;
  word-wrap: break-word;
  overflow: hidden;

  & a {
    width: 100%;
  }
}

.isOwner {
  justify-content: flex-end;

  .ava {
    order: 2;
    margin-left: 12px;
    margin-right: 0;
  }

  .container {
    order: 1;
    background: colorConcrete;
  }

  .content {
    background: colorConcrete;
  }
}

.delteIcon {
  padding: 0;
  background: none;
}

.dropdownGroup {
  width: unset;
  top: auto;
  bottom: calc(100% + 8px);
}

.hidden {
  display: none;
}

.deletion {
  position: absolute;
  top: 0;
  left: 0;
  @mixin drawer;
  z-index: 100;
  padding: 16px;
  border-radius: 24px;
  box-sizing: border-box;
  size: 100%;
}

.deletionContent {
  width: 100% !important;
}

.closeBtn {
  position: absolute;
  top: 0px;
  left: -76px;
  height: 60px;
  width: 60px;
  border-radius: 16px;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.unhide {
  margin-left: 4px;
  cursor: pointer;
}

.hiddenText {
  color: colorGrey100;
}

.date {
  color: colorGrey100;
  text-align: right;
}
