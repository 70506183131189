.root {
  position: relative;
}

.noPaidText span {
  border-bottom: 1px dashed;
}

.dateField {
  top: 0;
  position: absolute;
  size: 100%;
  cursor: pointer;
  overflow: hidden;
  opacity: 0;
}
