@value colorYellow, colorWhite from '~Styles/vars/colors.css';

.barter {
  size: 24px;
  @mixin center;
  border-radius: 4px;
  background: colorYellow;
}

.platform {
  width: 240px;
  margin-right: 20px;
}

.genderDropdown {
  flex-wrap: wrap;
  width: 440px;
}

.ageDropdown {
  flex-wrap: wrap;
  justify-content: flex-end;
  width: 456px;
}

.geoDropdown {
  align-items: baseline !important;
}

.primaryCategoryDropDown {
  flex-direction: column;
  align-items: stretch !important;
  width: 280px;
}

.formSearch {
  & > div:first-child {
    min-width: 100%;
  }
}

.badgeItem {
  padding: 12px 20px;
}

.amazonFilter,
.creatorsWorkedWithFilter,
.tiktokShop,
.favoriteFilter {
  span {
    white-space: pre;
  }
}

.dropdownButtonIcon {
  height: 24px;
  width: 24px;
  margin-right: 0 !important;
  margin-left: 4px;
}

.customListsContainer {
  display: flex;
  flex-direction: column;
  gap: 0px !important;
  padding: 8px !important;
}

.actions {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.creatorTypes {
  width: 240px;
  height: 40px;
  border-radius: 4px;
  background: colorWhite;
}

.creatorTypesWrap {
  margin-right: 20px;
}

.experienceContainer {
  padding: 8px;
  gap: 8px !important;
}
