@value colorGrey50 from '~Styles/vars/colors.css';

.root.withToggle {
  &:not(:last-child) {
    padding-right: 20px;
  }
}

.header {
  display: flex;
  justify-content: space-between;
}

.labelWrap {
  display: flex;
  margin-bottom: 12px;
}

.label {
  margin-left: 12px;
}

.swicth {
  display: flex;
  align-items: baseline;
}

.btn:not(:last-child) {
  &::after {
    content: '/';
    padding: 0 10px;
    color: colorGrey50;
  }
}
