@value colorWetAsphalt, colorGrayDog, colorBase, lightBase from '~Styles/vars/colors.css';

.root {
  width: 200px;
}

.item {
  width: 100%;
  display: flex;
  cursor: pointer;

  &::before {
    content: '';
    display: block;
    size: 2px 32px;
    background: colorWetAsphalt;
  }

  &.active {
    background: lightBase;
    border-radius: 0 8px 8px 0;

    &::before {
      background: colorBase;
    }
  }

  &.blocked {
    cursor: default;
    color: colorGrayDog;

    &::before {
      background: colorGrayDog;
    }
  }

  &:not(:last-child) {
    margin-bottom: 4px;
  }
}

.itemContent {
  display: flex;
  padding: 8px 20px;
  width: 100%;
  box-sizing: border-box;
  justify-content: space-between;
}

.link {
  width: 100%;
  box-sizing: border-box;
}
