@value colorApproval100, colorSun100, colorSun80, colorError100, colorSuccess100 from '~Styles/vars/colors.css';

.root {
  display: inline-grid;
  grid-template-columns: 14px max-content;
  grid-gap: 4px;
  align-items: center;
}

.checkCircleIcon {
  display: flex;

  &.iconGreen {
    color: colorSuccess100;
  }

  &.iconYellow {
    color: colorSun80;
  }
}

.crossCircleIcon {
  display: flex;
  color: colorSun100;
}

.text {
  &.redText {
    color: colorError100;
  }
}
