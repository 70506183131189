@value desktopMinWidth from '~Styles/vars/query.css';
@value headerHeight from '~Styles/vars/size.css';
@value colorGrey10, colorGrey100, colorWhite from '~Styles/vars/colors.css';

.root {
  display: block;
  width: 100%;
  padding-top: 16px;
  box-sizing: border-box;
  background: colorGrey10;
}

.content {
  @mixin newInnerContainer;
}

.title {
  margin-bottom: 16px;
}

.container {
  width: desktopMinWidth;
  margin: 0 auto;
}

.selectCheckbox {
  width: 405px;
  background: colorWhite;
}

.filters {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;
}

.list {
  width: 764px;
}

.preloader {
  margin: 20px auto;
}

.columns {
  display: flex;
  gap: 52px;
}

.subscriptionTitle {
  margin-top: 40px;
}

.subscriptionDescr {
  color: colorGrey100;
  margin-bottom: 16px;
}
