@value (colorPink60) from '~Styles/vars/colors.css';

.tooltipContainer {
  left: 50% !important;
  transform: translateX(-50%) !important;
  top: -90px !important;
  width: 284px !important;
}

.tutorialTooltip {
  max-width: 484px;
  right: 16px !important;
  bottom: 8px !important;
  left: auto !important;
  top: auto !important;
  z-index: 10;
  height: 431px;
  position: fixed !important;
}

.shipmentTooltip {
  max-width: 304px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  .actionContainer {
    cursor: pointer;
    color: colorPink60;
  }
}

.closeIcon {
  width: 24px;
  height: 24px;
  cursor: pointer;
  position: absolute;
  top: 12px;
  right: 16px;
  color: white;
}

.tutorialTooltipContent {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.gif {
  border-radius: 16px;
}

.link {
  cursor: pointer;
  color: colorPink60;
}
