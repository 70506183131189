@value colorError100, colorWhite from '~Styles/vars/colors.css';

.drawer {
  @mixin drawer;
}

.drawerContainer {
  @mixin drawerContainer;
  justify-content: flex-start;
}

.root {
  display: block;
}

.title {
  margin-bottom: 20px;
}

.descr {
  margin-bottom: 24px;
}

.btn {
  margin-top: 40px;
}

.disclaimer {
  margin-top: 4px;
}

.error {
  color: colorError100;
}

.input {
  height: 72px;
}

.content {
  flex: 1;
}
