.root {
  .preloader {
    margin: 16px auto;
  }

  .headerControls {
    display: flex;
    gap: 8px;
    justify-content: space-between;

    .startHeaderControls {
      justify-self: flex-start;
    }

    .endHeaderControls {
      display: flex;
      gap: 8px;
    }
  }

  .textQuery {
    width: 240px;
  }

}
