@value colorGrey100, colorGrey10, colorPurple100, colorWhite, colorError100, colorApproval100, colorGrass40 from '~Styles/vars/colors.css';

.root {
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
}

.title {
  margin-bottom: 24px;
}

.content {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.payBtn {
  .btn {
    width: 100%;
    margin-bottom: 16px;
  }
}

.total {
  margin-top: 32px;

  .param {
    display: flex;
    justify-content: space-between;
    margin-top: 4px;
  }
}

.card {
  display: flex;
  align-items: center;
  margin-top: 32px;
  cursor: pointer;

  .brandLogo {
    display: inline-block;
    width: 24px;
    height: auto;
    margin: 0 8px 0 10px;
  }

  .brandName {
    text-transform: capitalize;
  }

  .change {
    margin-left: auto;
  }
}

.error {
  margin-bottom: 20px;
  text-align: center;
  color: colorError100;
}

.newCardWrap {
  flex: 1;
  margin-top: 32px;
}

.detailsControl {
  display: flex;
  align-items: center;
  color: colorGrey100;
  gap: 4px;
  cursor: pointer;
  user-select: none;
}

.totalParam {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
}

.details {
  border-radius: 16px;
  background: colorGrey10;
  padding: 16px 16px;
  box-sizing: border-box;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.method {
  color: colorGrey100;
  display: flex;
  align-items: center;
  gap: 4px;
}

.totalValue {
  text-align: right;
}

.policy {
  display: flex;
  color: colorGrey100;
}

.policyLink {
  color: colorPurple100;
}

.edit {
  cursor: pointer;
}

.paymentSelect {
  background: colorWhite;
  size: 100%;
  display: flex;
  flex-direction: column;
}

.backButton {
  size: 60px;
  background: colorWhite;
  position: fixed;
  left: -76px;
  top: 0;
  border-radius: 16px;
  @mixin center;
  cursor: pointer;
  z-index: 1;
}

.preloader {
  margin: 40px auto;
}

.container {
  flex: 1;
}

.resultContainer {
  flex: 1;
  align-items: center;
  justify-content: center;
  display: flex;
}

.greenIcon {
  height: 180px;
  width: 180px;
  border-radius: 90px;
  background-color: colorGrass40;
  position: relative;

  &:before {
    content: '';
    display: block;
    height: 80px;
    width: 40px;
    border-bottom: 3px solid colorApproval100;
    border-right: 3px solid colorApproval100;
    position: absolute;
    top: calc(50% - 10px);
    left: 50%;
    transform: translate3d(-50%, -50%, 0) rotate(45deg);
  }
}
