@value (colorGrey100, colorError100) from '~Styles/vars/colors.css';

.root {
  max-width: 532px;
  min-width: 532px;
  margin: 0 auto;
  padding: 8px 16px;
  box-sizing: border-box;

  .termsOfService {
    padding: 8px 16px;
  }

  .termsOfServiceText {
    color: colorGrey100;
  }

  .link {
    display: inline-block;
    border-bottom: 1px solid rgba(136, 136, 136, .6);
  }
  .checkboxContainer {
    display: flex;
    gap: 12px;
    align-items: center;
    cursor: pointer;
    padding: 4px 0px;
  }
}
