@value mobileMax from '~Styles/vars/query.css';

.root {
  display: flex;
  align-items: center;
}

.root > * {
  flex-shrink: 0;

  &:not(:last-child):not(:only-child) {
    margin-right: 12px;
  }
}

.text {
  flex: 1;
}

@media (max-width: mobileMax) {
  .root > * {
    &:not(:last-child):not(:only-child) {
      margin-right: 8px;
    }
  }
}
