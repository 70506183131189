@value colorPurple100 from '~Styles/vars/colors.css';

.blockedName {
  position: relative;
}

.blured {
  padding-left: 3px;
  filter: blur(4px);
  user-select: none;
  color: colorPurple100;
}

.lockIcon {
  top: 4px;
  position: absolute;
  color: colorPurple100;
  cursor: pointer;
  z-index: 1;
  cursor: pointer;
}
