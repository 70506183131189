@value colorError100 from '~Styles/vars/colors.css';

.content {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
  flex: 1;
}

.title {
  margin-bottom: 16px;
}

.descr {
  margin-bottom: 24px;
}

.tabs > * {
  flex: 1;
}

.input {
  height: 72px;
}

.error {
  color: colorError100;
}
