@value mobileMaxInnerWidth, newDesktopMinWidth from '~Styles/vars/query.css';
@value (
  colorGrey10,
  colorGrey100,
  colorSand60
) from '~Styles/vars/colors.css';

.root {
  background: colorGrey10;
  display: flex;
  flex-direction: column;
}

.container {
  @mixin newInnerContainer;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.title {
  padding-top: 16px;
}

.descr {
  color: colorGrey100;
}

.videos {
  display: flex;
  gap: 16px;
  margin-top: 24px;
}

.warning {
  width: 584px;
  background: colorSand60;
  border-radius: 16px;
  padding: 12px 12px 12px 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;

  .controls {
    padding-right: 0;
    flex-shrink: 0;
  }

  .control,
  .closeWraning {
    padding: 8px 18px;
  }

  .closeWraning {
    background: none;
  }
}

.warningWrap {
  display: flex;
  justify-content: flex-end;
}
