@value colorGrass40, colorError50, colorError100, colorSuccess100, colorSun100 from '~Styles/vars/colors.css';

.root {
  display: flex;
  gap: 8px;

  .answerValue {
    font-weight: 700;
  }
}

.answer {
  padding: 6px 8px;
  border-radius: 16px;
  display: flex;
  cursor: default;
}

.rightAnswer {
  background: colorGrass40;
  color: colorSuccess100;
}

.wrongAnswer {
  background: colorError50;
  color: colorError100;
  border: 1px solid transparent;

  &:hover {
    border-color: colorSun100;
  }
}

.tootlipContent {
  max-width: 258px;
  word-break: break-word;
}
