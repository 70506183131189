.list {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.content {
  flex: 1;
  overflow: auto;
}

.descr {
  margin: 16px 0;
  color: colorGrey100;
}
