.root {
  display: flex;
  align-items: flex-end;
  flex-wrap: wrap;
  gap: 8px;
  position: relative;
  margin-bottom: 32px;

  .date {
    width: 147px;
    border-radius: 8px;
  }
}

.clearParams {
  top: -40px;
  right: 0;
  position: absolute;
}

.input {
  width: 290px;
}
