@value colorGrey50 from '~Styles/vars/colors.css';

.placeholder {
  background-color: colorGrey50;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.errorIcon {
  color: white;
}