@value colorPink60, colorPurple60, colorSand40, colorBrown100, colorBase from '~Styles/vars/colors.css';

.root {
  margin: 32px 0;
}

.tag,
.reach {
  flex: 1;
}

.imageWrap {
  position: relative;
  height: 102px;
  text-align: center;

  &::before {
    content: '';
    border-radius: 16px 16px 0 0;
    background: colorPink60;
    size: 100% 84px;
    display: block;
    position: absolute;
    bottom: 0;
  }

  img {
    position: relative;
  }
}

.banners {
  display: flex;
  gap: 8px;
}

.bannerDescr {
  padding: 16px 12px;
  border-radius: 0 0 16px 16px;
  background: #fff1ff;
}

.tag {
  .imageWrap {
    &::before {
      background: colorPurple60;
    }
  }

  .bannerDescr {
    background: #edefff;
  }
}

.info {
  padding: 16px 16px 20px;
  background: colorSand40;
  border-radius: 16px;
  margin-top: 32px;
}

.infoAlert {
  color: colorBrown100;
}

.infoDescr {
  display: inline;
}

.wlToggler {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 8px;
  margin-top: 24px;
}

button.readMore {
  div {
    font-size: 12px !important;
    line-height: 16px !important;
    color: colorBase;
  }
}

.psDays {
  margin-top: 16px;
}
