@value headerHeight from '~Styles/vars/size.css';
@value newDesktopMinWidth, mobileMaxInnerWidth from '~Styles/vars/query.css';

.root {
  width: 100%;
  min-height: 100vh;
  display: block;
  box-sizing: border-box;
}

.container {
  @mixin newInnerContainer;
}

.search {
  margin: 21px 0;
}

.bar {
  padding-left: 20px;
  margin-bottom: 15px;
}

.preloader {
  margin: 40px auto;
}

.title {
  padding-top: 16px;
  padding-bottom: 16px;
}

.input {
  width: 450px;
  margin-bottom: 32px;
}

.preloaderWrap {
  width: 100%;
  margin: 40 auto;
}

.preloader {
  margin: 0 auto;
}
