@font-face {
  font-family: 'Aeroport';
  src: local('Aeroport Bold'), local('Aeroport-Bold'), url('Aeroport-Bold.woff2') format('woff2');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Aeroport';
  src: local('Aeroport Light'), local('Aeroport-Light'), url('Aeroport-Light.woff2') format('woff2');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Aeroport';
  src: local('Aeroport Medium'), local('Aeroport-Medium'), url('Aeroport-Medium.woff2') format('woff2');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Aeroport';
  src: local('Aeroport'), url('Aeroport.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'insense';
  src: url('insense.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}
