@value colorGrey50, colorWhite from '~Styles/vars/colors.css';

.root {
  width: 477px;
}

.title {
  margin-bottom: 16px;
  padding-top: 8px;
}

.input {
  height: 72px;
  box-sizing: border-box;
}

.nameWrap {
  display: flex;
  gap: 16px;
  margin-bottom: 16px;
}

.name {
  flex: 1;
}

.dropdown {
  height: 72px;
  max-height: 72px;
  border-radius: 16px;
  border-color: colorGrey50;
  margin-bottom: 16px;
  display: flex;
}

.dropdownGroup {
  width: 100%;
}

.rolesList {
  overflow: auto;
  height: 210px;
}
