@value colorGrey100 from '~Styles/vars/colors.css';

.field {
  flex: 1;

  &:not(:last-child) {
    margin-right: 20px;
  }
}

.subtitle {
  color: colorGrey100;
}
