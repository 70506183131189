@value colorGrey50, colorGrey100, colorError50, colorError100 from '~Styles/vars/colors.css';

.drawer {
  background-color: white;
}

.drawerContainer {
  display: flex;
  height: 100%;
  width: 516px;
  flex-direction: column;
  justify-content: space-between;

  .errorText {
    color: colorError100;
    padding: 8px 0;
  }

  .brandData {
    margin-bottom: 32px;
  }

  .header {
    margin-bottom: 16px;
  }

  .logoContainer {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
  }

  .logoImgContainer {
    height: 148px;
    width: 148px;
    min-width: 148px;
    border-radius: 16px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid colorGrey50;
    margin-bottom: 8px;
    overflow: hidden;
    cursor: pointer;

    &.error {
      border-color: colorError100;
    }

    .logoImg {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      z-index: 2;
      background-size: cover;
      background-position: center;
    }

    .addLogoIcon {
      position: absolute;
      top: 8px;
      right: 8px;
      z-index: 3;
    }

    .editLogoIcon {
      position: absolute;
      top: 8px;
      right: 8px;
      z-index: 3;
      border-radius: 8px;
      background-color: white;
    }

    .addLogoText {
      position: absolute;
      bottom: 8px;
      left: 8px;
      z-index: 3;
      color: colorGrey100;
    }
  }

  .logoDescription {
    margin-left: 16px;
    color: colorGrey100;
  }

  .form {
    display: flex;
    flex-direction: column;
    gap: 16px;

    .formInput {
      height: 72px;
    }

    .fileUpload {
      display: none;
    }
  }

  .dropdown {
    border: 1px solid colorGrey50;
    border-radius: 16px;
    padding-top: 11px;
    padding-bottom: 11px;
    max-height: initial;

    &.error {
      background-color: colorError50;
      border-color: colorError100;
    }
  }

  button {
    width: 100%;
  }

  .dropdownGroup {
    width: 100%;
    height: 200px;
    overflow: hidden;
    overflow-y: scroll;
  }
}

.summary {
  min-height: 72px;
}
