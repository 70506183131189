.root {
  position: relative;
  z-index: 2;

  .username {
    font-size: 24px;
    line-height: 32px;
  }
}

.userWrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

.user {
  display: flex;
  align-items: center;
}

.avaWrap {
  position: relative;
  flex-shrink: 0;
}

.ava {
  width: 100%;
}

.names {
  margin-left: 20px;
}

.namesContent {
  display: flex;
  gap: 8px;
}

.title {
  display: flex;
  align-items: center;
}

.icon {
  margin-left: 12px;
}

.stats {
  margin-top: 20px;
  display: flex;
  align-items: center;
}

.score {
  display: flex;
  align-items: center;
}

.scoreTitle {
  margin-right: 8px;
  font-size: 24px;
  line-height: 32px;
}

.scoreDisclamer {
  margin-bottom: 4px;
}

.additionInfo {
  display: flex;
}

.badgeTitle {
  padding: 0;
}

:global(.show) {
  &.tooltip {
    opacity: 1 !important;
  }
}

.platform {
  size: 24px;
  background: rgba(256, 256, 256, 0.9);
  border-radius: 50%;
  @mixin center;
  position: absolute;
  bottom: 0;
  right: 0;
}

.platformItem {
  height: 16px;

  svg {
    size: 16px;
  }
}

.customListSelection {
  margin-left: 16px;
}

.signs {
  display: flex;
  gap: 8px;
  align-items: center;
}

.tiktokLink {
  display: flex;
  gap: 4px;
}

.links {
  display: flex;
  gap: 8px;
}
