@value colorBase, colorRed from '~Styles/vars/colors.css';

.root {
  width: 100%;
  position: relative;
}

.header {
  width: 100%;
  display: flex;
  @mixin vertical-center;
  justify-content: space-between;
  margin-bottom: 8px;
}

.titleWrap {
  @mixin vertical-center;
}

.title {
  margin-right: 4px;
}

.required {
  .titleWrap::before {
    content: '';
    display: block;
    size: 4px;
    border-radius: 50%;
    background-color: colorBase;
    margin-right: 8px;
  }

  &.error {
    .titleWrap::before {
      background-color: colorRed;
    }
  }
}

.error {
  .header {
    opacity: 1;
  }
}

.errorText {
  margin-top: 8px;
}