@value colorGrey100, colorPurple100, colorGrey10 from '~Styles/vars/colors.css';

.titleContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 148px;
  flex: 1;
}

.root {
  background-color: white;
  padding: 8px 0 16px;
  display: flex;
  width: 100%;
  box-sizing: border-box;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  border-bottom: 2px solid colorGrey10;
}

.counter {
  color: colorGrey100;
}

.purple {
  color: colorPurple100;
}
