@value colorGrey10, colorGrey50, colorGrey100 from '~Styles/vars/colors.css';

.root {
  position: relative;

  ul.list {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(auto-fit, minmax(0, max-content));

    & > li {
      height: auto;
      padding: 8px 16px;

      .productVariantCard {
        background-color: colorGrey10;
        border-radius: 16px;
        padding: 8px 0;

        .productVariant {
          display: grid;
          grid-template-rows: 1fr;
          grid-template-columns: 44px 112px 112px 80px 40px;
          grid-gap: 16px;
          padding: 8px 16px;
          align-items: center;
          width: 100%;
          box-sizing: border-box;
          height: 60px;
          overflow: hidden;

          .productVariantImg {
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: white;
            height: 44px;
            width: 44px;

            img {
              display: block;
              max-width: 100%;
              max-height: 100%;
            }

            .noImageIcon {
              color: colorGrey50;
              display: block;
            }

            img + .noImageIcon {
              display: none;
            }
          }

          .productVariantTitle {
            overflow: hidden;
            max-height: 100%;
          }

          .productVariantQuantity {
            text-align: center;
            width: 40px;
          }

          .productVariantPrice {
            color: colorGrey100;
          }
        }
      }
    }
  }
}
