@value colorGrayDog, colorError100, colorGreen, colorPink40, colorPink100 from '~Styles/vars/colors.css';

div.root {
  width: 620px;

  .ratingWrapper {
    margin: 40px 0;

    .textualRating {
      display: flex;
      gap: 12px;
      margin: 0 50px;
      &.hidden {
        display: none;
      }

      .textualRatingButton {
        height: 32px;
      }
    }

    .starMeaning {
      display: flex;
      justify-content: space-between;
      flex-direction: row;
      width: 100%;
      min-height: 50px;
      align-items: center;

      .starMeaningText {
        color: colorGrayDog;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
      }
    }
  }
}

.title {
  margin-bottom: 20px;
}

.item {
  cursor: pointer;
  margin: 0 6px;
  width: 75px;

  .icon {
    size: 60px;
    margin: 0 auto;
  }

  svg {
    size: 60px;
  }
}

.container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.content {
  flex: 1;
}

.error {
  color: colorError100;
  margin-bottom: 16px;
}

.recommendation {
  color: colorGreen;
}

.wouldCreatorRecommended {
  margin-bottom: 16px;
  text-align: center;
}

.carechteristics {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-top: 12px;
  margin-bottom: 24px;
  gap: 8px;
}

.carechteristicItem {
  margin-bottom: 8px;
}

.button {
  margin-bottom: 20px;
}

.drawer {
  @mixin drawer;
}

.drawerContainer {
  @mixin drawerContainer;
}

.textualRating {
  display: flex;
  justify-content: flex-start;
  margin-top: 8px;
  margin-bottom: 8px;
  gap: 8px;
}

.comment {
  margin-top: 16px;
  max-height: 96px;
}

.submit {
  width: 100%;
}

.ratingWrapper {
  margin-top: 16px;
  margin-bottom: 16px;
}

.subtitle {
  margin-bottom: 16px;
}

.result {
  size: 180px;
  background: colorPink40;
  border-radius: 50%;
  @mixin center;
}

.resultIcon {
  color: colorPink100;
}

.resultContent {
  flex: 1;
  @mixin center;
}
