.mainButton {
  margin-top: 16px;
  width: calc(100% - 32px);
  display: flex;
  align-items: center;
  justify-content: center;
}

.root {
  background-color: white;

  .container {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .textContainer {
      display: flex;
      flex-direction: column;
      gap: 16px;
    }

    .imageContainer {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;

      .image {
        height: 202px;
      }
    }
  }
}
