@value colorConcrete from '~Styles/vars/colors.css';

.header {
  display: flex;
  align-items: center;
}

.characteristics {
  display: flex;
  flex-wrap: wrap;
  margin-top: 8px;
}

.characteristicItem {
  padding: 4px 8px;
  background: colorConcrete;
  border-radius: 4px;
  margin-right: 8px;
  margin-bottom: 8px;
}

.date {
  margin-left: 8px;
}