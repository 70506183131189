@value  colorPink60 from '~Styles/vars/colors.css';
.tooltipClose {
  width: 24px;
  height: 24px;
  position: absolute;
  top: 16px;
  right: 16px;
  cursor: pointer;
}

.link {
  cursor: pointer;
  color: colorPink60;
}

.onboardingTooltipContainer {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
