@value colorConcrete, colorError100, colorWhite from '~Styles/vars/colors.css';

.root {
  position: relative;
  padding: 20px;
}

.info {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.names {
  margin-left: 12px;
  flex-grow: 1;
  overflow: hidden;
}

.usernameWrap {
  display: inline-block;
  overflow: hidden;
}

.marks {
  display: flex;
}

.mark {
  flex: 1;
  text-align: center;
}

.mark :not(:last-child) {
  border-right: 1px solid colorConcrete;
}

.stat {
  width: 100%;
  margin-top: 24px;
}

.storeFront {
  margin-bottom: 20px;
}

.images {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.image {
  size: 64px;
  border-radius: 4px;
  overflow: hidden;
  margin-bottom: 8px;
}

.image:not(:last-child) {
  margin-right: 4px;
}

.avaWrap {
  position: relative;
}

.archive {
  margin-top: 20px;
}

.blockedCreator {
  color: colorError100 !important;
}

.shortlistedButton {
  padding: 0;
}

.controls {
  display: flex;
  align-items: center;
  gap: 16px;
}

.blocklistDrawerContainer {
  padding: 0;
}

.blocklistDrawer {
  background-color: colorWhite;
  border-radius: 24px;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: calc(100% - 32px);
  padding: 16px;
  gap: 16px;
}

.blocklistControls {
  margin-bottom: 0;
  margin-top: auto;
  display: flex;
  gap: 8px;
  width: 100%;
}
