@value colorCreamCan from '~Styles/vars/colors.css';

.link {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 14px;
  line-height: 24px;
  color: colorCreamCan;
  margin-left: 4px;
  cursor: pointer;

  &::before {
    content: '';
    height: 24px;
    width: 24px;
    background-image: url('../../../files/images/icons/amazon.png');
    background-size: 16px;
    background-position: center;
    background-repeat: no-repeat;
    margin-right: 4px;
  }
}
