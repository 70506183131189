.labelValue {
  width: 100%;
  max-width: calc(100% - 32px);
  display: flex;
  align-items: center;
  justify-content: space-between;
  .labelValueText {
    max-width: calc(100% - 22px);
  }
  .label {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
