.item {
  width: 180px;
  margin-bottom: 20px;

  &:not(:nth-child(3n)) {
    margin-right: 40px;
  }
}

.section {
  flex-wrap: wrap;
  display: flex;
  margin: 40px 0;

  &:not(:last-child) {
    margin-bottom: 40px;
  }
}