@value colorGrey10 from '~Styles/vars/colors.css';

.campaign {
  margin: 8px 0;
  padding: 8px 16px 8px 0;
  border-radius: 16px;
  background: colorGrey10;
  display: block;
}

.campaignHeader {
  align-items: center;
  display: flex;
  flex: 1;

  .headerText {
    margin-left: 8px;
  }
}
