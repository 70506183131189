@value colorGrey10, colorGrey50, colorGrey100, colorDark100, colorGrass40, colorApproval100 from '~Styles/vars/colors.css';

.drawerContainer {
  .drawerBackdrop {
    z-index: 1100;
  }

  .drawerWrapper {
    z-index: 1101;
    background-color: white;

    .root {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .formContainer {
        display: flex;
        flex-direction: column;
        flex: 1;
        .title {
          margin-bottom: 24px;
        }
        .subtitle {
          margin-bottom: 16px;
        }
      }


      .bottomContainer {
        width: 100%;
        display: flex;
        flex-direction: column;

        .buttonContainer {
          width: 100%;
          display: flex;
          flex-direction: row;
          text-align: center;
          gap: 16px;
          margin-top: 16px;
          .buttonText {
            width: 100%;
            text-align: center;
          }
        }
      }
      
    }
  }
}