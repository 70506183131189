@value colorGrey10 from '~Styles/vars/colors.css';
@value newDesktopMinWidth from '~Styles/vars/query.css';

.root {
  background: colorGrey10;
}

.container {
  @mixin newInnerContainer;
}
