@value colorConcrete, colorGrey100, colorGrey50 from '~Styles/vars/colors.css';

.brandSelector {
  width: 186px;
  border-radius: 8px;

  .label {
    color: colorGrey100;
    text-wrap: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .group {
    width: 100%;
  }
}

.brandDropdownList {
  max-height: 50vh;
  overflow-y: auto;
}

.clearBtn {
  width: 100%;
  padding: 12px 20px 20px;
  border-bottom: 1px solid colorConcrete;
}

.dirty {
  border: 1px solid colorGrey50;

  .label {
    color: unset;
  }
}
