.root {
  position: relative;
  margin-bottom: 32px;
}

.input {
  width: 500px;
}

.clearParams {
  top: -40px;
  right: 0;
  position: absolute;
}

.params {
  display: flex;
  gap: 8px;
  margin-top: 8px;
}
