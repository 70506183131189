@value colorWhite from '~Styles/vars/colors.css';

.filter {
  background: colorWhite;
}

.container {
  width: max-content;
  max-width: 426px;
}
