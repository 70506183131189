.root {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
}

.item {
  flex: 1;
  width: 100%;

  .customListDropdownGroup {
    right: unset;
    left: -4px;
  }
}

.preloader {
  width: 100%;
  flex: none;
  display: flex;
  justify-content: center;
}

.noResult {
  margin-left: 10px;
}

.grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 20px;

  &.smallGrid {
    grid-template-columns: repeat(auto-fit, minmax(250px, calc(25% - 23px)));
  }
}