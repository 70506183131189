@value colorOcean100 from '~Styles/vars/colors.css';

.root {
  display: flex;
  flex-direction: column;
  flex: 1;
  margin: 8px 0;
  box-sizing: border-box;

  .list {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
  .actions {
    padding: 16px 0 0 0;
    display: flex;
    flex-direction: column;

    .saveButton {
      margin-top: 16px;
    }
  }
  .firstStepText {
    color: colorOcean100;
    margin-top: 16px;
  }
}