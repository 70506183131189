@value colorGrey50 from '~Styles/vars/colors.css';

.root {
  display: inline-flex;
  flex-direction: column;
  gap: 8px;
  flex-wrap: wrap;
  max-width: 457px;
  border: 1px solid colorGrey50;
  padding: 8px 12px;
  border-radius: 16px;
  margin-bottom: 12px;
}

.medias {
  display: inline-flex;
  flex-wrap: wrap;
  gap: 8px;
}

.footer {
  &:not(:empty) {
    margin: 8px 0;
  }
}

.carousel {
  size: 100vw 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.9);
  z-index: 9999;
  @mixin center;
}

.carouselCloseBtn {
  position: absolute;
  height: 60px;
  width: 60px;
  color: white;
  top: 26px;
  right: 42px;
  @mixin center;
  cursor: pointer;
}
