.dates {
  margin-top: 20px;
  display: flex;
}

.date {
  flex-grow: 1;

  &:not(:last-child) {
    margin-right: 20px;
  }
}
