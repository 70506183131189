@value colorWhite from '~Styles/vars/colors.css';

.container {
  padding: 8px 16px;
  box-sizing: border-box;
  background: colorWhite;
  border-radius: 16px;
}

.container::after {
  content: '';
  width: 380px;
}
