@value colorWhite, colorGrey100 from '~Styles/vars/colors.css';

.root {
  position: relative;
  width: 100%;
  background: colorWhite;
  padding: 13px 0;
  box-sizing: border-box;
  border: 1px solid transparent;
  display: flex;
  flex-direction: column;
  gap: 26px;

  &:not(:last-child) {
    border-bottom: 1px solid #e7e5e4;
  }
}

.row {
  position: relative;
  display: flex;
  gap: 16px;
  align-items: center;
}

.counters {
  display: flex;
  align-items: center;
  gap: 32px;
  justify-content: flex-end;
  margin-left: 52px;
}

.additional {
  display: flex;
  gap: 16px;
}

.grey {
  color: colorGrey100;
}

.subtitle {
  color: colorGrey100;
}

.campaign {
  display: flex;
  gap: 16px;
  align-items: center;

  .platform {
    bottom: -8px;
    right: -8px;
  }
}

.spacer {
  flex: 1;
}

.headerText {
  width: 332px;
}

.tags {
  display: flex;
  flex-direction: column;
  gap: 4px;
  align-items: flex-start;
  width: 199px;

  .tag {
    padding: 4px 8px;
  }
}

.hidden {
  display: none;
}

.arrow {
  margin-left: -20px;
}

.closedCount {
  width: 167px;
}
