@value (colorGrey50, colorDark100, colorGrey10, colorPurple60, colorPink100, colorWhite) from '~Styles/vars/colors.css';

@value headerHeight from '~Styles/vars/size.css';

@keyframes showTooltip {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.root {
  background-color: white;
  width: 64px;
  height: 100%;
  box-sizing: border-box;
  z-index: 2;
  transition: 0.3s width;

  .nav {
    border-right: 1px solid colorGrey50;
    background-color: white;
    position: fixed;
    left: 0;
    top: headerHeight;
    width: 63px;
    height: calc(100vh - headerHeight);
    transition: 0.3s width;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    ul.list {
      box-sizing: border-box;
      padding: 16px 8px;
      width: 100%;

      li {
        box-sizing: border-box;
        position: relative;
        width: 100%;

        .navLink {
          width: 100%;
        }

        .tooltip {
          position: absolute;
          left: calc(100% + 5px);
          top: 50%;
          transform: translateY(-50%);
          opacity: 0;
          pointer-events: none;
        }

        .tooltipText {
          color: white;
          text-wrap: nowrap;
        }

        &:hover .tooltip {
          animation: showTooltip 0.5s;
          animation-fill-mode: forwards;
          animation-delay: 1s;
        }
      }
    }

    .button {
      box-sizing: border-box;
      height: 48px;
      width: 48px;
      padding: 12px;
      align-items: center;
      justify-content: flex-start;
      display: flex;
      margin: 0;
      overflow: hidden;

      &.active {
        border-color: colorDark100;
      }

      .buttonText {
        opacity: 0;
        transition: 0.3s opacity;
        text-wrap: nowrap;
      }
    }
  }
}

.chat {
  position: relative;
}

.unreadCount {
  top: -4px;
  right: -4px;
  position: absolute;
  min-width: 16px;
  height: 16px;
}

@media (min-width: 1460px) {
  .root {
    width: 206px;

    .nav {
      width: 205px;

      .button {
        justify-content: flex-start;
        width: 100%;

        .buttonText {
          opacity: 1;
        }
      }
    }

    ul.list li .tooltip {
      display: none;
    }
  }

  .startHere {
    .startHereLabel {
      width: 24px;
    }

    .startHereText {
      display: block;
    }
  }
}

.beta {
  position: absolute;
  bottom: -12px;
  left: 6px;
  color: colorWhite !important;
}

.tourWrap {
  size: 0;
}

.guideTooltip {
  position: absolute;
  top: -50%;
  left: calc(100% + 20px);
  z-index: 1;
  width: 278px;
  box-sizing: border-box;
  min-width: unset !important;
}

.info {
  margin-bottom: 100px;
  padding-left: 8px;
  padding-right: 8px;

  .infoBtn {
    size: 48px;
    padding: 12px;
    box-sizing: border-box;
  }
}

.startHere {
  border-radius: 8px;
  padding: 10px;
  border: 2px solid colorWhite;
  position: relative;
  display: flex;
  gap: 8px;
  align-items: center;

  .mark {
    border-radius: 50%;
    background: colorPink100;
    border: 2px solid colorDark100;
    position: absolute;
    display: none;
    size: 8px;
    box-sizing: border-box;
    top: -3px;
    right: -3px;
  }

  &:hover {
    padding: 11px;
    background: colorGrey10;
    border: 1px solid colorGrey50;

    .mark {
      display: block;
    }
    .startHereLabel {
      border-color: colorPurple60;
    }
  }

  &.active {
    padding: 11px;
    border: 1px solid colorDark100;
    background: colorGrey10;
    .mark {
      display: block;
    }
    .startHereLabel {
      border-color: colorPurple60;
    }
  }
}

.startHereLabel {
  line-height: 9px !important;
  font-weight: 500 !important;
  text-align: center;
  border-top: 1px solid;
  border-bottom: 1px solid;
  padding-top: 2px;
  padding-bottom: 2px;
  transition: border 0.1s ease-in;
}

.startHereText {
  display: none;
}
