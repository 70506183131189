@value desktopMinWidth from '~Styles/vars/query.css';
@value colorGrey10 from '~Styles/vars/colors.css';

.root {
  padding-top: 8px;
  background: colorGrey10;
}

.wrapper {
  width: desktopMinWidth;
  margin: 0 auto;
}

.header {
  display: flex;
  justify-content: flex-end;
}

.container {
  padding: 16px 0 20px;
  box-sizing: border-box;
}

.title {
  margin-bottom: 20px;
}
