@value headerHeight from '~Styles/vars/size.css';
@value colorWhite, colorGrayDog from '~Styles/vars/colors.css';
@value mobileMax, desktopMinWidth from '~Styles/vars/query.css';

.root {
  width: desktopMinWidth;
  display: block;
  margin: 0 auto;
  box-sizing: border-box;
  padding-top: headerHeight;
  text-align: left;
}

.content {
  width: desktopMinWidth;
  margin: 40px auto;
}

.title {
  margin-bottom: 40px;
}

.warning {
  width: 180px;
  margin-top: 20px;
}

.ava {
  position: relative;
  display: inline-block;
}

.avaWrap {
  margin-right: 80px;
}

.icon {
  size: 48px;
  position: absolute;
  bottom: 0;
  right: 0;
  border-radius: 50%;
  background: colorWhite;
  @mixin center;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.05), 0 1px 4px rgba(0, 0, 0, 0.1);
}

.container {
  display: flex;
  justify-content: center;
}

.field {
  flex: 1;

  &:not(:last-child) {
    margin-right: 20px;
  }
}

.form {
  width: 580px;
}

.barter {
  align-items: flex-start;
  display: flex;
  margin-bottom: 32px;
}

.barterDesrc {
  margin-left: 12px;
}

.row {
  display: flex;
  margin-bottom: 32px;
}

.done {
  margin-top: 40px;
  margin-bottom: 40px;
}

.stub {
  size: 100% calc(headerHeight + 2px);
  position: fixed;
  background: colorWhite;
  top: 0;
  z-index: 90;
}

.badgeWrap {
  display: block;
}

.badge {
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.05), 0 1px 4px rgba(0, 0, 0, 0.1);
  border-radius: 99px;
  background: colorWhite;
  padding: 12px 20px;
  margin-top: 20px;
  display: flex;
  @mixin center;
  cursor: pointer;

  &:hover {
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.05), 0 8px 20px rgba(0, 0, 0, 0.1);
  }
}

.badgeIcon {
  margin-right: 12px;
}

.option {
  .optionName {
    font-size: 14px;
    line-height: 14px;
  }

  .optionDescr {
    margin-top: 3.5px;
    font-size: 12px;
    line-height: 16px;
    white-space: break-spaces;
    color: colorGrayDog;
  }
}

.categories {
  display: flex;
}

.categoriesRequest {
  margin-top: 8px;
  margin-bottom: 20px;
  display: flex;
}

:global .isMobile {
  :local .root {
    width: 100%;

    .content {
      width: 100%;
      padding: 0 16px;
      box-sizing: border-box;
    }

    .row {
      display: block;
    }

    .field {
      width: 100%;

      &:not(:last-child) {
        margin-right: 0;
        margin-bottom: 32px;
      }
    }

    .avaWrap {
      display: none;
    }
  }
}

.fieldItem {
  margin-bottom: 32px;
}
