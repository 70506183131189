.root {
  display: flex;
  gap: 16px;
}

.applicants,
.messages,
.review {
  width: 167px;
  display: flex;
}

.button {
  padding-left: 8px;
  padding-right: 8px;
  margin-left: -8px;
}
