.checkbox {
  size: 24px;
  padding: 4px;
  @mixin center;
  background: url('~files/images/icons/checkbox-off.svg') 0 0 no-repeat;
  background-position: center;
}

.isActive {
  .checkbox {
    background: url('~files/images/icons/checkbox-on.svg') 0 0 no-repeat;
    background-position: center;
  }
}

.disabled {
  opacity: 0.5;
}
