@value colorGrey100 from '~Styles/vars/colors.css';

.root {
  flex: 1;
  width: calc(100% - 52px);
}
.group {
  min-width: max-content;
  min-width: 290px;
  width: 290px;
}

.dropdownList {
  max-height: 340px;
  overflow: auto;
}
.valueText {
  width: max-content;
  max-width: calc(100% - 32px);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 4px;
}
.selectorLabel {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.item {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  padding-right: 16px;
  box-sizing: border-box;
}
.filterContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  padding: 0 16px;
}

.additionalGroupTitle {
  color: colorGrey100;
  padding: 8px 16px;
}
