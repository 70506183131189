@value colorWhite, colorGrey100, colorPink100 from '~Styles/vars/colors.css';
@value headerHeight from '~Styles/vars/size.css';

.container {
  padding: 8px 16px;
  box-sizing: border-box;
  background: colorWhite;
  border-radius: 16px;
}

.bars {
  display: flex;
  padding-left: 56px;
  padding-top: 8px;
  padding-bottom: 8px;
  gap: 16px;
  border-bottom: 1px solid #e7e5e4;
  z-index: 1;
  position: sticky;
  background: colorWhite;
  top: headerHeight;
}

.bar {
  color: colorGrey100;
  display: flex;
  gap: 8px;
  align-items: center;
}

.mark {
  size: 8px;
  border-radius: 50%;
  background: colorPink100;
}
