.error {
  margin-top: 4px;
  color: colorError100;
}

.ttShop {
  display: flex;
  gap: 4px;
  align-items: center;
}
