@value colorWhite, colorGrey100, colorGrey50 from '~Styles/vars/colors.css';

@keyframes loading {
  to {
    transform: translateX(100%);
  }
}

.content {
  padding: 16px 16px 0 16px;
}

.root {
  background-color: colorWhite;
  border-radius: 16px;
  padding-bottom: 16px;
}

.infoDescription {
  display: flex;
  gap: 4px;
  align-items: flex-start;
}

.description {
  color: colorGrey100;
}

.infoContainer {
  padding: 8px 0 8px 0;
}

.headerContainer {
  width: 100%;
  display: flex;
  gap: 32px;
  row-gap: 16px;
  flex-wrap: wrap;
}

.tooltipIcon {
  color: colorGrey100;
}

.headerLoading {
  display: flex;
  gap: 32px;
  justify-content: flex-start;
  margin-bottom: 30px;
  flex-wrap: wrap;
  margin-top: 13px;
}

.titleBase {
  display: flex;
  gap: 8px;
  flex-direction: column;
}

.titlePlaceholder {
  height: 14px;
  width: 114px;
  border-radius: 16px;
  @mixin skeletonLoading;
}

.descriptionPlaceholder {
  height: 8px;
  width: 80px;
  border-radius: 16px;
  @mixin skeletonLoading;
}

.loadingStub {
  margin-bottom: 16px;
}

.graphContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: calc(100% - 8px);
  min-height: 199px;
  padding: 19px 0 0px;
  box-sizing: border-box;
  margin: 0 auto 13px;
}

.graphContainerStub {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: calc(100% - 30px);
  min-height: 199px;
  padding: 19px 0 0px;
  box-sizing: border-box;
  margin: 0 auto 13px;
}

.lineContainer {
  display: flex;
  width: 100%;
  gap: 2px;
  align-items: center;
  .yAxisLabel {
    height: 8px;
    width: 30px;
    border-radius: 16px;
    @mixin skeletonLoading;
  }
}
.line {
  background-color: colorGrey50;
  width: 100%;
  height: 1px;
}

.footer {
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 100%;
}

.xLabelContainer {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.xAxisLabel {
  height: 8px;
  width: 42px;
  border-radius: 16px;
  @mixin skeletonLoading;
}

:global .recharts-tooltip-label {
  padding-bottom: 4px;
}

:global .recharts-tooltip-item {
  font-size: 12px;
  font-family: 'Aeroport';
  color: #ffffff;
}

:global .recharts-tooltip-item {
  &:nth-child(1) {
    color: #feaafe !important;
  }

  &:nth-child(2) {
    color: #77f7ff !important;
  }

  &:nth-child(3) {
    color: #b9baff !important;
  }
  &:nth-child(4) {
    color: #ff9c5e !important;
  }
  &:nth-child(5) {
    color: #70ef9a !important;
  }
}
