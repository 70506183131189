@value colorBase, colorConcrete from '~Styles/vars/colors.css';

.otherParams {
  height: 66px;
  border-radius: 8px;
  width: 129px;
}

.geoDropdown {
  align-items: flex-start;
}

.genderDropdown {
  width: 440px;
  flex-wrap: wrap;
}

.ageDropDown {
  flex-wrap: wrap;
  width: 460px;
  justify-content: flex-end;
}

.primaryCategoryDropDown {
  flex-direction: column;
  align-items: stretch;
  width: 280px;
}

.amazonFilter,
.favoritesFilter {
  background-color: colorConcrete;
  height: 66px;

  span {
    white-space: break-spaces;
    text-align: center;
  }

  &.activeDropdownButton {
    color: colorBase;
    border: 1px solid colorBase;
    box-shadow: 0 0 0 1px colorBase;
    background-color: rgba(78, 66, 228, 0.1);

    &:hover {
      background-color: colorConcrete;

      &::before {
        opacity: 0;
      }
    }
  }

  &.open {
    border: 1px solid colorBase;
    box-shadow: 0 0 0 1px colorBase;
    background-color: colorConcrete;
  }
}

.amazonMenu,
.favoritesMenu {
  span {
    white-space: pre;
  }
}

.newFilterContainer {
  position: relative;
  z-index: 1;
}

.oldFilterContainer {
  position: relative;
  z-index: 0;
}
