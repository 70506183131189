@value colorConcrete from '~Styles/vars/colors.css';

.root {
  width: 100%;
  padding: 40px 0;
  box-sizing: border-box;
  border-bottom: 1px solid colorConcrete;
}

.container {
  margin-top: 40px;
}

.dateWrap {
  display: flex;
}

.date {
  margin-left: 4px;
}

.user {
  text-align: right;
}

.row {
  display: flex;
  justify-content: space-between;
}

.detail {
  margin-top: 32px;
}