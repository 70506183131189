@value (
  colorWhite,
  colorRed,
  colorBase,
  colorGrayDog,
  colorGrey50,
  colorPink100,
  colorPink40,
  colorGrey100,
  colorDark100,
  colorDark70,
  colorSun100,
  colorSun60,
  colorSun40,
  colorOcean100,
  sky100,
  sun60
) from '~Styles/vars/colors.css';
@value (borderRadius20) from '~Styles/vars/query.css';

.root {
  color: colorDark100;
  position: relative;
  padding: 32px 16px 64px;
  background: colorWhite;
  border-radius: borderRadius20;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr max-content max-content;
  grid-gap: 16px;
  transition: background-color 0.1s ease-in-out, color 0.1s ease-in-out;

  &.darkColor {
    background-color: colorDark100;
    color: white;
  }

  &.oceanColor {
    background-color: colorOcean100;
    color: white;
  }

  .iceIcon {
    margin-right: 0 !important;

    svg {
      height: 24px;
      width: 24px;
    }

    &.dark {
      path {
        fill: colorDark100;
      }
    }

    &.light {
      path {
        fill: white;
      }
    }
  }

  .subscriptionPaymentWrap {
    .subscriptionPaymentText {
      &::before {
        content: '*';
        display: inline-block;
        margin-right: 1px;
      }
    }
  }

  &.skyColor {
    .subscriptionPaymentWrap {
      .subscriptionPaymentText {
        &::before {
          color: sky100;
        }
      }
    }
  }
  &.pinkColor {
    .subscriptionPaymentWrap {
      .subscriptionPaymentText {
        &::before {
          color: colorPink100;
        }
      }
    }
  }

  .paymentInfoContainer {
    min-height: 106px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    gap: 8px;
  }

  .referButtonWrapper {
    position: absolute;
    bottom: 0;
    width: 100%;
    left: 0;
    .referButtonBlock {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      gap: 4px;
      padding: 16px;
      background-color: colorPink40;
      border-bottom-left-radius: 16px;
      border-bottom-right-radius: 16px;

      .learnMoreButton {
        text-decoration: underline;
      }
    }
  }

  &:hover {
    background-color: colorDark70;
    color: white;

    .addonsCounter {
      .addonButton {
        border-color: white;

        div {
          background-color: white;
          &::after {
            background-color: white;
          }
        }
      }
    }

    .button {
      background-color: colorPink100;
      border: 0;
      &.disabledButton {
        color: white;

        &:hover {
          color: colorDark70;
        }
      }
    }

    .referButtonWrapper {
      .referButtonBlock {
        color: colorDark100;
      }
    }
  }
}

.nameWrap {
  margin-bottom: 16px;
}

.title {
  color: colorGrey100;
  font-size: 28px;
  font-weight: 500;
  line-height: 32px;
  transition: color 0.1s ease-in-out;

  &.skyColor {
    color: sky100;
  }

  &.pinkColor {
    color: colorPink100;
  }

  &.whiteColor {
    color: white;
  }

  &.sunColor {
    color: colorSun100;
  }

  &.whiteColor {
    color: white;
  }
}

.subtitle {
  color: colorGrey100;
  font-size: 15px;
  font-weight: 400;
  line-height: 24px;
  transition: color 0.1s ease-in-out;

  &.sunColor {
    color: sun60;
  }
}

.descriptionWrapper {
  display: block;
  min-height: 48px;
  margin-bottom: 16px;
  width: 100%;
}

.description {
  white-space: initial;
  min-height: 96px;
}

.price {
  display: grid;
  grid-template-columns: 1fr;

  .priceContainer {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    flex-wrap: wrap;

    .trialUpgrade {
      width: 100%;
      color: colorGrey100;
    }
  }

  .priceColumnWrapper {
    display: flex;
    flex-direction: column;
  }
}

.priceText {
  transition: color 0.1s ease-in-out;

  &.default {
    color: colorGrey100;
    font-size: 40px;
    line-height: 44px;
  }

  &.sky {
    color: sky100;
    font-size: 40px;
    line-height: 44px;
  }

  &.pink {
    color: colorPink100;
    font-size: 40px;
    line-height: 44px;
  }

  &.white {
    color: white;
  }

  &.sun {
    color: colorSun100;
  }

  &.sun {
    color: colorSun60;
  }
}

.summaryPrice {
  &.hidden {
    visibility: hidden;
  }
}

.periodText {
  font-size: 17px;
  line-height: 24px;
  color: colorGrey100;
}

.summaryPrice {
  color: colorGrey100;
  font-size: 17px;
  line-height: 24px;
  font-weight: 400;
}

.originalPrice {
  display: flex;
  align-items: baseline;
  margin-bottom: 4px;
  text-decoration: line-through;
}

.oldPrice {
  margin-right: 8px;
}

.benefits {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 8px;
}

.benefit {
  display: grid;
  grid-template-columns: 25px 1fr;
  grid-gap: 8px;
}

.button {
  border-radius: 8px !important;
  height: 48px !important;

  &.disabledButton {
    /* background-color: unset;
    color: inherit !important; */
    border: 0;
  }

  &.disabledButtonDark {
    background-color: unset;
    color: inherit !important;
    border: 0;
  }

  &.withBorder {
    border: 1px solid colorGrey100;
  }
}

.submit {
  margin-top: 40px;
}

.comparison {
  margin: 20px auto 0;
}

.popular {
  background-color: colorPink100;
  color: white;
  font-size: 15px;
  line-height: 24px;
  white-space: nowrap;
  transition: background-color 0.1s ease-in-out, color 0.1s ease-in-out;

  &.colorSun {
    background-color: colorSun40;
    color: colorSun100;
  }
}

.try {
  background: linear-gradient(135deg, #21d4fd 0%, #b721ff 100%);
}

.disclaimer {
  height: 16px;
  margin-bottom: 32px;
  margin-top: 8px;
}

.creatorCostWrapper {
}

.switchWrapper {
  min-height: 16px;

  .intervalSwitchContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    margin: 16px 0;

    .activeItem {
      font-weight: 500;
    }
  }
}
