@value colorGrey50, colorConcrete, colorWhite from '~Styles/vars/colors.css';

.root {
  width: 100%;
}

.item {
  display: flex;
  align-items: center;
  margin-right: 20px;
}

.wrapper {
  display: flex;
}

.wrapperWithPadding {
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  padding: 0 25px;
}

.userWrap {
  padding: 12px 20px;
  display: flex;
}

.names {
  margin-left: 12px;
}

.ratingWrap {
  width: 100%;
  box-sizing: border-box;
  padding: 12px 20px;
  display: flex;
  cursor: pointer;

  &:hover {
    background: colorConcrete;
  }
}

.rating {
  display: flex;
  margin-left: 12px;
  padding: 4px 8px;
  border: 1px solid colorGrey50;
  border-radius: 99px;
  background: colorWhite;
}

.ratingIcon {
  margin-right: 4px;
}

.userContent {
  padding-bottom: 4px;
  border-bottom: 1px solid colorGrey50;
}

.tooltip {
  top: 53px;
  right: 0;
  z-index: 99;
  position: absolute;

  &::after {
    right: 21px !important;
  }
}
