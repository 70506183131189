@value colorConcrete from '~Styles/vars/colors.css';

.root {
  display: flex;
  background: colorConcrete;
}

.preloader {
  margin: 0 auto;
  padding: 40px;
}
