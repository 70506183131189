@value colorGrey50 from '~Styles/vars/colors.css';

.root {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 8px;
  width: 100%;
}

.filterRow {
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
}
.allFiltersButton {
  width: max-content;
  white-space: nowrap;
}

.horizontalDivider {
  width: 100%;
  height: 1px;
  background-color: colorGrey50;
}