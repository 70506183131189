@value colorGrey50 from '~Styles/vars/colors.css';

.header {
  display: flex;
  justify-content: space-between;
}

.content {
  margin: 40px 0;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.graphContent {
  margin-top: 40px;
  display: flex;
  flex-wrap: wrap;
  gap: 40px;
}

.graph {
  margin-bottom: 40px;
}

.bar {
  width: 290px;
  margin-bottom: 20px;

  &:not(:nth-child(2n)) {
    margin-right: 40px;
  }
}

.realFollowers {
  border-radius: 4px;
  border: 1px solid colorGrey50;
  padding: 20px;
  box-sizing: border-box;
  width: 280px;
}
