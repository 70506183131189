.cards {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.creatorsCount {
  margin: 10px 0;
  display: inline-flex;
  gap: 8px;
  align-items: center;
  padding-left: 16px;
}

.pointer {
  cursor: pointer;
}

.checkbox {
  padding: 6px;
  width: 16px;
  height: 16px;
  > div {
    border-radius: 4px !important ;
    > div {
      border-radius: 2px !important ;
    }
  }
}

.creatorsContainerRoot {
  position: relative;
}

.creatorsContainerRoot {
  position: relative;
}
