@value colorPurple100 from '~Styles/vars/colors.css';

.drawer {
  background-color: white;
}

.contentRoot {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.title {
  font-size: 24px;
  font-weight: 400;
  line-height: 32px;
  margin-bottom: 16px;
}

.description {
  display: inline-flex;
  margin: 16px 0;
  gap: 4px;
}

.image {
  size: 144px;
  transform: rotate(-11deg);
}

.preloader {
  flex: 1;
  margin: 40px auto;
}

.link {
  color: colorPurple100;
}

.contentContainer {
  flex: 1;
}

.buttonContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
  margin-bottom: 16px;
}

.additionalButton {
  min-width: 220px;
}