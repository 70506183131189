@value (
  colorPink40,
  colorPink100
) from '~Styles/vars/colors.css';

.root {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin: 0px 0 16px;
  padding: 16px;
  box-sizing: border-box;

  .limit {
    display: flex;
    width: 418px;
    align-items: center;
    gap: 12px;
    .progressBar {
      min-width: 243px;
    }
  }
  .controls {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    gap: 16px;
    .button {
      margin-left: 16px;
    }
  }
  .barContainer {
    background-color: colorPink40;
  }
  .bar {
    background-color: colorPink100;
  }
}
