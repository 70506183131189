.buttons {
  display: flex;
  gap: 8px;
  flex-shrink: 0;
}

.formContent {
  flex: 1;
}

.control {
  flex: 1;
}
