@value colorConcrete, colorWhite, colorGrey100 from '~Styles/vars/colors.css';

.contentRoot {
  width: 140px;
  min-width: 140px;
  position: relative;

  .sliderContainer {
    width: 100%;
    height: 100%;
    overflow: hidden;
    isolation: isolate;
    position: relative;
  }
}

.portfolioSlider {
  width: 100%;
  height: 100%;
}

.portfolioTooltip {
  position: absolute;
  bottom: calc(100% + 14px);
}

.stub {
  background: colorWhite;
  width: 140px;
  @mixin center;
  flex-shrink: 0;
}

.stubWrap {
  display: flex;
  flex-direction: column;
  gap: 8px;
  color: colorGrey100;
  align-items: center;

  .stubTitle {
    text-align: center;
  }
}

.inNewCard {
  &.contentRoot {
    width: 172px;
    min-width: 172px;
    max-height: 232px;
  }
  &.stub {
    width: 172px;
  }
}
