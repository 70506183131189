@value colorWhite from '~Styles/vars/colors.css';

.account {
  size: 24px;
  background-color: colorWhite;
  border-radius: 4px;
  margin-right: 8px;
  @mixin center;
  display: inline-flex;
}

.noactive {
  opacity: 0.5;
}

.ba {
  color: #D63774;
}