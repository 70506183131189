@value colorWhite, colorGrey10 from '~Styles/vars/colors.css';

.drawer {
  @mixin center;
}

.content {
  background: colorWhite;
  width: 471px;
  border-radius: 22px;
  padding: 20px;
  box-sizing: border-box;
  text-align: center;
}

.imageWrap {
  size: 100% 159px;
  @mixin center;
  background: colorGrey10;
  border-radius: 12px;
  margin-bottom: 16px;
}

.closeWrap {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 12px;
}

.controls {
  display: flex;
  justify-content: space-between;
  margin-top: 27px;
}

.upload {
  position: relative;
}

.uploader {
  position: absolute;
  top: 0;
  left: 0;
  size: 100%;
}
