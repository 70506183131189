.uploaderWrapper {
  position: absolute;
  z-index: 1;
  size: 100%;
  @mixin center;
}

.uploading,
.failedUpload {
  &::after {
    content: '';
    size: 100%;
    display: block;
    background: rgba(256, 256, 256, 0.8);
    border-radius: 16px;
    position: absolute;
    border: 1px solid rgba(256, 256, 256, 0.8);
    box-sizing: border-box;
  }
}

.failedUpload {
  &::after {
    border-color: colorError100;
  }
}

.uploader {
  size: 48px;
  border-radius: 50%;
  background: rgba(0, 0, 0, 0.4);
  position: absolute;
  z-index: 1;
  @mixin center;
}

.progressBar {
  width: 42px;
  height: 42px;
  border-radius: 50%;
  color: white;
  @mixin center;
}

.downloadRetry {
  display: flex;
  gap: 2px;
  padding: 4px 8px 4px 4px;
  background: #999999;
  border-radius: 40px;
  color: colorWhite;
  position: absolute;
  z-index: 1;
  cursor: pointer;
}
