.root {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 4px;
  gap: 20px;
}

.textSection {
  display: block;
  margin-bottom: 16px;
}

.dropdownGroup {
  display: flex;

  &:not(:last-child) {
    margin-right: 20px;
  }
}

.newDesign {
  .dropdownGroup {
    flex-wrap: wrap;
    gap: 8px;
  }
}
