.root {
  display: block;
  width: 100%;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.title {
  display: flex;
  align-items: baseline;
}

.count {
  margin-left: 8px;
}