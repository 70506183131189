@value colorWhite, colorConcrete from '~Styles/vars/colors.css';
@value desktopMinWidth from '~Styles/vars/query.css';

.root {
  position: fixed;
  bottom: 0;
  size: desktopMinWidth 68px;
  padding: 22px 20px;
  background: colorWhite;
  border-top: 1px solid colorConcrete;
  box-sizing: border-box;
}

.values {
  display: flex;
}

.value {
  display: flex;
  align-items: center;
  margin-left: 20px;
}

.container {
  display: flex;
  justify-content: space-between;
}

.valueText {
  margin-left: 8px;
}
