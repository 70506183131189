.content {
  display: flex;
}

.submit {
  margin-left: 20px;
}

.input {
  width: 400px;
}

.title {
  margin-bottom: 20px;
}

.result {
  margin-top: 10px;
}