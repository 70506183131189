@value colorDark40 from '~Styles/vars/colors.css';
.root {
  position: relative;
  overflow: hidden;

  .sliderContainer {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    transition: transform .3s;

    .slide {
      height: 100%;
      width: 100%;
      min-width: 100%;
      position: relative;
      cursor: pointer;

      & > img, video {
        position: absolute;
        object-fit: cover;
        object-position: center;
        z-index: 0;
        height: 100%;
        width: 100%;
      }
    }
  }
  .pagination {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 8px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 4px;

    .paginationItem {
      height: 7px;
      width: 7px;
      border-radius: 4px;
      background-color: colorDark40;

      &.activePaginationItem {
        background-color: rgba(255,255,255,.8);
      }
    }
  }
  .controlContainer {
    height: 100%;
    width: 100%;
    position: absolute;
    pointer-events: none;
    z-index: 1;

    .prevBtn {
      left: 4px;
    }
  
    .nextBtn {
      right: 4px;
    }
  
    .controlButton {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      height: 24px;
      width: 24px;
      border-radius: 12px;
      background-color: rgba(255,255,255,.8);
      cursor: pointer;
      pointer-events: all;
      opacity: 0;
      transition: opacity .3s;

      &:hover {
        background-color: rgba(255,255,255, 1);
      }
    }
  }
  &:hover {
    .controlButton {
      opacity: 1;
    }
  }
}