.root {
  display: flex;
  flex-direction: column;
  padding: 0 24px;
  box-sizing: border-box;
  height: calc(100vh - 64px);
  overflow: hidden;
}

.header {
  flex: none;
}

.up {
  cursor: pointer;
  position: fixed;
}

.resultsContainer {
  width: 100%;
}