@value colorWhite, colorGrey10, colorGrey100 from '~Styles/vars/colors.css';

.list {
  display: flex;
  flex-direction: column;
  margin: 0;
  max-height: calc(100vh - 292px);
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
  overflow: auto;
}

.drawer {
  background-color: colorWhite;
}

.emptyWrap {
  size: 100% 300px;
  @mixin center;
  flex-direction: column;
  color: colorGrey100;
  text-align: center;
  gap: 8px;
}

.unlockBanner {
  right: 16px;
  bottom: 8px;
  top: unset;
}

.listHeader {
  display: grid;
  grid-template-columns: 2fr 2fr 1fr 1fr 1fr 178px;
  grid-column-gap: 8px;
  width: 100%;
  padding: 16px;
  background-color: white;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  box-sizing: border-box;
  margin-top: 32px;
  border-bottom: 2px solid colorGrey10;

  .listHeaderText {
    color: colorGrey100;
    cursor: default;
    &.profile {
      text-align: center;
    }
  }
}