@value colorGrey100 from '~Styles/vars/colors.css';

.root {
  display: flex;
  flex-direction: column;

  .titleContainer {
    padding: 12px 24px;

    .groupLabel {
      color: colorGrey100;
    }
  }

  .portfolioContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
}