@value colorGrey10, colorGrey50, colorGrey100, colorDark100, colorError100, colorApproval100 from '~Styles/vars/colors.css';

.drawerContainer {
  .drawerBackdrop {
    z-index: 1100;
  }

  .drawerWrapper {
    z-index: 1101;
    background-color: white;

    .root {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .formContainer {
        display: flex;
        flex-direction: column;
        flex: 1;
        .title {
          margin-bottom: 24px;
        }
        .subtitle {
          margin-bottom: 16px;
        }

        .descriptionText {
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 8px;
          margin-bottom: 16px;
        }

        .itemDividerText {
          margin-top: 16px;
          margin-bottom: 16px;
        }

        .itemContainer {
          background-color: colorGrey10;
          padding: 16px;
          border-radius: 16px;
          display: flex;
          flex-direction: column;
          gap: 16px;

          .item {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 8px;

            .itemIconFeature {
              height: 24px;
              width: 24px;
              min-width: 24px;
              border-radius: 12px;
              background-color: colorApproval100;
              color: white;
            }

            .itemIconLose {
              height: 24px;
              width: 24px;
              min-width: 24px;
              border-radius: 12px;
              background-color: colorError100;
              color: white;
            }
          }
        }
      }


      .bottomContainer {
        width: 100%;
        display: flex;
        flex-direction: column;

        .buttonContainer {
          width: 100%;
          display: flex;
          flex-direction: row;
          text-align: center;
          gap: 16px;
          margin-top: 16px;
          .buttonText {
            width: 100%;
            text-align: center;
          }
        }
      }
      
    }
  }
}