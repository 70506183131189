@value colorSky40 from '~Styles/vars/colors.css';

.root {
  display: flex;
  margin-top: 44px;
  margin-bottom: 32px;
  border-radius: 16px;
  background: colorSky40;
  padding: 32px;
  padding-left: 246px;
  position: relative;
  justify-content: space-between;
}

.img {
  position: absolute;
  left: 24px;
  top: -28px;
}

.closeBtn {
  position: absolute;
  top: 8px;
  right: 8px;
}
