.root {
  display: flex;
  gap: 8px;
}

.chat {
  position: relative;
  display: flex;
  gap: 8px;
}

.unreadCount {
  top: -4px;
  left: 12px;
  position: absolute;
  min-width: 16px;
  height: 16px;
}

.drawer {
  width: 640px;
  background-color: white;
}
