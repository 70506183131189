@value colorDark100, colorGrey50, colorPink100, colorGreen from '~Styles/vars/colors.css';

.root {
  display: flex;
}

.text {
  margin-left: 12px;
}

.processingText {
  color: colorGrey50;
}

.draftText {
  color: colorPink100;
  margin-left: 4px;
}

.savedText {
  color: colorPink100;
}

.savedIcon {
  path {
    fill: colorPink100;
  }
}

.freeSavedText {
  color: colorDark100;
}
