@value colorSand40, colorWhite from '~Styles/vars/colors.css';

.drawerContainer {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  width: 100%;
  --transition-speed: 0.3s;

  &.in.open {
    .left,
    .right {
      transform: translateX(0);
    }

    .top,
    .bottom {
      transform: translateY(0);
    }
    .backdrop {
      opacity: 0.9;
    }
    .center {
      opacity: 1;
    }
  }

  &:not(.open) {
    .backdrop {
      opacity: 0;
    }
  }
  .backdrop {
    visibility: visible;
    opacity: 0;
    pointer-events: auto;
    z-index: 999;
    transition: opacity var(--transition-speed) ease;
  }
}

.drawer {
  width: 548px;
  height: calc(100vh - 32px);
  box-sizing: border-box;
  position: fixed;
  transition: transform var(--transition-speed) ease;
  z-index: 1000;
  padding: 16px;
  border-radius: 24px;
  margin-top: 16px;

  & > div {
    overflow: auto;
  }

  &.left {
    top: 0;
    left: 0;
    transform: translateX(-105%);
  }

  &.right {
    top: 0;
    right: 0;
    transform: translateX(100%);
    margin-right: 16px;
  }

  &.top {
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    transform: translateY(-100%);
    height: 40%;
  }

  &.bottom {
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    transform: translateY(100%);
    height: 40%;
  }

  &.center {
    opacity: 0;
    transition: opacity var(--transition-speed) ease;
  }
}

.backdrop {
  visibility: hidden;
  opacity: 0;
  background: colorSand40;
  transition: opacity var(--transition-speed) ease, visibility var(--transition-speed) ease;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: fixed;
  pointer-events: none;
  z-index: 0;
}

.closeBtn {
  position: absolute;
  top: 0px;
  left: -76px;
  height: 60px;
  width: 60px;
  border-radius: 16px;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

:global .isMobile {
  :local .backdrop {
    background-color: white;
    opacity: 1 !important;
  }
  :local .closeBtn {
    position: absolute;
    top: -32px;
    right: 0px;
    left: calc(100% - 48px);
    height: 24px;
    width: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
  :local .drawer {
    width: 100%;
    height: 100%;
    margin: 0;
    margin-top: 56px;
  }
}

.fullView {
  .drawer {
    width: 100%;

    .closeBtn {
      right: 0;
      left: unset;
    }
  }
}

.dark {
  .backdrop {
    background: rgba(0, 0, 0, 1);
  }
  .closeBtn {
    background: unset;
    color: colorWhite;
  }
}

.fullView {
  .center {
    .closeBtn {
      top: 26px;
      right: 42px;
    }
  }
}
