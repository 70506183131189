@value newDesktopMinWidth from '~Styles/vars/query.css';
@value colorGrey50, colorGrey10 from '~Styles/vars/colors.css';

.root {
  display: block;
  background-color: colorGrey10;
}

.container {
  width: newDesktopMinWidth;
  padding: * 10px;
  margin: 40px auto;
  box-sizing: border-box;
}

.header {
  display: flex;
  align-items: center;
  margin: 0 auto 24px;
}

.dropDown {
  margin-left: 20px;
}

.transactionShowToggle {
  margin-left: 20px;

  &::before {
    content: '';

    display: block;
    height: 24px;
    width: 1px;
    margin-right: 20px;

    background-color: colorGrey50;
  }
}
