@value colorConcrete from '~Styles/vars/colors.css';

.psError {
  padding: 8px 16px;
}


.psErrorBtn {
  flex-shrink: 0;
  margin-top: 20px;
}

:global .isMobile {
  :local .psError {
    margin: 0;
    border-radius: 0;
    display: flex;
    border-bottom: 1px solid colorConcrete;
  }

  :local .psErrorBtn {
    margin: 0 0 0 20px;
  }
}
