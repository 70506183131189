@value colorWhite, colorConcrete from '~Styles/vars/colors.css';

.root {
  width: 100%;
  background-color: colorWhite;
  border-radius: 4px;
  margin-bottom: 12px;

  &:nth-last-of-type {
    margin-bottom: 0;
  }
}

.content {
  padding: 20px;
  box-sizing: border-box;
}

.controls {
  margin-top: 20px;
}

.decline {
  border-top: 1px solid colorConcrete;
  padding: 16px 0;
  box-sizing: border-box;
}

.declineBtn {
  margin: 0 auto;
}