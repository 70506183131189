@value colorGrayDog from '~Styles/vars/colors.css';

.root {
  position: relative;
}

.icon {
  color: colorGrayDog;
}

.uploader {
  size: 100%;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}
