@value colorWhite, colorGrey100 from '~Styles/vars/colors.css';

.root {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.payments {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.method {
  display: flex;
  gap: 8px;
  align-items: center;
}

.title {
  margin-bottom: 24px;
}

.brand {
  flex: 1;
}

.newMethod {
  display: flex;
  gap: 8px;
  align-items: center;
  margin-top: 24px;
  margin-bottom: 16px;
}

.paymentForm {
  flex: 1;
}

.buttons {
  display: flex;
  gap: 8px;
}

.button {
  flex: 1;
}

.spacer {
  flex: 1;
}

.detailsControl {
  display: flex;
  align-items: center;
  color: colorGrey100;
  gap: 4px;
  cursor: pointer;
  user-select: none;
  margin-top: 16px;
  margin-bottom: 8px;
}

.newMethodWrap {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.content {
  display: flex;
  flex-direction: column;
  flex: 1;
}
