@value (colorGrey100, colorError100, colorPink40, colorSun40, colorPurple40, colorSky40) from '~Styles/vars/colors.css';

@keyframes appearanceRightToLeft {
  0% {
    opacity: 0;
    right: -150px;
  }
  100% {
    opacity: 1;
    right: 0;
  }
}

@keyframes appearanceLeftToRight {
  0% {
    opacity: 0;
    left: -150px;
  }
  100% {
    opacity: 1;
    left: 0;
  }
}

.root {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 154px;
  position: relative;

  .stepWrapper {
    position: relative;

    &.appearanceRightToLeft {
      animation: appearanceRightToLeft .5s;
    }

    &.appearanceLeftToRight {
      animation: appearanceLeftToRight .5s;
    }
  }

  .footer {
    --pink: colorPink40;
    --sun: colorSun40;
    --purple: colorPurple40;
    --sky: colorSky40;
    --bg-opacity: 80;

    transition: .5s background-color, .5s backdrop-filter;
    backdrop-filter: blur(5px);
    width: 50%;
    padding-bottom: 8px;
    position: fixed;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1;

    &.themePink {
      background-color: var(--pink)var(--bg-opacity);
    }

    &.themeSun {
      background-color: var(--sun)var(--bg-opacity);
    }

    &.themePurple {
      background-color: var(--purple)var(--bg-opacity);
    }

    &.themeSky {
      background-color: var(--sky)var(--bg-opacity);
    }

    .footerInner {
      max-width: 500px;
      width: 100%;
      margin: 0 auto;
    }

    .errorMessage {
      color: colorError100;
      padding: 8px 16px;
      visibility: hidden;

      &.visible {
        visibility: visible;
      }
    }

    .buttonsGroup {
      display: grid;
      grid-template-columns: max-content 1fr;
      grid-gap: 8px;
      padding: 8px 16px;
      transition: .5s grid-gap;

      &.oneColumn {
        grid-gap: 0;
      }

      .nextStepButtonWrapper {
        position: relative;
        width: 100%;

        .nextStepButton {
          box-sizing: border-box;
          width: 100%;
        }

        .clicker {
          position: absolute;
          left: 0;
          top: 0;
          height: 100%;
          width: 100%;
          opacity: 0;
          cursor: pointer;
        }
      }

      .prevStepButton {
        transition: .5s width, .5s padding, .5s border;
        width: 72px;

        &.hidden {
          width: 0;
          padding: 0;
          border: 0 solid transparent;
          overflow: hidden;
        }
      }
    }
  }
}
