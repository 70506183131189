.header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 40px;
}

.preloader {
  margin: 20px auto;
}

.searchInput {
  width: 280px;
}
