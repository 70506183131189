@value (
  colorGrey50,
  colorGrey100,
  colorGrey10,
  colorWhite,
  colorDark100,
  colorPink100,
  colorDark100,
  colorPurple100,
  colorError50
) from '~Styles/vars/colors.css';

.root {
  margin-bottom: 6px;
  background: colorWhite;
  position: relative;
}

.content {
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
}

.items {
  display: flex;
  align-items: center;
}

.controls {
  display: flex;
  align-items: center;
  gap: 8px;
  z-index: 10;
}

.username {
  margin-left: 16px;
  margin-right: 16px;
}

.tag {
  margin: 0 2px !important;
}

.shipment {
  color: colorGrey50;
  margin-top: -4px;
  cursor: pointer;
}

.shipmentTitle {
  color: colorGrey100;
}

.shipmentDescr {
  padding-right: 20px;
}

div.shipmentTooltip {
  border-radius: 8px;
  background: colorDark100 !important;
  padding: 20px 44px 20px 20px;
  box-shadow: unset;
  width: unset !important;
  min-width: 314px !important;
  max-width: 400px !important;

  &::before,
  &::after {
    content: unset;
  }
}

.shipmentItem {
  display: flex;
  gap: 4px;
  color: colorWhite;
}

.copyBtn {
  top: 8px;
  right: 0px;
  position: absolute;
  transition: unset;
  color: colorWhite;
}

div.archivationTooltip {
  opacity: 1;
}

.archivationTitle {
  color: colorWhite;
}

.archivationLink {
  cursor: pointer;
  color: colorPurple100;
}

.tooltipStub {
  top: -10px;
  height: 20px;
  width: 100%;
  position: absolute;
}

.stage {
  color: colorGrey100;
}

.decline {
  border-radius: 16px;
  background: colorError50;
  padding: 16px;
  box-sizing: border-box;
}
