@value colorGrey10 from '~Styles/vars/colors.css';

.root {
  height: 100%;
  overflow: auto;
}

.title {
  margin-bottom: 8px;
}

.ava {
  margin-right: 16px;
}

.creator {
  display: flex;
  align-items: center;
  padding: 16px 16px 16px 0;
}

.sectionTitle {
  margin: 8px 0;
}

.infoBlock {
  padding: 8px 0;
  display: flex;
  align-items: center;
  gap: 16px;
}

.iconWrap {
  size: 40px;
  padding: 8px;
  box-sizing: border-box;
  background: colorGrey10;
  border-radius: 8px;
}

.dates {
  flex: 1;
}

.preloader {
  margin: 0 auto;
}
