@value colorConcrete,colorDark40, colorWhite, colorWetAsphalt from '~Styles/vars/colors.css';

.root {
  @mixin vertical-center;
  padding: 6px 8px;
  height: 32px;
  border-radius: 16px;
  box-sizing: border-box;
  background: colorDark40;
  color: colorWhite;
  gap: 8px;

  img {
    size: 16px;
  }
}

.cross {
  path {
    fill: colorWhite !important;
  }
}

.delete {
  display: flex;
}
