@value (colorSand40, colorBrown40, colorPink100, colorDark100) from '~Styles/vars/colors.css';
@value (borderRadius16) from '~Styles/vars/query.css';

.root {
  margin-bottom: 64px;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 16px;
}

.list {
  display: grid;
  grid-template-rows: max-content;
  grid-template-columns: repeat(auto-fit, minmax(0, 1fr));
  grid-gap: 16px;
}

.fullyManagedServiceBanner {
  display: flex;
  border-radius: borderRadius16;
  width: 880px;
  background-color: colorSand40;
  gap: 10px;
  padding: 24px 32px;
  box-sizing: border-box;
  position: relative;
  margin: 64px auto 0;

  .fullyManagedServiceBannerTextContainer {
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 510px;

    .fullyManagedServiceBannerButtonContainer {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 8px;

      button {
        min-width: 177px;
        justify-content: center;
      }
    }
  }
  .fullyManagedServiceBannerBg {
    position: absolute;
    bottom: 0;
    right: -78px;
    background-image: url('~files/images/plans/fullymanagebanner.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100%;
    width: 395px;
    height: 187px;
  }
}
