.root {
  margin-bottom: 32px;
}

.wrapper {
  display: flex;
}

.input {
  width: 200px;
  margin-right: 20px;
}

.result {
  margin: 20px 0;
}

.warning {
  margin-top: 12px;
}