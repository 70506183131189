@value colorDark100, colorGrey100, colorGrey50, colorPink60 from '~Styles/vars/colors.css';

.root {
  z-index: 3;
  position: relative;

  .customDay {
    padding: 0 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    color: colorDark100;
    font-size: 15px;
    line-height: 24px;
    font-weight: 400;
    border-radius: 0;
    flex-grow: 1;
  }

  [class*='react-datepicker__day'],
  [class*='react-datepicker__day--selected'],
  [class*='react-datepicker__day--today'] {
    background-color: transparent;

    &:hover {
      background-color: transparent;
    }
  }

  [class*='react-datepicker__day--outside-month'] {
    color: colorGrey50;
  }

  [class*='.react-datepicker__month'] {
    margin: 0;
  }

  .weekDayClassName {
    padding: 8px 16px;
    margin: 0;
    color: colorGrey100;
    font-weight: 400;
    flex-grow: 1;
  }
}
