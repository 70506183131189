@value colorYellow, colorConcrete from '~Styles/vars/colors.css';
@value briefWidth from '~Styles/vars/size.css';

.root {
  width: briefWidth;
  margin: 0 auto;
}

.section {
  padding: 40px 0;
  border-top: 1px solid colorConcrete;
}

.title {
  margin-bottom: 20px;
}

.requirement {
  display: flex;
  margin-bottom: 20px;
}

.requirementIcon {
  margin-right: 16px;
}

:global .isMobile,
.mobileView {
  :local .root {
    width: 100%;
  }

  :local .section {
    padding: 32px 16px;
  }
}
