@value colorWhite, colorConcrete from '~Styles/vars/colors.css';
@value headerHeight from '~Styles/vars/size.css';

:root {
  --infoBlog: 96px;
}

.root {
  padding: 12px;
  box-sizing: border-box;
  background-color: colorConcrete;
}

.title {
  letter-spacing: 0.3px;
}

.section {
  width: 100%;
  background-color: colorWhite;
  border-radius: 4px;
  margin-bottom: 12px;
  padding: 20px;
  box-sizing: border-box;

  &:nth-last-of-type {
    margin-bottom: 0;
  }
}

.brief {
  padding: 20px;
  composes: section;
}

.container {
  height: var(--infoBlog);
  composes: section;
}

.item {
  border-radius: 4px;
  margin-bottom: 12px;
  background-color: colorWhite;
}