@value mobileMaxInnerWidth from '~Styles/vars/query.css';

.root {
  margin-bottom: 20px;
}

.container {
  flex: 1;
}

.header {
  margin-bottom: 24px;
}

.title {
  padding-top: 16px;
  margin-bottom: 16px;
}

.loadingContainer {
  flex: 1;
  height: calc(100vh - 280px);
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.preloader {
  margin: 0px auto;
}

.serachInputWrap {
  flex: 1;

  .serachInput {
    border-radius: 8px;
  }
}

.params {
  display: flex;
  gap: 16px;
}

.brands {
  width: 280px;
}

.insenseWelcome {
  margin-top: 30px;
}

.emailVerificationBanner {
  margin: 30px 0;
  width: calc(50% - 30px);
}

:global .isMobile {
  :local .root {
    width: 100%;
    margin: 0 auto;
    max-width: mobileMaxInnerWidth;
    padding-left: 16px;
    padding-right: 16px;
    box-sizing: border-box;

    .serachInputWrap {
      display: none;
    }
  }

  :local .title {
    padding-left: 16px;
    padding-right: 16px;
    margin: 12px 0;
  }
}

.titleWrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 8px;
  margin-bottom: 8px;
}

.titleActions {
  display: flex;
  gap: 32px;
}
