@value colorRed from '~Styles/vars/colors.css';

.root {
  height: 24px;
  border-radius: 15px;
  background: colorRed;
  text-align: center;
  padding: 0 9px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.size-sm {
  height: 20px;
  padding: 0 6px;
  border-radius: 12px;
  min-width: 20px;
  box-sizing: border-box;
}