.content {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;

  > li {
    size: 128px;
    box-sizing: border-box;
  }
}

.uploadFile {
  size: 128px;
  position: relative;
}