@value (colorGrey50, colorGrey100, colorDark100, colorError100, colorError50) from '~Styles/vars/colors.css';

.root {
  padding: 12px 16px;
  border-radius: 8px;
  position: relative;
  background-color: white;
  border: 1px solid transparent;
  cursor: pointer;
  max-height: 48px;
  box-sizing: border-box;

  &.bordered {
    border: 1px solid colorGrey50;

    &:hover {
      border: 1px solid colorGrey100;
    }

    &:focus {
      border: 1px solid colorDark100;
    }

    &.withValue {
      border-color: colorDark100;
    }
  }

  &.active {
    border: 1px solid colorDark100;
  }

  &.error {
    background-color: colorError50;
    border-color: colorError100;
  }

  .openIcon {
    transform: rotateX(180deg);
  }

  .childContainer {
    &:not(.opened) {
      display: none;
    }
  }

  &.disabled {
    cursor: default;
    .value {
      opacity: 0.8;
    }
  }

  .value {
    display: flex;
    align-items: flex-start;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    gap: 8px;

    .placeholder {
      color: colorGrey100;
    }
  }
}
