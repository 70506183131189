@value colorWhite, colorConcrete, colorBase, colorPurple100, colorGrey10 from '~Styles/vars/colors.css';

.root {
  position: relative;
  vertical-align: top;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 16px 8px;

  &:hover {
    z-index: 10;
  }
}

.content {
  height: 100%;
  background: colorWhite;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
}

.container {
  height: 100%;
  position: relative;
  flex-grow: 1;
}

.details {
  border-radius: 0 0 4px 4px;
  flex-shrink: 0;
}

.addedToMediaplan {
  box-shadow: 0 0 0 2px colorBase, 0 1px 4px rgba(0, 0, 0, 0.1);

  &:hover {
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1), 0 0 0 2px colorBase;
  }
}

.hiddenState {
  top: 20px;
  right: 20px;
  position: absolute;
  display: flex;
  align-items: center;
  padding: 4px 8px;
  background: colorWhite;
  border-radius: 4px;
}

.viewed {
  .details {
    opacity: 0.5;
  }
}

.blockedName {
  position: relative;
}

.blured {
  padding-left: 3px;
  filter: blur(4px);
  user-select: none;
}

.lockIcon {
  top: 4px;
  position: absolute;
  color: colorPurple100;
  cursor: pointer;
  z-index: 1;
}
