.root {
  display: flex;
  gap: 20px;
}

.content {
  display: block;
}

.drawer {
  position: relative;
  width: auto;
  margin-right: 0;
  @mixin center;
}

.iframe {
  border-radius: 16px;
}

.closeBtn {
  size: 40px;
  border-radius: 16px;
  background-color: white;
  @mixin center;
  cursor: pointer;
}
