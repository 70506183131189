.button {
  width: 100%;
  box-sizing: border-box;
  justify-content: center;
}

.error {
  bottom: -20px;
  position: absolute;
}
