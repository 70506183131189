.input {
  width: 180px !important;
  height: 40px;

  input {
    padding-top: 19px;
    padding-bottom: 19px;
    border-radius: 4px;
  }
}
