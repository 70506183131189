@value colorGrey10, colorGrey50 from '~Styles/vars/colors.css';
@value newDesktopMinWidth from '~Styles/vars/query.css';

.root {
  display: block;
  padding-top: 16px;
  background: colorGrey10;
}

.container {
  width: newDesktopMinWidth;
  padding: * 10px;
  margin: 0 auto;
  box-sizing: border-box;
}

.members {
  display: flex;
  flex-wrap: wrap;
  margin-top: 19px;
}

.addUser {
  margin-right: 8px;
  margin-bottom: 8px;
}

.member {
  padding: 11px 12px;
  border: 1px solid colorGrey50;
  border-radius: 4px;
  display: inline-flex;
  align-items: center;
  margin-right: 8px;
  margin-bottom: 8px;
}

.contact {
  margin-right: 4px;
}

.email {
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}

.phone {
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }

  &::before {
    content: ',';
    padding-right: 3px;
  }
}

.title {
  margin-bottom: 16px;
}
