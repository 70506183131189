@value colorGrey50 from '~Styles/vars/colors.css';

.root {
  size: 100%;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  overflow: hidden;
  position: absolute;
  border: 2px dashed colorGrey50;
  border-radius: 4px;
  padding: 20px;
  box-sizing: border-box;
}

.label {
  top: 0;
  left: 0;
  opacity: 0;
  size: 100%;
  position: absolute;
  overflow: hidden;
}

.input {
  size: 100%;
  cursor: pointer;
}

.descr {
  display: flex;
  flex-wrap: wrap;
  text-align: center;
  justify-content: center;
}

.plain {
  size: 100%;
  position: relative;
}
