@value colorWhite, colorDark100 from '~Styles/vars/colors.css';

.root {
  border-radius: 12px;
  gap: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 16px;
  box-sizing: border-box;
  position: sticky;
  z-index: 10;
  background-color: colorDark100;
  width: calc(1180px * 0.9);
  max-width: 90%;
  margin: 0 auto;
  bottom: 32px;
  box-shadow: 0px 4px 20px 0px #33333380;
  @media (max-width: 1216px) {
    width: calc( 100% - 64px);
    max-width: 100%;
    left: 64px;
  }
}
