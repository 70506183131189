@value colorGrey100, colorGrey10, colorWhite from '~Styles/vars/colors.css';

.root {
    padding: 16px;
    background: colorWhite;
    display: flex;
    justify-content: space-between;
    border-bottom: 2px solid colorGrey10;
}

.creator {
    position: relative;
    flex: 1;
}

.content {
    align-items: center;
    display: flex;
    width: 100%;
}

.count {
    color: colorGrey100;
    width: 140px;
    box-sizing: border-box;
    padding: 8px 16px;
}

.controls {
    display: flex;
    gap: 8px;
}

.tooltip {
    z-index: 2;
}
