@value colorWhite, colorGrey50, colorGrey80, colorDark from '~Styles/vars/colors.css';

.wrapper {
  size: 100%;
  display: flex;
  flex-direction: column;
}

.arrow {
  transition-duration: 300ms;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  color: colorGrey80;
}

.arrow:hover {
  transition-duration: 300ms;
  color: colorDark;
  background-color: colorGrey80;
}

.container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.backdrop {
  opacity: 1;
}

.sliderContainer {
  display: flex;
  overflow: hidden;
  max-width: 55vw;
}

.slide {
  width: 100%;
  min-width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  transition-duration: 300ms;
}

.root {
  position: relative;
  flex-grow: 1;
  @mixin center;
}

.close {
  top: 20px;
  right: 20px;
  cursor: pointer;
  position: absolute;
}

.image,
.caption {
  display: inline-block;
  vertical-align: top;
}

.image {
  max-width: 55vw;
  max-height: 80vh;
}

.caption {
  width: 300px;
  padding: 20px;
}

.singleCaption {
  width: 400px;
  min-height: 200px;
}

.container {
  display: block;
}

.counts {
  margin-top: 20px;
  padding-top: 20px;
  border-top: 1px solid colorGrey50;
}

.text {
  max-height: 75vh;
  overflow: auto;
  word-break: break-word;
}

.hidden {
  visibility: hidden;
}

div.youtube {
  width: auto;
}

.footer,
.header {
  padding: 20px;
  min-height: 24px;
  display: flex;
  justify-content: space-between;
  position: relative;
}

.controls {
  display: flex;
}

.control:not(:last-child) {
  margin-right: 16px;
}

.galleryContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  gap: 24px;
}

:global .isMobile {
  :local .root {
    width: 100%;
    box-sizing: border-box;
    flex: 1;
    @mixin center;

    .close {
      top: 12px;
      right: 12px;
    }
  }

  :local .wrapper {
    background-color: rgba(0,0,0,0.8);
  }
}
