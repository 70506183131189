@value colorGrey100, colorGrey10, colorGrey50, colorDark100, colorError100, colorPurple40, colorSuccess100, colorPink100, colorPink40 from '~Styles/vars/colors.css';

.drawer {
  @mixin drawer;
}

.drawerContainer {
  @mixin drawerContainer;
}

.root {
  padding: 16px;
  position: relative;
  background: colorGrey10;
  display: flex;
  flex-direction: column;
  flex: 1;
}

.content {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding-bottom: 16px;
  
  .settingsContainer {
    display: flex;
    flex-direction: row;
    flex: 1;
    gap: 8px;

    .settings {
      width: 360px;
      max-width: 360px;
      background-color: white;
      padding: 16px;
      border-radius: 16px;
      display: flex;
      flex-direction: column;
      flex: 1;

      .tabContainer {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 8px;

        .tab {
          flex: 1;
          border-radius: 16px;
          border: 1px solid colorGrey50;
          padding: 12px 16px;
          margin-bottom: 8px;
          cursor: pointer;

          &.active {
            border-color: colorDark100;
          }
        }
      }
    }

    .template {
      flex: 1;
      background-color: white;
      padding: 16px;
      border-radius: 16px;
    }
  }
  .templateEditor {
    display: flex;
    background-color: white;
    flex: 1;
    border-radius: 16px;
  }
}

.file {
  padding: 16px;
  background: colorGrey10;
  border-radius: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
}

.fileDetails {
  flex: 1;
}

.uploader {
  position: absolute;
  top: 0;
  left: 0;
  size: 100%;
}

.uploaderWrapper {
  position: relative;
  display: inline-flex;
}

.subtitle {
  color: colorDark100;
  margin-bottom: 8px;
}

.size {
  color: colorGrey100;
}

.dropdown {
  width: 177px;
  background: colorGrey10;
  margin-bottom: 16px;
}

.dropdownValue {
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: calc(100% - 32px);
  overflow: hidden;
}

.dropdownInputValue {
  border: none !important;
}

.dropdownGroupLabel {
  color: colorGrey100;
  margin-left: 16px;
}

.accordionMenu {
  padding: 0 !important;
}

.dropdownSettings {
  width: 100%;
  border: 1px solid colorGrey50;
  margin-bottom: 16px;
}

.dropdownName {
  width: 100%;
  border: 1px solid colorGrey50;
  margin-bottom: 16px;
  padding: 0px;
  max-height: 54px;
}

.dropdownMenu {
  width: 100%;
  right: 0;
  max-height: 276px;
  overflow: auto;
}

.dropdownItem {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  width: 100%;
  flex: 1;
  padding: 8px;

  .dropdownText {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  .dropdownSecondaryText {
    color: colorGrey50;
  }

  .dropdownItemActive {
    min-width: 32px;
    width: 32px;
  }
}

.templates {
  display: flex;
  flex-direction: column;
  gap: 8px;
  max-height: 240px;
  overflow: auto;
}

.template {
  display: flex;
  gap: 8px;
  cursor: pointer;

  .templateDetails {
    flex: 1;
    display: flex;
    gap: 11px;
    align-items: center;
  }

  .templateDetails,
  .show {
    flex-shrink: 0;
    background: colorGrey10;
    border-radius: 8px;
    padding: 12px 16px;
  }
}

.placeholder {
  color: colorGrey100;
}

.error {
  color: colorError100;
}

.commonError {
  color: colorError100;
  text-align: center;
  margin-bottom: 16px;
}

.secondaryText {
  color: colorGrey50;
}

.resetTemplate {
  padding: 0px;
  margin-bottom: 6px;
  
  i {
    font-style: normal;
  }
}

.templateContent {
  pointer-events: none;
  margin: 32px auto;
}

.editor,
.templateContent {
  b {
    font-weight: bold;
  }
  i {
    font-style: italic;
  }
  ul {
    list-style: inside;
    padding-left: 24px;
  }
  ol {
    list-style: decimal;
    padding-left: 24px;
  }
  h1 {
    font-size: 2em;
    font-weight: bold;
  }
  h2 {
    font-size: 1.5em;
    font-weight: bold;
  }
  h3 {
    font-size: 1.17em;
    font-weight: bold;
  }
  h4 {
    font-size: 1em;
    font-weight: bold;
  }
  h5 {
    font-size: 0.87em;
    font-weight: bold;
  }
  h6 {
    font-size: 0.67em;
    font-weight: bold;
  }
  a {
    color: colorPink100;
    text-decoration: underline;
    cursor: pointer;
  }
}

.addNewAccountButton {
  width: 200px;
}

.addAccountButton {
  width: 170px;
  color: colorDark100;
}

.requirements {
  margin-left: 34px;
}

.example {
  margin-top: 12px;
  margin-bottom: 20px;
}

.showErrors {
  color: colorGrey100;
  cursor: pointer;
}

.importInfo {
  display: flex;
  gap: 8px;

  &:not(.error) {
    color: colorSuccess100;
  }
}

.importHeader {
  display: flex;
  justify-content: space-between;
}

.buttonWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
  margin-top: 16px;
}

.listWrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;

  .listItem {
    display: flex;
    flex-direction: row;
    gap: 16px;
    align-items: center;
  }

  .listAddButton {
    max-width: 130px;
  }
}

.listCompleteButton {
  max-width: 200px;
}

.editorContainer {
  display: flex;
  flex-direction: column;
  flex: 1;

  &.hidden {
    display: none;
  }

  .editorContent {
    flex: 1;
    background-color: white;
    border-radius: 16px;
  }

  .editorWrapper {
    width: 100%;
    position: relative;
    margin-bottom: 32px;
  }

  .buttonPanel {
    display: flex;
    flex-direction: column;
    background-color: colorGrey10;
    gap: 8px;
    padding: 8px 16px;
    border-radius: 8px;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;

    &.onTop {
      border-radius: 8px;
      flex-direction: row;
      align-items: center;
      gap: 0;
    }

    .subjectTitle {
      color: colorGrey100;
    }

    .subjectInput {
      background-color: colorGrey10;
      padding: 20px 16px;
      flex: 1;
      overflow-x: hidden;
      text-overflow: ellipsis;

      &:focus {
        outline: none;
      }
    }
  
    .buttonBetweenRow {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
    }

    .buttonPanelRow {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 4px;

      .previewButton {
        font-style: normal;
        margin-right: 8px;
        cursor: pointer;
      }
    }

    .delimiter {
      width: 0px;
      border-left: 1px solid colorGrey50;
      height: 100%;
    }

    .control {
      width: 32px;
      height: 32px;
      
      border-radius: 8px;
      text-align: center;
      cursor: pointer;
      font-size: 14px;
      user-select: none;
      position: relative;

      &:hover {
        background-color: colorGrey10;
      }

      &.variable {
        width: auto;
        margin-right: 8px;
      }

      &.withIcon {
        i {
          font-style: normal;
          pointer-events: none;
        }
      }
    }

    .editorSelect {
      -webkit-appearance: none;
      appearance: none;

      padding: 8px 16px;
      border-radius: 8px;
      position: relative;
      background-color: colorGrey10;
      cursor: pointer;
      max-height: 48px;
      box-sizing: border-box;
      font-size: 14px;
      color: colorGrey10;
      font-size: 0px;
      outline: none;
      border: none;
      font-weight: bold;
      background-position: 50% calc(50% - 2px);
      background-repeat: no-repeat;
      width: 50px;
      height: 24px;

      &.headers {
        background-image: url('../../../../files/images/icons/wysiwygText.svg');
      }
      &.variables {
        background-image: url('../../../../files/images/icons/wysiwygVariables.svg');
        width: 96px;
      }
    }
  }

  .variables {
    width: 200px;

    i {
      font-style: normal;
    }
  }

  .editor {
    padding: 16px;
    border-radius: 0px;
    outline: none;
    overflow: auto;
    min-height: 420px;
    max-height: calc(100vh - 436px);
    width: 100%;
    box-sizing: border-box;
    display: inline-block;
    line-height: 22px;
  }
  .editorPreview {
    flex: 1;
    height: 100%;
  }
}

.preview {
  white-space: pre-wrap;
  word-wrap: break-word;
}

.stepContainer {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 540px;
}

.hidden {
  display: none;
}

.errorText {
  padding: 16px;
  width: 100%;
  text-align: center;
  color: colorError100;
}

.titleContainer {
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;
  gap: 16px;
  padding: 4px 0 20px;

  .backContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 4px;
  }
}

.addListButton {
  width: 100%;
}

.checkContainer,
.timeOptionContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
}

.timeOptionContainer {
  margin-left: 42px;
  margin-bottom: 16px;
}

.validTemplate,
.invalidTemplate {
  height: 20px;
  width: 20px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin-left: 4px;

  &:after {
    content: '';
    display: block;
    position: absolute;
    height: 24px;
    width: 24px;
    border-radius: 12px;
    top: -2px;
    left: -2px;
  }
}

.validTemplate {
  background-color: colorSuccess100;

  &:after {
    background-color: colorSuccess100;
    opacity: 0.2;
  }
}

.invalidTemplate {
  background-color: colorError100;
  color: white;

  &:after {
    background-color: colorError100;
    opacity: 0.2;
  }
}

.dropdownItemColumnValue {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 4px;
}

.dropdownItemText {
  display: flex;
  flex: 1;
  
}

.dropdownItemRowValue {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  padding: 16px;
  box-sizing: border-box;
  border-radius: 8px;

  &:hover {
    transition: .3s;
    background-color: colorGrey10;


    .dropdownItemColumnValue {
      max-width: calc(100% - 32px);
    }

    .dropdownItemIcon {
      display: block;
    }
  }
}

.dropdownItemIcon {
  margin-left: 8px;
  display: none;
}

*[contenteditable="true"]{white-space: pre-wrap;word-break: break-all;}

*[data-variable] {
  background-color: colorPurple40;
  color: colorDark100;
  padding: 2px 8px;
  border-radius: 12px;
  font-size: 12px;
}