@value (
  colorWetAsphalt,
  colorBase,
  colorGrayDog,
  colorRed,
  colorWhite,
  colorYellow,
  colorOrange,
  colorGreen,
  colorOlive,
  colorTurquoise,
  colorCreamCan,
  colorDark40,
  colorDark100,
  colorGrey50,
  colorGrey100,
  colorPink100,
  colorPurple100,
  sky100
) from '~Styles/vars/colors.css';
@value mobileMax from '~Styles/vars/query.css';

/* GENERAL */

.root {
  display: block;
  font: 15px/24px 'Aeroport';
  color: colorWetAsphalt;
  white-space: pre-wrap;
  transition: color 0.1s ease-in;
}

.root strong {
  font-family: 'Aeroport';
  font-weight: bold;
}

.overflow {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

/* ________ */

/* MODIFICATIONS */

/* ALIGN */

.align-center {
  text-align: center;
}

.align-left {
  text-align: left;
}

.align-right {
  text-align: right;
}

/* COLORS */

.color-base {
  color: colorBase;
}

.color-grayDog {
  color: colorGrayDog;
}

.color-red {
  color: colorRed;
}

.color-white {
  color: colorWhite;
}

.color-yellow {
  color: colorYellow;
}

.color-green {
  color: colorGreen;
}

.color-creamCan {
  color: colorCreamCan;
}

.color-orange {
  color: colorOrange;
}

.color-olive {
  color: colorOlive;
}

.color-turquoise {
  color: colorTurquoise;
}

.color-dark40 {
  color: colorDark40;
}

.color-dark100 {
  color: colorDark100;
}

.color-grey50 {
  color: colorGrey50;
}

.color-grey100 {
  color: colorGrey100;
}

.color-pink {
  color: colorPink100;
}

.color-colorPink100 {
  color: colorPink100;
}

.color-sky100 {
  color: sky100;
}

.color-purple {
  color: colorPurple100;
}

.color-inherit {
  color: inherit;
}

/* DISPLAY */

.display-inline-block {
  display: inline-block;
}

.display-inline {
  display: inline;
}

/* HEADINGS */

.h1 {
  font-size: 40px;
  line-height: 44px;
  font-family: 'Aeroport';
  font-weight: bold;
}

.h2 {
  font-size: 20px;
  line-height: 24px;
  font-family: 'Aeroport';
  font-weight: bold;
}

.h3 {
  font-size: 17px;
  line-height: 24px;
  font-family: 'Aeroport';
  font-weight: bold;
}

.h4 {
  font-size: 14px;
  line-height: 24px;
}

.h5 {
  font-size: 12px;
  line-height: 16px;
}

.h6 {
  font-size: 10px;
  line-height: 12px;
}

/* SIZES */

.size-md {
  font-size: 15px;
  line-height: 24px;
}

.size-sm {
  font-size: 14px;
  line-height: 16px;
}

.size-xs {
  font-size: 12px;
  line-height: 16px;
}

.size-inherit {
  font-size: inherit;
  line-height: inherit;
}

/* TRANSFORMS */

.transform-uppercase {
  text-transform: uppercase;
}

.transform-capitalize {
  text-transform: capitalize;
}

.transform-lineTrough {
  text-decoration: line-through;
}

.transform-capitalizeFirstLetter::first-letter {
  text-transform: uppercase;
}

/* WEIGHT */

.weight-300 {
  font-family: 'Aeroport';
  font-weight: 200;
}

.weight-500 {
  font-family: 'Aeroport';
  font-weight: 500;
}

.weight-700 {
  font-family: 'Aeroport';
  font-weight: bold;
}

.weight-inherit {
  font-weight: inherit;
}

/* ERROR */

.type-error {
  composes: size-sm color-red;
}

@media (max-width: mobileMax) {
  div.root {
    font-size: 12px;
    line-height: 16px;
  }
}
