.drawer {
  @mixin drawer;
}

.drawerContainer {
  @mixin drawerContainer;
  justify-content: flex-start;
}

.preloader {
  margin: 40px auto;
}
