@value colorPink100 from '~Styles/vars/colors.css';

.root {
  position: relative;

  &.withGuideTourTooltip {
    background-color: white;
  }
}

.guideTourTooltip {
  position: absolute;
  top: -120px;
  left: 0;
  z-index: 1003;
}

.controls {
  margin: 12px 0 12px 20px;
  display: flex;
  align-items: center;
  gap: 12px;
}

.longTermDescription {
  color: colorPink100;
  padding: 8px 16px;
  display: flex;
  justify-content: center;
}

.link {
  text-decoration: underline;
}
