@value colorGrey10, colorGrey100, colorDark100, colorPink40 from '~Styles/vars/colors.css';

@define-mixin line {
  height: 2px;
  width: 14px;
  background-color: colorDark100;
  border-radius: 1px;
  position: absolute;
  top: 50%;
  left: 50%;
}

.addButton {
  justify-content: center;

  &:disabled {
    background-color: colorGrey10;
    color: colorGrey100;
  }
}

.limitWarning {
  margin-top: 16px;
  border-radius: 16px;
  background: colorPink40;
  padding: 12px 16px;
}

.drawerContainer {
  .drawerBackdrop {
    z-index: 1100;
  }

  .drawerWrapper {
    z-index: 1101;
    background-color: white;

    .root {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .formContainer {
        display: flex;
        flex-direction: column;
        flex: 1;
        overflow: auto;
        .titleWrap {
          margin-bottom: 32px;
        }

        .addonType {
          margin-top: 8px;
          margin-bottom: 32px;
        }

        .addonDescription {
          margin-top: 8px;
        }

        .addonPrice {
          margin-bottom: 16px;
          color: colorGrey100;
        }

        .addonCounter {
          background-color: colorGrey10;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-bottom: 8px;
          border-radius: 8px;
          min-height: 200px;
          flex: 1;

          .addonCounterButtonContainer {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 24px;
            text-align: center;
            user-select: none;
            .addonCounterButton {
              background-color: white;
              border-radius: 10px;
              min-height: 24px;
              min-width: 24px;
              padding: 12px 16px;
              cursor: pointer;

              &.disabled {
                opacity: 0.5;
                cursor: default;
              }

              &.buttonMinus {
                position: relative;
                &::before {
                  content: '';
                  display: block;
                  @mixin line;
                  transform: translate3d(-50%, -50%, 0);
                }
              }
              &.buttonPlus {
                position: relative;
                &::before {
                  content: '';
                  display: block;
                  @mixin line;
                  transform: translate3d(-50%, -50%, 0);
                }

                &::after {
                  content: '';
                  display: block;
                  @mixin line;
                  transform: translate3d(-50%, -50%, 0) rotate(90deg);
                }
              }
            }

            .addonCounterValue {
              color: colorDark100;
              min-width: 44px;
            }
          }
        }
        .addonPriceContainer {
          background-color: colorGrey10;
          text-align: center;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          padding: 16px;
          border-radius: 8px;
        }
      }

      .buttonContainer {
        width: 100%;
        display: flex;
        flex-direction: column;
        text-align: center;
        gap: 16px;
        margin-top: 16px;
        .buttonText {
          width: 100%;
          text-align: center;
        }
      }
    }
  }
}
