@value colorGrey100 from '~Styles/vars/colors.css';

.root {
  display: block;
}

.medias {
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
  max-width: 457px;
}

.date {
  color: colorGrey100;
}

.footer {
  &:not(:empty) {
    margin-top: 12px;
  }
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.mediaCarousel {
  position: fixed;
  z-index: 1;
}

.carousel {
  size: 100vw 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.9);
  z-index: 9999;
  @mixin center;
}

.carouselCloseBtn {
  position: absolute;
  height: 60px;
  width: 60px;
  color: white;
  top: 26px;
  right: 42px;
  @mixin center;
  cursor: pointer;
}
