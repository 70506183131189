@value colorGrey100, colorError100 from '~Styles/vars/colors.css';

.drawer {
  background-color: white;
  padding-top: 8px;

  .contentRoot {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: max-content 1fr max-content;
    height: calc(100vh - 64px);
    margin: 0 -16px;

    .content {

      .shopifyAuthInstructionImg {
        max-width: 100%;
      }

      .textWrapper {
        padding: 8px 16px;
      }

      .textWrapperWithoutVerticalPadding {
        padding: 0 16px;
      }

      .textWrapperWithoutHorizontalPadding {
        padding: 8px 0;
      }

      .errorText {
        color: colorError100;
      }
    }

    .buttonWrapper {
      box-sizing: border-box;
      position: relative;
      width: 100%;

      .button {
        box-sizing: border-box;
        width: 100%;
      }

      .clicker {
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
        opacity: 0;
        cursor: pointer;
      }
    }

    .input {
      height: 72px;
      padding-top: 24px;
    }

    .shopifyStoreUrlExampleText {
      color: colorGrey100;
    }

    .spacer16 {
      display: block;
      height: 16px;
      font-size: 0;
      line-height: 0;
    }

    .mb16 {
      margin-bottom: 16px;
    }
  }
}
