@value colorGrey50, colorPink60 from '~Styles/vars/colors.css';

.campaignActions {
  display: flex;
  flex-shrink: 0;
  gap: 8px;
}

.notification {
  display: flex;
  border: 1px solid colorGrey50;
  border-radius: 8px;
  gap: 8px;
  align-items: center;
  padding: 8px 16px;
  box-sizing: border-box;
}

.inviteMenu {
  padding: 0;

  .inviteMenuGroup {
    width: auto;
    min-width: 240px;
  }

  .inviteMenuDropdownItem {
    width: 100%;
  }
}

.resumePlanTooltipLink {
  color: colorPink60;
  margin-top: 8px;
}
