.switch {
  margin: 0 auto 20px;
  display: flex;
  justify-content: center;
}

.hidden {
  display: none;
}

.disabled {
  opacity: 0.5;
  pointer-events: none;

  &.switch {
    filter: blur(1px);
  }
}
