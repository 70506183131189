@value colorGrey50 from '~Styles/vars/colors.css';

.spacer {
  flex: 1;
}

.devider {
  size: 100% 1px;
  background: colorGrey50;
}
