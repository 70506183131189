@value colorGrey50,colorPurple80, colorGrey100, colorDark100,colorGrey10, colorSuccessful100, colorWhite from '~Styles/vars/colors.css';

.root {
  height: 48px;
  position: relative;
  padding: 12px 16px;
  border-radius: 8px;
  box-sizing: border-box;
  border: 1px solid transparent;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  &.bordered {
    border-color: colorGrey50;
  }
  &.dirty {
    border: 1px solid colorGrey100;
    background-color: colorWhite;
  }
  &:hover {
    background-color: colorGrey10;
    border: 1px solid colorGrey100;
  }
  &.dirty:hover {
    border: 1px solid colorGrey100;
  }

  &.disabled {
    cursor: default;
  }
}

.open {
  background-color: colorGrey10;
  border: 1px solid colorGrey100;
}

.container {
  position: absolute;
  top: calc(100% + 8px);
  box-sizing: border-box;
  padding: 8px;
  border-radius: 8px;
  border: 1px solid colorGrey50;
  display: flex;
  flex-direction: column;
  z-index: 50;
  max-height: 368px;
  overflow-y: auto;
  min-width: 260px;
  cursor: unset;
  background-color: colorWhite;
}

.left {
  left: 0;
}

.right {
  right: 0;
}

.content {
  overflow: auto;
}

.varitantTitle {
  padding: 16px 8px;
}

.roundItem {
  padding: 16px 12px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 8px;
  gap: 8px;
  &:hover {
    background-color: colorGrey10;
    cursor: pointer;
  }
}

.title {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.label {
  padding: 8px 12px;
  cursor: unset;
  &.centered {
    text-align: center;
  }
}

.round {
  width: 18px;
  height: 18px;
  border-radius: 50%;
  border: 2px solid colorGrey100;
  position: relative;
  box-sizing: border-box;
  flex-shrink: 0;
  &.checked {
    border: 2px solid colorDark100;
  }
}

.radioCheckedItem {
  border-radius: 50%;
  width: 12px;
  height: 12px;
  background-color: colorDark100;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.accentSelect {
  border: 1px solid colorSuccessful100 !important;
}

.emptyLabel {
  padding: 8px 12px;
}

.clearBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  width: 100%;
}

.img {
  size: 24px;
}

.itemContent {
  display: flex;
  gap: 8px;
}
