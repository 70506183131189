@value colorSky40 from '~Styles/vars/colors.css';

.root {
  border-radius: 16px;
  background: colorSky40;
  display: flex;
  padding: 16px;
  align-items: center;
  gap: 20px;
  margin-bottom: 32px;
}

.imgWrap {
  position: relative;
}

.plus {
  top: 8px;
  right: -12px;
  position: absolute;
}

.spacer {
  flex: 1;
}
