@value headerHeight, subheaderHeight from '~Styles/vars/size.css';

.rootWrapper {
  height: 100%;
}

.root {
  flex-grow: 1;
  height: 100%;
  min-height: calc(100vh - headerHeight);
  box-sizing: border-box;
}

:global .subheader {
  :local .showHeader {
    padding-top: calc(headerHeight + 40px);
  }
}

.header {
  width: 100%;
  top: 0;
  z-index: 55;
  display: block;
  position: fixed;
}

:global .subheader {
  :local .header {
    top: 40px;
  }
}

:global .isMobile.subheader {
  :local .header {
    top: 40px;
  }
}
