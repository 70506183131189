@value colorGrey100 from '~Styles/vars/colors.css';

.root {
  display: flex;
  justify-content: center;
  color: colorGrey100;
  margin-top: 130px;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  text-align: center;
}

.newCampaign {
  margin-top: 16px;
}
