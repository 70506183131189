@value colorError100, colorError50, colorSky40 from '~Styles/vars/colors.css';

.imageWrap {
  display: flex;
  gap: 20px;
}

.image {
  size: 120px;
  flex-shrink: 0;
  overflow: hidden;
  border-radius: 8px;
  background-size: cover;
  background-position: center;
  background-image: url('~files/images/brief/product_stub.jpeg');
}

.uploaderWrap {
  position: relative;
  display: inline-block;

  .uploadError {
    background: colorError50;
    border-color: colorError100;
  }
}

.uploader {
  position: absolute;
  top: 0;
  left: 0;
  size: 100%;
}

.errorText {
  color: colorError100;
  margin-top: 8px;
}

.warning {
  background: colorSky40;
  padding: 8px;
  display: flex;
  gap: 8px;
  border-radius: 8px;
  color: unset;
  margin-top: 8px;
}
