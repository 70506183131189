@value colorGrey10 from '~Styles/vars/colors.css';

.root {
  position: sticky;
  top: 0;
  z-index: 1;
  display: flex;
  justify-content: space-between;
  height: 60px;
  background: colorGrey10;
}

.tabs {
  height: 50px;
  margin: 10px auto 0;
}
