.root {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 8px 16px;
  flex: 1;

  .textContainer {
    display: flex;
    flex-direction: column;
  }

  .header {
    display: flex;
    flex: 1;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    flex-wrap: nowrap;

    i {
      margin-left: 16px;
    }
  }

  .subtitle {
    flex: 1;
  }

  .rightComponent {
    margin-left: 16px;
  }
}