@value colorConcrete from '~Styles/vars/colors.css';

.root {
  position: relative;
}

.form {
  margin-top: 20px;
  display: flex;
}

.organizations {
  margin-top: 20px;
  position: relative;
}

.excludeWrap {
  margin-top: 20px;
  display: flex;
  align-items: center;
}

.status {
  width: 170px;
  margin-right: 20px;
  flex-shrink: 0;
}

.campaigns {
  flex-grow: 1;
  position: relative;
}

.exclusions {
  margin-top: 8px;
  display: flex;
  flex-wrap: wrap;
}

.excludeTitle {
  margin-left: 12px;
}

.exlusion {
  background: colorConcrete;
  border-radius: 4px;
  padding: 8px;
  display: flex;
  margin-top: 8px;

  &:not(:last-child) {
    margin-right: 12px;
  }

  .icon {
    margin-left: 8px;
  }
}

.allCampaigns {
  padding: 18px 12px;
  border-bottom: colorConcrete;
  margin-bottom: 8px;
}

.showStateControl {
  cursor: pointer;
}

.clearAll {
  margin-top: 8px;
  height: 32px;
}

.status {
  .statuses {
    height: 40px;
    border-radius: 4px;
  }
}
