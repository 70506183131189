@value colorSand40 from '~Styles/vars/colors.css';

.root {
  background-color: colorSand40;
  opacity: .9;
  height: 100vh;
  width: 100vw;
  position: fixed;
  left: 0;
  top: 0;
  cursor: default;
}
