.root {
  display: flex;
  flex: 1;
  background-color: white;
  border-radius: 8px;
  width: 1148px;
  max-width: 1148px;
  padding: 0 16px;

  .outreach {
    width: 100%;
  }
  .invite {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 24px;
  }
}