.root {
  display: flex;
  align-items: center;
  padding: 20px 0;
}

.tabs {
  width: 100%;
}

.additional {
  flex-grow: 1;
  display: flex;
  align-items: center;
}
