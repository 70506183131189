.root {
  display: block;
}

.tooltipWrap {
  overflow: hidden;
}

.overflowBlock {
  overflow: hidden;
}