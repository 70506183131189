@value colorBase, colorGrey50, colorGrey100, colorError100, colorError50, colorRed from '~Styles/vars/colors.css';

.root {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

.buttonTooltip {
  max-width: 304px;
  top: -155px !important;
}

.tooltipContainer {
  flex: 1;
  justify-content: flex-start;
  text-align: start;
}

.control {
  flex: 1;
  padding: 20px;
  border: 1px solid colorGrey50;
  border-radius: 8px;
  position: relative;
  @mixin center;
  flex-direction: column;

  &.active {
    border: 1px solid colorBase;
    box-shadow: 0 0 0 1px colorBase;
    z-index: 1;
  }

  &:not(.active) {
    cursor: pointer;
  }

  &.disabled {
    color: colorGrey50;
    cursor: default;
  }

  &.error {
    border-color: colorError100;
    background: colorError50;
  }
}

.radius-bottom {
  gap: 0;

  .control {
    border-radius: 0;

    &:first-child {
      border-radius: 0 0 0 8px;
    }

    &:last-child {
      border-radius: 0 0 8px;
    }
  }
}

.icon {
  margin-bottom: 8px;
}

.tooltip {
  top: 8px;
  right: 8px;
  position: absolute;
  text-align: start;
}

.active.color-red {
  border: 1px solid colorRed;
  box-shadow: 0 0 0 1px colorRed;
}

.tagWrap {
  position: absolute;
  top: 12px;
  right: 12px;
  cursor: pointer;
  &.bottom {
    position: static;
    margin-top: auto;
    margin-bottom: 0;
  }
}

.tag {
  cursor: pointer;
}

.subtitle {
  color: colorGrey100;
}

.tooltipContainer {
  position: relative;
  .closeIcon {
    width: 24px;
    height: 24px;
    position: absolute;
    right: 0;
    top: 0;
    cursor: pointer;
  }
}

.errorMsg {
  margin-top: 8px;
  color: colorError100;
}
