@value colorDark100, colorGrey10, colorGrey50, colorGrey100, colorPink100, colorWhite, colorSuccessful100 from '~Styles/vars/colors.css';

button {
  all: unset;
  cursor: pointer;
}

button:active {
  outline: none;
}

.root {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 16px;
  padding: 24px;
  transition: 0.3s all;
  background-color: colorWhite;
  color: colorDark100;
  border: 1px solid colorGrey50;
  box-sizing: border-box;

  &:disabled {
    cursor: not-allowed;
    pointer-events: none;

    .text {
      cursor: not-allowed;
    }
  }

  &.black {
    background-color: colorDark100;
    color: colorWhite;
    border: 1px solid colorDark100;

    &:hover {
      &:not(:disabled) {
        background-color: colorPink100;
        color: colorWhite;
        border: 1px solid colorPink100;
      }
    }

    &:disabled {
      color: colorWhite;
      background-color: colorGrey50;
      border-color: colorGrey50;
    }
  }

  &.white {
    &:disabled {
      color: colorGrey50;
    }
  }

  .counter {
    color: colorGrey100;
  }

  .text {
    text-align: center;
    cursor: pointer;
    white-space: nowrap;
  }

  &.green {
    color: colorWhite;
    background-color: colorSuccessful100;
    border-color: colorSuccessful100;

    &:hover(:not(:disabled)) {
      background-color: colorSuccessful100;
    }
  }

  &.fluid {
    flex: 1;
    width: 100%;
  }

  &.active {
    background-color: colorGrey10;
  }

  &:not(:disabled) {
    cursor: pointer;
  }

  &:hover {
    &:not(:disabled) {
      background-color: colorGrey10;
    }
  }
  :not(:first-child) {
    margin-left: 12px;
  }
}
