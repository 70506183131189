@value colorGrey100, colorSky100, colorPink100 from '~Styles/vars/colors.css';

.root {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 8px;

  .checkIcon {
    &.default {
      color: colorGrey100;
    }
    &.sky {
      color: colorSky100;
    }
    &.pink {
      color: colorPink100;
    }
  }

  .item {
    display: grid;
    grid-template-columns: 25px 1fr;
    grid-gap: 8px;
  }

  .tooltipInfo {
    display: inline-flex;
    margin-left: 4px;
    position: relative;
    bottom: -2px;
  }
}
