@value colorBase, colorYellow, colorWetAsphalt from '~Styles/vars/colors.css';

.root {
  display: block;

  &:not(.isHidden):not(.loading) {
    &:hover {
      .back {
        opacity: 0.5;
      }
    }
  }

}

.preloader {
  size: 24px;
  top: 20px;
  right: 20px;
  position: absolute;
}