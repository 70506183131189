@value colorGrey50 from '~Styles/vars/colors.css';

.root {
  position: relative;
  .title {
    text-transform: uppercase;
  }
  .helpBlock {
    width: calc(100% + 40px);
    margin: 20px 0 -20px -20px;
    padding: 20px;
    border-top: 1px solid colorGrey50
  }

  .addressText {
    word-break: break-word;
  }
}

.reportButton {
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
  .tooltip {
    max-width: 350px;
  }
}

.connectButton {
  margin-top: 20px;
  width: calc(100% - 40px);
  display: flex;
  justify-content: center;
}
