.drawer {
  background-color: white;
  padding-top: 8px;

  .textPreset {
    margin-bottom: 16px;
  }

  .textPresetDescription {
    margin-top: 16px;
  }

  .contentRoot {
    display: flex;
    flex-direction: column;
    height: calc(100vh - 64px);
    gap: 8px;
    justify-content: space-between;
  }

  .featureContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    margin-bottom: 8px;
  }

  .applyImg {
    width: 100%;
    object-fit: contain;
  }

  .textContent {
    padding: 16px;
  }

  .submitButtonWrapper {
    box-sizing: border-box;
    position: relative;
    width: 100%;
    margin-top: 32px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 16px;

    .submitButton {
      box-sizing: border-box;
      width: 100%;
    }
  }


}
