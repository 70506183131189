@value mobileMax from '~Styles/vars/query.css';
@value colorGrey100, colorError100, colorPurple100, colorSuccess100, colorWhite, colorDark, colorNewPink from '~Styles/vars/colors.css';

.text {
  font-family: 'Aeroport';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  white-space: pre-wrap;

  u {
    text-decoration: line-through;
  }

  &.h1 {
    font-size: 20px;
  }

  &.h2 {
    font-size: 17px;
  }

  &.h3 {
    font-size: 16px;
    line-height: 24px;
    font-weight: 700;
  }

  &.d1 {
    font-size: 40px;
    line-height: 44px;
  }

  &.d2 {
    font-weight: 400;
    font-size: 24px;
    line-height: 32px;
  }

  &.md {
    font-weight: 400;
    font-size: 15px;
    line-height: 24px;
  }

  &.s {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
  }

  &.sm {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
  }

  &.label {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
  }

  &.labelSm {
    font-weight: 400;
    font-size: 10px;
    line-height: 14px;
  }

  &.pointer {
    cursor: pointer;
  }

  &.button {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
  }

  &.grey {
    color: colorGrey100;
  }

  &.red {
    color: colorError100;
  }

  &.purple {
    color: colorPurple100;
  }

  &.green {
    color: colorSuccess100;
  }

  &.white {
    color: colorWhite;
  }

  &.dark {
    color: colorDark;
  }

  &.pink {
    color: colorNewPink;
  }

  b,
  &.bold {
    font-weight: 700;
  }
}

@media (max-width: mobileMax) {
  .text {
    &.d1 {
      font-size: 20px;
      line-height: 24px;
    }

    &.h1 {
      font-weight: 400;
      font-size: 15px;
      line-height: 24px;
    }
  }
}
