@value colorGrey10, colorGrey100 from '~Styles/vars/colors.css';

.drawerContainer {
  display: flex;
  height: 100%;
  width: 516px;
  flex-direction: column;
  justify-content: space-between;
}

.root {
  height: 100%;
  overflow: auto;
}

.title {
  margin-bottom: 8px;
}

.subtitle {
  color: colorGrey100;
  margin: 16px 0;
}

.campaign {
  margin: 8px 0;
  padding: 8px 16px 8px 0;
  border-radius: 16px;
  background: colorGrey10;
  display: block;
}

.campaignHeader {
  align-items: center;
  display: flex;
  flex: 1;

  .headerText {
    margin-left: 8px;
  }
}
