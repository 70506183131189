@value colorGrey50, colorPink60 from '~Styles/vars/colors.css';

.root {
  display: flex;
  align-items: center;
}

.divider {
  size: 1px 24px;
  background: colorGrey50;
  margin-left: 24px;
  margin-right: 24px;
}

.tabs {
  display: flex;
  align-items: center;
  gap: 8px;
}

.resumePlanTooltipLink {
  color: colorPink60;
  margin-top: 8px;
}
