@value (colorWhite, colorGrey50) from '~Styles/vars/colors.css';
@value headerHeight from '~Styles/vars/size.css';
@value desktopMinWidth from '~Styles/vars/query.css';

.root {
  size: 100% headerHeight;
  @mixin vertical-center;
  background: colorWhite;
  border-bottom: 1px solid colorGrey50;
  z-index: 2;
  flex-shrink: 0;
}

.item {
  width: 400px;
  @mixin vertical-center;
}

.title {
  flex-grow: 1;
  text-align: center;
}

.action {
  justify-content: flex-end;
}

.back {
  margin-left: 26px;
  @mixin vertical-center;
}

.headerItem {
  @mixin vertical-center;

  &:last-of-type {
    justify-content: flex-end;
  }

  &:first-of-type {
    justify-content: flex-start !important;
  }
}

.container {
  width: 100%;
  margin: 0 auto;
  @mixin vertical-center;
  padding-left: 25px;
  padding-right: 25px;
  box-sizing: border-box;
  justify-content: space-between;
}

.controls {
  @mixin vertical-center;
}

.leftAlign {
  justify-content: flex-end;
}

.button {
  margin-left: 20px;
}

.badge {
  margin-left: 20px;
}

.spacer {
  flex: 1;
}
