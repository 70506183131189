@value colorError100, colorOcean80, colorGrey100, colorPink100 from '~Styles/vars/colors.css';

.root {
  width: 100%;
  display: flex;
  padding: 0 16px;
  box-sizing: border-box;
}

.amountWrap {
  display: flex;
  align-items: center;
  gap: 4px;
}

.errorStatus {
  color: colorError100 !important;
}

.isIncrease {
  color: colorOcean80;
}

.multupleItem {
  padding-top: 12px;
  padding-bottom: 12px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.accountTypeLabel {
  color: colorGrey100;
}

.link {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.link:hover {
  color: colorPink100;
}

.canceledStatus {
  .amount {
    text-decoration: line-through;
  }
}

.icon {
  margin-top: 4px;
}
