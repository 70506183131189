@value colorGrey50 from '~Styles/vars/colors.css';

.root {
  size: 128px;
  position: relative;
  overflow: hidden;
  box-sizing: border-box;
  border-radius: 4px;
  border: 1px solid colorGrey50;

  &:hover {
    .iconDelete {
      opacity: 1;
    }
  }
}

.thumbnail {
  @mixin center;

  size: 100%;
  background-size: cover;
  background-position: center;
  cursor: pointer;
}

.thumbnailFile {
  @mixin center;

  flex-direction: column;

  img {
    width: 48px;
    margin-bottom: 10px;
  }
}

.thumbnailCaption {
  padding: 0 10px;
  word-wrap: break-word;
}

.thumbnailIcon {
  @mixin center;

  size: 48px;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 50px;

  img {
    width: 20px;

    /* Move triangle to the right to make it visually centered */
    margin-left: 2px;
  }
}

.iconDelete {
  size: 24px;
  position: absolute;
  top: 0;
  right: 0;
  opacity: 0;
  transition: opacity 0.2s ease-in;
  cursor: pointer;
  border-radius: 4px;
}

.processing {
  background-color: rgba(0, 0, 0, 0.5);
}

.preloader {
  size: 24px;
}

.preloaderWrap {
  position: absolute;
}

:global .isMobile {
  :local .iconDelete {
    opacity: 1;
  }
}