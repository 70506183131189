.drawer {
  width: 640px;
  background-color: white;
}

.openChats {
  position: absolute;
  top: 76px;
  left: -76px;
  height: 60px;
  width: 60px;
  border-radius: 16px;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
