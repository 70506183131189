.root {
  position: relative;
}

.edit {
  size: 46px;
  bottom: 4px;
  right: 4px;
  @mixin center;
  position: absolute;
  border-radius: 12px;
  background: rgba(256, 256, 256, 0.85);
}

.oldSize {
  size: 80px;
  background-color: black;
  background-position: center;
  border-radius: 8px;
  @mixin center;

  img {
    width: 100%;
  }
}

.upload {
  position: relative;
}

.uploader {
  position: absolute;
  size: 46px;
  bottom: 4px;
  right: 4px;
}

.closeTooltip {
  cursor: pointer;
  position: absolute;
  top: 12px;
  right: 16px;
}
