@value colorBase, colorYellow, colorWetAsphalt from '~Styles/vars/colors.css';

.root {
  display: block;

  &:not(.isHidden):not(.loading) {
    &:hover {
      .back {
        opacity: 0.5;
      }
    }
  }

}

.addedToMediaplan {
  .back  {
    opacity: 0.5;
  }
}

.addition {
  position: absolute;
  bottom: 20px;
  left: 20px;
  display: flex;
}

.preloader {
  size: 24px;
  top: 20px;
  right: 20px;
  position: absolute;
}

.viewed {
  .medias {
    opacity: 0.5;
  }
}

.barter {
  size: 24px;
  @mixin center;
  border-radius: 4px;
  background: colorYellow;
}