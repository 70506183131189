@value colorSand40, colorWhite from '~Styles/vars/colors.css';

.mobileContainer {
  position: fixed;
  z-index: 999;
  height: 100vh;
  width: 100vw;
  display: none;
  touch-action: none;
  background-color: colorSand40;

  .mobileContent {
    display: flex;
    flex-direction: column;
    max-width: 375px;
    margin: 0 auto;
  }

  .imgWrapper {
    display: flex;
    flex-shrink: 0;

    img {
      height: 100%;
      width: 100%;
      object-fit: contain;
    }
  }

  .textContent {
    padding: 16px;
    display: flex;
    flex-direction: column;
    margin-top: 14px;
  }

  .title {
    margin-bottom: 16px;
  }

  .text {
    font-size: 15px;
    line-height: 24px;
  }

  .mobileBtn {
    margin-top: 24px;
    justify-content: center;
    padding: 24px;
    border-radius: 16px;
  }
}

:global .isMobile {
  :local .mobileContainer {
    display: flex;
  }
}

@media (max-width: 990px) {
  .mobileContainer {
    display: flex;
  }
}
