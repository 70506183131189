.title {
  margin-bottom: 20px;
}

.item:not(:last-child) {
  margin-bottom: 20px;
}

.header {
  display: flex;
}

.status {
  margin-left: 12px;
}

.activeDescr {
  margin-top: -8px;
  margin-bottom: 20px;
}

.newDealDescr {
  margin-bottom: 20px;
}

.adsTooltip {
  margin-left: 4px;
}

.adsTooltipContainer {
  max-width: 260px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  padding: 12px 16px;
  pointer-events: auto !important;
  &:hover {
    visibility: visible !important;
    opacity: 1 !important;
  }
}

.adsGuideLink {
  display: block;
}
