@value colorGrey10 from '~Styles/vars/colors.css';

.root {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: max-content 1fr;
  background: colorGrey10;
  position: relative;
  text-align: center;
  width: auto;
  min-height: 100vh !important;

  .content {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;

    .outcomeComponentRoot {
      width: 343px;
      max-width: 100vw;
    }

    .titleText {
      text-transform: capitalize;
    }

  }
}
