@value colorPink100, colorWhite from '~Styles/vars/colors.css';

.root {
  .bell {
    padding: 0;
  }

  .btnWrap {
    display: block;
    width: 100%;
    padding-left: 16px;
    padding-right: 16px;
    box-sizing: border-box;
  }

  .btn {
    margin-top: 8px;
    margin-bottom: 8px;
    width: 100%;
    box-sizing: border-box;
    justify-content: center;
  }

  .dropdownGroup {
    width: 480px;
    top: calc(100% + 16px);
    right: -48px !important;
  }
}

.value {
  overflow: unset !important;
}

.emptyTitle {
  padding-top: 12px;
  padding-left: 16px;
  padding-bottom: 4px;
}

.content {
  display: flex;
  flex-direction: column;
}

.iconWrap {
  position: relative;
}

.unreadMark {
  position: absolute;
  top: -6px;
  right: -6px;
}
