.root {
  display: flex;
  gap: 8px;
  position: relative;
  margin-bottom: 32px;
}

.input {
  width: 380px;
}

.clearParams {
  top: -40px;
  right: 0;
  position: absolute;
}

.filter {
  width: 200px;
}
