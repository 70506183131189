@value colorGrey100 from '~Styles/vars/colors.css';

.root {
  position: relative;
  width: 300px;
}

.active {
  border-color: colorGrey100 !important;
}
