.drawer {
  background-color: white;
}

.drawerContainer {
  display: flex;
  height: 100%;
  width: 516px;
  flex-direction: column;
  justify-content: center;

  .drawerTitleText {
    margin-bottom: 16px;
  }
}

.inputWrap {
  flex: 1;
}

.input {
  min-height: 144px;
}

.descr {
  margin-bottom: 16px;
}
