@value colorSuccess100, colorError100, colorSun60, colorWhite, colorGrey100, colorPink100, colorPurple100 from '~Styles/vars/colors.css';

.green {
  color: colorSuccess100;
}

.red {
  color: colorError100;
}

.sun {
  color: colorSun60;
}

.white {
  color: colorWhite;
}

.grey {
  color: colorGrey100;
}

.pink {
  color: colorPink100;
}

.purple {
  color: colorPurple100;
}

i {
  font-family: 'insense' !important;
  font-size: 24px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  &.withPointer {
    cursor: pointer;
  }
}

.icon-15-seconds-back:before { content: "\ea01"; }
.icon-15-seconds-forward:before { content: "\ea02"; }
.icon-Accessories:before { content: "\ea03"; }
.icon-Add-plus-circle:before { content: "\ea04"; }
.icon-Add-plus:before { content: "\ea05"; }
.icon-Affiliate:before { content: "\ea06"; }
.icon-Analytics:before { content: "\ea07"; }
.icon-Arrow-back:before { content: "\ea08"; }
.icon-Arrow-big-down:before { content: "\ea09"; }
.icon-Arrow-big-left:before { content: "\ea0a"; }
.icon-Arrow-big-right:before { content: "\ea0b"; }
.icon-Arrow-big-up:before { content: "\ea0c"; }
.icon-Arrow-down-right:before { content: "\ea0d"; }
.icon-Arrow-small-down:before { content: "\ea0e"; }
.icon-Arrow-small-left:before { content: "\ea0f"; }
.icon-Arrow-small-right:before { content: "\ea10"; }
.icon-Arrow-small-up:before { content: "\ea11"; }
.icon-Arrow-up-right:before { content: "\ea12"; }
.icon-Arrows-left-right:before { content: "\ea13"; }
.icon-Arrows-up-down:before { content: "\ea14"; }
.icon-Assets:before { content: "\ea15"; }
.icon-Attach-link:before { content: "\ea16"; }
.icon-Attachment:before { content: "\ea17"; }
.icon-Audio-sound-disabled:before { content: "\ea18"; }
.icon-Audio-sound:before { content: "\ea19"; }
.icon-Background:before { content: "\ea1a"; }
.icon-Badge-UGC-premium-stroke:before { content: "\ea1b"; }
.icon-Badge-responsive-stroke:before { content: "\ea1c"; }
.icon-Badge-rising-stroke:before { content: "\ea1d"; }
.icon-Badge-top-ratad-stroke:before { content: "\ea1e"; }
.icon-Bank-account-card:before { content: "\ea1f"; }
.icon-Beauty:before { content: "\ea20"; }
.icon-Bell-notifications-disabled:before { content: "\ea21"; }
.icon-Bell-notifications:before { content: "\ea22"; }
.icon-Blocklist:before { content: "\ea23"; }
.icon-Bold:before { content: "\ea24"; }
.icon-Brands:before { content: "\ea25"; }
.icon-Brief:before { content: "\ea26"; }
.icon-Bullited-list:before { content: "\ea27"; }
.icon-Calendar-schedule:before { content: "\ea28"; }
.icon-Camera-video:before { content: "\ea29"; }
.icon-Campaigns:before { content: "\ea2a"; }
.icon-Cards-specials:before { content: "\ea2b"; }
.icon-Chat:before { content: "\ea2c"; }
.icon-Check-circle:before { content: "\ea2d"; }
.icon-Check-double:before { content: "\ea2e"; }
.icon-Check:before { content: "\ea2f"; }
.icon-Checkmark:before { content: "\ea30"; }
.icon-Close-circle:before { content: "\ea31"; }
.icon-Close-small:before { content: "\ea32"; }
.icon-Commission-percent:before { content: "\ea33"; }
.icon-Compass-explore:before { content: "\ea34"; }
.icon-Computer:before { content: "\ea35"; }
.icon-Copy:before { content: "\ea36"; }
.icon-Delivery-disabled:before { content: "\ea37"; }
.icon-Delivery:before { content: "\ea38"; }
.icon-Document:before { content: "\ea39"; }
.icon-Dots-menu:before { content: "\ea3a"; }
.icon-Download-upload:before { content: "\ea3b"; }
.icon-Edit:before { content: "\ea3c"; }
.icon-Effects-disabled:before { content: "\ea3d"; }
.icon-Effects:before { content: "\ea3e"; }
.icon-Email-at:before { content: "\ea3f"; }
.icon-Eye-hidden:before { content: "\ea40"; }
.icon-Eye:before { content: "\ea41"; }
.icon-Fashion:before { content: "\ea42"; }
.icon-Filter:before { content: "\ea43"; }
.icon-Flag:before { content: "\ea44"; }
.icon-Food:before { content: "\ea45"; }
.icon-Format-16-9:before { content: "\ea46"; }
.icon-Format-4-5:before { content: "\ea47"; }
.icon-Format-9-16:before { content: "\ea48"; }
.icon-Gift:before { content: "\ea49"; }
.icon-Grid-view:before { content: "\ea4a"; }
.icon-Group-users:before { content: "\ea4b"; }
.icon-Hand-Tap-Click:before { content: "\ea4c"; }
.icon-Hand-select:before { content: "\ea4d"; }
.icon-Hd-resolution:before { content: "\ea4e"; }
.icon-Heart:before { content: "\ea4f"; }
.icon-Home-house:before { content: "\ea50"; }
.icon-Info:before { content: "\ea51"; }
.icon-Invoice:before { content: "\ea52"; }
.icon-Italic:before { content: "\ea53"; }
.icon-Key-password:before { content: "\ea54"; }
.icon-Kids-children:before { content: "\ea55"; }
.icon-Lamp-spark:before { content: "\ea56"; }
.icon-Learning:before { content: "\ea57"; }
.icon-License:before { content: "\ea58"; }
.icon-Lifestyle-game:before { content: "\ea59"; }
.icon-List-view:before { content: "\ea5a"; }
.icon-Location:before { content: "\ea5b"; }
.icon-Lock:before { content: "\ea5c"; }
.icon-Log-out-exit:before { content: "\ea5d"; }
.icon-Mail:before { content: "\ea5e"; }
.icon-Messages-chat:before { content: "\ea5f"; }
.icon-Meta Whitelisted Ads:before { content: "\ea60"; }
.icon-Money:before { content: "\ea61"; }
.icon-Music-disabled:before { content: "\ea62"; }
.icon-Music:before { content: "\ea63"; }
.icon-No-ads:before { content: "\ea64"; }
.icon-No-internet:before { content: "\ea65"; }
.icon-Notes-filled:before { content: "\ea66"; }
.icon-Notes:before { content: "\ea67"; }
.icon-Offers:before { content: "\ea68"; }
.icon-Open-chat:before { content: "\ea69"; }
.icon-Orderd-list:before { content: "\ea6a"; }
.icon-Other:before { content: "\ea6b"; }
.icon-Paid-ads-connect:before { content: "\ea6c"; }
.icon-Paid-ads:before { content: "\ea6d"; }
.icon-Pause:before { content: "\ea6e"; }
.icon-Pen-draw:before { content: "\ea6f"; }
.icon-Pets-cat:before { content: "\ea70"; }
.icon-Phone:before { content: "\ea71"; }
.icon-Photo-disabled:before { content: "\ea72"; }
.icon-Photo:before { content: "\ea73"; }
.icon-Picture-image-stars:before { content: "\ea74"; }
.icon-Planet:before { content: "\ea75"; }
.icon-Platform-facebook:before { content: "\ea76"; }
.icon-Platform-instagram:before { content: "\ea77"; }
.icon-Platform-tiktok:before { content: "\ea78"; }
.icon-Platform-youtube:before { content: "\ea79"; }
.icon-Play:before { content: "\ea7a"; }
.icon-Player-browser:before { content: "\ea7b"; }
.icon-Posting-disabled:before { content: "\ea7c"; }
.icon-Posting:before { content: "\ea7d"; }
.icon-Product-box:before { content: "\ea7e"; }
.icon-Profile-message:before { content: "\ea7f"; }
.icon-Profile:before { content: "\ea80"; }
.icon-Purchase:before { content: "\ea81"; }
.icon-Question:before { content: "\ea82"; }
.icon-Refresh-rotate:before { content: "\ea83"; }
.icon-Reimbursement-cart:before { content: "\ea84"; }
.icon-Review:before { content: "\ea85"; }
.icon-Rocket:before { content: "\ea86"; }
.icon-Rotate-360:before { content: "\ea87"; }
.icon-Save-list-filled:before { content: "\ea88"; }
.icon-Save-list:before { content: "\ea89"; }
.icon-Science:before { content: "\ea8a"; }
.icon-Search-loop:before { content: "\ea8b"; }
.icon-Search:before { content: "\ea8c"; }
.icon-Seeding:before { content: "\ea8d"; }
.icon-Send:before { content: "\ea8e"; }
.icon-Settings:before { content: "\ea8f"; }
.icon-Share:before { content: "\ea90"; }
.icon-Single-user:before { content: "\ea91"; }
.icon-Sort:before { content: "\ea92"; }
.icon-Sparkle:before { content: "\ea93"; }
.icon-Spinner:before { content: "\ea94"; }
.icon-Sport-sneakers:before { content: "\ea95"; }
.icon-Star-shining:before { content: "\ea96"; }
.icon-Star:before { content: "\ea97"; }
.icon-Stars:before { content: "\ea98"; }
.icon-Strikethrough:before { content: "\ea99"; }
.icon-Supplements:before { content: "\ea9a"; }
.icon-Support-help:before { content: "\ea9b"; }
.icon-Support-sad:before { content: "\ea9c"; }
.icon-Thumbs-down-reject:before { content: "\ea9d"; }
.icon-Thumbs-up-accept:before { content: "\ea9e"; }
.icon-TikTok Spark ads:before { content: "\ea9f"; }
.icon-Time-clock:before { content: "\eaa0"; }
.icon-Timer:before { content: "\eaa1"; }
.icon-Top_up:before { content: "\eaa2"; }
.icon-Trash-delele:before { content: "\eaa3"; }
.icon-Travel-plane:before { content: "\eaa4"; }
.icon-Underline:before { content: "\eaa5"; }
.icon-Unread-Applications:before { content: "\eaa6"; }
.icon-Unread-Messages:before { content: "\eaa7"; }
.icon-Video-movies:before { content: "\eaa8"; }
.icon-Wallet:before { content: "\eaa9"; }
.icon-Warning-error:before { content: "\eaaa"; }
.icon-Withdraw-money:before { content: "\eaab"; }
.icon-Work:before { content: "\eaac"; }
.icon-flag-05:before { content: "\eaad"; }
.icon-graduation-hat-01:before { content: "\eaae"; }
.icon-graduation-hat-02:before { content: "\eaaf"; }
.icon-target-bullseye:before { content: "\eab0"; }
.icon-timer-hourglass:before { content: "\eab1"; }
.icon-timer-return:before { content: "\eab2"; }