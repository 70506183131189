@value colorGrayDog, colorWhite from '~Styles/vars/colors.css';

.root {
  padding-top: 20px;
  margin-bottom: 40px;
}

.title {
  margin-bottom: 32px;
}

.row {
  position: relative;
  display: flex;
  gap: 20px;
  margin-bottom: 32px;
  align-items: flex-end;
}

.field {
  flex: 1;
}

.cities {
  margin-bottom: 32px;
}

.option {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .optionName {
    font-size: 14px;
    line-height: 14px;
  }
}

.checkboxWrap {
  display: flex;

  &:not(:last-child) {
    margin-bottom: 32px;
  }
}

.checkbox {
  cursor: pointer;
  margin-right: 12px;
}

.bindedCreators {
  margin: 40px 0;
}

.bindedCreator {
  display: block;

  &:not(:first-child) {
    margin-top: 20px;
  }
}

.bindedCreatorType {
  position: absolute;
  bottom: -4px;
  right: -4px;
  background: colorWhite;
  border-radius: 50%;
  padding: 2px;
}

.birthdateWrap {
  flex: 1;
  position: relative;

  .birthdateInput {
    border-radius: 8px;
    height: 48px;
    width: unset;

    input {
      height: 48px;
      padding-top: 19px;
      padding-bottom: 19px;
    }
  }
}

.age {
  top: 43px;
  right: 19px;
  z-index: 4;
  position: absolute;
}

.fieldTitle {
  margin-bottom: 8px;
}
