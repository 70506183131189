@value colorGrey100, colorGrey10 from '~Styles/vars/colors.css';

.root {
  display: flex;
  gap: 16px;
  width: 100%;
  cursor: pointer;
}

.countries {
  color: colorGrey100;
}

.details {
  display: flex;
  flex-direction: row;
  gap: 16px;
  width: 100%;
  min-width: 240px;
  overflow: hidden;
}

.creatorName {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.badges {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
}

.badgeIcon {
  size: 16px;
}

.badge {
  padding: 0;
}

.registartionMark {
  margin-right: 4px;
}

.avatarWrap {
  position: relative;
  max-height: 48px;
}

.username {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.voteCountText,
.blockedUsername {
  color: colorGrey100;
}

.creatorData {
  display: grid;
  grid-template-columns: 3fr 1fr 1fr 1fr 1fr 1fr;
  grid-column-gap: 8px;
  width: 100%;
  margin-right: 8px;
}

.categories {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 8px;
}

.followers,
.er,
.views,
.actions {
  display: flex;
  align-items: center;
}

.actions {
  gap: 8px;
  cursor: default;
  justify-content: center;
}

.usernameContainer,
.profileInfo {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.vettedIcon {
  margin-left: 4px;
}

.profileData {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
}

.iconButton {
  cursor: pointer;
  padding: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;

  &:hover {
    background-color: colorGrey10;
  }
}