@value colorPurple100, colorPurple40, colorSuccessful100, colorPink100, colorGrey100, colorGrey10 from '~Styles/vars/colors.css';

.root {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 32px;
  position: relative;
}

.selectCheckbox {
  width: 169px !important;
  input {
    font-size: 15px;
    line-height: 24px;
    font-weight: 500;
  }
}

.rowContainer {
  display: flex;
  gap: 16px;
  flex-wrap: wrap;
}

.selectCheckboxItem {
  flex: 1;
}

.language {
  max-width: 170px;
}

.title {
  margin-bottom: 16px;
  font-weight: 500 !important;
}

.titleDescr {
  color: colorGrey100;
  margin-top: -16px;
  margin-bottom: 16px;
}

.filterWithPercent {
  display: flex;
  align-items: flex-end;
  gap: 4px;
  margin-bottom: 40px;
}

.percentFilter {
  width: 48px !important;
}

.medianViewsFilter {
  width: 70px !important;
}

.dirtyPercent {
  padding: 12px 0 !important;
  justify-content: center !important;
}

.rangeFilterInput {
  min-height: 48px;
  width: 69px;
  border-radius: 8px !important;
  input {
    font-weight: 500;
    font-size: 15px !important;
    line-height: 24px;
  }
}

.selectMin {
  width: 120px !important;
}

.rowContainerSpace {
  margin-bottom: 32px;
}

.suggestedFilters {
  color: colorSuccessful100;
  background-color: colorSuccessful100;
  padding: 6px 8px;
  border-radius: 16px;
  height: 28px;
  box-sizing: border-box;
}
.titleRow {
  display: flex;
  gap: 8px;
  margin-bottom: 16px;
}

.accordion {
  position: relative;
  z-index: 0;
}

.advancedAccordion {
  z-index: 1;
}

.suggestedFilterButton {
  padding: 12px 16px;
  width: 56px;
  height: 48px;
  border-radius: 8px;
  align-self: end;
}

.suggestedFilterButtonContainer {
  align-self: flex-end;
}

.erInput {
  input {
    font-size: 15px;
    line-height: 24px;
    font-weight: 500;
  }
}

.suggestedFilterEffects {
  color: colorPink100;
}

.suggestedWarning {
  margin-top: 8px;
}

.greenColored {
  color: colorSuccessful100;
}

.suggestionsTooltip {
  background: colorGrey10;
  border-radius: 16px;
  padding: 16px;
  position: fixed;
  width: 272px;
  box-sizing: border-box;
  bottom: 32px;
  left: 0;
  opacity: 0;
  transition: all 0.2s ease-in;

  & > * {
    display: inline;
  }

  &.inView {
    opacity: 1;
    left: 90px;
  }
}

.audienceFilter {
  position: relative;
}

@media (min-width: 1460px) {
  .suggestionsTooltip.inView {
    left: 256px;
  }
}
