.root {
  display: block;
}

.preloader {
  width: 100%;
  flex: none;
  display: flex;
  margin: 20px 0;
  justify-content: center;
}

.descr {
  margin-top: 18px;
}

.content {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  margin-bottom: 40px;
}
