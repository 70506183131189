@value colorGrey100 from '~Styles/vars/colors.css';

.root {
  background-color: white;
  display: grid;
  grid-template-columns: 240px 120px 350px 160px 90px 72px;
  grid-column-gap: 24px;
  padding: 16px;
  box-sizing: border-box;
  align-items: center;
  cursor: pointer;

  .creatorsCountText {
    color: colorGrey100;
    text-align: left;
    word-break: break-word;

    &.center {
      text-align: center;
    }

    &.right {
      text-align: right;
    }
  }
}
