@value colorBase, colorGrey50, colorGrayDog, colorConcrete, colorWhite from '~Styles/vars/colors.css';
@value desktopMinWidth, newDesktopMinWidth from '~Styles/vars/query.css';

@keyframes loading {
  to {
    transform: translateX(100%);
  }
}

@define-mixin centerV2 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
}

@define-mixin center {
  display: flex;
  justify-content: center;
  align-items: center;
}

@define-mixin vertical-center {
  display: flex;
  align-items: center;
}

@define-mixin horizontal-center {
  display: flex;
  justify-content: center;
}

@define-mixin flexColumn {
  display: flex;
  flex-direction: column;
}

@define-mixin round {
  border-radius: 50%;
}

@define-mixin items_centered_4 $width {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  &::after {
    content: '';
    width: $width;
  }

  &::before {
    content: '';
    width: $width;
    order: 1;
  }
}

@define-mixin items_centered_3 $width {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  &::after {
    content: '';
    width: $width;
  }
}

@define-mixin cuttedText {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@define-mixin mobileContent {
  width: 100%;
  max-width: 572px;
  padding-right: 16px;
  padding-left: 16px;
  box-sizing: border-box;
}

@define-mixin innerContainer {
  width: desktopMinWidth;
  margin: 0 auto;
  box-sizing: border-box;
}

@define-mixin newInnerContainer {
  width: newDesktopMinWidth;
  margin: 0 auto;
  padding: 0 16px;
  box-sizing: border-box;
}

@define-mixin field {
  max-width: 100%;
  min-height: 38px;
  border-radius: 4px;
  box-sizing: border-box;
  color: #404040;
  font: 14px/24px 'Aeroport';
  border: 1px solid #dbdbdb;
  transition: border 0.1s ease-in;

  &:hover {
    border-color: #afafaf;
  }

  &:focus:not(.error) {
    border-color: #4e42e4;
    box-shadow: 0 0 0 1px #4e42e4;
  }

  &:disabled {
    border: #f2f2f2;
    background-color: #f2f2f2;
  }

  &.error {
    background: #ffefee;
    border-color: #f54747;
  }

  &::placeholder {
    color: #afafaf;
  }
}

@define-mixin formWrap {
  width: 100%;
  min-width: 288px;
  max-width: 375px;
  padding: * 16px;
  box-sizing: border-box;
  text-align: center;

  .image {
    margin-bottom: 32px;
  }

  .title {
    text-align: center;
    margin-bottom: 32px;
  }
}

@define-mixin card {
  position: relative;
  padding: 20px;
  background: colorWhite;
  box-sizing: border-box;
  border-radius: 4px;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1), 0 0 2px rgba(0, 0, 0, 0.05);

  &:hover {
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1), 0 0 2px rgba(0, 0, 0, 0.05);
  }
}

@define-mixin skeletonLoading {
  overflow: hidden;
  position: relative;
  background: #e7e7e7;
  border-radius: 16px;

  &::after {
    animation: loading 1.5s infinite;
    content: '';
    height: 100%;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    transform: translateX(-100%);
    z-index: 1;
    background: linear-gradient(90deg, transparent 14.08%, #cbcbcb 57.26%, transparent 72.49%);
  }
}

@define-mixin drawer {
  background-color: white;
}

@define-mixin drawerContainer {
  display: flex;
  height: 100%;
  width: 516px;
  flex-direction: column;
  justify-content: center;
}
