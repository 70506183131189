@value colorSand40, colorSand80 from '~Styles/vars/colors.css';

.root {
  position: relative;
  border-radius: 6px;
  background-color: colorSand40;
  height: 16px;

  .bar {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    border-radius: 6px;
    background-color: colorSand80;
    transition: .3s width;
  }
}