@value colorGrey10, colorGrey100, colorDark100, colorWhite from '~Styles/vars/colors.css';
.drawer {
  background-color: colorWhite;
  padding-top: 8px;

  .titleTextPreset {
    margin-bottom: 16px;
  }

  .contentRoot {
    display: flex;
    flex-direction: column;
    height: calc(100vh - 64px);
    margin: 0 -16px;
    gap: 8px;
    justify-content: space-between;
  }

  .content {
    flex: 1;
    display: flex;
    flex-direction: column;
    max-height: calc(100% - 112px);
  }

  .titleText {
    padding: 16px;
  }

  .submitButtonWrapper {
    box-sizing: border-box;
    position: relative;
    width: 100%;
    margin-top: 16px;
    display: flex;
  }

  .input {
    height: 48px;
    background-color: colorGrey10;
    border-radius: 8px;
    margin-bottom: 8px;
  }

  .listWrapper {
    flex: 1;
    overflow: auto;
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 8px 16px;
  }

  .loaderWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
  }

  .listItem {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 16px;
    width: 100%;
    padding: 16px;
    box-sizing: border-box;
    border: 1px solid transparent;
    border-radius: 16px;
    cursor: pointer;

    &.active {
      border-color: colorDark100;
    }
  }
  

  .listItemIcon {
    padding: 8px;
    background-color: colorGrey10;
    border-radius: 8px;
  }

  .listItemNameText {
    flex: 1;
  }
  .listItemCountText {
    color: colorGrey100;
  }
}
