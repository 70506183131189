@value colorWhite, colorDark100, colorGrey100, colorGrey50, colorGrey10 from '~Styles/vars/colors.css';

.filters {
  background: colorWhite;
  border-radius: 8px;
  width: 100%;
  padding-bottom: 8px;
}

.controls {
  display: flex;
  padding: 8px;
  justify-content: space-between;
}

.filter {
  width: 100%;
  max-width: 100%;
}

.activeFilter {
  border-color: colorDark100;
}

.clearParams {
  margin: 8px auto 0;
  color: colorGrey100;
}

.hidden {
  display: none;
}

.textQueryWrap {
  padding: 8px;
}

.dropdown {
  border-color: transparent !important;

  &:hover {
    background-color: colorGrey10;
  }
}

.dropdownContainer {
  left: 0;
  right: unset;
}
