.root {
  display: inline-flex;
  align-items: center;
  cursor: pointer;
}

.arrow {
  margin-right: 12px;
}

.button {
  max-width: 320px;
  overflow: hidden;
}