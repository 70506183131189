@value headerHeight from '~Styles/vars/size.css';

.root {
  display: flex;
  justify-content: space-between;
  padding-top: headerHeight;
}

.content {
  width: 620px;
  margin: 0 auto;
  padding: 40px;
  box-sizing: border-box;
}

.title {
  margin-bottom: 32px;
}
