@value colorBase, colorConcrete, colorRed, colorWhite, colorWetAsphalt, colorGrayDog from '~Styles/vars/colors.css';

.root {
  display: flex;
  flex-direction: column;
  height: 100%;

  &:not(:last-child) {
    margin-right: 40px;
  }

  &:not(.isActive) {
    cursor: pointer;
  }
}

.big {
  .text {
    padding: 19px 0;
  }
}

.content {
  display: flex;
  align-items: center;
}

.isActive {
  .leftIcon {
    path {
      fill: colorBase;
    }
  }

  .line {
    background: colorBase;
  }

  .icon {
    opacity: 1;
  }

  .counter {
    background: colorBase;
  }
}

.line {
  width: 100%;
  height: 2px;
  border-radius: 100px;
  transition: background 0.1s ease-in;
}

.text {
  padding: 12px 0;
}

.textWrap {
  flex-grow: 1;
  display: flex;
  align-items: center;
  user-select: none;
}

.icon {
  opacity: 0.3;
  margin-left: 8px;
}

.leftIcon {
  margin-right: 8px;
}

.counter {
  min-width: 24px;
  height: 24px;
  padding: 0 8px;
  margin-left: 8px;
  box-sizing: border-box;
  @mixin center;
  position: relative;
  border-radius: 100px;
  background: colorConcrete;
}

.counter.unread {
  &::after {
    content: '';
    top: -2px;
    right: -4px;
    size: 10px;
    position: absolute;
    border: 1px solid colorWhite;
    border-radius: 50%;
    background: colorRed;
    box-sizing: border-box;
  }
}

.unreadCount {
  margin-left: 8px;
}

.scrollLink {
  span {
    color: colorGrayDog!important;
  }
}

.activeLink {
  .text {
    font-weight: 500;
  }
  border-bottom: 2px solid colorBase;

  span {
    color: colorWetAsphalt!important;
  }

  svg path {
    fill: colorBase!important;
  }
}