@value colorWhite, colorGrayDog from '~Styles/vars/colors.css';

.root {
  size: 380px 88px;
  border-radius: 4px;
  background: colorWhite;
  padding: 20px;
  box-sizing: border-box;
  box-shadow: 0 0 5px rgba(102, 102, 102, 0.05);
  border: 1px solid #F0F3F5;
  display: flex;
  margin-bottom: 20px;
  cursor: pointer;

  &:hover {
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  }
}

.info {
  margin-left: 20px;
}

.descr {
  display: flex;
  align-items: center;
  height: 24px;
}

.icon {
  margin-left: 11px;
}

.infoItem:not(:first-child) {
  &::before {
    content: ' / ';
    color: colorGrayDog;
  }
}

:global .isMobile {
  :local .root {
    width: 100%;
    margin-bottom: 0;
    padding: 16px;
    border-top-color: transparent;
  }
}