.root {
  margin: 40px 0;
}

.preloader {
  margin: 0 auto;
}

.title {
  margin-bottom: 40px;
}