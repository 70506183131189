@value colorGrey100 from '~Styles/vars/colors.css';

.root {
  &:not(:last-child) {
    margin-bottom: 32px;
  }
}

.descr {
  color: colorGrey100;
  margin-bottom: 8px;
  display: inline-block;
  gap: 4px;
}

.titleWrap {
  display: flex;
}

.optional {
  margin-left: 4px;
}

.range {
  align-items: flex-start;
  display: flex;
}

.field {
  flex: 1;
}

.seperator {
  margin: 8px;
}
