@value mobileMaxInnerWidth, newDesktopMinWidth from '~Styles/vars/query.css';
@value colorGrey10 from '~Styles/vars/colors.css';

.root {
  background: colorGrey10;
}

.title {
  padding-top: 16px;
}

.content {
  padding-top: 16px;
}

.container {
  @mixin newInnerContainer;
}

.spinner {
  position: relative;
  top: calc(50% - 20px);
  left: calc(50% - 20px);
}

:global .isMobile {
  :local .root {
    width: 100%;
    margin: 0 auto;
    max-width: mobileMaxInnerWidth;
    padding-left: 16px;
    padding-right: 16px;
    box-sizing: border-box;

    .serachInput {
      display: none;
    }
  }

  :local .title {
    padding-left: 16px;
    padding-right: 16px;
    margin: 12px 0;
  }
}
