@value colorDark, colorWhite,colorGrey50, colorError100, colorGrey10, colorApproval100 from '~Styles/vars/colors.css';

.root {
  background-color: colorGrey10;
  display: flex;
  flex-grow: 1;
  .preloader {
    margin: 16px auto;
  }

  .tooltip {
    width: 254px;
    word-break: break-word;
    text-align: left;
  }

  .container {
    width: 1180px;
    margin: 0 auto;
    padding: 24px 16px 0;
    height: 100%;
  }

  .headerControls {
    display: flex;
    gap: 8px;
    justify-content: space-between;

    .startHeaderControls {
      justify-self: flex-start;
    }

    .endHeaderControls {
      display: flex;
      gap: 8px;
    }
  }

  .textQuery {
    width: 240px;
  }
}

.header {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.connectionStatus {
  justify-self: flex-end;
  margin-left: auto;
  display: flex;
  gap: 16px;
}

.connectedContainer {
  display: flex;
  gap: 16px;
  align-items: center;
  .check {
    color: colorApproval100;
  }
  .disabled {
    color: colorError100;
  }
  .info {
    display: flex;
    flex-direction: column;
  }
}

.orderControls {
  display: flex;
  gap: 8px;
  .input {
    width: 250px;
    border-radius: 8px;
    i {
      color: colorDark;
    }
  }
  .button {
    justify-content: flex-start;
    width: 210px;
    box-sizing: border-box;
  }
  .createOrder {
    &:disabled {
      background-color: colorGrey50 !important;
      opacity: 1 !important;
    }
  }

  .filter {
    width: 180px;
  }
}

.checkboxContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 48px;
  width: 56px;
  background-color: colorWhite;
  border-radius: 8px;
  cursor: pointer;
  &.disabled {
    cursor: auto;
  }
}

.elementSkeleton {
  size: 100% 140px !important;
}
