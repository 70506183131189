@value colorGrey10 from '~Styles/vars/colors.css';

.root {
  background-color: white;
  display: flex;
  flex-direction: column;

  .content {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 16px;
    flex: 1;

    .checkContainer {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      gap: 32px;
      padding: 16px;
      border-radius: 16px;
      background-color: colorGrey10;
    }
  }

  .buttonContainer {
    width: 100%;

    .mainBtn {
      width: 100%;
    }
  }
}
