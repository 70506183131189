@value colorWhite, colorGrey10 from '~Styles/vars/colors.css';

.root {
  width: calc(33.3% - 20px);
  display: inline-block;
  overflow: hidden;
  box-sizing: border-box;
  position: relative;
  cursor: pointer;
  border-radius: 4px;
  margin: 10px;
  filter: drop-shadow(0 0 2px rgba(0, 0, 0, 0.05)) drop-shadow(0 1px 4px rgba(0, 0, 0, 0.1));
}

.content {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 100%;
}

.imageWrap {
  size: 100%;
  position: absolute;
  top: 0;
}

.image {
  size: 100%;
}

.imageLoader {
  display: none;
}

.count {
  flex: 1;
  text-align: center;

  &:not(:last-child) {
    margin-right: 12px;
  }
}

.icon {
  margin-right: 8px;
}

.dislikeIcon {
  transform: rotate(180deg);
}

.detailsWrap {
  width: 100%;
  transition: opacity 0.1s ease-in;
  padding: 8px;
  box-sizing: border-box;
  display: flex;
  align-items: flex-end;
  background: colorWhite;
}

.details {
  display: flex;
  width: 100%;
}

.playIcon {
  @mixin centerV2;
}

@media(max-width: 1279px) {
  .root {
    width: calc(50% - 20px);
  }
}

@media(min-width: 1624px) {
  .root {
    width: calc(25% - 20px);
  }
}