@value colorPink60 from '~Styles/vars/colors.css';

.demo {
  color: colorPink60;
  text-decoration: underline;
}

.tooltip {
  top: 72px;
  right: 16px;
}
