.root {
  display: flex;
  flex-direction: column;
}

.header {
  flex: none;
  display: flex;
  margin-bottom: 32px;
  flex-direction: row;
  align-items: center;
}

.summary {
  width: 100px;
  flex: none;
  margin-right: 20px;
}

.searchParams {
  flex: auto;
}

.list {
  flex: auto;
}

.trial {
  width: 100%;
  padding: 47px 0 40px;
  text-align: center;
  box-sizing: border-box;
}

.descr {
  margin-top: 15px;
  margin-bottom: 26px;
}

.preloader {
  display: flex;
  flex-wrap: wrap;
  margin: -10px;
}

.showMore {
  margin-top: 20px;
  margin-bottom: 28px;
}
