@value colorSuccessful100, colorError100 from '~Styles/vars/colors.css';

.root {
  display: flex;
  border-left: 2px solid colorSuccessful100;
  padding: 8px 16px;
  display: flex;
  align-items: center;
  gap: 16px;

  .icon {
    color: colorSuccessful100;
  }

  &.error {
    border-color: colorError100;
    .icon {
      color: colorError100;
    }
  }
}
