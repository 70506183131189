
.root {
  width: calc(33.3% - 20px);
  display: inline-block;
  box-sizing: border-box;
  position: relative;
  cursor: pointer;
  background-size: cover;
  border-radius: 4px;
  margin: 10px;
  filter: drop-shadow(0 0 2px rgba(0, 0, 0, 0.05)) drop-shadow(0 1px 4px rgba(0, 0, 0, 0.1));

  &.processing {
    cursor: default;
    background: rgba(0, 0, 0, 0.3);
  }

  @media(max-width: 1279px) {
    width: calc(50% - 20px);
  }

  @media(min-width: 1624px) {
    width: calc(25% - 20px);
  }
}

.root::after {
  content: "";
  display: block;
  padding-bottom: 100%;
}

.image {
  size: 100%;
  background-size: cover;
}

.stub {
  size: 40px;
  border-radius: 50%;
  background: rgba(0, 0, 0, 0.5);
  position: absolute;
  @mixin center;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
}

.play {
  size: 48px;
  background-color: rgba(0, 0, 0, 0.5);
  background-image: url('~files/images/general/play_white.svg');
  background-repeat: no-repeat;
  background-position: calc(50% + 1px) center;
  background-size: 24px;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
}

.itemTag {
  position: absolute;
  right: 4px;
  bottom: 4px;
}