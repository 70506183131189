.root {
  display: flex;
  align-items: center;
  margin-bottom: 32px;
  gap: 20px;
}

.ava {
  flex-shrink: 0;
}

.details {
  flex: 1;
}
