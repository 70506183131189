.root {
  width: 100%;
  margin-bottom: 20px;
}

.params {
  margin-top: 20px;
}

.cities {
  display: flex;
  gap: 6px;

  &:not(:empty) {
    margin-top: 12px;
  }
}
