@value (
  colorBase,
  colorWhite,
  colorDarkBase,
  colorGrey50,
  colorGrayDog,
  colorWetAsphalt,
  colorConcrete,
  colorRed,
  colorGreen,
  colorYellow,
  colorGrey50,
  colorGrey100,
  colorDark100
) from '~Styles/vars/colors.css';
@value borderRadius20 from '~Styles/vars/query.css';

:root {
  --time: 0.1s;
  --ease: ease-in;
}

/* GENERAL */

.root {
  @mixin center;
  outline: none;
  border: 1px solid transparent;
  box-sizing: border-box;
  border-radius: 4px;
  font-family: 'Aeroport';
  font-size: 15px;
  line-height: 24px;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  background: none;
  transform: translate3d(0,0,0);
  transition: background var(--time) var(--ease),
  border var(--time) var(--ease),
  /* opacity var(--time) var(--ease), */
  color var(--time) var(--ease);
}

.icon {
  height: 24px;
}

.content > * {
  flex-shrink: 0;

  &:not(:last-child):not(:only-child) {
    margin-right: 8px;
  }
}

.content {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px 16px;
  width: 100%;
  overflow: hidden;
  position: relative;
}

.text {
  padding: * 8px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.preloader {
  position: absolute;
}

.loading {
  .content,
  .icon {
    opacity: 0;
  }
}

.disabled {
  cursor: default;
}

.contentAlign-center {
  .text {
    flex: unset;
  }
}

/* ________ */

/* MODIFICATIONS */

/* WIDTH */

.width-full {
  width: 100%;
}

/* SIZES */

.size-sm {
  padding: 4px;
  font-size: 12px;
  line-height: 16px;
  height: 24px;

  .icon {
    height: 16px;
  }

  .preloader {
    size: 16px;
  }
}

.size-md {
  padding: 8px;
  height: 40px;

  .preloader {
    size: 24px;
  }
}

.size-lg {
  padding: 16px;
  height: 56px;

  .preloader {
    size: 24px;
  }
}

.size-xl {
  padding: 20px;
  height: 64px;

  .preloader {
    size: 24px;
  }
}

/* MAIN COLORS */

.color-primary {
  &.mainColor-base {
    background-color: colorDark100;
  }

  &.mainColor-vividRed {
    background-color: colorRed;
  }

  &.mainColor-green {
    background-color: colorGreen;
  }

  &.mainColor-yellow {
    background-color: colorYellow;
  }
}

.theme-dark.color-normal {
  &.mainColor-vividRed {
    border-color: colorRed;
    color: colorRed;

    &:not(.disabled) {
      &.loading,
      &:hover {
        background-color: colorRed;
        color: colorWhite;
      }
    }
  }
}

/* COLORS */

.color-primary {
  color: colorWhite;

  :global .path {
    fill: colorWhite;
  }

  &::before {
    content: '';
    top: 0;
    left: 0;
    size: 100%;
    position: absolute;
    background-color: rgba(0, 0, 0, 0.3);
    opacity: 0;
    transition: opacity 0.1s ease-in;
  }

  &:not(.disabled) {
    &.loading,
    &:hover {
      &::before {
        opacity: 1;
      }
    }
  }

  &.disabled {
    background-color: colorGrey50;
  }
}

.color-secondary {
  color: colorGrey100;
  background-color: colorGrey50;

  :global .path {
    fill: colorBase;
  }

  &.disabled {
    color: colorGrayDog;
    border-color: colorGrey50;

    :global .path {
      fill: colorGrayDog;
    }
  }

  &:not(.disabled) {
    &:hover {
      border: 1px solid colorGrey50;
    }
  }
}

.color-delete {
  color: colorRed;
  background-color: colorConcrete;
}

/* THEME COLORS MODIFICATION */

.theme-dark.color-primary {
  &.disabled {
    color: (256, 256, 256, 0.5);
    background-color: (256, 256, 256, 0.1);

    :global .path {
      fill: (256, 256, 256, 0.5);
    }
  }
}

.theme-light.color-secondary {
  &.disabled {
    border: 1px solid colorGrey50;
  }

  &:not(.disabled) {
    &.loading {
      background-color: rgba(78, 66, 228, 0.1);
    }
  }
}

.theme-dark.color-secondary {
  &:not(.disabled) {
    &.loading {
      background-color: rgba(256, 256, 256, 0.5);
    }
  }

  &.disabled {
    color: rgba(256, 256, 256, 0.5);
    background-color: rgba(256, 256, 256, 0.1);

    :global .path {
      fill: rgba(256, 256, 256, 0.5);
    }
  }
}

.theme-light.color-normal {
  color: colorWetAsphalt;
  background-color: colorWhite;
  border: 1px solid colorGrey50;

  :global .path {
    fill: colorWetAsphalt;
  }

  &:not(.disabled) {
    &.loading,
    &:hover {
      background-color: colorConcrete;
    }
  }

  &.disabled {
    color: colorGrayDog;

    :global .path {
      fill: colorGrayDog;
    }
  }
}

.theme-dark.color-normal {
  color: colorWhite;
  border: 1px solid colorWhite;
  background: none;

  :global .path {
    fill: colorWhite;
  }

  &:not(.disabled) {
    &.loading,
    &:hover {
      color: colorWetAsphalt;
      background-color: colorWhite;

      :global .path {
        fill: colorWetAsphalt;
      }
    }
  }

  &.disabled {
    opacity: 0.5;
  }
}

/* ________ */


/* BORDER RADIUS */

.radius-round {
  border-radius: 50%;
}

.radius-20 {
  border-radius: borderRadius20;
}

.border-dashed {
  border-width: 2px !important;
  border-style: dashed !important;
}