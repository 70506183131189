@value colorDark100, colorPurple60, colorWhite, colorGrey50, colorGrey100 from '~Styles/vars/colors.css';

.root {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 11px 16px;
  border-radius: 12px;
  border: 1px solid colorGrey50;
  transition: border 0.1s ease-in, 0.1s background-color;
  gap: 8px;
  position: relative;

  &:not(.isActive):not(.isDisabled) {
    cursor: pointer;

    &:hover {
      background-color: colorWhite;
    }
  }

  &.isActive {
    background: colorWhite;
    border-color: colorDark100;

    span {
      cursor: default;
    }
  }
}

.devider {
  background-color: colorGrey50;
  height: 24px;
  width: 1px;
  margin: 0 15px;
}

.count {
  padding-left: 0;
  padding-right: 0;
  color: colorGrey100;
}

.filled {
  background-color: colorWhite;
}

.disabled {
  color: colorGrey50;
}

.beta {
  position: absolute;
  bottom: -12px;
  right: 6px;
  color: colorWhite !important;
}

.fluid {
  flex: 1;
}
