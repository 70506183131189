@value headerHeight from '~Styles/vars/size.css';
@value newDesktopMinWidth, mobileMaxInnerWidth from '~Styles/vars/query.css';
@value colorGrey10 from '~Styles/vars/colors.css';

.root {
  width: 100%;
  min-height: 100vh;
  display: block;
  box-sizing: border-box;
}

.container {
  @mixin newInnerContainer;
}

.title {
  padding-top: 16px;
  padding-bottom: 16px;
}

.dates {
  gap: 6px;
  display: flex;

  div {
    &:first-child {
      border-bottom-right-radius: 0;
      border-top-right-radius: 0;
    }

    &:last-child {
      border-bottom-left-radius: 0;
      border-top-left-radius: 0;
    }
  }
}

.date {
  width: 178px;
}

.filters {
  display: flex;
  gap: 18px;
}

.searchParams {
  button {
    height: 50px;
  }
}

.datesWrap {
  gap: 16px;
  margin-bottom: 16px;
  display: flex;
}
