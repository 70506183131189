@value colorWhite from '~Styles/vars/colors.css';

.root {
  display: block;
}

.backdrop {
  opacity: 1;
}

.control {
  size: 48px;
  background: colorWhite;
  border-radius: 50%;
  @mixin center;
}

.controlWrap {
  height: 80%;
  @mixin center;
}

.leftControl {
  left: 0;
}

.rightControl {
  right: 0;
}

.container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.content {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  flex: 1;
  box-sizing: border-box;
  padding-left: 44px;
  padding-right: 44px;

  &.multiple {
    justify-content: space-between;
  }
}

.mediaWrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.media {
  max-width: 55vw;
  max-height: 80vh;
  user-select: none;
}

.footer {
  display: flex;
  justify-content: flex-end;
  padding: 0 44px 28px;
}

.carousel {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}
