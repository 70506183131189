@value colorGrey10, colorGrey50 from '~Styles/vars/colors.css';

.root {
  width: 282px;
}

.container {
  box-sizing: border-box;
  padding: 24px 16px;
  background: colorGrey10;
  border-radius: 16px;
  width: 282px;
  position: fixed;
}

.range {
  margin-top: 16px;
  margin-bottom: 16px;
  display: flex;
  gap: 8px;
  align-items: center;
}

.warning {
  margin-top: 8px;
}

.disclaimer {
  margin-top: 4px;
  padding-top: 12px;
  border-top: 1px solid colorGrey50;
}

.info {
  cursor: pointer;
}

.row {
  display: flex;
  justify-content: space-between;
  gap: 16px;
  align-items: flex-start;
}

.rows {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 16px;
}
