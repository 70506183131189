@value desktopMinWidth from '~Styles/vars/query.css';
@value colorBase, lightBase from '~Styles/vars/colors.css';

.root {
  display: flex;
}

.content {
  margin: 40px auto;
  flex: 1;
}

.sidebar {
  flex-shrink: 0;
  width: 300px;
}

.list {
  border: 2px solid colorBase;
  margin: 20px;
}

.item {
  padding: 20px;
}


.itemWrap {
  &.active {
    background: lightBase;
  }
}