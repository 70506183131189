@value colorPurple100 from '~Styles/vars/colors.css';

.drawer {
  background-color: white;
}

.contentRoot {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr auto;
  height: calc(100vh - 64px);
}

.title {
  font-size: 24px;
  font-weight: 400;
  line-height: 32px;
  margin-bottom: 16px;
}

.description {
  margin: 16px 0;
}

.image {
  size: 144px;
  transform: rotate(-11deg);
}

.preloader {
  margin: 40px auto;
}

.link {
  color: colorPurple100;
}
