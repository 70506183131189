@value colorPink100, colorPink40 from '~Styles/vars/colors.css';

.root {
  display: flex;
  gap: 12px;

  img {
    width: 100%;
    height: auto;
  }

  .adminBadge {
    cursor: default;
    background-color: colorPink40;
    color: colorPink100;
    display: inline-block;
    padding: 6px 8px;
    border-radius: 16px;
  }
}
