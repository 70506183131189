@value colorSand40, colorPink100 from '~Styles/vars/colors.css';

.root {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: colorSand40;
  height: 68px;
}

.link {
  color: colorPink100;
  font-weight: 500;
  margin-left: 8px;
}

.imageContainer {
  margin-right: 8px;
}
