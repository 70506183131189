@value colorGrey100 from '~Styles/vars/colors.css';

.root {
  size: 100% 300px;
  @mixin center;
  flex-direction: column;
  color: colorGrey100;
  text-align: center;
  gap: 8px;
}
