@value colorError100 from '~Styles/vars/colors.css';

.productPriceInput {
  input {
    border-radius: 4px !important;
    &:focus {
      border: 1px solid #4e42e4;
      box-shadow: 0 0 0 1px #4e42e4;
    }
  }
}

.textarea {
  min-height: 48px;
  padding-top: 10px;
}

.root {
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.error {
  margin-top: 4px;
  color: colorError100;
}

.ttShop {
  display: flex;
  gap: 4px;
  align-items: center;
}
