@value colorGrey10, colorGrey100, colorError100 from '~Styles/vars/colors.css';

.drawer {
  background-color: white;
  padding-top: 8px;

  .contentRoot {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: max-content calc(100vh - 202px) max-content;
    height: 100%;
    margin: 0 -16px;

    &.confirmOrderContentRoot {
      grid-template-rows: max-content calc(100vh - 178px) max-content;
    }

    .content {
      overflow: auto;
      margin-bottom: 8px;

      &.confirmOrderContent {
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: max-content max-content 1fr max-content max-content;

        .productVariantsList {
          height: calc(100vh - 410px);
          overflow: auto;
        }
      }

      &.orderCreatedContent {
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: 1fr;
      }

      .textWrapper {
        padding: 8px 16px;
      }
      .titleWrapper {
        display: grid;
        grid-template-columns: 203px 128px 96px 1fr;
        padding: 0 16px;
      }


      .counterContainer {
        display: flex;
        width: 100%;
        margin-bottom: 24px;
      }

      .creatorsCounter {
        display: grid;
        grid-template-columns: max-content 1fr;
        align-items: center;
        gap: 16px;
        padding: 8px 16px;
        width: 50%;

        .iconWrapper {
          display: inline-flex;
          align-items: center;
          justify-content: center;
          background-color: colorGrey10;
          border-radius: 8px;
          height: 40px;
          width: 40px;
        }
      }

      .noResultsMessage {
        color: colorGrey100;
        position: absolute;
        top: 50%;
        left: calc(50% - 90px);
        width: 180px;
        text-align: center;
      }
    }

    .buttonWrapper {
      box-sizing: border-box;
      position: relative;
      padding: 0 16px;
      width: 100%;
      height: 72px;

      .button {
        box-sizing: border-box;
        width: 100%;
      }

      .clicker {
        height: 100%;
        width: 100%;
        cursor: pointer;
      }
    }

    .spinner {
      position: absolute;
      top: calc(50% - 20px);
      left: calc(50% - 20px);
    }

    .textColorGrey100 {
      color: colorGrey100;
    }

    .textColorGrey10 {
      color: colorGrey10;
    }

    .textColorError100 {
      color: colorError100;
    }
  }
}
.nextButton {
  width: 100%;
}

.backButton {
  height: 100%;
  border-radius: 16px;
  width: 72px;
  box-sizing: border-box;
  justify-content: center;
}
