.root {
  margin-top: 8px;
  margin-bottom: 14px;
  position: relative;

  .filters {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(0, 1fr));
    grid-gap: 8px;
    width: 100%;

    .progressiveInput {
      background-color: white;
      border-radius: 8px;
    }

    .dateRangeInput {
      border-radius: 8px;
      padding-right: 0;
      left: 0;
      right: 0;
      width: 190px;
    }
  }
}
