@value colorGrey100, colorDark100 from '~Styles/vars/colors.css';

.root {
  padding: 4px;
}
.list {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 4px;
 
}

.listItem {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  border: 1px solid colorGrey100;
  flex: auto;
  padding: 4px 8px;
  color: colorGrey100;
  cursor: pointer;

  &.active {
    background-color: colorDark100;
    color: white;
    border-color: colorDark100;
  }
}