.root {
  .anchorButton {
    background-color: transparent;
    padding: 0;
    border-radius: 0;
    border: 0;
    height: 24px;
    width: 24px;
    position: relative;

    .iconToggler {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      color: white;
    }
  }

  .dropdownRoot {
    background-color: transparent;
    padding: 0;

    .dropdownGroup {
      right: -4px;
      top: calc(100% + 8px);
      max-height: 330px;
      overflow: auto;
    }
  }
}
