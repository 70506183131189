@value colorConcrete, colorGrey50, colorGrey100, colorGrayDog from '~Styles/vars/colors.css';

.paymentInfo {
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;

  .planName {
    display: flex;
  }

  .price {
    min-width: 100px;
    text-align: right;
  }
}

.item {
  display: flex;
  justify-content: space-between;
  gap: 5px;
  margin: 12px 0;

  &:first-child {
    margin-top: 0;
  }

  &:last-child {
    margin-bottom: 0;
  }

  .option {
    text-align: left;
  }
}

hr.item {
  background-color: #dbdbdb;
  border: 0;
  height: 1px;
}

.paymentDetailedInfo {
  width: 100%;
  box-sizing: border-box;
  padding: 20px;
  margin-top: 16px;
  margin-bottom: 12px;
  background-color: colorConcrete;
  border-radius: 8px;
}

.showDetailsBtn,
.method {
  color: colorGrey100;
  display: flex;
  gap: 4px;
  align-items: center;
  user-select: none;
}

.showDetailsBtn {
  cursor: pointer;
}

.amountWrap {
  text-align: right;
}

.edit {
  cursor: pointer;
}
