.root {
  background-color: white;

  .contentContainer {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .header {
      margin-bottom: 8px;
      .title {
        padding: 8px 0;
      }
    }

    .buttonContainer {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;

      .mainBtn {
        width: 100%;
        margin-bottom: 16px;
      }
    }
  }
}
