@value colorWhite, colorGrey50 from '~Styles/vars/colors.css';

.root {
  &:not(:first-child) {
    margin-top: 40px;
  }
}

.header {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px 0;
  gap: 6px;
}

.descr {
  margin-left: 8px;
}
