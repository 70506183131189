@value colorGrey50, colorWhite, colorBase, colorDark, colorSand80 from '~Styles/vars/colors.css';

.wrapper {
  display: flex;
  align-items: center;
}

.wrapper {
  &.labelSide-right {
    .root {
      order: 1 !important;
    }

    .title {
      order: 2 !important;
      margin-right: unset;
      margin-left: 12px;
    }
  }
}

.root {
  size: 52px 28px;
  background: colorGrey50;
  border-radius: 99px;
  padding: 2px;
  cursor: pointer;
  box-sizing: border-box;
  transition: background 0.1s ease-in;
  position: relative;
}

.pointer {
  size: 24px;
  left: 2px;
  position: absolute;
  border-radius: 50%;
  background: colorWhite;
  top: 50%;
  transform: translateY(-50%);
  transition: left right 0.1s ease-in;
}

.on {
  justify-content: flex-end;

  &:not(.disabled) {
    &.sand {
      background: colorSand80;
    }

    &.black {
      background: colorDark;
    }
  }

  .pointer {
    right: 2px;
    left: initial;
  }
}

.title {
  margin-right: 12px;
}

.root.disabled {
  cursor: default;
}