@value (colorDark100, colorDark70, sky100, colorPink100, colorGrey50, colorSand40) from '~Styles/vars/colors.css';

.root {
  margin-top: 80px;
  margin-bottom: 80px;
}

.title {
  font-size: 40px;
  line-height: 44px;
  font-weight: 500;
  text-align: center;
}

.subtitle {
  color: colorGrey100;
  font-size: 24px;
  line-height: 50px;
  font-weight: 400;
  margin-bottom: 32px;
  text-align: center;
}

.plansWrapper {
  display: grid;
  grid-template-columns: 450px 1fr;
  grid-column-gap: 50px;
  margin: 60px 0 168px 0;

  .plans {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(0, 1fr));
    grid-gap: 50px;

    .plan {
      margin: 0;
    }

    .planTitle {
      color: colorGrey100;
      font-size: 20px;
      line-height: 24px;
      font-weight: 500;
    }

    .skyColor {
      color: sky100;
    }

    .pinkColor {
      color: colorPink100;
    }

    .price {
      margin-bottom: 32px;
    }

    .priceText {
      font-size: 40px;
      line-height: 44px;
      font-weight: 500;
      margin-bottom: 4px;
    }

    .periodText {
      font-size: 17px;
      line-height: 24px;
      font-weight: 400;
      color: colorGrey100;
    }

    .summaryPriceRemark {
      color: colorGrey100;
      font-size: 12px;
      line-height: 16px;
      font-weight: 400;
    }

    .summaryPrice {
      color: colorGrey100;
      font-size: 17px;
      line-height: 24px;
      font-weight: 400;

      &.hidden {
        visibility: hidden;
      }
    }
  }
}

.headerWrap {
  margin-bottom: 4px;
}

.planFeatures {
  display: grid;
  grid-gap: 8px;
  color: colorDark70;
  font-size: 17px;
  line-height: 24px;
  font-weight: 400;

  * {
    text-align: left;
  }

  .planFeature {
    display: grid;
  }

  .planFeatureGroupName {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(0, max-content));
    grid-gap: 4px;

    .planFeatureGroupNameTitle {
      color: colorDark100;
      font-weight: 500;
      font-size: 20px;
      line-height: 24px;
    }
  }

  .planFeaturesGroupRow {
    display: grid;
    grid-template-columns: 450px repeat(auto-fit, minmax(0, 1fr));
    border-bottom: 1px solid colorGrey50;
    padding: 16px 0;
    align-items: center;
    grid-column-gap: 50px;
    user-select: none;

    &.planFeaturesGroupRowTitle {
      background-color: colorSand40;
      padding-left: 42px;
      border-radius: 12px;
      border-bottom: none;
      width: 100%;
      margin-left: -42px;
    }
  }

  .planFeaturesGroupFirstRow {
    display: grid;
    grid-template-columns: 450px repeat(auto-fit, minmax(0, 1fr));
    padding: 10px 0;
    align-items: center;
    grid-column-gap: 50px;
    margin-bottom: -16px;
  }

  .featureName {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(0, max-content));
    grid-gap: 4px;

    .featureNameText {
      font-size: 17px;
      color: colorDark70;
    }
    .featureNameInfo {
      font-size: 17px;
      color: colorGrey100;
    }
  }

  .planFeatureValue {
    font-size: 17px;
    line-height: 24px;
    font-weight: 500;
  }

  .planTitle {
    color: colorGrey100;
    font-size: 20px;
    line-height: 24px;
    font-weight: 500;
    margin-bottom: 0;

    &.pinkColor {
      color: colorPink100;
    }

    &.skyColor {
      color: sky100;
    }
  }
}

.button {
  height: 36px;
  border-radius: 8px;
  margin-bottom: 16px;
  padding: 0;
  &.disabledButton {
    border: 0;
  }

  &.disabledButtonDark {
    background-color: unset;
    color: inherit !important;
    border: 0;
  }
}
