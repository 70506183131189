@value colorRed, colorGreen, colorBase from '~Styles/vars/colors.css';

.projectStatus {
  width: 100%;
  border-radius: 4px;
  padding: 20px;
  box-sizing: border-box;
  margin-bottom: 12px;
  display: flex;
  justify-content: space-between;

  &.notHired {
    background: colorRed;
  }

  &.hired {
    background: colorGreen;
  }

  &.completed {
    background: colorBase;
  }
}

.statusTitleWrap {
  display: flex;
  align-items: center;
}

:global .isMobile {
  :local .projectStatus {
    margin: 0;
    border-radius: 0;
  }
}