@value colorDark100 from '~Styles/vars/colors.css';

div.tooltip {
  border-radius: 12px;
  font-family: 'Aeroport';
  font-weight: 400;
  font-style: normal;
  font-size: 15px;
  line-height: 24px;
  padding: 12px 16px;
  opacity: 1;
  z-index: 10;
  white-space: pre-wrap;
  span {
    text-align: left !important;
  }

  &.dark {
    background: colorDark100;
  }

  &.light {
    background: white;
  }
}
