@value (
  colorGrey10,
  colorLightOcean,
  colorLightBeige,
  colorOcean40,
  colorOcean100,
  colorGrey100,
  colorGrey50,
  colorSun40,
  colorSun100,
  colorBrown40,
  colorBrown100,
  colorDark40,
  colorSand100,
  colorSand40,
  colorDark100,
  colorPink40,
  colorPink100,
  colorPurple40,
  colorPurple60,
  colorPurple100,
  colorGrass40,
  colorGrass60,
  colorGrass100,
  colorSuccess100,
  colorWhite
  ) from '~Styles/vars/colors.css';

.root {
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;

  padding: 6px 8px;
  border-radius: 16px;
  &.rootMargin {
    margin: 8px 16px;
  }

  display: inline-flex;
  align-items: center;
  gap: 6px;
  cursor: default;

  &.badge {
    font-size: 10px;
    line-height: 14px;
    padding: 4px 8px;
    background-color: colorPurple60 !important;
    color: colorWhite;
  }

  &.m {
    font-size: 14px;
    line-height: 16px;
  }

  &.grey {
    background-color: colorGrey10;
    color: colorGrey100;
    border: 1px solid colorGrey10;

    &.hover:hover {
      border: 1px solid colorGrey50;
    }
  }

  &.sun {
    background-color: colorSun40;
    color: colorSun100;
  }

  &.brown {
    background-color: colorBrown40;
    color: colorBrown100;
  }

  &.lightOcean {
    background-color: colorLightOcean;
    color: colorDark100;
  }

  &.lightBeige {
    background-color: colorLightBeige;
    color: colorDark100;
  }

  &.ocean {
    background-color: colorOcean40;
    color: colorOcean100;
  }

  &.dark {
    background-color: colorDark40;
    color: colorWhite;
  }

  &.sand {
    background-color: colorGrey10;
    color: colorSand100;
  }

  &.white {
    background-color: colorWhite;
    color: colorDark100;
  }

  &.pink {
    background-color: colorPink40;
    color: colorPink100;
  }

  &.purple {
    background-color: colorPurple40;
    color: colorPurple100;
  }

  &.grass {
    background-color: colorGrass60;
    color: colorSuccess100;
  }

  &.transparent {
    background-color: colorGrey10;
    color: colorGrey100;
  }

  &.stroke {
    background-color: transparent;
    color: colorDark100;
    border: 1px solid colorDark100;

    &.hover:hover {
      background-color: colorGrey50;
    }
  }
}
