@value colorGrey10 from '~Styles/vars/colors.css';
@value desktopMinWidth from '~Styles/vars/query.css';

.root {
  background: colorGrey10;
}

.container {
  width: desktopMinWidth;
  margin: 0 auto;
  padding-top: 20px;
}

.items {
  display: flex;
  gap: 16px;
  padding-top: 20px;
}
