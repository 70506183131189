@value colorGrey100 from '~Styles/vars/colors.css';

.root {
  position: relative;
  max-width: 100vw;

  .imgWrapper {
    margin-bottom: 38px;
    text-align: center;

    .image {
      width: 170px;
    }
  }

  .textPreset {
    margin-bottom: 116px;
    justify-content: center;
    box-sizing: border-box;
    width: 100%;

    .textContainer {
      align-items: center;
    }

    .title {
      text-align: center;
    }

    .subtitle {
      color: colorGrey100;
      text-align: center;
    }
  }

  .buttonPreset {
    width: 100%;
    max-width: 100%;
    box-sizing: border-box;

    .button {
      width: 100%;
    }
  }
}
