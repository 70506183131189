@value colorDark100 from '~Styles/vars/colors.css';

.root {
  display:flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
}
.divider {
  width: 15px;
  height: 1px;
  background-color: colorDark100;
}