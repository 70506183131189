@value colorWhite from '~Styles/vars/colors.css';

.root {
  position: absolute;
  z-index: 54;

  &.withCloseButton {
    padding-right: 48px;
  }
}

.contentWrapper {
  display: grid;
  grid-gap: 8px;
}

.text {
  white-space: pre-wrap;
}

.canComplete {
  .text {
    padding-right: 8px;
  }

  .close {
    top: 15px;
    right: 12px;
    color: colorWhite;
    position: absolute;
  }
}

.backdrop {
  z-index: 1001;
  & + * {
    z-index: 1002;
  }
}
