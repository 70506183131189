@value colorGrey100 from '~Styles/vars/colors.css';

.drawer {
  background-color: white;
}

.root {
  display: flex;
  align-items: center;
  background-color: white;
  padding: 16px;
  border-radius: 16px;
  position: relative;

  .names {
    padding-top: 0;
    padding-bottom: 0;
    width: 204px;
    overflow: hidden;
    margin-left: 16px;
  }

  &.disabled {
    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      background-color: rgba(255, 255, 255, 0.4);
      border-radius: 16px;
      pointer-events: none;
    }
  }

  .brandInfo {
    display: flex;
    align-items: center;
    min-width: 0;
    padding-right: 16px;

    .brandName {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .brandSite {
      color: colorGrey100;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  .controls {
    display: flex;
    align-items: center;

    .editBtn {
      margin-right: 16px;
      padding: 12px 16px;
    }
  }
}

.info {
  padding: 12px 16px;
}

.stub {
  flex: 1;
}

.campaignsCount {
  color: colorGrey100;
  padding-left: 16px;
}
