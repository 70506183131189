@value colorGrayDog, colorGrey50, colorWetAsphalt from '~Styles/vars/colors.css';

.root {
  position: relative;
}

.panel {
  cursor: pointer;
  size: 42px 38px;
  position: relative;
  @mixin center;
  border-radius: 4px;
  border: 1px solid colorGrey50;
  box-sizing: border-box;
  transition: border 0.1s ease-in;

  &:hover {
    border-color: colorGrayDog;

    .icon,
    .icon::before,
    .icon::after {
      background-color: colorWetAsphalt;
    }
  }
}

.open {
  .panel {
    border-color: colorGrayDog;

    .icon,
    .icon::before,
    .icon::after {
      background-color: colorWetAsphalt;
    }
  }
}

.icon {
  position: relative;
}

.icon,
.icon::before,
.icon::after {
  size: 4px;
  background-color: colorGrayDog;
  border-radius: 50%;
  transition: background 0.1s ease-in;
}

.icon::before,
.icon::after {
  content: '';
  top: 0;
  display: inline-block;
  position: absolute;
}

.icon::before {
  left: 8px;
}

.icon::after {
  right: 8px;
}

.menu {
  top: 8px;
  position: relative;
  min-width: 230px;
}