.root {
  top: 0;
  left: 0;
  size: 100%;
  z-index: 1100;
  position: fixed;
  overflow: auto;
}

.backdrop {
  size: 100%;
  top: 0;
  left: 0;
  position: absolute;
  background: rgba(0, 0, 0, 0.9);
  z-index: -1;
}

.content {
  size: 100%;
  min-height: 100%;
  height: 100%;
  @mixin center;
}

:global .isMobileOnly {
  :local .root {
    .content {
      display: flex;
      flex-direction: column;
    }
  }
}
