@value colorCreamCan, kashmirBlue from '~Styles/vars/colors.css';

.header {
  display: flex;
  align-items: center;
}

.title {
  margin-right: 4px;
}

.container {
  margin-bottom: 8px;
}

.content {
  display: flex;
  align-content: center;

  a {
    color: colorCreamCan;

    &:active {
      color: kashmirBlue;
    }
  }
}

.value {
  font-weight: 700 !important;
}

.additional {
  margin-left: 4px;
  display: flex;
  align-items: center;

  &::before {
    content: '';
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 4px 6px;
    border-color: transparent transparent #1fac4e;
    display: block;
    margin-right: 4px;
  }
}
