.drawer {
  background-color: white;
}

.preloader {
  margin: 0 auto;
  overflow: unset !important;
}

.hidden {
  display: none;
}
