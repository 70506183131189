@value colorGrey100, colorDark100 from '~Styles/vars/colors.css';

.round {
  width: 18px;
  height: 18px;
  border-radius: 50%;
  border: 2px solid colorGrey100;
  position: relative;
  box-sizing: border-box;
  flex-shrink: 0;
  cursor: pointer;

  &.checked {
    border: 2px solid colorDark100;
    cursor: default;
  }
}

.radioCheckedItem {
  border-radius: 50%;
  width: 12px;
  height: 12px;
  background-color: colorDark100;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
