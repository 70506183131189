@value (colorSand40, colorGrey10, colorError100) from '~Styles/vars/colors.css';

.root {
  background-color: colorGrey10;
  padding: 12px 16px;
  border-radius: 8px;

  &:not(:last-child) {
    margin-bottom: 8px;
  }
  .title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    box-sizing: border-box;
    margin-bottom: 16px;

    .error {
      color: colorError100;
    }
  }

  .buttonContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
  }

  .progress {
    margin: 8px 0 16px;
  }

  .progressUnlimited {
    margin: 8px 0 16px;
    position: relative;
    border-radius: 6px;
    background-color: colorSand40;
    height: 16px;
    background-image: url('~files/images/general/progressLine.svg');
  }
}
