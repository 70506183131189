@value colorGrey100, colorGrey10, colorError100 from '~Styles/vars/colors.css';

.root {
  display: block;
}

.text {
  min-height: 78px;
}

div.typeSelect {
  max-width: 380px;
  flex: 1;
  height: 72px;
  max-height: 72px;
  padding-top: 24px;
}

.controls {
  display: flex;
  gap: 8px;
  margin-top: 16px;
}

.defaultType {
  color: colorGrey100;
}

.question {
  border-radius: 16px;
  padding: 8px 8px 8px 16px;
  background: colorGrey10;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.details {
  display: flex;
  gap: 16px;
}

.dropdownGroup {
  width: 100%;
}

.label {
  position: absolute;
  top: 1px;
  left: 16px;
  z-index: 2;
  font-size: 12px;
  color: colorGrey100;
  background-color: colorWhite;
  padding: 7px 0 0;
  width: calc(100% - 32px);
}

.selectedType {
  padding-top: 8px;
}

.finalText {
  max-width: 350px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.error {
  margin-top: 8px;
  color: colorError100;
}

.control {
  width: 72px;
}

.editControl {
  padding: 8px;
}

.editControls {
  display: flex;
}
