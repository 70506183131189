@value colorGrey50 from '~Styles/vars/colors.css';

.root {
  padding-top: 20px;
}

.title {
  margin-bottom: 32px;
}

.row {
  display: flex;
  margin-bottom: 32px;
}

.field {
  flex: 1;

  &:not(:last-child) {
    margin-right: 20px;
  }
}

.reason {
  display: flex;

  &:not(:empty) {
    margin-top: 8px;
  }
}

.absentIcon {
  size: 24px;
  border-radius: 50%;
  border: 1px solid colorGrey50;
}

.badgeDropdown {
  min-width: 100%;
}
