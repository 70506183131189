@value (colorSand40, colorWhite, colorGrey10) from '~Styles/vars/colors.css';
@value borderRadius8 from '~Styles/vars/query.css';

.root {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 20px;
}

.listItem {
  display: grid;
  grid-template-columns: 40px 1fr max-content;
  align-items: center;
  grid-gap: 16px;
}

.iconWrapper {
  border-radius: borderRadius8;
  background-color: colorGrey10;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
}

.value {
  font-weight: 500;
  font-size: 17px;
  line-height: 24px;
}

.msg {
  font-weight: 400;
  font-size: 15px;
  line-height: 24px;
}
