.root {
  display: block;
  padding: 20px;

  .title {
    flex-grow: 1;
    font-weight: 500;
  }
}

.header {
  display: flex;
}

.textarea {
  margin-top: 20px;
}

.icon {
  cursor: pointer;
}
