.root {

  .preloader {
    margin: 16px auto;
  }

  .headerControls {
    display: flex;
    gap: 8px;
    justify-content: space-between;
    align-items: center;

    .startHeaderControls {
      justify-self: flex-start;
    }

    .endHeaderControls {
      display: flex;
      gap: 8px;
    }
  }

  .textQuery {
    width: 254px;
  }

}
