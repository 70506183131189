.root {
  display: flex;
  align-items: center;
  width: 100%;
}

.ava {
  flex-shrink: 0;
}

.details {
  overflow: hidden;
  margin-left: 12px;
}

.reverse {
  flex-direction: row-reverse;

  .details:not(:empty) {
    margin-left: 0;
    margin-right: 12px;
  }
}

.avaWrap {
  position: relative;
}