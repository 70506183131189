@value colorDark100 from '~Styles/vars/colors.css';

.root {
  position: relative;

  .buttonsContainer {
    display: grid;

    .buttonPreset {
      box-sizing: border-box;
      width: 100%;
    }

    &.oneColumn {
      grid-template-columns: 1fr;
    }

    &.twoColumns {
      grid-template-columns: 1fr 1fr;

      .buttonPreset:nth-child(2n + 1) {
        padding-right: 8px;
      }

      .buttonPreset:nth-child(2n + 2) {
        padding-left: 8px;
      }
    }

    .alterButton {
      border: 1px solid transparent;
      justify-content: flex-start;
      box-sizing: border-box;
      height: 100%;

      &.selected {
        border-color: colorDark100;
      }
    }
  }
}
