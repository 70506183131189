.preloader {
  margin: 0 auto;
}

.title {
  margin-bottom: 40px;
}

.root {
  padding: 40px 0;
}