@value colorGrey10, colorPink100 from '~Styles/vars/colors.css';

.mobileContainer {
  position: fixed;
  z-index: 999;
  height: 100vh;
  width: 100vw;
  display: none;
  touch-action: none;
  background-color: white;

  .mobileHeader {
    width: 100%;
    height: 64px;
    background-color: white;
    display: flex;
    align-items: center;
    padding: 0 20px;

    .mobileLogo {
      height: 24px;
      width: 104px;
    }
  }

  .mobileContent {
    padding: 16px;
    display: flex;
    flex-direction: column;

    .mobileDescription {
      font-weight: 500;
      margin: 8px 0;
    }

    .mobileBtn {
      margin-top: 16px;

      div {
        text-align: center;
        width: 100%;
      }
    }
  }

  .textBlock {
    background-color: colorGrey10;
    padding: 8px 16px;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    margin-top: 24px;

    .textBlockTitle {
      color: colorPink100;
      font-weight: 500;
    }

    .marketBtnContainer {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 16px;

      .marketBtn {
        height: 58px;
  
        &.appleBtn {
          height: 40px;
        }
      }
    }
  }
  
}

:global .isMobile {
  :local .mobileContainer {
    display: block;
  }
}