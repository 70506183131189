.root {
  margin-left: 12px;
  display: flex;
  position: relative;
}

.tooltip {
  bottom: 110px;
  left: -152px;
  position: absolute;
  width: 386px;
  z-index: 1;
}

.adsWrapper {
  position: relative;
}

.adsManager {
  width: 84px;
  margin-right: 16px;
}