.types {
  flex-direction: column;
}

.typeItem {
  align-items: flex-start !important;
}

.strategyControl {
  min-width: 224px;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 8px;
  min-height: 240px;
  box-sizing: border-box;
  &.small {
    min-height: auto;
  }
}

.upgradeTooltip {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.goalItem {
  text-align: center;
  padding: 12px 20px;
}
