@value colorGrey100 from '~Styles/vars/colors.css';

.root {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.info {
  flex: 1;
  display: flex;
  align-items: center;
  gap: 16px;
}

.details {
  display: flex;
  align-items: center;
  width: 100%;
}

.detail {
  display: flex;
  gap: 4px;
  align-items: center;

  &:not(:last-child) {
    &::after {
      content: '';
      display: block;
      size: 2px;
      background: colorGrey100;
      border-radius: 50%;
      margin: 0 12px;
    }
  }
}

.names {
  flex: 1;
}

.campaignInfo {
  display: flex;
  align-items: center;
  &:not(:last-child) {
    margin-bottom: 4px;
  }
}

.campaignName {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
}

.paused {
  color: colorGrey100;
  margin-left: 20px;
}
