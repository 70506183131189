@value colorOcean40, colorGrey50, colorWhite, colorGrey10, colorOcean80, colorOcean100, colorGrey100 from '~Styles/vars/colors.css';

.root {
  display: block;
  width: 283px;
  border-radius: 16px;
  border: 1px solid colorGrey50;
  background-color: colorWhite;
  position: relative;
  box-sizing: border-box;
  &.accent {
    border: 2px solid colorOcean80;
  }
}

.badge {
  background-color: colorOcean40;
  position: absolute;
  left: 50%;
  top: 0;
  transform: translate(-50%, -50%);
  color: colorOcean100;
  display: flex;
  gap: 4px;
  border-radius: 16px;
  padding: 6px 8px;
  white-space: nowrap;
  align-items: center;
}

.avatar {
  background-color: colorGrey10;
}

.dots {
  margin-left: auto;
  margin-right: 0;
  box-sizing: border-box;
  padding-right: 0;
}

.dropdownMenu {
  width: 210px;
  right: -18px !important;
  padding: 8px;
}

.header {
  display: flex;
  gap: 16px;
  align-items: center;
  height: 64px;
  padding: 12px 16px;
  width: 100%;
  box-sizing: border-box;
}

.footer {
  display: flex;
  width: 100%;
  padding: 16px;
  gap: 8px;
  box-sizing: border-box;
}

.imgWrap {
  position: relative;
}

.img {
  background-color: colorGrey10;
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 284px;
}

.tag {
  padding: 6px 4px;
  background-color: colorGrey10;
  border-radius: 16px;
  display: flex;
  gap: 4px;
  justify-content: center;
  flex-grow: 1;
}

.tagWrap {
  flex-grow: 1;
}

.placement {
  color: colorGrey100;
}

.openSharing {
  position: absolute;
  bottom: 16px;
  left: 16px;
}
