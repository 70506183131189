@value colorGrey100, colorDark100, colorSuccessful100 from '~Styles/vars/colors.css';
.titleContainer {
  display: flex;
  gap: 6px;
}

.root {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.dirtyText {
  color: colorDark100;
}

.dirtyBorder {
  border: 1px solid colorDark100;
}

.input {
  border-radius: 8px !important;
}

.tooltipIcon {
  color: colorGrey100;
}

.error {
  position: absolute;
  top: calc(100% + 2px);
  left: 0;
  color: red;
  white-space: nowrap;
}

.accentTitle {
  color: colorSuccessful100;
}
