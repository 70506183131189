@value colorGrey100 from '~Styles/vars/colors.css';

.root {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.description {
  display: flex;
  justify-content: center;
  gap: 4px;
}

.tooltipIcon {
  color: colorGrey100;
}
