.drawer {
  background-color: white;
}

.drawerContainer {
  display: flex;
  height: 100%;
  width: 516px;
  flex-direction: column;
  justify-content: center;

  .drawerTitleText {
    margin-bottom: 16px;
  }
}

.drawerInputWrap {
  flex: 1;
  margin-top: 16px;
}

.btn {
  padding: 7px 16px;
}

.input {
  min-height: 144px;
}
