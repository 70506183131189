.root {
  @mixin center;

  size: 129px;
  position: relative;
  overflow: hidden;
  box-sizing: border-box;
  border-radius: 4px;
  background-size: cover;
  background-position: center;

  .videoPreview {

    position: absolute;
    size: 100%;
    @mixin center;
  }

  .preloaderWrap {
    position: absolute;
    size: 48px;
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 50px;
    @mixin center;
  }

  &::before {
    content: '';

    size: 100%;

    position: absolute;
    top: 0;
    left: 0;

    background-color: rgba(0, 0, 0, 0.8);
  }
}