@value colorGrey10 from '~Styles/vars/colors.css';

@keyframes loading {
  to {
    transform: translateX(100%);
  }
}

.root {
  position: relative;
  box-shadow: 0 1px 4px 0 #0000001a, 0 0 2px 0 #0000000d;
  border-radius: 4px;
  margin: 20px 10px 10px;
  width: calc(33.3% - 20px);

  @media (min-width: 1650px) {
    width: calc(25% - 20px);
  }

  @media (max-width: 1649px) {
    &:nth-child(4) {
      display: none;
    }
  }
}

.medias {
  display: flex;
  flex-wrap: wrap;
  font-size: 0;
  position: relative;

  @mixin skeletonLoading;
}

.medias::before {
  content: '';
  display: block;
  padding-top: 100%;
}

.info {
  padding: 12px 20px;
  display: flex;
  align-items: center;
}

.statistic {
  padding: 12px 20px;
  background: colorGrey10;
  display: flex;
}

.ava {
  size: 40px;
  border-radius: 50%;
  margin-right: 12px;
  @mixin skeletonLoading;
}

.line {
  border-radius: 99px;
  @mixin skeletonLoading;
}

.statItem {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
}
