.root {
  display: flex;
  flex-wrap: wrap;

  &:not(:empty) {
    margin-bottom: 24px;
    margin-top: 10px;
  }
}

.searchParam:not(:last-child) {
  margin: 0 8px 8px 0;
}

.clearBtn {
  height: 32px;
}
