@keyframes backgroundAnimate {
  from {
    background-position: 0;
  }

  to {
    background-position: 39.2px;
  }
}

.root {
  size: 100% 24px;
  background: #D9D8F2;
  border-radius: 28px;
  overflow: hidden;
  position: relative;
  display: none;
  margin-bottom: 20px;
}

.opened {
  display: block;
}

.progress {
  height: 100%;
  border-radius: 28px;
  background-color: #6258e2;
  background-image: url('~files/images/icons/progressLine.svg');
  animation: backgroundAnimate 1s infinite linear;
}

.text {
  position: absolute;
  top: 4px;
  left: 12px;
}

.close {
  position: absolute;
  top: 4px;
  right: 8px;
  cursor: pointer;
}

.completed {
  .progress {
    background-image: none;
    animation: none;
  }

  .close {
    display: block;
  }
}