@value colorGrey10 from '~Styles/vars/colors.css';

.root {
  flex: 1;
}

.dropdownContainer {
  max-height: 74px;
  padding: 0px;
  margin-bottom: 16px;
  border-radius: 16px;
}

.menuGroup {
  width: 100%;
}

.productListTitle {
  margin-bottom: 16px;
}

.productsContainer {
  overflow: auto;
  display: flex;
  flex-direction: column;
  gap: 8px;

  .productItem {
    display: flex;
    flex-direction: column;
    padding: 16px;
    background-color: colorGrey10;
    border-radius: 16px;
    gap: 16px;

    .productTitle {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 16px;
    }

    .productLogo {
      height: 44px;
      width: 44px;
    }
    .productTitle {
      flex: 1;
    }
  }

  .comissionInput {
    max-width: 200px;
  }
}

.label {
  padding: 16px;
}
