@value colorError100 from '~Styles/vars/colors.css';

.root {
  display: block;
}

.title {
  margin-bottom: 20px;
}

.descr {
  margin-bottom: 40px;
}

.disclaimer {
  margin-top: 4px;
  color: colorError100;
}

.drawer {
  @mixin drawer;
}

.drawerContainer {
  @mixin drawerContainer;
}

.content {
  flex: 1;
}

.input {
  height: 72px;
}
