@value colorYellow, colorConcrete, colorWhite, colorRed from '~Styles/vars/colors.css';

.root {
  padding: 20px 0;
  background-color: colorWhite;
  border-top: 1px solid colorConcrete;
}

.content {
  display: flex;
  justify-content: space-between;
  width: 720px;
  margin: 0 auto;
}

.button {
  flex: 1;

  &:not(:last-child) {
    margin-right: 20px;
  }
}

.buttonsInModal {
  width: 100%;
  margin-top: 40px;
  display: flex;
  flex-direction: column-reverse;

  .button {
    width: 100%;

    &:not(:last-child) {
      margin-right: 0;
      margin-top: 20px;
    }
  }
}

.errorContainer {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 20px;
  width: 100%;
}

.errorMsg {
  color: colorRed;
}

:global .isMobile {
  :local .root {
    position: fixed;
    bottom: 0;
    padding: 16px;
    width: 100%;
    box-sizing: border-box;
  }

  :local .button {
    &:not(:last-child) {
      margin-right: 16px;
    }
  }

  :local .content {
    width: 100%;
  }
}
