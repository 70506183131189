@value colorGrey100 from '~Styles/vars/colors.css';

.root {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: colorGrey100;
}
