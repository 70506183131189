@value colorNewPink from '~Styles/vars/colors.css';

.root {
  display: flex;
  align-items: center;
  size: 100% 52px;
  background: radial-gradient(274.02% 615.01% at 62.75% -62.22%, #f9e6f4 0%, #ffffff 100%);
  padding: 0 16px;
  box-sizing: border-box;
  position: relative;
  flex-shrink: 0;
}

.title {
  display: flex;
}

.spacer {
  flex: 1;
}

.joinBtn {
  height: 35px;
  box-sizing: border-box;
  font-weight: 500;
  color: colorNewPink;
  border-color: colorNewPink;
  margin-right: 15px;
}

.image {
  position: absolute;
  top: 0;
  right: 335px;
}
