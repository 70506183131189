@value colorGrey100 from '~Styles/vars/colors.css';

.dates {
  display: flex;
  margin-top: 20px;
  margin-bottom: 32px;
}

.date {
  flex: 1;
  position: relative;

  .deadlineDescription {
    margin-bottom: 8px;
    color: colorGrey100;
  }

  &:not(:first-child) {
    margin-left: 20px;
  }
}

.tutorial {
  position: absolute;
  z-index: 2;
}

.btn {
  margin-bottom: 20px;
}

.draftDate {
  cursor: pointer;
}
