@value colorGrey100, colorGrey10, colorGrey50, colorDark100, colorError100, colorPurple40, colorSuccess100, colorPink100, colorPink40 from '~Styles/vars/colors.css';

.templateDrawer {
  background-color: colorGrey10;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 16px;
  box-sizing: border-box;
  z-index: 3;
  min-height: fit-content;

  .previewContainer {
    width: 1240px;
    margin: 0 auto;
  }

  .mailPreviewContainer {
    width: 100%;
    margin: 0 auto;
    background-color: white;
    border-radius: 16px;
    padding: 0 16px;
    box-sizing: border-box;
  }

  .previewMailSecondaryText {
    color: colorGrey50;
  }

  .mailList {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-top: 16px;

    .mailPreview {
      border-top: 1px solid colorGrey50;
      padding-top: 16px;
    }
  }

  .userInfo {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 16px;
  }

  .titleContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;

    .backContainer {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 8px;
    }
  }

  .title {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;

    .titleSubjectText {
      color: colorGrey50;
    }
  }

  .selectorContainer {
    background-color: white;
    padding: 16px;
    border-radius: 16px;
  }
}