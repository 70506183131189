@value colorBase, colorWhite, colorGrey50, colorGrayDog, colorWetAsphalt, colorConcrete from '~Styles/vars/colors.css';

.root {
  position: relative;
}

.input {
  width: 100%;
  height: 40px;
  padding: 8px 12px;
  background: colorWhite;
  border: 1px solid colorGrey50;
  box-sizing: border-box;
  border-radius: 4px;
  color: colorWetAsphalt;
  font-size: 14px;

  &::placeholder {
    color: colorGrayDog;
  }

  &:disabled {
    background-color: colorConcrete;
    border: none;
    cursor: not-allowed;
  }

  &:focus {
    border-color: colorBase;
    box-shadow: 0 0 0 1px colorBase;
  }
}

.addition {
  top: 8px;
  right: 12px;
  position: absolute;
}