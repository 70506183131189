@value desktopMinWidth from '~Styles/vars/query.css';
@value colorGrey10 from '~Styles/vars/colors.css';

.root {
  flex: 1;
  display: flex;
  flex-direction: column;
  background: colorGrey10;
}

.header {
  @mixin innerContainer;
  display: flex;
  justify-content: space-between;
  position: relative;
  margin-top: 24px;
  margin-bottom: 16px;
}

.content {
  flex-grow: 1;
}

.contentWrap {
  @mixin innerContainer;
  padding-bottom: 64px;
}

.filtersContainer {
  width: 100%;
}

.filters {
  display: flex;
  gap: 8px;
  flex-shrink: 0;
}

.filtersWrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.sortPanel {
  margin-bottom: 20px;
}

.listHeader {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

.stub {
  flex-grow: 1;
}

.preloader {
  margin: 40px auto;
}
