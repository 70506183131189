@value colorGrey10, colorDark100 from '~Styles/vars/colors.css';

.title {
  margin-bottom: 16px;
}

.drawer {
  @mixin drawer;
}

.drawerContainer {
  @mixin drawerContainer;
  justify-content: flex-start;
}

.list {
  display: flex;
  flex-direction: column;
  gap: 16px;
  overflow: auto;
  margin-bottom: 24px;
  flex: 1;
}

.item {
  padding: 16px;
  box-sizing: border-box;
  width: 100%;
  display: flex;
  background: colorGrey10;
  border-radius: 16px;
  align-items: center;
  gap: 16px;
  border: 1px solid colorGrey10;
  cursor: pointer;
}

.active {
  border-color: colorDark100;
  cursor: default;
}

.username {
  flex: 1;
}

.input {
  height: 72px;
  margin-bottom: 16px;
}

.emptyTitle {
  flex: 1;
}
