@value colorWhite, colorDark40 from '~Styles/vars/colors.css';

.title {
  margin-bottom: 20px;
  font-weight: 500 !important;
}

.creative {
  > div {
    margin: 0 auto;
  }
}

.nextArrow,
.prevArrow {
  top: 50%;
  position: absolute;
  z-index: 1;
  size: 40px;
  cursor: pointer;
  color: colorDark40;
  border-radius: 50%;
  background: colorWhite;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  @mixin center;
}

.nextArrow {
  right: 0;
}
