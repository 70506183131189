@value colorGrey10 from '~Styles/vars/colors.css';
@value desktopMinWidth from '~Styles/vars/query.css';

.root {
  width: 100%;
  background: colorGrey10;
  flex: 1;
  display: flex;
  flex-direction: row;
  gap: 16px;
  padding: 16px;
  box-sizing: border-box;
}

.container {
  display: flex;
  flex-wrap: wrap;
  flex: 1;
  box-sizing: border-box;
  justify-content: center;
}

.invite {
  margin-top: 24px;
}

.outreach {
  min-width: calc(desktopMinWidth - 16px);
}
