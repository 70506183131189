@value subheaderHeight from '~Styles/vars/size.css';

.root {
  position: absolute;
  top: 0;
  left: 0;
  size: 100%;
  display: flex;
  flex-direction: column;
}

.content {
  display: flex;
}
